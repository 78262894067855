import React from 'react';
import { Row } from 'antd';
import CommonRecover from './CommonRecover';
import './Mobile.less';

export default ({ recoveryText, buttonText, spamText, props }: any) => {

  return (
    <>
      <Row className='recover-container'>
        <CommonRecover recoveryText={recoveryText} buttonText={buttonText} spamText={spamText} props={props} />
      </Row>
    </>
  )
}

import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Avatar as AntAvatar, Divider, Rate, Typography} from 'antd';
import styled from '@emotion/styled';
import {getLessonTitleToDisplay} from "../../components/static";
import {hourlyRateCalculator} from "../common/utils";

const {Paragraph} = Typography;
const Avatar = styled(AntAvatar)`
  background: hsl(0, 66%, 66%);
`


export default ({id, name, avatar, distance, rate, lessonTitle, title, rating, feedback, biography}: any) => {

    let formattedRate = rate ? hourlyRateCalculator(rate) : 0;
    return (
        <>
            <Col>
                <Row className="tutor-container-height"/>
                <Row className="tutors-outer-container">
                    <Col span={24}>


                        <Link to={`/tutors/${id}`}>
                            <Col span={24} className="flex avatar-div-mobile ">
                                <div className="avatar-specs">
                                    <Avatar shape='circle' size={64}
                                            src={avatar?.url}>{name.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}</Avatar>
                                    {distance != null &&
                                    <div className="flex">
                                        <img src="/icons/explore/small-location-icon.svg"
                                             className="small-location-icon"/>
                                        <div className=' km-font font-size-icon'> {Number(distance).toFixed(1)} km</div>
                                    </div>
                                    }

                                </div>
                                <div className="full-width">
                                    <div className="tutor-name">
                                        {name.charAt(0).toUpperCase() + name.slice(1)}
                                    </div>
                                    {title ? (
                                            <div className="tutor-detail">
                                                {title}
                                            </div>
                                        ) :
                                        (
                                            <>
                                                {biography ? (
                                                    <Paragraph className="tutor-detail">
                                                        {biography.substring(0, 72)}&emsp;<span
                                                    ><u>read more</u></span> . . .
                                                    </Paragraph>
                                                ) : null
                                                }
                                            </>
                                        )}

                                </div>
                            </Col>
                            <Col span={24} className="divider-margin">
                                <Row className="row-height"/>
                                <Divider type="horizontal" className="no-margin"/>
                                <Row className="row-height"/>
                            </Col>
                            <Row className="flex">
                                <Col span={9} className="rating-margin">
                                    <div className="per-hour-rate">${rate}/hour <span className={"serve-fee-text"}>{' '}+Pocketnote Fee</span>
                                    </div>
                                    <div className="flex">
                                        <Rate className="star-space" disabled value={rating}/>
                                        <div className="flex out-of-padding-mobile rate-space">
                                            <div className='total-rating'>{rating}.0</div>
                                            <div className="number-rating">({feedback?.length})</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={13} className="work-hour-div">
                                    <div className="flex">
                                        <img src="/icons/explore/laptop-icon.svg" className="laptop-align-3"/>
                                        <div className="offer-text offer-align-5">Offers <span
                                            className="lessons-text">{getLessonTitleToDisplay(lessonTitle)}</span>
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                        </Link>
                    </Col>
                </Row>
            </Col>
        </>
    )
}

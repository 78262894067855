import React, {useState} from 'react';
import {Button, Input, Typography} from "antd";
import {Field, Form, Formik} from "formik";
import {useMutation} from "@apollo/client";
import gql from "graphql-tag";
import {Header} from "../components";
import InputWithTitle from "../../components/InputWithTitle";

const {Text} = Typography;

export default () => {
    const [ndisHelpForm] = useMutation(gql`
        mutation ndisHelpForm($input: NdisFormInput!) {
            ndisHelpForm(input: $input)
        }
    `);

    const [submittedForm, setSubmittedForm] = useState(false);
    const [submittingForm, setSubmittingForm] = useState(false);

    const initValues = {
        type: "SELF_MANAGED",
        firstName: "",
        lastName: "",
        ndisParticipantName: "",
        ndisParticipantEmail: "",
        ndisParticipantNumber: "",
        phone: "",
        postCode: "",
        streetAddress: "",
        city: "",
        state: "",
        dob: "",
        onlineTutoring: "yes",
        supportBudget: "CORE",
        additionalComments: "",
    }

    // const initValues = {
    //     type: "SELF_MANAGED",
    //     firstName: "firstName",
    //     lastName: "lastName",
    //     ndisParticipantName: "ndisParticipantName",
    //     ndisParticipantEmail: "ndisParticipantEmail@a.com",
    //     ndisParticipantNumber: "9983929329",
    //     phone: "9983929329",
    //     postCode: "1232",
    //     streetAddress: "streetAddress",
    //     city: "city",
    //     state: "state",
    //     onlineTutoring: "yes",
    //     subject: "subject",
    //     yearLevel: "yearLevel",
    //     supportBudget: "supportBudget",
    //     additionalComments: "additionalComments",
    // }

    return <div>
        {submittedForm && <>
            <Header>Thank you!</Header>
            <p>
                Someone will be in touch soon.
            </p>
        </>}
        {!submittedForm &&
            <Formik initialValues={initValues} onSubmit={async (values) => {
                setSubmittingForm(true);
                try {
                    await ndisHelpForm({
                        variables: {
                            input: values,

                        },
                    });
                    setSubmittedForm(true);
                } catch (e) {
                }
                setSubmittingForm(false);
                console.log(values);
            }}>
                <Form>
                    <div style={{position: "relative"}} className='align-item-center w-full align-content-center'>
                        <div
                            className='ndis-tutor-form lg:px-24 px-4 background-primary border-radius-13 w-full'>
                            <div className='align-center become-tutor-form'>
                                Don't Wait Any Longer!
                            </div>
                            {/*<div className="lg:px-44 text-sm align-center mt-2 text-white">*/}
                            {/*    Sign up today and experience the Pocketnote difference and connect your child with a tutor*/}
                            {/*    and mentor who will work alongside them on their learning and life skill development journey.</div>*/}
                            <div className={"md:grid md:grid-cols-1 md:grid-cols-2 md:gap-4 w-full lg:px-64 py-10"}>
                                <div className="col-span-1">
                                    <Field as={InputWithTitle} color="white" title={"First Name"} name="firstName" required
                                           pattern=".+"/>
                                </div>
                                <div className="col-span-1">
                                    <Field as={InputWithTitle} color="white" title={"Last Name"} name="lastName" required pattern=".+"/>
                                </div>
                                <div className="col-span-1">
                                    <Field as={InputWithTitle} color="white" title={"NDIS Participant Name"} name="ndisParticipantName"
                                           required pattern=".+"/>
                                </div>
                                <div className="col-span-1">
                                    <Field as={InputWithTitle} color="white" title={"NDIS Participant Email"} name="ndisParticipantEmail"
                                           required type="email"/>
                                </div>
                                <div className="col-span-1">
                                    <Field as={InputWithTitle} color="white" title={"NDIS Participant Number"}
                                           name="ndisParticipantNumber" required pattern="[0-9]+"/>
                                </div>
                                <div className='col-span-1'>
                                    <Field as={InputWithTitle} type="tel" color="white" title="Phone Number" name="phone"
                                           pattern="[0-9]{10}"
                                           maxLength={10}/>
                                </div>
                                <div className='col-span-1'>
                                    <Field as={InputWithTitle} color="white" title="Postal Code" type={"tel"} name="postCode" required
                                           pattern="^(\d{4})$" maxLength={4}/>
                                </div>
                                <div className='col-span-1'>
                                    <Field as={InputWithTitle} color="white" title="Street Address" name="streetAddress" required
                                           pattern=".+"/>
                                </div>

                                <div className='col-span-1'>
                                    <Field as={InputWithTitle} color="white" title="City" name="city" required pattern=".+"/>
                                </div>

                                <div className='col-span-1'>
                                    <Field as={InputWithTitle} color="white" title="State/Region" name="state" required pattern=".+"/>
                                </div>

                                <div className='col-span-1'>
                                    <Field as={InputWithTitle} color="white" title="Date of Birth (dd-mm-yyyy)" name="dob" required
                                           pattern="^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$"/>
                                </div>


                                <div>
                                    <Text className={"text-sm select-form-text"}>
                                        Will online help be required?*
                                    </Text>
                                    <Field className={"pl-1 select-form-dropdown"} as={"select"} name="onlineTutoring"
                                           required
                                    >
                                        <option value={"yes"}>Yes</option>
                                        <option value={"no"}>No</option>
                                    </Field>
                                </div>

                                <div>
                                    <Text className={"text-sm select-form-text"}>
                                        Your status*
                                    </Text>
                                    <Field className={"pl-1 select-form-dropdown"} as={"select"} name="type"
                                           required
                                    >
                                        <option value={"SELF_MANAGED"}>Self-Managed</option>
                                        <option value={"PLAN_MANAGED"}>Plan-Managed</option>
                                        <option value={"AGENCY_NDIA"}>Agency/NDIA-Managed</option>
                                    </Field>
                                </div>

                                <div>
                                    <Text className={"text-sm select-form-text"}>
                                        What support budget do you have?
                                    </Text>
                                    <Field className={"pl-1 select-form-dropdown"} as={"select"} name="supportBudget"
                                           required
                                    >
                                        <option value={"CORE"}>Core Supports</option>
                                        <option value={"CAPACITY_BUILDING"}>Capacity Building Supports</option>
                                    </Field>
                                </div>

                                <div className='col-span-1'>
                                    <Field as={InputWithTitle} color="white" title="Additional comments" name="additionalComments"
                                           pattern=".*"/>
                                </div>

                                <div className='col-span-2'>
                                    <Button htmlType='submit' className='box-shadow btn-secondary w-full margin-top-10'
                                            size='large'>
                                        <span
                                            className="continue-btn-text">{submittingForm ? "submitting..." : "Submit"}</span></Button>
                                </div>
                            </div>
                        </div>
                        <div
                            className={"hidden lg:block"}
                            style={{
                                backgroundImage: `url(/icons/ndis-landing/form-character-1.png)`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                minWidth: 300,
                                height: 300,
                                position: "absolute",
                                left: "-1.3em",
                                bottom: "-2em",

                            }}
                        ></div>
                        <div
                            className={"hidden lg:block"}
                            style={{
                                backgroundImage: `url(/icons/ndis-landing/form-character-2.png)`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                minWidth: 300,
                                height: 300,
                                position: "absolute",
                                right: "1.3em",
                            }}
                        ></div>
                        <div
                            className={"hidden lg:block"}
                            style={{
                                backgroundImage: `url(/icons/ndis-landing/form-image-paper.png)`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                minWidth: 150,
                                height: 300,
                                position: "absolute",
                                left: "66.8em",
                                top: "4.6em",

                            }}
                        ></div>
                        <div
                            className={"hidden lg:block"}
                            style={{
                                backgroundImage: `url(/icons/ndis-landing/form-image-pens.png)`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                minWidth: 100,
                                height: 300,
                                position: "absolute",
                                right: "66.8em",
                                top: "9.6em",

                            }}
                        ></div>
                    </div>
                </Form>
            </Formik>}



    </div>
};

import React from 'react';
import { Col, Row } from 'antd';
import { Typography } from 'antd';
import './Question.less';
const { Paragraph } = Typography;

export default ({ question, answer }: any) => {
  return (
    <>
      <Row className="margin-14">
        <Col span={22}>
          <Paragraph className="question">{question}</Paragraph>
        </Col>
        <Col span={22}>
          <>
            {
              Array.isArray(answer) ? answer.map(item => <div className="answer" dangerouslySetInnerHTML={{ __html: item }} />) : <div className="answer" dangerouslySetInnerHTML={{ __html: answer }} />
            }
          </>
        </Col>
      </Row>
      <Row className="row-30"></Row>
    </>
  );
}

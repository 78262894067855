import React from 'react';
import Desktop from './Desktop';

const intro = `The Company provides the Pocketnote online platform for finding suitable local tutors, and arranging tutorial sessions. Use of Pocketnote is subject to these Terms of Service.`
const headLine = `The following terms are used regularly throughout these Terms of Service and have a particular meaning:`
const termsOfServices = [
  `ACN means Australian Company Number.`,
  `Agreement means the agreement formed between the Users and the Company under, and on the terms of, these Terms of Service.`,
  `Booking means a booking for a Session.`,
  `Business Day means a day (other than a Saturday, Sunday or public holiday) on which banks are open for general banking business in Sydney, Australia.`,
  `Company means Pocketnote Pty Ltd ACN 637 568 677.`,
  `Corporations Act means the Corporations Act 2001 (Cth).`,
  `Fee means a fee charged by the Company for use of Pocketnote.`,
  `Equipment & Materials means any equipment and materials (such as books, videos or other learning materials etc.) necessary for a Session.`,
  `GST has the meaning given by the A New Tax System (Goods and Services Tax) Act 1999 (Cth).`,
  `Intellectual Property means all copyright, patents, inventions, trade secrets, know-how, product formulations, designs, circuit layouts, databases, registered or unregistered trademarks, brand names, business names, domain names and other forms of intellectual property.`,
  `Intellectual Property Rights means, for the duration of the rights in any part of the world, any industrial or intellectual property rights, whether registrable or not, including in respect of Intellectual Property, applications for the registration of any Intellectual Property and any improvements, enhancements or modifications to any Intellectual Property registrations.`,
  `Notes means the study notes created by the Tutor and available for purchase via Pocketnote.`,
  `Parent means a user that uses the features of Pocketnote associated with a registered Parent account, namely managing an account for multiple Students.`,
  ` Pocketnote means:`,
  [
    `The Pocketnote digital platform accessible from www.pocketnote.com.au and/or`,
    `Any Pocketnote mobile application.`
  ],
  `Privacy Act means the Privacy Act 1988 (Cth).`,
  `Privacy Policy means the Company’s privacy policy as updated from time-to-time, which can be found at www.Pocketnote.com.au/privacy.`,
  `Sale means a sale for Notes via Pocketnote as between the Tutor and a Student.`,
  `Sale Price means the price payable by Students to purchase the Notes, as set by the Tutor in their discretion.`,
  `Session means a tutorial or coaching session provided by a Tutor to a Student, as arranged via Pocketnote.`,
  `Student means a user that uses the features of Pocketnote associated with a registered Student account, and includes a Parent where implied by context.`,
  `Tax Invoice has the meaning given by the A New Tax System (Goods and Services Tax) Act 1999 (Cth).`,
  `Terms of Service means the terms and conditions of using Pocketnote, as updated from time-to-time, which can be found at www.Pocketnote.com.au/terms..`,
  `Session Fee means the fee paid by a Student for a Session.`,
  `Tutor means a suitably qualified tutor, coach, or instructor that that uses the features of Pocketnote associated with a registered Tutor account.`,
  `Tutor Agreement means a separate agreement between the Company and a Tutor governing the Tutors access and use of Pocketnote.`,
  `Tutor Profile means the profile of a Tutor within Pocketnote.`,
  `User means any registered Tutor, or Student or any other person that uses Pocketnote.`,
  `User Content means images, information, documents or other data that is uploaded or input into Pocketnote by the User or that forms part of the User’s Intellectual Property.`,
]
const general = [
  {
    index: '1.1',
    description: `General`
  },
  [
    {
      index: '(a)',
      description: `To use the full set of Pocketnote's features, the User must log into Pocketnote and have set up their account.`
    },
    {
      index: '(b)',
      description: `The normal day-to-day operations of our business.`
    },
    {
      index: '(c)',
      description: `The User agrees that all use of Pocketnote is subject to these Terms of Service.`
    },
    {
      index: '(d)',
      description: `The Student shall be responsible for paying the Session Fees for each Session via Pocketnote.`
    },
    {
      index: '(e)',
      description: `The Company may suspend the account or restrict the access of any User that breaches the terms of this Agreement.`
    },
    {
      index: '(f)',
      description: `Users under the age of 18 years must not use Pocketnote without their parent or legal guardian's consent.`
    }
  ],
]

const licence = [
  {
    index: '2.1',
    description: `Licence`
  },
  [
    {
      index: '(a)',
      description: `The Company grants the Tutor, a licence to access Pocketnote subject to the terms of this Agreement and any Tutor Agreement.`
    },
    {
      index: '(b)',
      description: `The Company grants each Student a licence subject to the terms of this Agreement.`
    }
  ],
  {
    index: '2.2',
    description: `User Access`
  },
  [
    {
      index: '(a)',
      description: `To use Pocketnote, the User agrees to the terms of:`
    },
    [
      {
        index: '(i)',
        description: `This Agreement;`
      },
      {
        index: '(ii)',
        description: `The Privacy Policy; and`
      },
      {
        index: '(iii)',
        description: ` Any applicable Tutor Agreement.`
      },
    ],
    {
      index: '(b)',
      description: `The User must immediately cease to use Pocketnote if the User can no longer agree or adhere to clause (a).`
    }
  ],
  {
    index: '2.3',
    description: `Using Pocketnote`
  },
  [
    {
      index: '(a)',
      description: `Pocketnote shall enable a Student to:`
    },
    [
      {
        index: '(i)',
        description: ` Browse and search for Tutors;`
      },
      {
        index: '(ii)',
        description: `View Tutor Profiles;`
      },
      {
        index: '(iii)',
        description: `Arrange Sessions with Tutors;`
      },
      {
        index: '(iv)',
        description: ` Pay Session Fees for Sessions; and`
      },
      {
        index: '(v)',
        description: `Such other features the Company may enable for the Student from time-to-time.`
      },
    ],
    {
      index: '(b)',
      description: `Pocketnote shall enable a Tutor to:`
    },
    [
      {
        index: '(i)',
        description: `Create and manage a Tutor Profile;`
      },
      {
        index: '(ii)',
        description: `Arrange Sessions with Students; and`
      },
      {
        index: '(iii)',
        description: `Accept payment of Session Fees from Students;`
      },
      {
        index: '(iv)',
        description: `Such other features the Company may enable for the Tutor from time-to-time.`
      },
    ],
    {
      index: '(c)',
      description: `For the avoidance of doubt, the Company does not provide education services or advice, and owes no User a duty of care for such.`
    },
    {
      index: '(d)',
      description: `Any dispute between Users relative to their use of Pocketnote, must be dealt with by the relevant Tutor. In no event will the Company perform a dispute resolution service in the event of a dispute between Users.`
    },
  ],
  {
    index: '2.4',
    description: `Tutor Profiles`
  },
  [
    {
      index: '(a)',
      description: `Each Tutor is responsible for ensuring the accuracy of all information described in a Tutor Profile.`
    },
    {
      index: '(b)',
      description: `The Company makes no representation as to the accuracy of any information contained in a Tutor Profile, including with regard to the character or credentials of a Tutor.`
    },
    {
      index: '(c)',
      description: `It is each Tutor's responsibility to ensure they hold any relevant qualifications necessary to make use of Pocketnote.`
    },
  ],
  {
    index: '2.5',
    description: `Conduct. The User acknowledges and accepts that:`
  },
  [
    {
      index: '(a)',
      description: ` The Company accepts no responsibility for the conduct of any User of Pocketnote.`
    },
    {
      index: '(b)',
      description: `The Company accepts no responsibility for any interaction between Users, whether that interaction occurs via Pocketnote or not (including a personal meeting in relation to a Booking).`
    },
    {
      index: '(c)',
      description: `The Company makes no warranty or representation as to the accuracy of any information provided by any User.`
    },
    {
      index: '(d)',
      description: `The Company makes no warranty as to the character or credentials of any User.`
    },
  ],
  {
    index: '2.6',
    description: `Lodging a Complaint.`
  },
  [
    {
      index: '(a)',
      description: `If a User believes that another user's behaviour is threatening, discriminatory, or deliberately offensive, the User may lodge a complaint to the Company via email at support@pocketnote.com.au.`
    },
    {
      index: '(b)',
      description: `The Company may suspend or delete the account of any User that Pocketnote determines conducts itself inappropriately.`
    },
    {
      index: '(c)',
      description: ` The Company will advise the User of a suspended or deleted account of the decision to do so, but is under no obligation to identify a complainant.`
    },
    {
      index: '(d)',
      description: `The Company's decision whether or not to suspend or remove a User account is at its absolute discretion. The Company's decision shall be final and not subject to review.`
    },
  ],
  {
    index: '2.7',
    description: `Ratings`
  },
  [
    {
      index: '(a)',
      description: `Users may use the rating system inside Pocketnote to rate and provide feedback on Tutor Profiles.`
    },
    {
      index: '(b)',
      description: `The User agrees to provide accurate information and to not unreasonably give another user a low rating.`
    },
  ]
]

const sessions = [
  {
    index: '3.1',
    description: `Booking Sessions`
  },
  [
    {
      index: '(a)',
      description: `A booking for a Session is not confirmed until the Student completes payment via Pocketnote (Booking).`
    },
    {
      index: '(b)',
      description: `The Tutor shall be responsible for setting the pricing of Sessions within Pocketnote, including the pricing of packages of Sessions.`
    },
    {
      index: '(c)',
      description: `The Tutor shall be responsible for setting the pricing of Sessions within Pocketnote, including the pricing of packages of Sessions.`
    },
    {
      index: '(d)',
      description: `The Tutor shall be responsible for setting the pricing of Sessions within Pocketnote, including the pricing of packages of Sessions.`
    },
    {
      index: '(f)',
      description: `The Tutor shall be responsible for setting the pricing of Sessions within Pocketnote, including the pricing of packages of Sessions.`
    },
    {
      index: '(g)',
      description: `The Tutor shall be responsible for setting the pricing of Sessions within Pocketnote, including the pricing of packages of Sessions.`
    },
    [
      {
        index: '(i)',
        description: `The Student with a Tax Invoice for its payment; and`
      },
      {
        index: '(ii)',
        description: `The Tutor with a recipient created Tax Invoice for each payment it makes to the Tutor.`
      },
    ],
    {
      index: '(h)',
      description: ` Once a Booking has been confirmed Pocketnote will notify both the Student and Tutor of the confirmed time of the Booking.`
    },
  ],
  {
    index: '3.2',
    description: `Conducting Sessions`
  },
  [
    {
      index: '(a)',
      description: `The Tutor and Student are responsible for arranging the location of each Session.`
    },
    {
      index: '(b)',
      description: ` Where the Session Fee includes the provision of Equipment & Materials, the Tutor will ensure they have those with them. Otherwise, the Student must ensure they have all necessary Equipment & Materials for the Tutor to conduct the Session.`
    },
    {
      index: '(c)',
      description: `The Company makes no representation that a Student will achieve any particular outcome as the result of attending a Session.`
    },
    {
      index: '(d)',
      description: `The Student must provide a rating of the Tutor following each Session. The Student may not be able to book a subsequent Session until the Student has Provided a rating for the previous Session.`
    },
  ]
]
const notesContent = [

    {
    index: '4.1',
    description: `To access Notes, the Student may pay the Sale Price in full via Pocketnote.`
  },
    {
    index: '4.2',
    description: `Once payment has been successfully made via Pocketnote, the Notes will be immediately available via the Student’s account.`
  },
    {
    index: '4.3',
    description: `The Student acknowledges and accepts that the Tutor will set the Sale Price for their Notes without input of the Company and some Notes will be more expensive than others.`
  },
    {
    index: '4.4',
    description: `The Student must independently review and determine that the Notes are appropriate for purchase to support their personal study efforts prior to completing a Sale. There will be no refund or return for a change of mind.`
  },
    {
    index: '4.5',
    description: `The Student shall only use the Notes for their own independent private use and must not copy, duplicate, disseminate, sell, commercialise or make publicly available the Notes.`
  },
    {
    index: '4.6',
    description: `The Student acknowledges and accepts that each Sale forms a direct transaction with the relevant Tutor and the Company merely provides the digital platform to facilitate the Sale, and is not responsible for the validity, accuracy or completeness of the Notes.`
  },
    {
    index: '4.7',
    description: `The Company shall not be liable in any way for any loss or damage suffered by them in connection with their use of the Notes.`
  },
    {
    index: '4.8',
    description: `The Student shall be encouraged to provide any feedback on the Notes received from a completed Sale, and in particular any errors or false statements within the Notes, or alleged infringements of intellectual property rights, in which case the Company agrees to investigate the Notes in question.`
  },
]
const userContent = [
  {
    index: '5.1',
    description: `The User acknowledges and agrees that:`
  },
  [
    {
      index: '(a)',
      description: `Pocketnote may enable the User to create User Content, but that by doing so the User shall not acquire an interest to any Intellectual Property owned by the Company which may exist in Pocketnote.`
    },
    {
      index: '(b)',
      description: `User Content is the sole responsibility of the person that provided the User Content to Pocketnote.`
    },
    {
      index: '(c)',
      description: `The User indemnifies the Company for any User Content that is illegal, offensive, indecent or objectionable that the User makes available using Pocketnote.`
    },
    {
      index: '(d)',
      description: `The Company may suspend accessibility to User Content via Pocketnote that the Company determines is illegal, offensive, indecent or objectionable in its sole discretion`
    },
    {
      index: '(e)',
      description: ` To the extent permitted by law, under no circumstances will the Company be liable in any way for User Content.`
    },
    {
      index: '(f)',
      description: `The User warrants that it has all necessary Intellectual Property Rights to use User Content, and shall indemnify the Company for any infringement the User commits of third-party Intellectual Property Rights by using User Content on Pocketnote.`
    },
    {
      index: '(g)',
      description: ` In order to provide the services afforded by Pocketnote, where the User Content includes the User's brand, logo or other intellectual property, it grants the Company a worldwide, revocable license to use the User Content, for the term of this Agreement.`
    },
    {
      index: '(h)',
      description: `The Company may delete User Content on termination of this Agreement.`
    },
  ]
]

const refunds = [
  {
    index: '6.1',
    description: `The Student shall pay the Company the Session Fee for a Session conducted by a Tutor. The Company will issue a Tax Invoice to the Student for the Session Fee via Pocketnote and/or email. All payments for a Session must be made through Pocketnote (or at the Company's discretion, directly to the Company).`
  },
  {
    index: '6.2',
    description: `Payment of the Session Fee will be charged to the Student's credit card when the Student confirms the Booking.`
  },
  {
    index: '6.3',
    description: `Payment of Session Fees shall be made by the Company to Tutors in accordance with the Tutor Agreement.`
  },
  {
    index: '6.4',
    description: `Pocketnote will provide a refund or credit of some or all of the Session Fee (as appropriate) where, by no fault of the Student, the Session is not performed or cannot be satisfactorily completed. Otherwise, not other refunds are provided except where required under law.`
  },

]
const contracting = [
  {
    index: '7.1',
    description: `Without the written consent of the Company, the Tutor shall not directly contract to provide services to a Student outside of Pocketnote (and thereby exclude the Company) within 12 months of the last day that the Tutor conducted a Session with the relevant Student. Where such a direct relationship is formed without the consent of the Company, the Student agrees to pay to the Company 60% of the Session Fees that would have applied to had those services been undertaken as Sessions.`
  },
  {
    index: '7.2',
    description: `The Company will issue a Tax Invoice to the Student for all such fees payable to the Company under clause 6.1.`
  },
]

const relationship = [
  {
    index: '8.1',
    description: `The parties agree that the Tutor is an independent Tutor to the Student. Each Tutor and Student is responsible to each other with respect to each Session. The parties acknowledge and agree that:`
  },
  [
    {
      index: '(a)',
      description: `The Tutor is not an employee or sub-contractor of the Company, and the Tutor does not provide any Sessions to Students on behalf of the Company;`
    },
    {
      index: '(b)',
      description: `The Company is only the Tutor's agent for the purpose of arranging Sessions and receiving payments from the Student;`
    },
    {
      index: '(c)',
      description: `The Tutor is not an employee of the Student or the Company; and`
    },
    {
      index: '(d)',
      description: `The Company is not an agent of the Student.`
    },
  ]
]

const conditions = [
  {
    index: '9.1',
    description: `Licence`
  },
  [
    {
      index: '(a)',
      description: ` By accepting the terms and conditions of this Agreement, the User is granted a limited, non-exclusive and revocable licence to access and use Pocketnote for the duration of this Agreement, in accordance with the terms and conditions of this Agreement.`
    },
    {
      index: '(b)',
      description: `The Company may issue the licence to the User on the further terms or limitations as it sees fit.`
    },
    {
      index: '(c)',
      description: `The Company may revoke or suspend the User's licence(s) at any time:`
    },
    [
      {
        index: 'i',
        description: `For breach of the terms and conditions in this Agreement by the User; or`
      },
      {
        index: 'ii',
        description: `If the Tutor Agreement is terminated for any reason (Tutors only).`
      },
    ]
  ],
  {
    index: '9.2',
    description: `Modification of Terms`
  },
  [
    {
      index: '(a)',
      description: `The terms of this Agreement may be updated by the Company from time-to-time.`
    },
    {
      index: '(b)',
      description: `Where the Company modifies the terms, it will provide the User with written notice, and the User will be required to accept the modified terms in order to continue using Pocketnote.`
    },
  ],
  {
    index: '9.3',
    description: ` Software-as-a-Service`
  },
  [
    {
      index: '(a)',
      description: `The User agrees and accepts that Pocketnote is:`
    },
    [
      {
        index: 'i',
        description: `Hosted by the Company and shall only be installed, accessed and maintained by the Company, accessed using the internet or other connection to the Company servers and is not available 'locally' from the User's systems; and`
      },
      {
        index: 'ii',
        description: ` Managed and supported exclusively by the Company from the Company servers and that no 'back-end' access to Pocketnote is available to the User unless expressly agreed in writing.`
      },
    ],
    {
      index: '(b)',
      description: `As a hosted and managed service, the Company reserves the right to upgrade, maintain, tune, backup, amend, add or remove features, redesign, improve or otherwise alter Pocketnote.`
    },
  ],
  {
    index: '9.4',
    description: `Support`
  },
  [
    {
      index: '(a)',
      description: `The Company provides user support for Pocketnote via the email address support@pocketnote.com.au.`
    },
    {
      index: '(b)',
      description: `The Company shall endeavour to respond to all support requests within 1 Business Day.`
    },
  ],
  {
    index: '9.5',
    description: `Use & Availability`
  },
  [
    {
      index: '(a)',
      description: `The User agrees that it shall only use Pocketnote for legal purposes and shall not use it to engage in any conduct that is unlawful, immoral, threatening, abusive or in a way that is deemed unreasonable by the Company in its discretion.`
    },
    {
      index: '(b)',
      description: ` The User is solely responsible for the security of its username and password for access to Pocketnote. The User shall notify the Company as soon as it becomes aware of any unauthorised access of its Pocketnote account.`
    },
    {
      index: '(c)',
      description: `The User agrees that the Company shall provide access to Pocketnote to the best of its abilities, however:`
    },
    [
      {
        index: 'i',
        description: `Access to Pocketnote may be prevented by issues outside of its control; and`
      },
      {
        index: 'ii',
        description: `It accepts no responsibility for ongoing access to Pocketnote.`
      },
    ]
  ],
  {
    index: '9.6',
    description: `Privacy`
  },
  [
    {
      index: '(a)',
      description: `The Company maintains the Privacy Policy in compliance with the provisions of the Privacy Act for data that it collects about the User.`
    },
    {
      index: '(b)',
      description: `The Privacy Policy does not apply to how the Tutor handles personal information. 'If necessary under the Privacy Act, it is the Tutor's responsibility to meet the obligations of the Privacy Act by implementing a privacy policy in accordance with law.`
    },
    {
      index: '(c)',
      description: `Pocketnote may use cookies (a small electronic tracking code) to improve a User's experience while browsing, while also sending browsing information back to the Company. The User may manage how it handles cookies in its own browser settings.`
    },
  ],
  {
    index: '9.7',
    description: `Data`
  },
  [
    {
      index: '(a)',
      description: `Security.' The Company takes the security of Pocketnote and the privacy of its Users very seriously.' The User agrees that the User shall not do anything to prejudice the security or privacy of the Company's systems or the information on them.`
    },
    {
      index: '(b)',
      description: ` Transmission.' The Company shall do all things reasonable to ensure that the transmission of data occurs according to accepted industry standards.' It is up to the User to ensure that any transmission standards meet the User's operating and legal requirements.`
    },
    {
      index: '(c)',
      description: `Storage. The Company stores data on servers based in New Zealand according to accepted industry standards. If the User requires its User Data to be stored in a different location, the Company may charge the User a fee to do so.`
    },
    {
      index: '(d)',
      description: `Backup.' The Company shall perform backups of its entire systems in as reasonable manner at such times and intervals as is reasonable for its business purposes.' The Company does not warrant that it is able to backup or recover specific User Data from any period of time unless so stated in writing by the Company.`
    },
  ],
  {
    index: '9.8',
    description: `Intellectual Property`
  },
  [
    {
      index: '(a)',
      description: `Trademarks. 'The Company has moral & registered rights in its trade marks and the User shall not copy, alter, use or otherwise deal in the marks without the prior written consent of the Company.`
    },
    {
      index: '(b)',
      description: `Proprietary Information. 'The Company may use software and other proprietary systems and Intellectual Property for which the Company has appropriate authority to use, and the User agrees that such is protected by copyright, trademarks, patents, proprietary rights and other laws, both domestically and internationally. 'The User warrants that it shall not infringe on any third-party rights through the use of Pocketnote.`
    },
    {
      index: '(c)',
      description: `The Pocketnote Application. 'The User agrees and accepts that Pocketnote is the Intellectual Property of the Company and the User further warrants that by using Pocketnote the User will not:`
    },
    [
      {
        index: 'i',
        description: `Copy Pocketnote or the services that it provides for the User's own commercial purposes; and`
      },
      {
        index: 'ii',
        description: `Directly or indirectly copy, recreate, decompile, reverse engineer or otherwise obtain, modify or use any source or object code, architecture, algorithms contained in Pocketnote or any documentation associated with it.`
      },
    ],
    {
      index: '(d)',
      description: `Content. 'All content submitted to the Company, whether via Pocketnote or directly by other means, becomes and remains the Intellectual Property of the Company, including (without limitation) any source code, analytics, insights, ideas, enhancements, feature requests, suggestions or other information provided by the User or any other party with respect to Pocketnote.`
    },
  ],
  {
    index: '9.9',
    description: `Disclaimer of Third Party Services & Information`
  },
  [
    {
      index: '(a)',
      description: `The User acknowledges that Pocketnote is dependent on third-party services, including but not limited to:`
    },
    [
      {
        index: 'i',
        description: `Telecommunications services;`
      },
      {
        index: 'ii',
        description: ` Hosting services;`
      },
      {
        index: 'iii',
        description: `Email services; and`
      },
      {
        index: 'iv',
        description: `Analytics services.`
      },
    ],
    {
      index: '(b)',
      description: `The User agrees that the Company shall not be responsible or liable in any way for:`
    },
    [
      {
        index: 'i',
        description: `Interruptions to the availability of Pocketnote due to third-party services; or`
      },
      {
        index: 'ii',
        description: `Information contained on any linked third party website.`
      },
    ]
  ],
  {
    index: '9.10',
    description: `   Liability & Indemnity`
  },
  [
    {
      index: '(a)',
      description: `The User agrees that it uses Pocketnote at its own risk.`
    },
    {
      index: '(b)',
      description: `The User acknowledges that the Company is not responsible for the conduct or activities of any User and that the Company is not liable for such under any circumstances.`
    },
    {
      index: '(c)',
      description: `The User agrees to indemnify the Company for any loss, damage, cost or expense that the Company may suffer or incur as a result of or in connection with the User's use of or conduct in connection with Pocketnote, including any breach by the User of these Terms of Service.`
    },
    {
      index: '(d)',
      description: ` In no circumstances will the Company be liable for any direct, incidental, consequential or indirect damages, damage to property, loss of property, loss or corruption of data, loss of profits, goodwill, bargain or opportunity, loss of anticipated savings or any other similar or analogous loss resulting from the User's access to, or use of, or inability to use Pocketnote, whether based on warranty, contract, tort, negligence, in equity or any other legal theory, and whether or not the Company knew or should have known of the possibility of such damage, or business interruption of any type, whether in tort, contract or otherwise.`
    },
    {
      index: '(e)',
      description: ` Certain rights and remedies may be available under the Competition and Consumer Act 2010 (Cth) or similar legislation of other States or Territories and may not be permitted to be excluded, restricted or modified. 'Apart from those that cannot be excluded, the Company and the Company's related entities exclude all conditions and warranties that may be implied by law. 'To the extent permitted by law, the Company's liability for breach of any implied warranty or condition that cannot be excluded is restricted, at the Company's option to:`
    },
    [
      {
        index: 'i',
        description: `The re-supply of services or payment of the cost of re-supply of services; or`
      },
      {
        index: 'ii',
        description: `The replacement or repair of goods or payment of the cost of replacement or repair.`
      },
    ]
  ],
  {
    index: '9.11',
    description: `Termination`
  },
  [
    {
      index: '(a)',
      description: ` Either party may terminate this Agreement by giving the other party 1 month's written notice.`
    },
    {
      index: '(b)',
      description: `Termination of this agreement is without prejudice to and does not affect the accrued rights or remedies of any of the parties arising in any way out of this agreement up to the date of expiry or termination.`
    },
    {
      index: '(c)',
      description: `Termination does not affect any of the rights accrued by a party prior to termination, and the rights and obligations under clauses 9.9, 9.12 and 9.13 survive termination of this Agreement.`
    },
  ],
  {
    index: '9.12',
    description: ` Dispute Resolution`
  },
  [
    {
      index: '(a)',
      description: `This section applies only to disputes between the Company and a User. If any dispute arises between the parties in connection with this Agreement (Dispute), then either party may notify the other of the Dispute with a notice (Dispute Notice) which:`
    },
    [
      {
        index: 'i',
        description: `Includes or is accompanied by full and detailed particulars of the Dispute; and`
      },
      {
        index: 'ii',
        description: `Is delivered within 10 Business Days of the circumstances giving rise to the Dispute first occurring.`
      },
    ],
    {
      index: '(b)',
      description: `Within 10 Business Days after a Dispute Notice is given, a representative of each party with the authority to resolve the dispute, must meet (virtually or otherwise) and seek to resolve the Dispute.`
    },
    {
      index: '(c)',
      description: ` Subject to clause (d), a party must not bring court proceedings in respect of any Dispute unless it first complies with the requirements of the dispute resolution mechanism outlined in this clause.`
    },
    {
      index: '(d)',
      description: `Nothing in this clause prevents either party from instituting court proceedings to seek urgent injunctive, interlocutory or declaratory relief in respect of a Dispute.`
    },
    {
      index: '(e)',
      description: ` Despite the existence of a Dispute, the parties must continue to perform their respective obligations under this document and any related agreements.`
    },
  ],
  {
    index: '9.13',
    description: 'Electronic Communication, Amendment & Assignment'
  },
  [
    {
      index: '(a)',
      description: `The words in this clause that are defined in the Electronic Transactions Act 1999 (Cth) have the same meaning.`
    },
    {
      index: '(b)',
      description: ` The User can direct notices, enquiries, complaints and so forth to the Company as set out in this Agreement. 'The Company will notify the User of a change of details from time-to-time.`
    },
    {
      index: '(c)',
      description: `The Company will send the User notices and other correspondence to the details that the User submits to the Company, or that the User notifies the Company of from time-to-time. It is the User's responsibility to update its contact details as they change.`
    },
    {
      index: '(d)',
      description: `A consent, notice or communication under this Agreement is effective if it is sent as an electronic communication unless required to be physically delivered under law.`
    },
    {
      index: '(e)',
      description: `Notices must be sent to the parties' most recent known contact details.`
    },
    {
      index: '(f)',
      description: `The User may not assign or otherwise create an interest in this Agreement.`
    },
    {
      index: '(g)',
      description: ` The Company may assign or otherwise create an interest in its rights under this Agreement by giving written notice to the User.`
    },
  ],
  {
    index: '9.13',
    description: 'General'
  },
  [
    {
      index: '(a)',
      description: `Special Conditions. The parties may agree to any Special Conditions to this Agreement in writing.`
    },
    {
      index: '(b)',
      description: `Prevalence. To the extent this Agreement is in conflict with, or inconsistent with any other Agreement (including a Tutor Agreement), or Special Conditions made under this Agreement, the terms of that Agreement or Special Conditions (as the case may be) shall prevail.`
    },
    {
      index: '(c)',
      description: `Disclaimer.' Each party acknowledges that it has not relied on any representation, warranty or statement made by any other party, other than as set out in this Agreement.`
    },
    {
      index: '(d)',
      description: `Relationship.' The relationship of the parties to this Agreement does not form a joint venture or partnership.`
    },
    {
      index: '(e)',
      description: `Waiver. 'No clause of this Agreement will be deemed waived and no breach excused unless such waiver or consent is provided in writing.`
    },
    {
      index: '(f)',
      description: `Further Assurances.' Each party must do anything necessary (including executing agreements and documents) to give full effect to this Agreement and the transaction facilitated by it.`
    },
    {
      index: '(g)',
      description: `Governing Law.' This Agreement is governed by the laws of Victoria, Australia.' Each of the parties hereby submits to the non-exclusive jurisdiction of courts with jurisdiction there.`
    },
    {
      index: '(h)',
      description: ` Severability.' Any clause of this Agreement, which is invalid or unenforceable, is ineffective to the extent of the invalidity or unenforceability without affecting the remaining clauses of this Agreement.`
    },
  ]
]

const main = [
  {
    index: 'USING POCKETNOTE',
    description: general
  },
  {
    index: 'LICENCE & USE',
    description: licence
  },
  {
    index: 'SESSIONS',
    description: sessions
  },
  {
    index: 'NOTES',
    description: notesContent
  },
  {
    index: 'USER CONTENT',
    description: userContent
  },
  {
    index: 'FEES, PAYMENTS & REFUNDS',
    description: refunds
  },
  {
    index: 'DIRECT CONTRACTING BETWEEN STUDENTS AND TUTORS',
    description: contracting
  },
  {
    index: 'RELATIONSHIP',
    description: relationship
  },
  {
    index: 'GENERAL CONDITIONS',
    description: conditions
  },
]

export default () => {

  return (
      <Desktop
        main={main}
        intro={intro}
        headLine={headLine}
        termsOfServices={termsOfServices}
      />
  )
};





import React from "react";
import styled from "@emotion/styled";
import {User} from "@graphql-client/types";
import {NavLink} from "../components";
import slugify from "../slugify";

const StyledNav = styled.nav`
  color: white;
  width: 100%;
  margin: 0 1em;
  font-family:Crimson Pro;
  font-size:21px;
  font-weight:600;
  @media screen and (max-width: 786px) {
    margin: 0;
    display:flex;
    font-size:18px;
    font-weight:600;
    line-height:normal
  }
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  width: 100%;
  margin: 1em;
  @media screen and (max-width: 786px) {
    justify-content:center;
  }
`;

export default ({user}: { user: User }) => (
    <StyledNav>
        {/* <MenuItem name="Status" /> */}
        <MenuItem name="Account"/>
        {user?.role === "PARENT" && (
            <>
                <MenuItem name="Payment"/>
            </>
        )}
        <MenuItem name="Security"/>
    </StyledNav>
);

const MenuItem = ({name}: { name: string }) => (
    <StyledNavLink to={`/settings/${slugify(name)}`}>{name}</StyledNavLink>
);

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";

export default ({onChange, placeholder, showIcon, type}) => {
    const inputClass = showIcon ? "col-span-11" : "col-span-12";
    return <div className={"grid grid-cols-12 background-grey blog-search-box py-2 px-4 rounded"}>
        <input type={type} onChange={onChange} placeholder={placeholder} className={inputClass}></input>

        {showIcon && <FontAwesomeIcon
            className={"col-span-1 self-center justify-self-end"}
            style={{color: "#E26F6F", margin: "auto"}}
            icon={faMagnifyingGlass}/>}
    </div>
}

import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Col, DatePicker, Divider, Input, Row, Select, Table, Tooltip, Typography} from 'antd';
import {Field, Form, Formik} from 'formik';
import moment from 'moment';
import {useHistory, useParams} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import * as Yup from 'yup';
import BottomNavigation from '../../components/BottomNavigation'
// Components
import {createEvent as createEventMutation} from '../common/mutations';
import {
    getCurrentUser,
    getCurrentUserProfile,
    getTutor,
    getTutorAvailability,
    TutorAvailabilityForParents
} from '../common/queries';
import {Sidebar} from './Sidebar';
import Students from './Students';
import CreateStudent from '../CreateStudent';
import {FullScreenLoader} from '../common/Loader';
import './index.less'
import {orderBy} from 'lodash';
import {InfoCircleOutlined, LeftOutlined} from "@ant-design/icons";
import {POCKETNOTE_PERCENT, STRIPE_FIXED, STRIPE_PERCENT} from "../common/utils";
import {timeOptions} from "../../components/static";
import {toast} from "react-toastify";

const combine = (date: moment.Moment, time: string) => {
    const [hour, minute] = time.split(':').map((v) => parseInt(v, 10));
    return moment(date).set({hour, minute});
};
const {Text, Title} = Typography;
const {TextArea} = Input
const round = (v: number) => (Math.round(v * 100) / 100).toFixed(2);

const columns = [
    {
        title: 'Day',
        dataIndex: 'day',
        key: 'day',
        render: text => <a>{text}</a>,
    },
    {
        title: 'From',
        dataIndex: 'from',
        key: 'from',
        render: text => <a>{text}</a>,
    },
    {
        title: 'To',
        dataIndex: 'to',
        key: 'to',
        render: text => <a>{text}</a>,
    },
    {
        title: 'Hours',
        dataIndex: 'hours',
        key: 'hours',
        render: text => <a>{text}</a>,
    },
]

const findDayName = (dayNumber) => {
    let dayName = ''
    switch (dayNumber) {
        case 0:
            dayName = 'Monday'
            break
        case 1:
            dayName = 'Tuesday'
            break
        case 2:
            dayName = 'Wednesday'
            break
        case 3:
            dayName = 'Thursday'
            break
        case 4:
            dayName = 'Friday'
            break
        case 5:
            dayName = 'Saturday'
            break
        case 6:
            dayName = 'Sunday'
            break
    }
    return dayName
}

export interface TutorWeekAvailabilityInterface {
    key: number,
    day: string,
    from: string,
    to: string,
    hours: number,
}

const Breakdown = ({values, rate}: any) => {

    const newRate = values.customRate ? values.customRate : rate
    const start = combine(values.date, values.startTime);
    const end = combine(values.date, values.endTime);
    let hours = end.diff(start, 'hours', true);
    hours = hours > 0 ? hours : 0;
    const [stripeFixed] = useState(STRIPE_FIXED);
    const [pocketnotePercent] = useState(POCKETNOTE_PERCENT);
    const goal = hours * newRate;
    const toPocketnote = goal * pocketnotePercent;
    const charge = (goal + toPocketnote + stripeFixed) / (1 - STRIPE_PERCENT);
    const toStripe = charge * STRIPE_PERCENT + stripeFixed;

    return goal ? (
        <Row className={"cost"}>
            <Col span={24} style={{textAlign: "center"}}>
                <Text className="width-100 common-heading" style={{color: 'white', fontWeight: 700}}>Cost</Text>
            </Col>
            <Col span={24} style={{padding: 4}}>
                <Row style={{padding: 5}}>
                    <Col span={12}>
                        <Text className="para-cost">Tuition</Text>
                    </Col>
                    <Col span={12} style={{textAlign: "right"}}>
                        <Text className="para-cost">${round(goal)}</Text>
                    </Col>
                </Row>
                <Row style={{padding:3, alignItems: "center"}}>
                    <Col span={14}>
                        <Text className="para-cost">Pocketnote Fee</Text>
                    </Col>
                    <Col span={10} style={{textAlign: "right"}}>
                        <Text className="para-cost">${round(toPocketnote + toStripe)}</Text>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Text className="para-cost-total">Total</Text>
                    </Col>
                    <Col span={12} style={{textAlign: "right"}}>
                        <Text className="para-cost-total">${Number(round(charge)).toFixed(2)}</Text>
                    </Col>

                </Row>
            </Col>

        </Row>
    ) : (
        <div></div>
    );
};

const CreateEventForm = ({id, tutor, me, tutorData}: any) => {

    const history = useHistory();
    const [submitted, setSubmitted] = useState(false);
    const [createEvent] = useMutation(createEventMutation);
    const {data: userData} = useQuery(getCurrentUserProfile);

    const card = me?.stripeCustomer?.sources?.data[0];
    let checkPaymentSetupParent = false
    if (me?.paymentMethod === 'STRIPE') {
        if (card) checkPaymentSetupParent = true
        else checkPaymentSetupParent = false

    } else checkPaymentSetupParent = true
    let initialValues = {
        adminPaid: false,
        everyWeek: false,
        description: '',
        date: moment().startOf('day').add(1, 'day'),
        startTime: '10:00',
        endTime: '11:00',
        students: [],
        customRate: null
    };

    const validationSchema = Yup.object().shape({
        students: Yup.array()
            .required('Student[s] must be enrolled')
            .min(1, 'Minimum of 1 student'),
    });

    const disabledDate = (current: any) => {
        console.log("current : ", current)
        return current && current < moment().startOf("day")
    }
    //time disable
    const getDisabledHours = () => {
        const hours = [];
        for (let i = 0; i < moment().hour(); i++) {
            hours.push(i);
        }
        return hours;
    }

    //time disable
    const getDisabledMinutes = (selectedHour: any) => {
        const minutes = [];
        if (selectedHour === moment().hour()) {
            for (let i = 0; i < moment().minute(); i++) {
                minutes.push(i);
            }
        }
        return minutes;
    }

    const CustomRateText = <span>Field is optional, complete this field if you have agreed to a custom rate with the tutor</span>


    return (
        <Formik
            {...{initialValues, validationSchema,}}
            onSubmit={async (values) => {

                //TODO if need to add a min value for custom rate

                // if(values.customRate < 1){
                //     addToast("Custom rate must be greater than 0 ", {
                //         appearance: "error",
                //         autoDismiss: true,
                //     });
                // }

                await createEvent({
                    variables: {
                        input: {
                            description: values.description,
                            startDate: combine(values.date, values.startTime).toISOString(
                                true,
                            ),
                            endDate: combine(values.date, values.endTime).toISOString(true),
                            tutor: id,
                            students: (values.students || []).map(({id}: any) => id),
                            parent: me?.id,
                            adminPaid: values.adminPaid,
                            everyWeek: values.everyWeek,
                            customRate: Number(values.customRate) > 0 ? Number(values.customRate) : null,
                        },
                    },
                });
                history.push('/events');

            }}
        >
            {({values, setFieldValue, errors}) => (
                <Form>
                    <Row>
                        <Col sm={24} xs={24} md={24} lg={18} xl={18} xxl={18}>
                            <Col sm={24} xs={24} md={24} lg={0} xl={0} xxl={0}>
                                <Divider type="horizontal"/>
                            </Col>
                            {/*for mobile */}
                            <Row style={{textAlign: 'center'}}>
                                <Col sm={24} xs={24} md={24} lg={0} xl={0} xxl={0}>
                                    <Text className={"view-profile"}><span
                                        style={{color: 'black'}}> Request a session </span></Text>
                                </Col>
                                <Col sm={24} xs={24} md={24} lg={0} xl={0} xxl={0}>
                                    <Text className={"view-profile"}>with {tutor?.name}</Text>
                                </Col>

                            </Row>
                            {/*For desktop */}
                            <Col sm={0} xs={0} md={0} lg={24} xl={24} xxl={24}>
                                <Text className={"view-profile"}><span
                                    style={{color: 'black'}}> Request a session </span>with {tutor?.name}</Text>
                            </Col>

                            <Divider type="horizontal"/>
                            <Col sm={24} xs={24} md={24} lg={24} xl={24} xxl={24}>
                                <Row>
                                    <Text className="width-100 common-heading"
                                          style={{color: '#E26F6F'}}>Availability</Text>
                                </Row>
                                <Row className={'table-margin'}>
                                    <Table columns={columns} dataSource={tutorData} pagination={false}/>
                                </Row>
                                <Row className="row-height"/>
                                <Row className="row-height"/>
                                <Row>
                                    <Text className="width-100 common-heading">Choose your Date & Time</Text>
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Row>
                                    <Col xs={23} sm={23} md={23} lg={6} xl={6} xxl={6}>
                                        <DatePicker
                                            size={'large'}
                                            className="select-width"
                                            format='DD-MM'
                                            defaultValue={values.date ? values.date : null}
                                            // disabledDate={disabledDate}
                                            onChange={(date) => {
                                                if (date) {
                                                    setFieldValue('date', moment(date));
                                                }
                                            }}
                                        />

                                    </Col>
                                    <Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1}/>
                                    <Col xs={23} sm={23} md={23} lg={6} xl={6} xxl={6}>
                                        <Select
                                                className={"select-width"}
                                                placeholder={"Start Time"}
                                                onChange={(time) => setFieldValue('startTime', time)}
                                                size={"large"}
                                                options={timeOptions}
                                        />

                                    </Col>
                                    <Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1}/>
                                    <Col xs={23} sm={23} md={23} lg={6} xl={6} xxl={6}>
                                        <Select
                                                className={"select-width"}
                                                placeholder={"End Time"}
                                                onChange={(time) => setFieldValue('endTime', time)}
                                                size={"large"}
                                                options={timeOptions}
                                        />
                                    </Col>

                                    <Col xs={23} sm={23} md={23} lg={0} xl={0} xxl={0}>
                                        <Row className="row-height"/>
                                        <Breakdown {...{values, rate: tutor?.rate}} />
                                    </Col>

                                    <Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1}/>
                                    <Col xs={2} sm={2} md={2} lg={1} xl={1} xxl={1} className={"info-week"}>
                                        <Field as={Checkbox} name={"everyWeek"} default={true}
                                               disabled={values.date < moment().startOf("day")}/>
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={2} xl={2} xxl={2} className={"info-week-text "}>
                                        Every week
                                    </Col>

                                </Row>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={22} xl={22} xxl={22}>
                                <Row className="row-height"/>
                                {userData?.me?.students?.length ? (
                                    <>
                                        <div>
                                            <Text className="width-100 common-heading"
                                                  style={{color: '#E26F6F'}}>Students</Text>
                                            {submitted && errors.students && (
                                                <div style={{color: 'hsl(0, 66%, 66%)'}}>
                                                    {errors.students}
                                                </div>
                                            )}
                                            <Students
                                                {...{values, students: userData?.me?.students}}
                                            />
                                        </div>
                                        <Field
                                            required
                                            name="description"
                                            as={TextArea}
                                            label="Description"
                                            placeholder="I need help with..."
                                            className="select-width"
                                            size="large"
                                            autoSize={{minRows: 4, maxRows: 6}}
                                        />
                                        {checkPaymentSetupParent &&
                                        <span>
                                            <Text className="width-100 custom-rate-heading">Custom Rate&emsp;</Text>
                                            <Tooltip title={CustomRateText}>
                                                <InfoCircleOutlined className="custom-rate-toolTip"/>
                                            </Tooltip>
                                        </span>
                                        }
                                        <Row>

                                            {checkPaymentSetupParent &&
                                            <>
                                                <Col span={11}>
                                                    <Field
                                                        as={Input}
                                                        name={"customRate"}
                                                        placeholder="Custom Rate"
                                                        className="select-width"
                                                        size="large"
                                                        type={"number"}
                                                        min={0}
                                                    />
                                                </Col>
                                                <Col span={1}/>
                                                <Col span={12}>
                                                    <p className={'error-text'}>Please enter a dollar amount you have
                                                        negotiated with the tutor. This will need to be approved by the
                                                        tutor first.</p>
                                                </Col>
                                            </>
                                            }


                                            <Col xs={24} sm={24} md={24} lg={11} xl={11} xxl={11}>
                                                {checkPaymentSetupParent &&
                                                <Button
                                                    className="message-request-btn"
                                                    size="large"
                                                    block
                                                    htmlType="submit"
                                                    onClick={() => setSubmitted(true)}
                                                >
                                        <span className="message-request-btn-text">
                                         Request Session
                                      </span>
                                                </Button>
                                                }

                                            </Col>
                                            <Col span={13}>
                                                {!checkPaymentSetupParent &&
                                                <p className={'error-text'}>Setup a payment method first before you can
                                                    send a message or book a session. You will not be charged until a
                                                    session is completed. <a onClick={() => {

                                                        toast.info("Please setup a payment method first. You will not be charged until a session is completed.");
                                                        history.push('/settings/payment')

                                                    }}><u> Click here</u></a> to continue</p>
                                                }

                                            </Col>
                                        </Row>
                                    </>
                                ) : (
                                    <CreateStudent {...{user: me}} />
                                )}
                            </Col>
                        </Col>
                        <Col sm={0} xs={0} md={0} lg={6} xl={6} xxl={6} style={{paddingRight: '10px'}}>
                            <Breakdown {...{values, rate: tutor?.rate}} />
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
};

export default () => {
    const history = useHistory()
    const {id} = useParams() as any
    const {data} = useQuery(getCurrentUser);
    const me = data?.me ? data?.me : JSON.parse(localStorage.getItem("user"));
    const [tutorData, setTutorData] = useState<TutorWeekAvailabilityInterface[]>([])


    const {data: {tutor} = {}, loading} = useQuery(getTutor, {
        variables: {id},
    });

    const {data: tutorWeeklyAvailability, loading: tutorDataLoading} = useQuery(getTutorAvailability, {
        variables: {
            id: tutor?.id
        }
    })

    const {data: tutorAvailabilityForParents} = useQuery(TutorAvailabilityForParents, {
        variables: {
            tutorId: tutor?.id,
            userId: me?.id
        }
    })

    useEffect(() => {
        let temp = []
        let key = 1;
        if (tutorDataLoading === false && tutorWeeklyAvailability?.getUserAvailability?.availabilities) {

            tutorWeeklyAvailability?.getUserAvailability?.availabilities.map(userAva => {

                const startTime = moment.unix(Number(userAva.start))
                const endTime = moment.unix(Number(userAva.end))
                const duration = moment.duration(endTime.diff(startTime));
                if (duration.asHours() > 0) {
                    temp.push({
                        key: key,
                        dayNumber: userAva.day,
                        day: findDayName(userAva.day),
                        from: startTime.format('HH:mm'),
                        to: endTime.format('HH:mm'),
                        hours: Number(duration.asHours()).toFixed(1),
                    })
                    key = key + 1

                }
            })
            setTutorData(orderBy(temp, ['dayNumber'], ['asc']))
        }

    }, [tutorWeeklyAvailability, tutorDataLoading])


    if (loading) return <FullScreenLoader/>
    return (
        <>
            <Col id="header-tutor" xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
                <Row className="keep-spaced">
                    <Col span={1} className="arrow-align">
                        <LeftOutlined onClick={() => {
                            history.goBack()
                        }}/>
                    </Col>
                    <Col span={1}/>
                    <Col span={12}>
                        <Title className="header-heading">Request a session</Title>
                    </Col>
                    <Col span={8}/>
                </Row>
            </Col>

            <Row id={"MainRow"}>
                <Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1}/>
                <Col xs={23} sm={23} md={23} lg={22} xl={22} xxl={22} id={"CreateEventDesktop"}>
                    <Row className="row-height"/>
                    {/*<Row className="row-height"/>*/}
                    <Row>
                        <Col sm={24} xs={24} md={24} lg={7} xl={7} xxl={7}>
                            <Sidebar {...{me, tutor}} request={false}/>
                        </Col>
                        <Col span={1}/>
                        <Col xs={22} sm={22} md={22} lg={16} xl={16} xxl={16}>
                            <CreateEventForm history={history} id={id} tutor={tutor} me={me} tutorData={tutorData}/>
                        </Col>
                    </Row>
                    <Row className="row-height"/>
                    <Row className="row-height"/>
                </Col>
                <Col span={24}>
                    <Row className="row-height"/>
                    <Row className="row-height"/>
                </Col>
                <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
                    <BottomNavigation role={me?.role}/>
                </Col>

            </Row>
        </>
    );
};

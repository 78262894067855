import React from "react";
import Links from "../Nav/Links";
import styled from "@emotion/styled";
import { useQuery } from "@apollo/client";
import { getCurrentUser } from "pages/common/queries";
import { User } from "@graphql-client/types";

const MenuContainer = styled.div`
  .Links {
    & > * {
      display: block;
      padding: 1em;
      display: flex;
      align-items: center;
    }
  }
`;

const Menu = (): JSX.Element => {
  const { data } = useQuery<{ me: User }>(getCurrentUser);
  return (
    <MenuContainer>
      <Links user={data?.me} />
    </MenuContainer>
  );
};

export default Menu;

import React, { createContext, useContext } from 'react';
import {
  Elements as ElementsInner,
  injectStripe,
  StripeProvider as StripeProviderInner,
} from 'react-stripe-elements';
import { useQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

const StripeAccount = ({ id, individual }: any) => {
  const [deleteStripeAccount] = useMutation(gql`
    mutation DeleteStripeAccount($id: String!) {
      deleteStripeAccount(id: $id)
    }
  `);
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div>{id}</div>
      <div>{individual?.email}</div>
      <div>{individual?.first_name}</div>
      <div>{individual?.last_name}</div>
      <button
        onClick={async () => {
          await deleteStripeAccount({ variables: { id } });
        }}
      >
        Delete
      </button>
    </div>
  );
};

export default () => {
  const { data } = useQuery(gql`
    query StripeAccounts {
      stripeAccounts(orphaned: true)
    }
  `);
  const accounts = data?.stripeAccounts || [];
  return (
    <div>
      {accounts.map((account: any) => (
        <StripeAccount {...account} />
      ))}
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
    </div>
  );
};

export const StripeProvider = ({ children }: any) => (
  <StripeProviderInner apiKey={process.env.REACT_APP_STRIPE_API_KEY as any}>
    {children}
  </StripeProviderInner>
);

const HookContext = createContext([]);
const HookProvider = injectStripe(({ stripe, elements, children }) => {
  return (
    <HookContext.Provider value={[stripe, elements] as any}>
      {children}
    </HookContext.Provider>
  );
});

export const Elements = ({ children }: any) => {
  return (
    <ElementsInner>
      <HookProvider>{children}</HookProvider>
    </ElementsInner>
  );
};

export const useStripe = () => useContext(HookContext);

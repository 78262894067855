import React from "react";
import TestimonialPresent from "./TestimonialPresent";

export type TestimonialType = {
    imgSrc: string;
    name: string;
    text: string;
}
type TestimonialComponentType = {
    testimonials: TestimonialType[]
}
export default (props: TestimonialComponentType) => {
    const {testimonials} = props;
    return <div>
        <div className={"my-4 px-4"}>
            <div className="text-center innovation-text-landing text-4xl mt-12">Our Testimonials</div>
            <div className="text-center grey-text text-2xl mt-4 text-base">
                With over 140 <span className={"red-highlight"}>5-star Google Reviews</span>, hear what some of our customers have to say:</div>
        </div>
        <div className={"md:grid md:grid-cols-3 md:py-10 py-6 place-items-center grid grid-cols-1"}>
            {testimonials.map((testimonial: TestimonialType, index: number) => <TestimonialPresent
                name={testimonial.name}
                imgSrc={testimonial.imgSrc}
                text={testimonial.text}/>)}
        </div>

    </div>
}

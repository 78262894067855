import {PropsFor123Component} from "./OneTwoThreeContainer";

export default (props: PropsFor123Component) => {

    const {imgSrc, title, description, type} = props;

    function getBgImage() {
        return `icons/${type}.png`
    }

    return <div className={"relative w-1/2 m-auto"}>
        <img src={getBgImage()}/>
        <div className={"onetwothree"}>
            <img className={"my-2 h-8 w-8"} src={imgSrc}/>
            <div className={"crimson-font red-highlight text-lg font-bold"}>{title}</div>
            <div className={"secondary-text-dmsans mt-2 text-sm"}>{description}</div>
        </div>
    </div>
}

import React from 'react';
import { Row, Col } from 'antd';
import { Form } from 'formik';
import { Avatar } from 'antd';
import CommonForm from './CommonForm';
import './Mobile.less';

export default ({ history }: any) => {
  return (
    <div className='background-secondary full-height login-container'>
      <Form>
        <Row className='background-plain main-container'>
          <Col onClick={() => history.goBack()} span={2}>
            <Avatar className='direction' src="/icons/direction-icons/left-arrow.svg" />
          </Col>
          <CommonForm history={history}/>
        </Row>
      </Form>
    </div>
  );
}

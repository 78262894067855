import React from 'react'
import {Col, Collapse, Tag, Row} from "antd";
import {MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";
const {Panel} = Collapse;

export default ({tutorSubjects}) => {

    return (
        <Row className="first-row margin-top-10">
            <Col span={24}>
                <Collapse
                    expandIcon={({isActive}) =>
                        isActive ? (
                            <MinusCircleOutlined className="icon-specs"/>
                        ) : (
                            <PlusCircleOutlined className="icon-specs"/>
                        )
                    }
                    expandIconPosition="right"
                >
                    <Panel header={"Subjects"} key="2" className="main-heading-collapase">
                        <Row>
                            <Col span={1}/>
                            <Col span={22}>
                                {tutorSubjects?.map(sub=>(
                                    <Tag style={{fontSize:'15px'}}>{sub.name}</Tag>
                                ))}

                            </Col>
                        </Row>
                    </Panel>
                </Collapse>
            </Col>
        </Row>
    )
}
import React from 'react';
import {Row, Col, Collapse} from 'antd';
import {
    MinusCircleOutlined,
    PlusCircleOutlined,
} from "@ant-design/icons";
import Question from './Question';
import './Desktop.less'
import {Link} from "react-router-dom";

const {Panel} = Collapse;

export default (
    {students, tutors}: any) => {
    return (
        <Row id="FaqsDesktop">
            <Col span={1}></Col>

            <Col span={4} className="terms">
                <Row>
                    <Link to="/terms-of-service">
                        <div className="terms-text">Terms & Conditions</div>
                    </Link>
                </Row>
                <Row className="row-height"/>
                <Row>
                    <Link to="/privacy-policy">
                        <div className="terms-text">Privacy Policy</div>
                    </Link>
                </Row>
                <Row className="row-height"/>
                <Row>
                    <Link to="/faqs">
                        <div className="terms-text">FAQs</div>
                    </Link>
                </Row>
            </Col>

            <Col span={16} className="faq-col">
                <div className="faq-text">FAQs</div>
                <div className="faq-des">We’ve created answers to frequently asked questions. If you have a question
                    that is not
                    here, please reach out to <a href="https://pocketnote.com.au/" target="_blank"
                                                 className="anchor-align">support@pocketnote.com.au</a></div>
                <Row className="first-row margin-top-10">
                    <Col span={24}>
                        <Collapse
                            expandIcon={({isActive}) =>
                                isActive ? (
                                    <MinusCircleOutlined className="icon-specs"/>
                                ) : (
                                    <PlusCircleOutlined className="icon-specs"/>
                                )
                            }
                            expandIconPosition="right"
                        >
                            <Panel header="FAQ for Parents and Students" key="3" className="collapse-background">
                                <Row>
                                    <Col span={2}></Col>
                                    <Col span={22}>
                                        <Row className="text-div">
                                            <div>
                                                <Col span={24} className="conv-col-overflow">
                                                    <Col span={24}>
                                                        {students.map((item: any) =>
                                                            <Question question={item.question}
                                                                      answer={item.answer}></Question>
                                                        )}
                                                    </Col>
                                                </Col>
                                            </div>
                                        </Row>
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
                <Row className="first-row margin-top-10"></Row>
                <Row className="first-row margin-top-10">
                    <Col span={24}>
                        <Collapse
                            expandIcon={({isActive}) =>
                                isActive ? (
                                    <MinusCircleOutlined className="icon-specs"/>
                                ) : (
                                    <PlusCircleOutlined className="icon-specs"/>
                                )
                            }
                            expandIconPosition="right"
                        >
                            <Panel header="FAQ for Tutors" key="3" className="collapse-background">
                                <Row>
                                    <Col span={1}></Col>
                                    <Col span={22}>
                                        <Row className="text-div">
                                            <div>
                                                <Col span={24} className="conv-col-overflow">
                                                    <Col span={24}>
                                                        {tutors.map((item: any) =>
                                                            <Question question={item.question}
                                                                      answer={item.answer}></Question>
                                                        )}
                                                    </Col>
                                                </Col>
                                            </div>
                                        </Row>
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
                <Row className="first-row margin-top-10"></Row>
            </Col>
        </Row>
    )
}

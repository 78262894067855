import React from 'react';
import { Formik, Form } from 'formik';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import Mobile from './Mobile';
import Switch from '../Switch';
import { Row } from 'antd';
import CommonRecover from './CommonRecover';

export function Recover({ user }: any) {
  const [recover] = useMutation(gql`
    mutation Recover($email: String!) {
      recover(email: $email)
    }
  `);
  const recoveryText = `Enter your user account's verified email address and we will send you a password reset link.`;
  const buttonText = `Send Password Reset Email`;
  const spamText = `Check your email for a link to reset your password. If it
  doesn’t appear within a few minutes, check your spam folder.`;
  const history = useHistory();

  if (user) {
    history.push("/");
  }

  return (
    <>
      <Formik
        initialValues={{}}
        onSubmit={async (values, actions) => {
          await recover({ variables: values });
          actions.setStatus(true);
        }}
      >
        {(props) => (
          <Form>

            <Switch >
              {[
                <Mobile recoveryText={recoveryText} buttonText={buttonText} spamText={spamText} props={props} />,
                <>
                  <Row style={{ margin: '10% 30%' }}>
                    <CommonRecover recoveryText={recoveryText} buttonText={buttonText} spamText={spamText} props={props} />
                  </Row>
                </>
              ]}</Switch>
          </Form>
        )}
      </Formik>
    </>
  );
}

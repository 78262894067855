import React from "react";
import {Row, Typography} from "antd";
const {Text} = Typography
export default ({tutor,userLanguages}) => {
    return (
        <>

                <Row>
                    <Text className="view-profile">View Profile</Text>
                </Row>
                <Row className="margin-top-30">
                    <Text className="width-100 common-heading">Available</Text>
                    {/*<Text className="width-100 common-category">*/}
                    {/*    More than 30 hrs/week*/}
                    {/*</Text>*/}
                    <Text className="width-100 common-category">
                        {tutor?.responseTime} hrs response time
                    </Text>
                </Row>
                {userLanguages.length ? (
                    <Row className="margin-top-30">

                        <Text className="width-100 common-heading">Languages</Text>
                        {userLanguages.map(lang => (
                            <Text className="width-100 common-category">{lang}</Text>
                        ))}

                    </Row>
                ) : null}
                {tutor?.school?.name && (
                    <>
                        <Row className="margin-top-30">
                            <Text className="width-100 common-heading">School</Text>
                            <Text className="width-100 common-category">
                                {tutor?.school?.name}
                            </Text>
                        </Row>
                        <Row className="row-height"/>
                    </>
                )}


        </>
    )

}
import React, {useState} from 'react';
import {Button, Col, Input, Row, Typography, Select, Checkbox} from "antd";
import {Field, Form, Formik} from "formik";
import "./Desktop.less";
import {useMutation} from "@apollo/client";
import gql from "graphql-tag";
import {Header} from "../components";

const {Text} = Typography;

export default () => {
    const [findTutorQuery] = useMutation(gql`
        mutation findTutorForm($input: FindTutorFormInput!) {
            findTutorForm(input: $input)
        }
    `);

    const [submittedForm, setSubmittedForm] = useState(false);
    const [submittingForm, setSubmittingForm] = useState(false);

    return <div style={{padding: '2em'}}>
        {submittedForm && <>
            <Header>Thank you!</Header>
            <p>
               Someone will be in touch soon.
            </p>
        </>}
        {!submittedForm &&
        <Formik initialValues={{
            email: "",
            name: "",
            phone: "",
            subject: "",
            yearLevel: "",
            postCode: "",
            hearAboutUs: "",
            studentParent: "parent",
            onlineTutoring: "yes",
        }} onSubmit={async (values) => {
            setSubmittingForm(true);
            try {
                await findTutorQuery({
                    variables: {
                        input: values,

                    },
                });
                setSubmittedForm(true);
            } catch (e) {
            }
            setSubmittingForm(false);
            console.log(values);
        }}>
            <Form>
                <Col md={24} lg={9} className='padding-w-12 align-item-center align-content-center'>
                    <Row className='find-tutor-form background-primary border-radius-13  padding-8' gutter={16}>
                        <Col span={24}>
                            <Text className='align-center become-tutor-form'>
                                Find a Tutor
                            </Text>
                        </Col>
                        <Col className="img-center" span={24}>
                            <Field as={Input} placeholder={"Name"} name="name" required pattern=".+"/>
                        </Col>
                        <Col className='margin-top-10 img-center' span={24}>
                            <Field as={Input} type="email" name="email" required placeholder="Email"/>
                        </Col>
                        <Col className='margin-top-10 img-center' span={24}>
                            <Field as={Input} type="tel" placeholder="Phone" name="phone" required pattern="[0-9]{10}"
                                   maxLength={10}/>
                        </Col>
                        <Col className='margin-top-10 img-center' span={24}>
                            <Field as={Input} placeholder="Subject" name="subject" required pattern=".+"/>
                        </Col>
                        <Col className='margin-top-10 img-center' span={24}>
                            <Field as={Input} placeholder="Year Level" name="yearLevel" required pattern=".+"/>
                        </Col>
                        <Col className='margin-top-10 img-center' span={24}>
                            <Field as={Input} placeholder="Post Code" type={"tel"} name="postCode" required pattern="^(\d{4})$" maxLength={4}/>
                        </Col>
                        <Col className='margin-top-10 img-center' span={24}>
                            <Field as={Input} placeholder="How did you hear about us?" name="hearAboutUs"
                                   pattern=".*"/>
                        </Col>
                        <Col className='margin-top-10' span={24}>
                            <Text className={"select-form-text"}>
                                Are you a student or parent?
                            </Text>
                            <Field className={"select-form-dropdown"} as={"select"} name="studentParent" required
                            >
                                <option value={"student"}>Student</option>
                                <option value={"parent"}>Parent</option>
                            </Field>
                        </Col>

                        <Col className='margin-top-10' span={24}>
                            <Text className={"select-form-text"}>
                                Are you open to online tutoring?
                            </Text>
                            <Field className={"select-form-dropdown"} as={"select"} name="onlineTutoring" required
                            >
                                <option value={"yes"}>Yes</option>
                                <option value={"no"}>No</option>
                            </Field>
                        </Col>
                        <Col className='img-center' span={24}>
                            <Button htmlType='submit' className='box-shadow btn-secondary continue-btn margin-top-10'
                                    size='large'>
                                <span className="continue-btn-text">{submittingForm ? "submitting..." : "Submit"}</span></Button>
                        </Col>
                    </Row>
                </Col>
            </Form>
        </Formik>}

    </div>
};

import React from 'react';
import { Row, Col, Collapse, Typography } from 'antd';
import {
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import Question from './Question';
import './Mobile.less'

const { Panel } = Collapse;


export default (
  { students, tutors }: any) => {
  return (
    <Row id="FaqsMobile">
      <Col span={22} className="faq-div">
        <div className="faq-text">FAQs</div>
        <div className="faq-des">We’ve created answers to frequently asked questions. If you have a question that is not
          here, please reach out to <a href="https://pocketnote.com.au/" target="_blank" className="anchor-align">support@pocketnote.com.au</a></div>
        <Row className="first-row margin-top-10">
          <Col span={24}>
            <Collapse
              expandIcon={({ isActive }) =>
                isActive ? (
                  <MinusCircleOutlined className="icon-specs-mobile" />
                ) : (
                  <PlusCircleOutlined className="icon-specs-mobile" />
                )
              }
              expandIconPosition="right"
              className="background-collapse"
            >
              <Panel
                header="FAQ for Parents and Students"
                key="1"
                className="main-heading-collapase"
              >
                <Row>
                  <Col span={24}>
                    <Row className="text-div">
                      <div>
                        <Col span={24} className="conv-col-overflow">
                          <Col span={24} className="text-width">
                            {students.map((item: any) =>
                              <Question question={item.question} answer={item.answer} ></Question>
                            )}
                          </Col>
                        </Col>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </Col>
        </Row>
        <Row className="first-row margin-top-10">
          <Col span={24}>
            <Collapse
              expandIcon={({ isActive }) =>
                isActive ? (
                  <MinusCircleOutlined className="icon-specs-mobile" />
                ) : (
                  <PlusCircleOutlined className="icon-specs-mobile" />
                )
              }
              expandIconPosition="right"
              className="background-collapse"
            >
              <Panel header="FAQ for Tutors" key="1" className="main-heading-collapase">
                <Row>
                  <Col span={24}>
                    <Row className="text-div">
                      <div>
                        <Col span={24} className="conv-col-overflow">
                          <Col span={24} className="text-width">
                            {tutors.map((item: any) =>
                              <Question question={item.question} answer={item.answer} ></Question>
                            )}
                          </Col>
                        </Col>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </Col>
        </Row>
        <Row className="first-row margin-top-10"></Row>
      </Col>
    </Row >
  )
}
import React from "react";
import {Form, Formik} from "formik";
import {useAuth} from "../Auth";
import {Link} from "react-router-dom";
import Switch from "../Switch";
import "./Mobile.less";
import Mobile from "./Mobile";
import Desktop from "./Desktop";

export default ({role}: { role: String }) => {
    const auth = useAuth();


    return (
        <Formik
            initialValues={{}}
            onSubmit={async (values, actions) => {
                try {
                    await auth.join({...values, role});
                    if (role === "TUTOR") {
                        (window as any).dataLayer.push({event: 'form_submit_join_tutor'})
                    }
                    if (role === "PARENT") {
                        (window as any).dataLayer.push({event: 'form_submit_join_parent'})
                    }
                } catch (e) {
                    actions.setStatus(false);
                }
            }}
        >
            {(props) => (
                <Form>
                    {
                        <Switch>
                            {[
                                <Mobile key="mobile" role={role} props={props}/>,
                                <>
                                    {props.status === false && (
                                        <div
                                            style={{
                                                border: "1px solid hsla(0, 50%, 50%, 0.25)",
                                                borderRadius: 3,
                                                padding: "1em",
                                            }}
                                        >
                                            <p>We had trouble creating a new account for you.</p>
                                            <p>An account may already exist with this email.</p>
                                            <p>
                                                Try <Link to="/login">signing in</Link> instead.
                                            </p>
                                        </div>
                                    )}
                                    <Desktop key="desktop" role={role}/>
                                </>,
                            ]}
                        </Switch>
                    }
                </Form>
            )}
        </Formik>
    );
};

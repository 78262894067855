import React, {useState, useEffect} from "react";
import {
    Row,
    Col,
    Collapse, Button, message,
} from "antd";
import {
    MinusCircleOutlined,
    PlusCircleOutlined,
} from "@ant-design/icons";
import "./Desktop.less";
import EducationForm from "./EducationForm";
import {useQuery, useMutation} from "@apollo/client";
import moment from 'moment'
import {addEducationMutation, getEducationQuery, deleteEducationMutation, updateEducationMutation} from './query'
import {getSchools} from '../../common/queries'
import {toast} from "react-toastify";

const {Panel} = Collapse;

export interface educationInterface {
    id: number
    name: string
    subject: string
    startDate: number
    endDate: number
    degree: string
    grade: string
}

export default ({user}) => {

    const [education, setEducation] = useState<educationInterface[]>([])
    const [addEducation] = useMutation(addEducationMutation)
    const {data, loading, refetch} = useQuery(getEducationQuery,
        {variables: {id: user?.id}},
    );
    const [deleteEducation] = useMutation(deleteEducationMutation)
    const [updateEducation] = useMutation(updateEducationMutation)

    const {data: schoolData} = useQuery(getSchools);
    let schools = []
    schoolData?.schools?.map(school => {
        schools.push({id: school.id, value: school.name})
    });

    const deleteEducationRow = async (id) => {
        const find = data?.getEducationByUser.find(edu => edu.id === id)
        if (find) {
            try {
                await deleteEducation({
                    variables: {
                        id: id
                    }
                })
                toast.success('Successfully deleted.');

            } catch (error) {
                message.error(error.message);
                toast.error('Unable to delete record.');
            } finally {
                refetch()
            }
        } else {

            const copyEducation = [...education]
            const remEducation = copyEducation.filter(edu => edu.id !== id)
            setEducation(remEducation)
        }
    }
    useEffect(
        () => {
            let temp = []
            if (loading === false && data?.getEducationByUser) {
                data?.getEducationByUser.map(edu => {
                    temp.push({
                        id: edu.id,
                        name: edu.instituteName,
                        subject: edu.majorSubject,
                        startDate: edu.startDate,
                        endDate: edu.endDate,
                        degree: edu.degree,
                        grade: edu.grade
                    })
                })
                setEducation(temp)
            }

        }, [loading, data])
    const addEducationRow = () => {
        const temp: educationInterface = {
            id: education.length ? education[education.length - 1].id + 1 : 0,
            name: '',
            subject: '',
            startDate: moment().unix(),
            endDate: moment().unix(),
            degree: '',
            grade: ''
        }

        let newEducation: educationInterface[] = [...education]
        newEducation.push(temp)
        setEducation(newEducation)
    }

    const saveEducation = () => {

        education.map(async edu => {
            let flag = true
            data?.getEducationByUser.map(dataItem => {
                if (dataItem.id === edu.id) flag = false
            })
            if (flag) {
                flag = false
                await addEducation({
                    variables: {
                        instituteName: edu.name,
                        startDate: edu.startDate,
                        endDate: edu.endDate,
                        majorSubject: edu.subject,
                        id: user?.id,
                        degree: edu?.degree,
                        grade: edu?.grade
                    }
                })

                toast.success('Saved');
                try {

                } catch (error) {

                    message.error(error.message);
                    toast.error('Error');


                } finally {
                    refetch()
                }

            } else {
                //updating current entry
                try {
                    await updateEducation({
                        variables: {
                            instituteName: edu.name,
                            startDate: edu.startDate,
                            endDate: edu.endDate,
                            majorSubject: edu.subject,
                            id: edu.id,
                            degree: edu?.degree,
                            grade: edu?.grade
                        }
                    })

                } catch (e) {
                    toast.error('Unable to update record.');
                }
            }
        })
    }

    return (
        <>
            <Col xs={23} sm={23} md={23} lg={18} xxl={18} xl={18} id="AccountDesktopCollapse">
                <Collapse
                    defaultActiveKey={["1"]}
                    expandIcon={({isActive}) =>
                        isActive ? (
                            <MinusCircleOutlined className="icon-specs"/>
                        ) : (
                            <PlusCircleOutlined className="icon-specs"/>
                        )
                    }
                    expandIconPosition="right"
                >
                    <Panel header="Education" key="0" className="collapse-background">
                        <Row className="row-height"/>
                        <Row>
                            <Col xs={2} sm={2} md={2} lg={16} xxl={16} xl={16}/>
                            <Col xs={20} sm={20} md={20} lg={4} xxl={4} xl={4} className="textAlign">
                                <Button onClick={addEducationRow} type="primary" size="large" block>
                                      <span className="upload-pic-text">
                                        Add Education +
                                      </span>
                                </Button>
                            </Col>
                        </Row>

                        <Row className="row-height"/>
                        <Row className="flex">

                            <Col span={2}/>
                            <Col span={20}>
                                {education.map(edu => (
                                    <>
                                        <EducationForm
                                            setEdu={setEducation}
                                            education={education}
                                            edu={edu}
                                            deleteEducationRow={deleteEducationRow}
                                            schools={schools}
                                        />
                                        <Row className="row-height"/>
                                    </>
                                ))}
                            </Col>
                            <Col span={2}/>
                        </Row>
                        {education.length ? (
                            <>
                                <Row>
                                    <Col xs={2} sm={2} md={2} lg={16} xxl={16} xl={16}/>
                                    <Col xs={20} sm={20} md={20} lg={4} xxl={4} xl={4} className="textAlign">
                                        <Button onClick={saveEducation} type="primary" size="large" block>
                                      <span className="upload-pic-text">
                                        Save Education
                                      </span>
                                        </Button>
                                    </Col>
                                </Row>
                                <Row className="row-height"/>
                            </>
                        ) : null}
                    </Panel>
                </Collapse>
            </Col>
        </>

    )


}
// --- PRIVATE ROUTES ---
import Settings from 'pages/Settings';
import Explore from 'pages/Explore';
import ConversationAdmin from "pages/Admin/Conversations";
import Chat from 'pages/Messenger';
import Tutor from 'pages/Tutor';
import CreateEvent from 'pages/CreateEvent';
import Event from 'pages/Event'
import Events from 'pages/Events';
import AdminEvents from 'pages/Admin/Events';
import CreatePayment from 'pages/CreatePayment';
import Skills from 'pages/Skills';
import Users from 'pages/Admin/Users';
import User from 'pages/User';
import Clicks from 'pages/Clicks';
import Dashboard from 'pages/Dashboard'
import Notes from 'pages/Notes'
import ReviewNotes from 'pages/Notes/ReviewNotes'
import PurchaseNotes from 'pages/Notes/Purchase'
import AdminDashboard from 'pages/Admin/Dashboard'

// --- LAYOUT ---
import AppLayout from 'layout/AppLayout';
import {PrivateRoutesLayout} from 'layout/AppLayout';

// --- ROUTES ---
import { Routes } from './index';



export const privateRoutes: Routes[] = [
  {
    path: '/dashboard/admin',
    component: AdminDashboard,
    Layout: PrivateRoutesLayout,
    authenticated: true
  },
  // {
  //   path: '/explore/:action?',
  //   component: Explore,
  //   Layout: PrivateRoutesLayout,
  //   authenticated: true
  // },
  {
    path: '/settings/:type',
    component: Settings,
    Layout: PrivateRoutesLayout,
    authenticated: true
  },
  {
    path: '/settings',
    component: Settings,
    Layout: PrivateRoutesLayout,
    authenticated: true
  },
  {
    path: '/conversations/admin',
    component: ConversationAdmin,
    Layout: AppLayout,
    authenticated: true
  },
  {
    path: '/conversations/:id?',
    component: Chat,
    Layout: PrivateRoutesLayout,
    authenticated: true
  },
  {
    path: '/tutors/:id',
    component: Tutor,
    Layout: PrivateRoutesLayout,
    authenticated: true
  },
  {
    path: '/events/create/:id',
    component: CreateEvent,
    Layout: PrivateRoutesLayout,
    authenticated: true
  },
  {
    path: '/events/admin',
    component: AdminEvents,
    Layout: AppLayout,
    authenticated: true
  },
  {
    path: '/review-notes',
    component: ReviewNotes,
    Layout: AppLayout,
    authenticated: true
  },
  {
    path: '/events/:id',
    component: Event,
    Layout: PrivateRoutesLayout,
    authenticated: true
  },
  {
    path: '/events',
    component: Events,
    Layout: AppLayout,
    authenticated: true
  },
  {
    path: '/dashboard',
    component: Dashboard,
    Layout: AppLayout,
    authenticated: true
  },
  {
    path: '/payments/new',
    component: CreatePayment,
    Layout: AppLayout,
    authenticated: true
  },
  {
    path: '/skills',
    component: Skills,
    Layout: AppLayout,
    authenticated: true
  },
  {
    path: '/users',
    component: Users,
    Layout: AppLayout,
    authenticated: true
  },
  {
    path: '/users/:id',
    component: User,
    Layout: AppLayout,
    authenticated: true
  },
  {
    path: '/clicks',
    component: Clicks,
    Layout: AppLayout,
    authenticated: true
  },
  {
    path: '/conversations/admin',
    component: Chat,
    Layout: AppLayout,
    authenticated: true
  },
  {
    path: '/notes',
    component: Notes,
    Layout: AppLayout,
    authenticated: true
  },
  {
    path: '/notes/:type',
    component: Notes,
    Layout: AppLayout,
    authenticated: true
  },
];

import {ApolloClient, InMemoryCache, split} from "@apollo/client";
import {WebSocketLink} from "@apollo/client/link/ws";
import {setContext} from "@apollo/client/link/context";
import {getMainDefinition} from "@apollo/client/utilities";
import {createUploadLink} from "apollo-upload-client";

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_HTTP_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const wsLink = () => {
  const token = localStorage.getItem('token');
  return new WebSocketLink({
    uri: process.env.REACT_APP_API_WS_ENDPOINT || `wss://api.pocketnote.com.au/`,
    options: {
      reconnect: true,
      connectionParams: {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
          "accept-encoding": "gzip"
        }
      },
    },
  });
};

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink(),
  authLink.concat(httpLink as any),
);

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      returnPartialData: true,
    },
    query: {
      fetchPolicy: 'cache-first',
    },
  },
});

export default client;

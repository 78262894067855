import React from 'react';
import {Button, Col, Input, Typography} from 'antd';
import './Desktop.less';
import {Link} from 'react-router-dom';
import {Field} from 'formik';

const {Title, Paragraph} = Typography;

export default ({ history }: any) => {

    return (
        <>
            <Col className='align-center margin-top-24' span={24}>
                <Title level={2}>Log In</Title>
            </Col>

            <Col className='align-center' span={24}>
                <Paragraph className='heading-text'>Don't have an account? <Link to={'/join'} className='link-text'>Sign
                    up for free</Link></Paragraph>
            </Col>

            <Col className='margin-top-24' span={24}>
                <Paragraph className='heading-text'><b>Email</b></Paragraph>
                <Field
                    as={Input}
                    type="email"
                    name="email"
                    required
                    autoComplete="email"
                />
            </Col>

            <Col className='margin-top-24' span={24}>
                <Paragraph className='heading-text'><b>Password</b></Paragraph>
                <Field
                    as={Input}
                    type="password"
                    name="password"
                    required
                    autoComplete="password"
                />
            </Col>

            <Col className='margin-top-24' span={24}>
                <Button htmlType={"submit"} type="primary" className='btn-primary full-width' size='large'>
                    Log In
                </Button>

            </Col>
            <Col className='align-center margin-top-8' span={24}>
                <Link to={'/recover'} className='link-text'>Forget email or password?</Link>
            </Col>
        </>
    );
}
import React from "react";
import ComponentContainer from "../../components/LandingTemplates/ComponentContainer";
import LandingRow from "../../components/LandingTemplates/LandingRow";
import {homePageBookTutorRedirection, homePageBuyNotesRedirection} from "../Constants/utilities";
import LandingWhyPanel, {PanelProps} from "../../components/LandingTemplates/LandingWhyPanel";
import OneTwoThreeComponent from "../../components/LandingTemplates/OneTwoThreeComponent";
import OneTwoThreeContainer from "../../components/LandingTemplates/OneTwoThreeContainer";
import FindYourNotes from "../../components/LandingTemplates/FindYourNotes";


const whyPn: PanelProps[] = [
    {
        text: "High-quality notes created by top-performing students",
        imgSrc: "icons/notes/why-pn-quality.png"
    },
    {
        text: "Comprehensive coverage of a range of subjects",
        imgSrc: "icons/notes/why-pn-coverage.png"
    },
    {
        text: "Affordable pricing and instant digital delivery",
        imgSrc: "icons/notes/why-pn-delivery.png"
    },
    {
        text: "Access to a community of like-minded students and tutors for additional support",
        imgSrc: "icons/notes/why-pn-community.png"
    }
]


export default (props) => {
    return <ComponentContainer>
        <LandingRow
            isHero={true}
            heading={"Buy Notes in Australia with Pocketnote"}
            subheading={"If you are looking for reliable, comprehensive and practical high school notes (including ATAR notes) " +
                "Pocketnote is here to help. Our easy-to-use platform makes it simple to buy notes in Australia for a " +
                "variety of subjects, all of which are created by top-performing students."}
            imgSrc={"icons/notes/hero-image.png"}
            primaryButton={{text: "Buy Notes", to: () => homePageBuyNotesRedirection(props.user)}}
        />
        <OneTwoThreeContainer
            one={{title: "Search", description: "Search for notes by subject, semester and exam", imgSrc: "icons/notes/search.png", type: "1"}}
            two={{title: "Buy", description: "Buy notes that suit your needs", imgSrc: "icons/notes/buy.png", type: "2"}}
            three={{title: "Learn", description: "Get back on track or stay ahead of the pack with your notes", imgSrc: "icons/notes/learn.png", type: "3"}}/>
        <LandingRow
            isHero={false}
            reverse={true}
            heading={"Why Pocketnote is the Best Place to Buy Notes in Australia"}
            subheading={"At Pocketnote, we understand that every student's journey is unique, which is why we offer a " +
                "diverse range of notes tailored to suit individual learning styles and preferences. " +
                "Our notes are created by experienced students who have excelled in their respective subjects, " +
                "and have a deep understanding of the high school and ATAR curriculum." +
                "\n" +
                "Whether you need notes to help you prepare for your next exam or to supplement your classroom learning, " +
                "Pocketnote has got you covered. We offer notes for a range of subjects, including English, Maths, Science, Humanities, Languages and more. " +
                "With our affordable pricing and instant digital delivery, you can easily get the help you need to achieve your academic goals."}
            imgSrc={"icons/notes/buy-notes-secondary.png"}
            primaryButton={{text: "Buy Notes", to: () => homePageBuyNotesRedirection(props.user)}}
        />

        <LandingWhyPanel panel={whyPn}
                         primaryButton={{text: "Buy Notes", to: () => homePageBuyNotesRedirection(props.user)}}
                         heading={"Why Choose Pocketnote to Buy Notes in Australia?"}
                         subheading={"Whether you are struggling to keep up with your coursework or simply looking for additional " +
                             "resources to help you excel in your studies, Pocketnote is the perfect solution to buy notes in AU. " +
                             "We also offer ATAR tutoring and ATAR notes tutoring services." +
                             "\n" +
                             "Kickstart your academic success today with Pocketnote, delivering instant access to high-quality " +
                             "study notes and ATAR notes in Australia. With Pocketnote, it has never been easier to buy notes in Australia."}/>
        <FindYourNotes/>
    </ComponentContainer>
}

import {Tag} from "antd";
import React from "react";

export function tagRender(props) {
    const {label, closable, onClose} = props;
    const onPreventMouseDown = event => {
        event.preventDefault();
        event.stopPropagation();
    };
    return (
        <Tag
            color={'green'}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{marginRight: 3, whiteSpace: "break-spaces"}}
        >
            {label}
        </Tag>
    );
}
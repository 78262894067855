import {Link} from "react-router-dom";

type NotesPresentProps = {
    title: string;
    description: string;
    sellPrice: number;
    name: string;
    semester: string;
    exam: string;
    yearLevel: string;
    numberOfPages: number;
    id: string;
}

export default (props: NotesPresentProps) => {
    const {title, description, sellPrice, name, semester, exam, yearLevel, numberOfPages, id} = props;

    return <Link to={`/notes/buy/${id}`} className={"col-span-1 p-3 border border-inherit grid grid-rows-10 w-full h-full"}>
        <div className={"row-span-1 font-bold text-lg crimson-font heading-text"}>{title}</div>
        <div className={"row-span-6 text-sm grey-text"}>{description}</div>
        <div className={"row-span-1 text-lg my-2 red-highlight"}>${sellPrice}</div>
        <div className={"flex flex-row items-center heading-text"}><img className={"h-4 w-4 mr-2"}
                                                                   src={"icons/notes/icon-time.png"}/>
            <div className={"row-span-1 text-sm heading-text"}>{`${name}, Semester ${semester}, ${exam}`}</div>
        </div>
        <div className={"flex flex-row items-center heading-text"}><img className={"h-4 w-4 mr-2"}
                                                                   src={"icons/notes/icon-pages.png"}/>
            <div className={"row-span-1 text-sm"}>{`${numberOfPages} pages`}</div>
        </div>
    </Link>
}

import React from 'react'
import {
    Typography,
    Row,
    Col,
    Divider,
    Rate
} from "antd";

const {Text} = Typography

export default ({feedback}) => {
    return (
        <>
            <Row style={{maxHeight: '700px', overflow: 'scroll'}}>
                {feedback?.map(feedback => (
                    <Col span={22} className="padding-20">
                        <Text className="reviewer">{feedback?.reviewer?.name}</Text>
                        <div className="flex">
                            <Rate className="star-space-desktop" disabled value={feedback?.rating}/>
                            <Text className="rating-text">{feedback?.rating}.0</Text>
                        </div>
                        <Text className="private">{feedback?.title}</Text>
                        <div>
                            <Text className="comment-text width-100">
                                “{feedback?.content}”
                            </Text>
                        </div>

                        <Divider type="horizontal"/>
                    </Col>
                ))}
            </Row>
        </>
    )
}
import gql from 'graphql-tag';
import React from 'react';
import {useQuery} from '@apollo/client';
import BankAccountSettings from './BankAccountSettings';
import DobSettings from './DobSettings';
import {Button, Col, Row, Tooltip,} from "antd";
import AddressSettings from "./AddressSettings";
import Title from "antd/es/typography/Title";
import {InfoCircleOutlined} from "@ant-design/icons";

const addressExclusionText =
    <span>Your address is required for payouts</span>

export default ({user, ...props}: any) => {


    const {data} = useQuery(
        gql`
            query User($id: ID!) {
                user(id: $id) {
                    id
                    dob
                    stripeAccount
                }
            }
        `,
        {variables: {id: user?.id}, skip: !user},
    );

    return data?.user ? (
        <>
            <Row>
                <Col span={24} id="PayoutDesktop">
                    <Row>

                        <Col span={24}>
                            <DobSettings {...data?.user} />
                            <Col span={24}>
                                <Row className="row-height"/>
                                <Row>
                                    <Title className="payout-info-title">Address</Title>
                                    <Col span={10}>
                                        <Tooltip title={addressExclusionText}>
                                            <InfoCircleOutlined style={{marginLeft: 5, color: '#E26F6F'}}/>
                                        </Tooltip>
                                    </Col>
                                </Row>

                                <Col span={21}>
                                    <AddressSettings {...props} />
                                </Col>
                            </Col>
                            <BankAccountSettings {...props} />
                        </Col>
                    </Row>
                    <Row className="row-height"/>
                    <Row className="payout-setting-align">
                        <Col xs={0} sm={0} md={0} lg={13} xxl={13} xl={13}/>
                        <Col xs={22} sm={22} md={22} lg={6} xxl={6} xl={6}>
                            <Button htmlType='submit' type="primary" size="large" block>
                      <span className="payout-save-btn">
                        Save
                      </span>
                            </Button>`
                        </Col>
                    </Row>

                </Col>
                <Col span={24}>
                    <Row className="row-height"/>
                    <Row className="row-height"/>
                </Col>
            </Row>
        </>
    ) : (
        <div></div>
    );
};

import Navigation from "./Navigation";
import {Col, Row} from "antd";
import React from "react";
import {useQuery} from "@apollo/client";
import {User} from "../../@graphql-client/types";
import {getCurrentUser, getCurrentUserProfile} from "../common/queries";
import {useGlobalLoading} from "../../hooks";
import {Redirect, Route, Switch} from "react-router-dom";

import {SellNotes} from "./CreatesNotes/Desktop";
import {MyNotes} from "./MyNotes/Desktop";
import {FindNotes} from "./FindNotes/Desktop";
import {MyActivity} from "./MyActivity/Desktop";

import {FAQ} from "./FAQ/Desktop";
import slugify from "../slugify";


let refetchQueries = [{query: getCurrentUserProfile}];

export default () => {

    const {loading: currentUserLoading, data: user} = useQuery<{ me: User }>(getCurrentUser);
    const {loading, data} = useQuery<{ me: User }>(getCurrentUserProfile);
    useGlobalLoading([loading, currentUserLoading]);

    return (
        <>
            <Col id="header-notes" xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
                <div>

                    <Navigation user={user?.me}/>
                </div>

            </Col>


            <Row id='MainRow' style={{alignItems: "center"}}>

                <Col style={{alignSelf: "flex-start"}}
                     xs={0} sm={0} md={0} lg={4} xxl={4} xl={4}>
                        <Navigation user={user?.me}/>
                </Col>

                <Col xs={23} sm={23} md={23} lg={20} xxl={20} xl={20}>
                    <Switch>
                        {[
                            ...[
                                ["Sell Your Notes", SellNotes],
                                ["My Notes", MyNotes],
                                ["Find Notes", FindNotes],
                                ["My Activity", MyActivity],
                                ["FAQ", FAQ],
                            ].map(([name, Component]) => [
                                name,
                                () => <Component key={slugify(name)}/>,
                            ]),
                        ].map(([name, constructor]: any) => (
                            <Route
                                key={slugify(name)}
                                exact
                                path={`/notes/${slugify(name)}`}
                                render={() => (
                                    <>
                                        {React.cloneElement(constructor(), {
                                            user: data?.me,
                                            refetchQueries,
                                            initialValues: data?.me,
                                        })}
                                    </>
                                )}
                            />
                        ))}
                        <Route
                            render={() => {
                                return <Redirect to="/notes/sell-your-notes"/>
                            }}
                        />
                    </Switch>
                </Col>


            </Row>
        </>
    )
}

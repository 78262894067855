import React from "react";
import {Avatar, Button, Col, Divider, Row, Typography} from "antd";

const {Title, Text} = Typography;
import "./Desktop.less";


export const GetStarted = (props: any) => {

    const {isButtonPressed} = props

    return (

        <>
            <Row>
                <Col xs={1} sm={1} md={1} lg={0} xxl={0} xl={0}/>

                <Col id="GettingStartedDesktop" xs={23} sm={23} md={23} lg={22} xxl={22} xl={22}>

                    <Row className="getting-started-notes-align">

                        <Col xs={18} sm={18} md={18} lg={22} xxl={22} xl={22}>
                            <Title className="getting-started-title">Sell Your Notes</Title>
                        </Col>

                        <Col xs={20} sm={20} md={20} lg={20} xxl={20} xl={20}>

                            <div style={{
                                marginTop: "5%",
                            }}>
                                <Row>

                                    <Col span={2}>

                                    </Col>

                                    <Col xs={20} sm={20} md={20} lg={24} xxl={24} xl={24} span={24}>
                                        To have the right to sell your notes, the work must be original. The way you
                                        summarise your subjects through language, layout, colour coding etc, creates a
                                        unique document. You are the author of this original work and own the IP.
                                    </Col>

                                    <Col span={2}>

                                    </Col>
                                </Row>

                            </div>

                        </Col>
                    </Row>

                    <Divider type="horizontal"/>

                    <Row>

                        <Col
                            style={{
                                // border: "1px solid red",
                                height: "150px"
                            }}
                            xs={18} sm={18} md={18} lg={8} xxl={8} xl={8}
                        >
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-evenly",
                                height: "100%",
                                paddingLeft: "10%",
                                paddingRight: "10%",
                            }}>


                                <Avatar
                                    size={40}
                                >1</Avatar>
                                <Title className="steps-title">Upload Notes</Title>

                                <div style={{fontSize: '15px', lineHeight: 1.5}}>
                                    Put all that hard work to good use and upload your masterpiece!
                                </div>


                            </div>

                        </Col>

                        <Col
                            style={{
                                // border: "1px solid red",
                                height: "150px"

                            }}
                            xs={18} sm={18} md={18} lg={8} xxl={8} xl={8}
                        >
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-evenly",
                                height: "100%",
                                paddingLeft: "10%",
                                paddingRight: "10%",
                            }}>
                                <Avatar
                                    size={40}
                                >2</Avatar>
                                <Title className="steps-title">Set price</Title>

                                <div style={{fontSize: '15px', lineHeight: 1.5}}>
                                    You set the price of your notes. We take a 30% commission on the sale.
                                </div>

                            </div>
                        </Col>

                        <Col
                            style={{
                                // border: "1px solid red",
                                height: "150px"
                            }}
                            xs={18} sm={18} md={18} lg={8} xxl={8} xl={8}
                        >
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-evenly",
                                height: "100%",
                                paddingLeft: "10%",
                                paddingRight: "10%",

                            }}>
                                <Avatar
                                    size={40}
                                >3</Avatar>

                                <Title className="steps-title">Start earning</Title>

                                <div style={{fontSize: '15px', lineHeight: 1.5}}>
                                    You will be paid immediately following the sale!
                                </div>

                            </div>
                        </Col>

                    </Row>

                    <Row className="row-height"/>


                    <Col
                        style={{
                            // border: "1px solid red",
                        }}
                        xs={18} sm={18} md={18} lg={8} xxl={8} xl={8}
                    >
                        <div style={{paddingLeft: "10%"}}>

                            <Button type="primary" size="large" block onClick={() => isButtonPressed(true)}>
                              <span style={{textAlign: "center", fontSize: "15px", fontWeight: "bold"}}>
                                Get Started
                              </span>
                            </Button>

                        </div>

                    </Col>

                    <Row className="row-height"/>

                </Col>
            </Row>
        </>
    )
}

import React from "react";
import {Col, Input, Row, Typography} from "antd";
import {InputNumber} from "antd/es";
import {UploadNotes} from "./UploadNotes";

const {TextArea} = Input
const {Title, Text} = Typography;

export const CreateRow = ({tutorNotes, setTutorNotes}) => {

    let newTutorNotes = {...tutorNotes}

    return (
        <Row>
            <Col xs={2} sm={2} md={2} lg={0} xxl={0} xl={0}/>

            <Col id="CreateNotesDesktop" xs={20} sm={20} md={20} lg={22} xxl={22} xl={22}>

                <Row >


                    <Col xs={24} sm={24} md={24} lg={24} xxl={24} xl={24}
                         style={{
                             // border: "1px solid blue",
                             display: "flex",
                             flexDirection: "column",
                             alignItems: "flex-start",
                             padding: 25
                         }}>
                        <Title className="create-notes-title">Let's create your notes</Title>
                        <span style={{paddingTop: 5}}>Describe your notes to attract potential buyers</span>
                    </Col>


                    <Col xs={24} sm={24} md={24} lg={10} xxl={10} xl={10}
                         style={{
                             // border: "1px solid blue",
                             display: "flex",
                             flexDirection: "column",
                             alignItems: "flex-start",
                             padding: 15
                         }}
                    >
                        <Input
                            placeholder="Title"
                            className="select-width"
                            size="large"
                            value={tutorNotes?.title}
                            onChange={(e) => {
                                newTutorNotes.title = e.target.value
                                setTutorNotes(newTutorNotes)
                            }}
                        />
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={24} xxl={24} xl={24}
                         style={{
                             // border: "1px solid blue",
                             display: "flex",
                             flexDirection: "column",
                             alignItems: "flex-start",
                             padding: 15
                         }}
                    >

                        <div>
                            By uploading, you agree that your notes:

                            <ul>
                                <li>are not pure class notes;</li>
                                <li> are not tutorial, or exam questions/answers;</li>
                                <li> are not assignments or essays (or could be used as assignments or essays);</li>
                                <li> are not hand-written (diagrams may be drawn);</li>
                                <li> do not include your name (we post anonymously);</li>
                                <li> are not for a single class, lecture or tutorial; and</li>
                                <li> do not include images or diagrams from textbooks or slides.</li>
                            </ul>
                        </div>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={20} xxl={20} xl={20}

                         style={{
                             // border: "1px solid blue",
                             display: "flex",
                             flexDirection: "column",
                             alignItems: "flex-start",
                             padding: 15
                         }}
                    >
                        <TextArea
                            name="info"
                            autoSize={{minRows: 4, maxRows: 6}}
                            placeholder="Tell us more about your notes. It is important to include:&#10;- List of topics covered&#10;- Readings that are covered"
                            className="select-width"
                            value={tutorNotes?.notesInfo}
                            onChange={(e) => {
                                newTutorNotes.notesInfo = e.target.value
                                setTutorNotes(newTutorNotes)
                            }}
                        />


                    </Col>

                    <Col xs={24} sm={24} md={24} lg={10} xxl={10} xl={10}
                         style={{
                             // border: "1px solid blue",
                             display: "flex",
                             flexDirection: "column",
                             alignItems: "flex-start",
                             padding: 15
                         }}>
                        <Text>Word count</Text>
                        <InputNumber
                            min={0}
                            style={{width: "93%", borderRadius: 5}}
                            size="large"
                            value={tutorNotes?.wordCount}
                            onChange={(value) => {
                                newTutorNotes.wordCount = value
                                setTutorNotes(newTutorNotes)
                            }}
                        />

                    </Col>

                    <Col xs={24} sm={24} md={24} lg={10} xxl={10} xl={10}

                         style={{
                             // border: "1px solid blue",
                             display: "flex",
                             flexDirection: "column",
                             alignItems: "flex-start",
                             padding: 15
                         }}
                    >
                        <Text>Number of pages</Text>
                        <InputNumber
                            min={0}
                            placeholder="Number of pages"
                            style={{width: "93%", borderRadius: 5}}
                            size="large"
                            value={tutorNotes?.numberOfPages}
                            onChange={(value) => {
                                newTutorNotes.numberOfPages = value
                                setTutorNotes(newTutorNotes)
                            }}
                        />

                    </Col>


                    <Col xs={24} sm={24} md={24} lg={10} xxl={10} xl={10}
                         style={{
                             // border: "1px solid blue",
                             alignItems: "flex-start",
                             padding: 15
                         }}

                    >
                        <Row>

                            <Col xs={22} sm={22} md={22} lg={22} xxl={22} xl={22}>

                                <UploadNotes
                                    title={newTutorNotes.title ? (newTutorNotes.title + "-sample_notes") : "sample-notes"}
                                    type="sampleNotes"
                                    tutorNotes={tutorNotes}
                                    setTutorNotes={setTutorNotes}
                                />
                            </Col>

                            <Col xs={22} sm={22} md={22} lg={22} xxl={22} xl={22}
                                 style={{
                                     // border: "1px solid blue",
                                     display: "flex",
                                     flexDirection: "column",
                                     alignItems: "flex-start",
                                     padding: 15,
                                     fontSize: 12,
                                     fontWeight: "bold",
                                     lineHeight: 1
                                 }}
                            >
                                <div>
                                    Upload a short sample of your notes as a preview for potential buyers (PDF only,
                                    optimal
                                    size 2MB)
                                </div>

                            </Col>

                        </Row>

                    </Col>

                    <Col xs={24} sm={24} md={24} lg={10} xxl={10} xl={10}
                         style={{
                             // border: "1px solid blue",
                             alignItems: "flex-start",
                             padding: 15
                         }}
                    >
                        <Row>

                            <Col xs={22} sm={22} md={22} lg={22} xxl={22} xl={22}>
                                <UploadNotes
                                    title={newTutorNotes.title ? (newTutorNotes.title + "-full_notes") : "full-notes"}
                                    type="notes"
                                    tutorNotes={tutorNotes}
                                    setTutorNotes={setTutorNotes}
                                />
                            </Col>


                            <Col xs={22} sm={22} md={22} lg={22} xxl={22} xl={22}
                                 style={{
                                     // border: "1px solid blue",
                                     display: "flex",
                                     flexDirection: "column",
                                     alignItems: "flex-start",
                                     padding: 15,
                                     fontSize: 12,
                                     fontWeight: "bold",
                                     lineHeight: 1
                                 }}
                            >
                                <div>
                                    PDF only (optimal size 10MB)
                                </div>

                            </Col>

                        </Row>


                    </Col>


                </Row>
            </Col>
        </Row>
    )
}

import React from "react";
import { Row, Col, Input } from "antd";
import { Button } from "antd";
import { Typography } from "antd";
import "./LandingPageStylesMobile.less";
import { Link } from "react-router-dom";

import {
    homePageBookTutorRedirection,
    homePageBecomeTutorRedirection,
    homePageBuyNotesRedirection,
} from "../Constants/utilities";
import LandingPageBottomCta from "./LandingPageBottomCta";

const { Title, Paragraph } = Typography;

export default ({
                    user,
    heroImage,
                    innovationText,
                    tutoringText,
                    parentText,
                    bookTutorBtnText,
                    becomeTutorBtnText,
                    buyNotesBtnText,
                    choosePocketnoteSection,
                    whyChooseTitle,
                    whyChooseSubtitle,
                    signUpText,
    endForm,
    children
                }: any) => {
    return (
        <>
            <Row className="margin-top-36" id={"landingPageTemplateMobile"}>
                <Col span={24}>
                    <Title className="header align-center"> {innovationText}</Title>
                </Col>
                <Col span={24}>
                    <Title className="header align-center">{tutoringText}</Title>
                </Col>

                <Col className="margin-top-36" span={24}>
                    <Paragraph className="align-center padding-48">
                        {parentText}
                    </Paragraph>
                </Col>

                <Col className="align-center" span={24}>
                    <Link to={homePageBookTutorRedirection(user)}>
                        <Button type="primary" size="large">
                            {bookTutorBtnText}
                        </Button>
                    </Link>
                </Col>
                <Col className="align-center" span={24}>
                    <Link to={homePageBecomeTutorRedirection(user)}>
                        <Button size="large">{becomeTutorBtnText}</Button>
                    </Link>
                </Col>
            </Row>
            <Row>
                <Col span={24} className="margin-top-36 img-center">
                    <div
                        style={{
                            backgroundImage: `url(${heroImage})`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            width: 350,
                            height: 200,
                        }}
                    ></div>
                </Col>

                <div className="section-choose-us background-secondary">
                    <div className="margin-top-30 margin-bottom-30">
                        <div className="fails-text landing-heading-text">
                            {whyChooseTitle}
                        </div>
                        {whyChooseTitle != null && <div className="mt-2 subtitle-text">{whyChooseSubtitle}</div>}
                    </div>
                    <Row align="middle">
                        {choosePocketnoteSection.map((section, index) => (
                            <Col
                                key={index}
                                xs={22}
                                sm={22}
                                md={11}
                                lg={11}
                                xl={11}
                                xxl={11}
                                className="box-container"
                            >
                                <div>
                                    <div
                                        style={{
                                            backgroundImage: section.icon,
                                            backgroundSize: "contain",
                                            backgroundRepeat: "no-repeat",
                                            backgroundPosition: "center",
                                            width: 40,
                                            height: 50,
                                        }}
                                    ></div>
                                </div>
                                <div>
                                    <h5>{section.heading}</h5>
                                </div>
                                <div>{section.details}</div>
                            </Col>
                        ))}
                        <Col span={24} className="text-center">
                            <Link className={"w-full"} to={homePageBuyNotesRedirection(user)}>
                                <Button type="primary" size="large">
                                    {buyNotesBtnText}
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                </div>
            </Row>
            {children}

            {endForm == null ?
                <LandingPageBottomCta user={user} signUpText={signUpText}/> : endForm}
        </>
    );
};

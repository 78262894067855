import React from 'react';
import {Button, Row, Col, Typography, Divider, Input} from 'antd';
import {Field} from 'formik'
import './Desktop.less';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

const {Title} = Typography;
export default ({auth, history}) => {
    try {
        if (window.location.search) {
            const searchParams = new URLSearchParams(window.location.search);
            const session = searchParams.get("session");
            if (session) {
                const { token, user } = JSON.parse(session);
                auth.dispatch({ token, user });
                history.push('/settings/security');
            }
        }
    } catch (e) {}

    return (
        <div className="custom-height">
            <Row>
                <Col xs={1} sm={1} md={1} lg={0} xxl={0} xl={0}/>
                <Col xs={23} sm={23} md={23} lg={9} xxl={9} xl={9} id='PasswordReset'>
                    <Row className="account-setting-align">
                        <Title className="account-setting-title">Change Password</Title>
                    </Row>
                    <Divider className="divider-margin" type="horizontal"/>
                    <Row>
                        <Col span={2}></Col>
                        <Col span={22}>
                            <Col>
                                <Col span={24} className="flex form-row">
                                    <Col span={22}>
                                        <Field
                                            as={Input.Password}
                                            type="password"
                                            name="password"
                                            required
                                            autoComplete="new-password"
                                            className="select-width"
                                            size='large'
                                            placeholder="New password"
                                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                        />

                                        <Field
                                            style={{color:'blue'}}
                                            as={Input.Password}
                                            type="password"
                                            name="confirmPassword"
                                            required
                                            autoComplete="new-password"
                                            className="select-width"
                                            size='large'
                                            placeholder="Confirm new password"
                                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                        />

                                        <Button htmlType='submit' type='primary' size="large" block><span
                                            className="upload-pic-text">Save</span></Button>

                                        <Row className="row-height"></Row>
                                    </Col>
                                </Col>
                            </Col>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>

    );
};

import React from 'react'
import {Row, Col, Typography} from 'antd';
import {useQuery} from "@apollo/client";
import gql from "graphql-tag";
import {FullScreenLoader} from "../common/Loader";
import {orderBy} from "lodash";
import moment from "moment";
import EventListItem from "../Events/EventListItem";
import StaySafeModal from "../StaySafeModal";

const {Title} = Typography;

export default ({reverse = false, selector, readOnly, heading}: any) => {
    const {loading, data, refetch} = useQuery(gql`
    query Events {
      me {
     
        id
        role
        ${selector} {
        review {
            id
            title
            rating
            content
        }
        customRate
         everyWeek
          id
          description
          startDate
          endDate
          owner {
            id name
          }
          enrollments {
            id
            approved
            termsAccepted
            student {
              id
              name
            }
            parent {
              id
            }
          }
        }
      }
    }
  `);
    const events = (data?.me && data?.me[selector]) || [];

    return (
        <>
            {events.length ? (

                <Col span={24} id='EventsDesktop'>
                    <StaySafeModal refetch={refetch}/>
                    <Row className="event-setting-align">
                        <Title className="event-setting-title">{heading}</Title>
                    </Row>
                    <Row className="row-height"/>
                    <Row className="row-height"/>
                    <div className="events-history-overflow">
                        <Col>
                            {loading ?
                                <FullScreenLoader/> : orderBy(events, ['startDate'], [reverse ? 'desc' : 'asc']).map(
                                    ({id, owner, startDate, endDate, enrollments, review, everyWeek, customRate}) => {
                                        const day = moment(startDate);
                                        return (
                                            <EventListItem
                                                {...{
                                                    key: id,
                                                    id,
                                                    readOnly,
                                                    me: data?.me,
                                                    day,
                                                    owner,
                                                    startDate,
                                                    endDate,
                                                    enrollments,
                                                    heading,
                                                    review,
                                                    everyWeek,
                                                    customRate
                                                }}
                                            />
                                        );
                                    },
                                )}
                            <Row className="row-height"/>
                            <Row className="row-height"/>
                        </Col>
                    </div>

                    <Row className="row-height"/>
                </Col>
            ) : null}
        </>
    )
}
import {Button} from "antd";
import React from "react";
import {Link} from "react-router-dom";
import "./LandingComponentStyles.less";
import {ButtonProps, getSubheadingWithLineBreak} from "../../pages/Constants/utilities";

type LandingRowProps = {
    heading: string;
    subheading: string;
    imgSrc: string;
    isHero: boolean;
    reverse?: boolean;
    primaryButton?: ButtonProps;
    secondaryButton?: ButtonProps;
    tertiaryButton?: ButtonProps;
    quaternaryButton?: ButtonProps;
}
export default (props: LandingRowProps) => {
    const {heading, subheading, imgSrc, isHero, reverse, primaryButton, secondaryButton, tertiaryButton, quaternaryButton} = props;

    return <div className={"md:grid md:grid-cols-2 md:px-20 gap-4 md:my-6 my-6 px-4"}>

        {reverse === true && <div className={"hidden md:block col-span-1 my-3 place-self-center"}>
            <img className={"max-h-96"} src={imgSrc}/>
        </div>}

        <div className={"col-span-1"}>
            {isHero ?
                <div className="innovation-text-landing text-4xl md:text-6xl my-3">{heading}</div> :
                <div className="innovation-text-landing text-4xl my-3">{heading}</div>
            }
            <img className={"md:hidden my-3 max-h-96"} src={imgSrc}/>
            <div className="secondary-text-dmsans text-md my-3">{getSubheadingWithLineBreak(subheading)}</div>
            <div className={"md:w-3/5 w-full md:grid md:grid-cols-2 md:gap-2"}>
                {primaryButton != null ?
                    <Link to={primaryButton.to()}>
                        <Button type="primary" className={"landing-button landing-button-primary"}>
                            {primaryButton.text}
                        </Button></Link> : null}
                {secondaryButton != null ?
                    <Link to={secondaryButton.to()}>
                        <Button className={"landing-button landing-button-secondary"}>
                            {secondaryButton.text}
                        </Button></Link> : null}
                {tertiaryButton != null ?
                    <Link to={tertiaryButton.to()}>
                        <Button type="primary" className={"landing-button landing-button-primary"}>
                            {tertiaryButton.text}
                        </Button></Link> : null}
                {quaternaryButton != null ?
                    <Link to={quaternaryButton.to()}>
                        <Button className={"landing-button landing-button-secondary"}>
                            {quaternaryButton.text}
                        </Button></Link> : null}
            </div>
        </div>

        {(reverse == null || reverse === false) && <div className={"hidden md:block col-span-1 my-3 place-self-center"}>
            <img className={"max-h-96"} src={imgSrc}/>
        </div>}

    </div>

}

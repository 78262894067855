import React from "react";
import {
    Row,
    Col,
    Input,
    DatePicker,
    Card,
    Select, Button
} from "antd";
import {
    MinusCircleOutlined,
} from "@ant-design/icons";
import "./Desktop.less";
import moment from 'moment';


export default ({setEdu, education, edu, deleteEducationRow, schools}) => {

    const onEndChange = (date) => {
        setEdu(
            education.map(item =>
                item.id === edu.id
                    ? {...item, endDate: moment(date).unix()}
                    : item
            ))
    }
    const onStartChange = (date) => {
        setEdu(
            education.map(item =>
                item.id === edu.id
                    ? {...item, startDate: moment(date).unix()}
                    : item
            ))
    }

    const onInputChange = (key, value) => {
        setEdu(
            education.map(item =>
                item.id === edu.id
                    ? {...item, [key]: value}
                    : item
            ))
    }

    return (
        <Card>
            <Row>
                <Col xs={24} sm={24} md={24} lg={21} xxl={21} xl={21}>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={11} xxl={11} xl={11}>
                            <Select
                                onChange={(value) => onInputChange('name', value)}
                                placeholder={"School"}
                                value={edu.name ? edu.name : null}
                                className="select-width"
                                options={schools}
                                showSearch
                                virtual={true}
                                size="large"
                            />

                            <Input
                                placeholder="Degree"
                                className="select-width"
                                size="large"
                                value={edu.degree}
                                onChange={(event) => onInputChange('degree', event.target.value)}

                            />

                            <DatePicker
                                size={'large'}
                                className="select-width"
                                picker="month"
                                placeholder={"Start Date"}
                                defaultValue={moment(moment.unix(edu.startDate).format('YYYY-MM'))}
                                onChange={onStartChange}
                            />

                        </Col>
                        <Col xs={0} sm={0} md={0} lg={2} xxl={2} xl={2}/>
                        <Col xs={24} sm={24} md={24} lg={11} xxl={11} xl={11}>
                            <Input
                                placeholder="Field of study"
                                className="select-width"
                                size="large"
                                value={edu.subject}
                                onChange={(event) => onInputChange('subject', event.target.value)}

                            />

                            <Input
                                placeholder="Grade"
                                className="select-width"
                                size="large"
                                value={edu.grade}
                                onChange={(event) => onInputChange('grade', event.target.value)}

                            />

                            <DatePicker
                                size={'large'}
                                className="select-width"
                                picker="month"
                                placeholder={"End Date"}
                                defaultValue={edu.endDate ? moment(moment.unix(edu.endDate).format('YYYY-MM')) : null}
                                onChange={onEndChange}
                            />

                        </Col>
                    </Row>
                </Col>
                <Col xs={0} sm={0} md={0} lg={2} xl={2} xxl={2}/>
                <Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1} className={"delete-icon"}>
                    <MinusCircleOutlined onClick={() => deleteEducationRow(edu.id)} className="icon-specs"/>
                </Col>
                <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}><Row className="row-height"/></Col>
                <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
                    <Button className="delete-btn" size="large" block onClick={() => deleteEducationRow(edu.id)}>
                        <span className="delete-btn-text">Delete</span>
                    </Button>
                </Col>
            </Row>

        </Card>
    )
}

import React from "react";
import {Avatar, Button, Col, Collapse, Divider, Input, Popconfirm, Row, Select, Tag, Typography, Upload} from "antd";
import {MinusCircleOutlined, PlusCircleOutlined, UserOutlined,} from "@ant-design/icons";
import {Field} from 'formik';
import "./Desktop.less";
import Education from './Education'
import Work from './Work'
import Profile from './Profile'
import {UserRole} from "@graphql-client/types";
import Verification from "./Verification";
import SetAvailability from "./SetAvailability";
import StudentsSettings from '../StudentsSettings'
import PayoutsSettings from "../PayoutsSettings";
import AddressSettings from "../AddressSettings";
import {useMutation} from "@apollo/client";
import {gql} from "graphql-tag";
import {updateUser as updateUserMutation} from "../../common/mutations";
import {toast} from "react-toastify";
import {TutorProfileQRCode} from "../../Tutor/TutorProfileQRCode";

const {Panel} = Collapse;

const {Title, Text} = Typography;
export default ({actions, user, props, Languages}) => {

    const [createUpload] = useMutation(gql`
        mutation CreateUpload($mime: String!, $filename: String, $title:String, $uploadType: String) {
            createUpload(mime: $mime, filename: $filename,title:$title, uploadType: $uploadType) {
                id
                signedUrl
            }
        }
    `);
    const [updateUser] =
        useMutation(updateUserMutation);

    const uploadProps = {
        name: 'file',
        multiple: false,
        action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
        showUploadList: false,
        accept: "image/*",
        beforeUpload() {
            return false;
        },
        async onChange({file}) {
            try {
                const {data} = await createUpload({
                    variables: {mime: file.type, filename: file.name, uploadType: "PROFILE"},
                });
                const upload = data.createUpload;

                await fetch(upload.signedUrl, {
                    method: 'PUT',
                    body: file,
                });

                await updateUser({
                    variables: {
                        id: user?.me?.id,
                        avatar: upload?.id,
                    },
                });

                toast.success('Profile picture saved!');

            } catch (e) {
                console.log("error ", e)
                toast.error('Profile picture uploading failed...');
            }

        },
    };

    const {handleDelete} = actions;

    function tagRender(props) {
        const {label, closable, onClose} = props;
        const onPreventMouseDown = event => {
            event.preventDefault();
            event.stopPropagation();
        };
        return (
            <Tag
                color={'green'}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{marginRight: 3}}
            >
                {label}
            </Tag>
        );
    }

    return (
        <>
            <Row>
                <Col xs={1} sm={1} md={1} lg={0} xxl={0} xl={0}/>
                <Col id="AccountDesktop" xs={23} sm={23} md={23} lg={18} xxl={18} xl={18}>
                    <Row className="account-setting-align">
                        <Col xs={18} sm={18} md={18} lg={16} xxl={16} xl={16}>
                            <Title className="account-setting-title">Account Settings</Title>
                        </Col>

                        <Col xs={0} sm={0} md={0} lg={6} xxl={6} xl={6}>
                            <Button htmlType='submit' type="primary" size="large" block>
                      <span className="upload-pic-text">
                        Save
                      </span>
                            </Button>
                        </Col>
                    </Row>
                    <Divider type="horizontal"/>
                    <Row>
                        <Col span={2}/>
                        <Col span={20}>
                            <Row className="flex">
                                <Col xs={24} sm={24} md={24} lg={6} xxl={6} xl={6} className="textAlign">
                                    <Avatar
                                        size={140}
                                        icon={<UserOutlined/>}
                                        src={user?.me?.avatar?.url}
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={11} xxl={11} xl={11} className="buttons-margin">
                                    <Upload
                                        multiple={false}
                                        showUploadList={false}
                                        {...uploadProps}
                                    >
                                        <Button type="primary" size="large" block>
                                          <span className="upload-pic-text">
                                            Upload New Picture
                                          </span>
                                        </Button>
                                    </Upload>
                                    <Popconfirm
                                        okText="Yes"
                                        cancelText="No"
                                        onConfirm={handleDelete}
                                        title="Are you sure to delete your profile picture ?"
                                    >
                                        <Button className="delete-btn" size="large" block>
                                            <span className="delete-btn-text">Delete</span>
                                        </Button>
                                    </Popconfirm>
                                    {/* )} */}
                                </Col>
                                {user?.me?.role === UserRole.Tutor &&
                                <Col xs={24} sm={24} md={24} lg={7} xxl={7} xl={7} className="buttons-margin">
                                    <TutorProfileQRCode tutorId={user?.me?.id} tutor={user?.me}/>
                                </Col>
                                }
                                <Col xs={0} sm={0} md={0} lg={1} xxl={1} xl={1}/>
                            </Row>
                            <Row className="row-height"/>
                            <Col>
                                <Title className="contact-info-title">Contact Info</Title>
                                <Row className="flex form-row">
                                    <Col xs={24} sm={24} md={24} lg={11} xxl={11} xl={11}>
                                        <Field
                                            as={Input}
                                            name="name"
                                            placeholder="First name:"
                                            className="select-width"
                                            size="large"
                                        />

                                        <Select
                                            onChange={(value) => {
                                                props.setFieldValue("languages", value)
                                            }}
                                            showArrow
                                            mode="multiple"
                                            tagRender={tagRender}
                                            placeholder="Languages"
                                            className="select-width"
                                            size="large"
                                            options={Languages}
                                            value={props.values.languages ? props.values.languages : null}
                                        />

                                        <Select
                                            onChange={(value) => {
                                                props.setFieldValue("gender", value)
                                            }}
                                            placeholder="Gender"
                                            value={props.values.gender ? props.values.gender : null}
                                            className="select-width"
                                            options={[{value: 'MALE'}, {value: 'FEMALE'}, {value: 'OTHER'}]}
                                            size="large"
                                        />
                                        <Row>
                                            <Col className="email-col">
                                                <Text className="email-address-text ">
                                                    {" "}
                                                    Email Address
                                                </Text>
                                                <Input
                                                    placeholder="Your email"
                                                    className="select-width"
                                                    size="large"
                                                    value={user?.me?.email}
                                                    disabled
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="row-height"/>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={11} xxl={11} xl={11}>

                                        {user?.me?.role !== UserRole.Tutor &&
                                        < AddressSettings {...props} />
                                        }

                                        <Field
                                            name="tel"
                                            as={Input}
                                            placeholder="Mobile"
                                            className="select-width"
                                            size="large"
                                        />
                                        {user?.me?.role === UserRole.Tutor &&

                                        <Select
                                            onChange={(value) => {
                                                props.setFieldValue("lessonTitle", value)
                                            }}
                                            style={{fontFamily: "Crimson Pro", fontSize: "16px", color: "black"}}
                                            placeholder="In-person Availability"
                                            value={props.values.lessonTitle}
                                            className="select-width"
                                            options={[{value: 'BOTH', label: "Both"}, {
                                                value: 'ONLINE',
                                                label: "Online"
                                            }, {value: 'IN_PERSON', label: "In Person"}]}
                                            size="large"
                                        />
                                        }

                                    </Col>
                                </Row>
                            </Col>
                            {user?.me?.role === UserRole.Tutor && (
                                <Col>
                                    <Row className="row-height"/>
                                    <Profile props={props}/>
                                    <Row className="row-height"/>
                                </Col>
                            )}
                        </Col>
                        <Col xs={2} sm={2} md={2} lg={0} xxl={0} xl={0}/>
                        <Col xs={2} sm={2} md={2} lg={0} xxl={0} xl={0}/>
                        <Col xs={20} sm={20} md={20} lg={0} xxl={0} xl={0} className="textAlign">
                            <Button htmlType='submit' type="primary" size="large" block>
                      <span className="upload-pic-text">
                        Save
                      </span>
                            </Button>
                            <Row className="row-height"/>
                        </Col>

                    </Row>
                </Col>

                {user?.me?.role === UserRole.Tutor && (
                    <>
                        <Col xs={23} sm={23} md={23} lg={18} xxl={18} xl={18} id="AccountDesktopCollapse">
                            <Collapse
                                defaultActiveKey={["1"]}
                                expandIcon={({isActive}) =>
                                    isActive ? (
                                        <MinusCircleOutlined className="icon-specs"/>
                                    ) : (
                                        <PlusCircleOutlined className="icon-specs"/>
                                    )
                                }
                                expandIconPosition="right"
                            >
                                <Panel header="Payouts" key="0" className="collapse-background">
                                    <Row className="row-height"/>
                                    <Row>
                                        <Col span={2}/>
                                        <Col xs={22} sm={22} md={22} lg={20} xxl={20} xl={20}>
                                            <PayoutsSettings user={user?.me} {...props}/>
                                        </Col>
                                    </Row>

                                </Panel>
                            </Collapse>
                        </Col>


                        <SetAvailability user={user?.me}/>
                        <Education user={user?.me}/>
                        <Work user={user?.me}/>
                        <Verification/>

                    </>
                )}
                {user?.me?.role === UserRole.Parent && (
                    <>
                        <Col xs={23} sm={23} md={23} lg={18} xxl={18} xl={18} id="AccountDesktopCollapse">
                            <Collapse
                                defaultActiveKey={["1"]}
                                expandIcon={({isActive}) =>
                                    isActive ? (
                                        <MinusCircleOutlined className="icon-specs"/>
                                    ) : (
                                        <PlusCircleOutlined className="icon-specs"/>
                                    )
                                }
                                expandIconPosition="right"
                            >
                                <Panel header="Students" key="0" className="collapse-background">
                                    <Row className="row-height"/>
                                    <Row>
                                        <Col sm={0} xs={0} md={0} lg={2} xl={2} xxl={2}/>
                                        <Col span={24} sm={24} xs={24} md={24} lg={20} xl={20} xxl={20}>
                                            <StudentsSettings user={user?.me}/>
                                        </Col>
                                    </Row>

                                </Panel>
                            </Collapse>
                        </Col>
                    </>
                )}


            </Row>
            <Row className="row-height"/>
            <Row className="row-height"/>
        </>
    );
};

import React from 'react';
import { Button } from 'antd';
import { Row, Col, List, Typography } from 'antd';
import jsPDF from 'jspdf';
import './Mobile.less'

function generatePdf() {
  const doc = new jsPDF();
  doc.html(document.querySelector("#privacy-policy-doc") as HTMLElement, {
    callback: function (pdf) {
      pdf.save("Pocketnote-Privay-Policy");
    }
  })
}

export default (
  { purpose, policyAppliesTo, info, howInfo, whenUsed, opting, safety, access, complainant, contactInd, contactUs, additions
  }: any) => {
  return (
    <Row id="PrivacyMobile">
      <Col span={22} className="privacy-col">
        <Row className="main-das">
          <div className="pri-policy-text">Privacy Policy</div>
          {/*<Button type="primary" size="small" className="download-btn" onClick={generatePdf}>Download</Button>*/}
        </Row>
        <Row className="text-div" id="privacy-policy-doc">
          <div>
            <Col span={24} className="conv-col-overflow">
              <Col span={22} className="text-width">
                <ol className="ordered-list">
                  <li className="headings"><span className="heading-text">PURPOSE OF OUR POLICY</span>
                    <List
                      dataSource={purpose}
                      renderItem={item => (
                        Array.isArray(item) ? <List
                          dataSource={item}
                          renderItem={item => (
                            <List.Item>
                              <Typography.Text className="bullet-text">{item["index"]}</Typography.Text>
                              <Typography.Text className="description">{item["description"]}</Typography.Text>
                            </List.Item>
                          )}
                        /> :
                          <List.Item>
                            <Typography.Text className="bullet-text">{item["index"]}</Typography.Text>
                            <Typography.Text className="description">{item["description"]}</Typography.Text>
                          </List.Item>
                      )}
                    />
                  </li>
                  <li className="headings"><span className="heading-text">WHO AND WHAT THIS POLICY APPLIES TO</span>
                    <List
                      dataSource={policyAppliesTo}
                      renderItem={item => (
                        <List.Item>
                          <Typography.Text className="bullet-text">{item["index"]}</Typography.Text>
                          <Typography.Text className="description">{item["description"]}</Typography.Text>
                        </List.Item>
                      )}
                    />
                  </li>
                  <li className="headings"><span className="heading-text">THE INFORMATION WE COLLECT</span>
                    <List
                      dataSource={info}
                      renderItem={item => (
                        Array.isArray(item) ? <List
                          dataSource={item}
                          renderItem={item => (
                            <List.Item>
                              <Typography.Text className="bullet-text">{item["index"]}</Typography.Text>
                              <Typography.Text className="description">{item["description"]}</Typography.Text>
                            </List.Item>
                          )}
                        /> :
                          <List.Item>
                            <Typography.Text className="bullet-text">{item["index"]}</Typography.Text>
                            <Typography.Text className="description">{item["description"]}</Typography.Text>
                          </List.Item>
                      )}
                    />
                  </li>
                  <li className="headings"><span className="heading-text">HOW INFORMATION IS COLLECTED</span>
                    <List
                      dataSource={howInfo}
                      renderItem={item => (
                        Array.isArray(item) ? <List
                          dataSource={item}
                          renderItem={item => (
                            <List.Item>
                              <Typography.Text className="bullet-text">{item["index"]}</Typography.Text>
                              <Typography.Text className="description">{item["description"]}</Typography.Text>
                            </List.Item>
                          )}
                        /> :
                          <List.Item>
                            <Typography.Text className="bullet-text">{item["index"]}</Typography.Text>
                            <Typography.Text className="description">{item["description"]}</Typography.Text>
                          </List.Item>
                      )}
                    />
                  </li>
                  <li className="headings"><span className="heading-text">WHEN PERSONAL INFORMATION IS USED & DISCLOSED</span>
                    <List
                      dataSource={whenUsed}
                      renderItem={item => (
                        Array.isArray(item) ? <List
                          dataSource={item}
                          renderItem={item => (
                            Array.isArray(item) ? <List
                              dataSource={item}
                              renderItem={item => (
                                <List.Item>
                                  <Typography.Text className="bullet-text">{item["index"]}</Typography.Text>
                                  <Typography.Text className="description">{item["description"]}</Typography.Text>
                                </List.Item>
                              )}
                            /> :
                              <List.Item>
                                <Typography.Text className="bullet-text">{item["index"]}</Typography.Text>
                                <Typography.Text className="description">{item["description"]}</Typography.Text>
                              </List.Item>
                          )}
                        /> :
                          <List.Item>
                            <Typography.Text className="bullet-text">{item["index"]}</Typography.Text>
                            <Typography.Text className="description">{item["description"]}</Typography.Text>
                          </List.Item>
                      )}
                    />
                  </li>
                  <li className="headings"><span className="heading-text">OPTING “IN” OR “OUT”</span>
                    <List
                      dataSource={opting}
                      renderItem={item => (
                        Array.isArray(item) ? <List
                          dataSource={item}
                          renderItem={item => (
                            <List.Item>
                              <Typography.Text className="bullet-text">{item["index"]}</Typography.Text>
                              <Typography.Text className="description">{item["description"]}</Typography.Text>
                            </List.Item>
                          )}
                        /> :
                          <List.Item>
                            <Typography.Text className="bullet-text">{item["index"]}</Typography.Text>
                            <Typography.Text className="description">{item["description"]}</Typography.Text>
                          </List.Item>
                      )}
                    />
                  </li>
                  <li className="headings"><span className="heading-text">THE SAFETY & SECURITY OF PERSONAL INFORMATION</span>
                    <List
                      dataSource={safety}
                      renderItem={item => (
                        <List.Item>
                          <Typography.Text className="bullet-text">{item["index"]}</Typography.Text>
                          <Typography.Text className="description">{item["description"]}</Typography.Text>
                        </List.Item>
                      )}
                    />
                  </li>
                  <li className="headings"><span className="heading-text">HOW TO ACCESS AND/OR UPDATE INFORMATION</span>
                    <List
                      dataSource={access}
                      renderItem={item => (
                        <List.Item>
                          <Typography.Text className="bullet-text">{item["index"]}</Typography.Text>
                          <Typography.Text className="description">{item["description"]}</Typography.Text>
                        </List.Item>
                      )}
                    />
                  </li>
                  <li className="headings"><span className="heading-text">COMPLAINTS AND DISPUTES</span>
                    <List
                      dataSource={complainant}
                      renderItem={item => (
                        <List.Item>
                          <Typography.Text className="bullet-text">{item["index"]}</Typography.Text>
                          <Typography.Text className="description">{item["description"]}</Typography.Text>
                        </List.Item>
                      )}
                    />
                  </li>
                  <li className="headings"><span className="heading-text">CONTACTING INDIVIDUALS</span>
                    <List
                      dataSource={contactInd}
                      renderItem={item => (
                        <List.Item>
                          <Typography.Text className="bullet-text">{item["index"]}</Typography.Text>
                          <Typography.Text className="description">{item["description"]}</Typography.Text>
                        </List.Item>
                      )}
                    />
                  </li>
                  <li className="headings"><span className="heading-text">CONTACTING US</span>
                    <List
                      dataSource={contactUs}
                      renderItem={item => (
                        Array.isArray(item) ? <List
                          dataSource={item}
                          renderItem={item => (
                            <List.Item>
                              <Typography.Text className="description">{item}</Typography.Text>
                            </List.Item>
                          )}
                        /> :
                          <List.Item>
                            <Typography.Text className="bullet-text">{item["index"]}</Typography.Text>
                            <Typography.Text className="description">{item["description"]}</Typography.Text>
                          </List.Item>
                      )}
                    />
                  </li>
                  <li className="headings"><span className="heading-text">ADDITIONS TO THIS POLICY</span>
                    <List
                      dataSource={additions}
                      renderItem={item => (
                        <List.Item>
                          <Typography.Text className="bullet-text">{item["index"]}</Typography.Text>
                          <Typography.Text className="description">{item["description"]}</Typography.Text>
                        </List.Item>
                      )}
                    />
                  </li>
                </ol>
              </Col>
            </Col>
          </div>
        </Row>
      </Col>
    </Row >
  )
}
export const LANGUAGES = [
    {value: 'English'},
    {value: 'Spanish'},
    {value: 'French'},
    {value: 'Urdu'},
    {value: 'Arabic'},
    {value: 'Russian'},
    {value: 'Mandarin'},
    {value: 'Hindi'},
    {value: 'Portuguese'},
    {value: 'Bengali'},
    {value: 'Javanese'},
    {value: 'Punjabi'},
    {value: 'German'},
    {value: 'Japanese'},
    {value: 'Wu'},
    {value: 'Malay'},
    {value: 'Telugu'},
    {value: 'Vietnamese'},
    {value: 'Korean'},
    {value: 'Marathi'},
    {value: 'Tamil'},
    {value: 'Turkish'},
    {value: 'Italian'},
    {value: 'Yue'},
    {value: 'Thai'},
    {value: 'Gujarati'},
    {value: 'Jin'},
    {value: 'Southern Min'},
    {value: 'Persian'},
    {value: 'Polish'},
    {value: 'Pashto'},
    {value: 'Kannada'},
    {value: 'Xiang'},
    {value: 'Malayalam'},
    {value: 'Sundanese'},
    {value: 'Hausa'},
    {value: 'Odia'},
    {value: 'Burmese'},
    {value: 'Hakka'},
    {value: 'Ukrainian'},
    {value: 'Bhojpuri'},
    {value: 'Tagalog'},
    {value: 'Yoruba'},
    {value: 'Maithili'},
    {value: 'Uzbek'},
    {value: 'Sindhi'},
    {value: 'Amharic'},
    {value: 'Fula'},
    {value: 'Romanian'},
    {value: 'Oromo'},
    {value: 'Igbo'},
    {value: 'Azerbaijani'},
    {value: 'Awadhi'},
    {value: 'Gan'},
    {value: 'Cebuano'},
    {value: 'Dutch'},
    {value: 'Kurdish'},
    {value: 'Serbo-Croatian'},
    {value: 'Malagasy'},
    {value: 'Saraiki'},
    {value: 'Nepali'},
    {value: 'Sinhala'},
    {value: 'Chittagonian'},
    {value: 'Zhuang'},
    {value: 'Khmer'},
    {value: 'Turkmen'},
    {value: 'Assamese'},
    {value: 'Madurese'},
    {value: 'Somali'},
    {value: 'Marwari'},
    {value: 'Magahi'},
    {value: 'Haryanvi'},
    {value: 'Hungarian'},
    {value: 'Chhattisgarhi'},
    {value: 'Greek'},
    {value: 'Chewa'},
    {value: 'Deccan'},
    {value: 'Akan'},
    {value: 'Kazakh'},
    {value: 'Northern Min'},
    {value: 'Sylheti'},
    {value: 'Zulu'},
    {value: 'Czech'},
    {value: 'Kinyarwanda'},
    {value: 'Dhundhari'},
    {value: 'Haitian Creole'},
    {value: 'Eastern Min'},
    {value: 'Ilocano'},
    {value: 'Quechua'},
    {value: 'Kirundi'},
    {value: 'Swedish'},
    {value: 'Hmong'},
    {value: 'Shona'},
    {value: 'Uyghur'},
    {value: 'Hiligaynon/Ilonggo'},
    {value: 'Mossi'},
    {value: 'Xhosa'},
    {value: 'Belarusian'},
    {value: 'Balochi'},
    {value: 'Konkani'},
]

export const getLessonTitleToDisplay = (lessonTitle) => {
    switch (lessonTitle) {
        case "ONLINE":
            return "Online"
        case "IN_PERSON":
            return "In-Person"
        case "BOTH":
            return "Online & In-Person"
    }
}

export const timeOptions = [
    {
        "label": "12:00 am",
        "value": "00:00"
    },
    {
        "label": "12:15 am",
        "value": "00:15"
    },
    {
        "label": "12:30 am",
        "value": "00:30"
    },
    {
        "label": "12:45 am",
        "value": "00:45"
    },
    {
        "label": "01:00 am",
        "value": "01:00"
    },
    {
        "label": "01:15 am",
        "value": "01:15"
    },
    {
        "label": "01:30 am",
        "value": "01:30"
    },
    {
        "label": "01:45 am",
        "value": "01:45"
    },
    {
        "label": "02:00 am",
        "value": "02:00"
    },
    {
        "label": "02:15 am",
        "value": "02:15"
    },
    {
        "label": "02:30 am",
        "value": "02:30"
    },
    {
        "label": "02:45 am",
        "value": "02:45"
    },
    {
        "label": "03:00 am",
        "value": "03:00"
    },
    {
        "label": "03:15 am",
        "value": "03:15"
    },
    {
        "label": "03:30 am",
        "value": "03:30"
    },
    {
        "label": "03:45 am",
        "value": "03:45"
    },
    {
        "label": "04:00 am",
        "value": "04:00"
    },
    {
        "label": "04:15 am",
        "value": "04:15"
    },
    {
        "label": "04:30 am",
        "value": "04:30"
    },
    {
        "label": "04:45 am",
        "value": "04:45"
    },
    {
        "label": "05:00 am",
        "value": "05:00"
    },
    {
        "label": "05:15 am",
        "value": "05:15"
    },
    {
        "label": "05:30 am",
        "value": "05:30"
    },
    {
        "label": "05:45 am",
        "value": "05:45"
    },
    {
        "label": "06:00 am",
        "value": "06:00"
    },
    {
        "label": "06:15 am",
        "value": "06:15"
    },
    {
        "label": "06:30 am",
        "value": "06:30"
    },
    {
        "label": "06:45 am",
        "value": "06:45"
    },
    {
        "label": "07:00 am",
        "value": "07:00"
    },
    {
        "label": "07:15 am",
        "value": "07:15"
    },
    {
        "label": "07:30 am",
        "value": "07:30"
    },
    {
        "label": "07:45 am",
        "value": "07:45"
    },
    {
        "label": "08:00 am",
        "value": "08:00"
    },
    {
        "label": "08:15 am",
        "value": "08:15"
    },
    {
        "label": "08:30 am",
        "value": "08:30"
    },
    {
        "label": "08:45 am",
        "value": "08:45"
    },
    {
        "label": "09:00 am",
        "value": "09:00"
    },
    {
        "label": "09:15 am",
        "value": "09:15"
    },
    {
        "label": "09:30 am",
        "value": "09:30"
    },
    {
        "label": "09:45 am",
        "value": "09:45"
    },
    {
        "label": "10:00 am",
        "value": "10:00"
    },
    {
        "label": "10:15 am",
        "value": "10:15"
    },
    {
        "label": "10:30 am",
        "value": "10:30"
    },
    {
        "label": "10:45 am",
        "value": "10:45"
    },
    {
        "label": "11:00 am",
        "value": "11:00"
    },
    {
        "label": "11:15 am",
        "value": "11:15"
    },
    {
        "label": "11:30 am",
        "value": "11:30"
    },
    {
        "label": "11:45 am",
        "value": "11:45"
    },
    {
        "label": "12:00 pm",
        "value": "12:00"
    },
    {
        "label": "12:15 pm",
        "value": "12:15"
    },
    {
        "label": "12:30 pm",
        "value": "12:30"
    },
    {
        "label": "12:45 pm",
        "value": "12:45"
    },
    {
        "label": "01:00 pm",
        "value": "13:00"
    },
    {
        "label": "01:15 pm",
        "value": "13:15"
    },
    {
        "label": "01:30 pm",
        "value": "13:30"
    },
    {
        "label": "01:45 pm",
        "value": "13:45"
    },
    {
        "label": "02:00 pm",
        "value": "14:00"
    },
    {
        "label": "02:15 pm",
        "value": "14:15"
    },
    {
        "label": "02:30 pm",
        "value": "14:30"
    },
    {
        "label": "02:45 pm",
        "value": "14:45"
    },
    {
        "label": "03:00 pm",
        "value": "15:00"
    },
    {
        "label": "03:15 pm",
        "value": "15:15"
    },
    {
        "label": "03:30 pm",
        "value": "15:30"
    },
    {
        "label": "03:45 pm",
        "value": "15:45"
    },
    {
        "label": "04:00 pm",
        "value": "16:00"
    },
    {
        "label": "04:15 pm",
        "value": "16:15"
    },
    {
        "label": "04:30 pm",
        "value": "16:30"
    },
    {
        "label": "04:45 pm",
        "value": "16:45"
    },
    {
        "label": "05:00 pm",
        "value": "17:00"
    },
    {
        "label": "05:15 pm",
        "value": "17:15"
    },
    {
        "label": "05:30 pm",
        "value": "17:30"
    },
    {
        "label": "05:45 pm",
        "value": "17:45"
    },
    {
        "label": "06:00 pm",
        "value": "18:00"
    },
    {
        "label": "06:15 pm",
        "value": "18:15"
    },
    {
        "label": "06:30 pm",
        "value": "18:30"
    },
    {
        "label": "06:45 pm",
        "value": "18:45"
    },
    {
        "label": "07:00 pm",
        "value": "19:00"
    },
    {
        "label": "07:15 pm",
        "value": "19:15"
    },
    {
        "label": "07:30 pm",
        "value": "19:30"
    },
    {
        "label": "07:45 pm",
        "value": "19:45"
    },
    {
        "label": "08:00 pm",
        "value": "20:00"
    },
    {
        "label": "08:15 pm",
        "value": "20:15"
    },
    {
        "label": "08:30 pm",
        "value": "20:30"
    },
    {
        "label": "08:45 pm",
        "value": "20:45"
    },
    {
        "label": "09:00 pm",
        "value": "21:00"
    },
    {
        "label": "09:15 pm",
        "value": "21:15"
    },
    {
        "label": "09:30 pm",
        "value": "21:30"
    },
    {
        "label": "09:45 pm",
        "value": "21:45"
    },
    {
        "label": "10:00 pm",
        "value": "22:00"
    },
    {
        "label": "10:15 pm",
        "value": "22:15"
    },
    {
        "label": "10:30 pm",
        "value": "22:30"
    },
    {
        "label": "10:45 pm",
        "value": "22:45"
    },
    {
        "label": "11:00 pm",
        "value": "23:00"
    },
    {
        "label": "11:15 pm",
        "value": "23:15"
    },
    {
        "label": "11:30 pm",
        "value": "23:30"
    },
    {
        "label": "11:45 pm",
        "value": "23:45"
    }]

export const semesterList = [
    {value: '1', name: '1'},
    {value: '2', name: '2'}
]
export const examList = [
    {value: 'HSC', name: 'HSC'},
    {value: 'VCE', name: 'VCE'},
    {value: 'IB', name: 'IB'},
    {value: 'SACE', name: 'SACE'},
    {value: 'WACE', name: 'WACE'},
    {value: 'QCE', name: 'QCE'},
]

export const yearLevel = [
    {value: '7', name: '7'},
    {value: '8', name: '8'},
    {value: '9', name: '9'},
    {value: '10', name: '10'},
    {value: '11', name: '11'},
    {value: '12', name: '12'},
]

import React from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

export default ({ ...props }: any) => {
  const { data } = useQuery(
    gql`
      query Emails {
        emails
      }
    `,
  );
  return <div>{(data?.emails || []).join(', ')}</div>;
};

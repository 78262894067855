import {
  faTrash,
  faCreditCard,
  faEdit,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../components';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

export default [
  {
    Header: 'Actions',
    accessor: (v: any) => v,
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ cell: { value: event } }: any) => {
      const [deleteEvent] = useMutation(gql`
        mutation DeleteEvent($id: ID!) {
          deleteEvent(id: $id)
        }
      `);
      const payable = event.enrollments.every(
        (enrollment: any) => !enrollment.payment,
      );
      return (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            style={{ margin: 0, padding: '0 2px',maxWidth:'35px' }}
            onClick={async () => {
              if (!window.confirm('Really delete event?')) {
                return;
              }
              await deleteEvent({
                variables: { id: event.id },
                refetchQueries: ['Events'],
              });
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
          <Link to={`/events/${event.id}`}>
            <Button style={{ margin: 0, padding: '0 2px',maxWidth:'35px' }}>
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          </Link>

          {payable && (
            <Link
              to={`/payments/new?event=${event.id}&payer=${
                event.creator?.id
              }&tutor=${event.owner?.id}&enrollments=${event.enrollments
                .map(({ id }: any) => id)
                .join(',')}`}
            >
              <Button style={{ padding: 2 ,maxWidth:'35px'}}>
                <FontAwesomeIcon icon={faCreditCard} />
              </Button>
            </Link>
          )}
        </div>
      );
    },
  },
  {
    Header: 'Date',
    accessor: 'startDate',
    id: 'startDate',
    disableFilters: true,
    Cell: ({ cell: { value } }: any) => (
      <div>
        {moment(value).format('ll')} ({moment(value).fromNow()})
      </div>
    ),
  },
  {
    Header: 'Tutor',
    accessor: 'owner',
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ cell: { value } }: any) => (
      <div>{`${value?.name} <${value?.email}>`}</div>
    ),
  },
  {
    Header: 'Enrollments',
    accessor: 'enrollments',
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ cell: { value }, row }: any) =>
      value.map((enrollment: any) => (
        <div style={{ display: 'flex' }}>
          <Button
            positive={enrollment.approved}
            negative={!enrollment.approved}
            style={{ padding: 2 }}
          >
            {enrollment?.student?.name || enrollment?.student?.id}
          </Button>
          {enrollment.payment && (
            <a
              href={`https://dashboard.stripe.com/charges/${enrollment.payment?.charge?.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button positive style={{ padding: 2 ,maxWidth:'35px'}}>
                <FontAwesomeIcon icon={faCreditCard} />
              </Button>
            </a>
          )}
        </div>
      )),
  },
  {
    Header: 'Recurring',
    accessor: 'everyWeek',
    id: 'recurring',
    Cell: ({ cell: { value } }: any) => <div>{value ? "Yes" : ""}</div>,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Start',
    accessor: 'startDate',
    id: 'start',
    Cell: ({ cell: { value } }: any) => <div>{moment(value).format('LT')}</div>,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'End',
    accessor: 'endDate',
    id: 'end',
    Cell: ({ cell: { value } }: any) => <div>{moment(value).format('LT')}</div>,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Created',
    accessor: 'createdAt',
    Cell: ({ cell: { value } }: any) => (
      <div>{moment(value).fromNow(true)}</div>
    ),
    disableFilters: true,
  },
];

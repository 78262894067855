import React from 'react';
import { useLocation } from 'react-router-dom';
import { NavLink } from '../components';

export default ({ id, name }: any) => {
  const query = new URLSearchParams(useLocation().search)
  const location = (query.get('location-granted') && 'location-granted=true')
    || (query.get('location-permission') && 'location-permission=true')
    || (query.get('location-denied') && 'location-denied=true')
  const to = location ? `/explore?${location}&&skills=${id}` : `/explore?skills=${id}`;
  return (
    <NavLink
      exact
      to={to}
      activeStyle={{ background: '#CED1D7' }}
      style={{ margin: '0.25em', padding: '0.25em' }}
      isActive={(_, location) => location.pathname + location.search === to}
    >
      {name}
    </NavLink>
  );
};

import React from "react";
import { Row, Col } from "antd";
import { Typography } from "antd";
import { Divider } from "antd";
import { Link } from "react-router-dom";
import "./Desktop.less";
import {
  homePageBookTutorRedirection,
  homePageBecomeTutorRedirection,
} from "../Constants/utilities";
const { Paragraph, Text } = Typography;

export default ({ user }: any) => {
  return (
    <>
      <Row>
        <Col span={3}></Col>
        <Col className="margin-top-48" span={5}>
          <div
            style={{
              backgroundImage: `url(/icons/pocketnote-logo.svg)`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: 256,
              height: 50,
            }}
          ></div>
        </Col>
        <Col md={1} lg={1} xl={4}></Col>
        <Col className="started-align" md={5} lg={5} xl={3}>
          <Text className="get-started">Get Started</Text>
          <Link to={homePageBookTutorRedirection(user)}>
            <Paragraph className="text-spacing margin-top-12 about-text">
              Book a Tutor
            </Paragraph>
          </Link>
          <Link to={homePageBecomeTutorRedirection(user)}>
            <Paragraph className="text-spacing margin-top-12 about-text">
              Become a Tutor
            </Paragraph>
          </Link>
        </Col>

        <Col className="pocketnote-align" md={5} lg={5} xl={3}>
          <Text className="get-started">Pocketnote</Text>
          <Link to="/about">
            <Paragraph className="text-spacing margin-top-12 about-text">
              About Us
            </Paragraph>
          </Link>
          <Link to="/blog">
            <Paragraph className="text-spacing margin-top-12 about-text">
              Blog
            </Paragraph>
          </Link>
          <a href={"mailto:support@pocketnote.com.au"}>
            <Paragraph className="text-spacing margin-top-12 about-text">
              Contact Us
            </Paragraph>
          </a>
          <Link to="/faqs">
            <Paragraph className="text-spacing margin-top-12 about-text">
              FAQs
            </Paragraph>
          </Link>
        </Col>
        <Col className="pocketnote-align" md={4} lg={4} xl={2}>
          <Text className="get-started">Find a Tutor</Text>
          <Link to="/math-tutoring">
            <Paragraph className="text-spacing margin-top-12 about-text">
              Maths Tutors
            </Paragraph>
          </Link>
          <Link to="/english-tutoring">
            <Paragraph className="text-spacing margin-top-12 about-text">
              English Tutors
            </Paragraph>
          </Link>
          {/*<Link to="/ndis-mentoring">*/}
          {/*  <Paragraph className="text-spacing margin-top-12 about-text">*/}
          {/*    NDIS Mentors*/}
          {/*  </Paragraph>*/}
          {/*</Link>*/}
        </Col>
        <Col md={1} lg={1} xl={1}></Col>
      </Row>

      <Row>
        <Col span={3}></Col>
        <Col span={18}>
          <Divider className="divider-footer" />
        </Col>
        <Col span={3}></Col>
      </Row>

      <Row>
        <Col span={3}></Col>
        <Col md={8} lg={8} xl={9}>
          <Paragraph className="terms-con">We make tutoring easy.</Paragraph>
          <Paragraph className="terms-con">Call us at: <a href={"tel:0467817086"}>0467 817 086</a></Paragraph>
        </Col>
        <Col md={1} lg={1} xl={3}></Col>
        <Col className="align-center margin-10" md={6} lg={6} xl={4}>
          <Link to="/terms-of-service">
            <Paragraph className="text-spacing privacy-policy terms-con">
              Terms and Conditions
            </Paragraph>
          </Link>
        </Col>
        <Col className="align-center" md={5} lg={5} xl={2}>
          <Link to="/privacy-policy">
            {" "}
            <Paragraph className="text-spacing privacy-policy terms-con">
              Privacy Policy
            </Paragraph>
          </Link>
        </Col>
        <Col md={1} lg={1} xl={3}></Col>
      </Row>
      <Row className="footer-row"></Row>
    </>
  );
};

import React from 'react';
import {useQuery, useMutation} from '@apollo/client';
import gql from 'graphql-tag';
import {Typography, Row, Col,} from 'antd'
import Tag from '../Tag'


const {Text} = Typography

export default ({event, me}: any) => {
    const [deleteEnrollment] = useMutation(gql`
    mutation DeleteEnrollment($id: ID!) {
      deleteEnrollment(id: $id)
    }
  `);

    const [createEnrollment] = useMutation(gql`
    mutation CreateEnrollment($input: CreateEnrollmentInput!) {
      createEnrollment(input: $input) {
        id
      }
    }
  `);

    const {data: studentData} = useQuery(gql`
    query {
      me {
        id
        students {
          id
          name
          email
          school {
            id
            name
          }
        }
      }
    }
  `);

    const students = studentData?.me?.students || [];

    return (
        <Row>
            <Col span={12}>
                <Text className="width-100 common-heading">Enrolled</Text>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        {(event?.enrollments || []).map((enrollment: any, index: any) => (
                            <Tag
                                key={index}
                                positive={enrollment.approved}
                                negative={!enrollment.approved}
                                hue={120}
                                onClick={async (event) => {
                                    event?.preventDefault();
                                    await deleteEnrollment({
                                        variables: {id: enrollment.id},
                                        refetchQueries: ['Event'],
                                    });
                                }}
                            >
                                {enrollment?.student?.name}
                            </Tag>
                        ))}
                    </Col>
                </Row>
            </Col>

            <Col span={12}>

                <Text className="width-100 common-heading">Unenrolled</Text>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        {(students || [])
                            .filter(
                                (student: any) =>
                                    !event?.enrollments
                                        .map(({student}: any) => student.id)
                                        .includes(student.id),
                            )
                            .map((student: any, index: any) => (
                                <Tag
                                    key={index}
                                    saturation={0}
                                    onClick={async (e) => {
                                        e?.preventDefault();
                                        await createEnrollment({
                                            variables: {
                                                input: {
                                                    event: event.id,
                                                    student: student.id,
                                                    parent: me.id,
                                                },
                                            },
                                            refetchQueries: ['Event'],
                                        });
                                    }}
                                >
                                    {student?.name}
                                </Tag>
                            ))}
                    </Col>
                </Row>
            </Col>

        </Row>
    );


};



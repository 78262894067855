import React, {useState} from "react";
import {Col, Row, Select, Typography} from "antd";
import {Link} from "react-router-dom";
import {AddMissingSubjectModal} from "./AddMissingSubjectModal";
import "./CreateYourNotes.less";


const {Title, Text} = Typography;


export const SubjectRow = ({subjectList, semesterList, examList, tutorNotes, setTutorNotes, yearLevel}) => {

    let newTutorNotes = {...tutorNotes}

    const [isAddSubjectModalVisible, setIsAddSubjectModalVisible] = useState(false);

    return (
        <Row>
            <Col xs={2} sm={2} md={2} lg={0} xxl={0} xl={0}/>
            <Col id="CreateNotesDesktop" xs={20} sm={20} md={20} lg={22} xxl={22} xl={22}>

                <Row style={{
                    // border: "1px solid red",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    alignItems: "center"
                }}>


                    <Col xs={24} sm={24} md={24} lg={5} xxl={5} xl={5} style={{
                        // // border: "1px solid blue",

                        display: "flex",
                        justifyContent: "space-between",
                    }}>
                        <Col span={2}/>


                        <Col span={15}>
                            <Title className="create-notes-title">Subject</Title>
                        </Col>


                        <Col
                            xs={6} sm={6} md={6} lg={0} xxl={0} xl={0}
                        >
                            <div className="read-more-margin-notes">
                                <Link to={"#"} onClick={() => setIsAddSubjectModalVisible(true)}
                                      className="text-decorate">
                                    +Add a missing subject
                                </Link>
                            </div>
                        </Col>

                        <Col span={2}/>


                    </Col>

                    <Col
                        xs={20} sm={20} md={20} lg={4} xxl={4} xl={4}
                        >

                        <Select
                            showArrow
                            placeholder="Subject"
                            className="select-width"
                            size="large"
                            options={subjectList}
                            value={tutorNotes?.subject ? tutorNotes?.subject : null}
                            onChange={(value, option) => {
                                console.log("option", option)
                                newTutorNotes.subject = option
                                setTutorNotes(newTutorNotes)
                            }}
                        />
                    </Col>

                    <Col
                        xs={20} sm={20} md={20} lg={4} xxl={4} xl={4}
                        style={{
                            width: "100%",
                            marginTop: '1%',
                            marginBottom: '1%',
                        }}>

                        <Select
                            showArrow
                            placeholder="Semester"
                            className="select-width"
                            size="large"
                            options={semesterList}
                            value={tutorNotes?.semester ? tutorNotes?.semester : null}
                            onChange={(value) => {
                                newTutorNotes.semester = value
                                setTutorNotes(newTutorNotes)
                            }}
                        />
                    </Col>

                    <Col
                        xs={20} sm={20} md={20} lg={4} xxl={4} xl={4}
                        style={{
                            width: "100%",
                            marginTop: '1%',
                            marginBottom: '1%',
                        }}>

                        <Select
                            showArrow
                            placeholder="Year"
                            className="select-width"
                            size="large"
                            options={yearLevel}
                            value={tutorNotes?.yearLevel ? tutorNotes?.yearLevel : null}
                            onChange={(value) => {
                                newTutorNotes.yearLevel = value
                                setTutorNotes(newTutorNotes)
                            }}
                        />
                    </Col>


                    <Col
                        xs={20} sm={20} md={20} lg={4} xxl={4} xl={4}
                        style={{
                            width: "100%",
                            marginTop: '1%',
                            marginBottom: '1%',
                        }}>
                        <Select

                            showArrow
                            placeholder="Exam"
                            className="select-width"
                            size="large"
                            options={examList}
                            value={tutorNotes?.exam ? tutorNotes?.exam : null}
                            onChange={(value) => {
                                newTutorNotes.exam = value
                                setTutorNotes(newTutorNotes)
                            }}
                        />
                    </Col>
                </Row>

                <Row>

                    <Col xs={18} sm={18} md={18} lg={6} xxl={6} xl={6}/>

                    <Col
                        xs={0} sm={0} md={0} lg={6} xxl={6} xl={6}
                        style={{
                            height: 50
                        }}>
                        <div className="read-more-margin ">
                            <Link to={"#"} onClick={() => setIsAddSubjectModalVisible(true)}
                                  className="text-decorate">
                                +Add a missing subject
                            </Link>
                        </div>
                    </Col>

                </Row>

                {
                    isAddSubjectModalVisible ?
                        <AddMissingSubjectModal
                            isAddSubjectModalVisible={isAddSubjectModalVisible}
                            setIsAddSubjectModalVisible={setIsAddSubjectModalVisible}
                        /> :
                        <></>
                }
            </Col>
            <Col xs={2} sm={2} md={2} lg={0} xxl={0} xl={0}/>
        </Row>
    )
}

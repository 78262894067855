import React, { useState } from 'react';
import Calendar from 'react-calendar';
import { Typography, Row, Col, Button, Checkbox, Input, Select, Avatar, Divider, Progress, Popover, Radio } from 'antd';
import { EnvironmentOutlined, CloseOutlined, MessageOutlined, ClockCircleOutlined, BellOutlined } from '@ant-design/icons';
import './Mobile.less';
import styled from '@emotion/styled';

const { Text, } = Typography;
const { Option } = Select;

const ModalContainer = styled.div`
  position: absolute;
  top: 399px;
  left: -19px;
  width: 100vw;
  height: 554px;
  background: rgba(0, 0, 0, 0.5);
`;

export default ({ id }: { id: string }) => {
  const [value, onChange] = useState(new Date());
  const [isOpen, setOpen] = useState(false);
  const [isContryPopup, setIsContryPopup] = useState(false);

  function handleChange(value: any) {
    setIsContryPopup(true)
    setPopoverContent(popoverThree)
  }


  function handleChangeTwo(value: any) {
    setIsContryPopup(false)
    setPopoverContent(popoverFour)
  }

  const popoverOne = (
    <div id='ReqSessionMobilePopoverOne'>
      <div className="choose-div"><Text className="choose-text">Choose your time</Text></div>
      <Col span={16} className="checkbox-align">
        <Checkbox className="checkbox-text">08:30 - 09:30am</Checkbox>
        <Checkbox className="checkbox-text">09:30 - 10:30am</Checkbox>
        <Checkbox className="checkbox-text">10:30 - 11:30am</Checkbox>
        <Checkbox className="checkbox-text">11:30 - 12:30am</Checkbox>
        <Checkbox className="checkbox-text">12:30 - 01:30pm</Checkbox>
        <Checkbox className="checkbox-text">01:30 - 02:30pm</Checkbox>
        <Checkbox className="checkbox-text">02:30 - 03:30pm</Checkbox>
        <Checkbox className="checkbox-text">03:30 - 04:30pm</Checkbox>
        <Checkbox className="checkbox-text">04:30 - 05:30pm</Checkbox>
        <Checkbox className="checkbox-text">05:30 - 06:30pm</Checkbox>
      </Col>
      <Row className="btn-row">
        <Col span={13} className="spaced-item">
          <Button className="delete"><span className="delete-font">Delete</span></Button>
          <Button type="primary" className="save" onClick={() => setPopoverContent(popoverTwo)}><span className="save-font">Save</span></Button>
        </Col>
      </Row>
    </div>
  )

  const [popoverContent, setPopoverContent] = useState(popoverOne);

  const popoverThree = (
    <div id='ReqSessionMobilePopoverFour'>
      <Row className="select-row">
        <Col className="arrow-align"><img src='/icons/session/back-arrow.svg' alt='back-arrow' /></Col>
        <Col span={10}>
          <Select className="border-width heading-text" defaultValue="|Your country or time zone" bordered={false} showArrow={false} onSelect={handleChangeTwo}>
            <Option value="jack">Jack</Option>
            <Option value="lucy">Lucy</Option>
          </Select>
        </Col>
      </Row>
      <Row className="spaced-item time-row-align">
        <Col span={2} className="earth-align">
          <img src="/icons/tutor-profile/earth.svg" alt="earth icon" />
        </Col>
        <Col span={13}>
          <div className="full-width"><Text className="selected-time">(GMT+02:00)</Text></div>
          <Text className="selected-time">Eastern European Time - Kiev</Text>
        </Col>
        <Col span={8}></Col>
      </Row>
    </div>
  )

  const popoverFour = (
    <div id='ReqSessionMobilePopoverThree'>
      <div className="align-center notification-div"><Text className="notification-font">Notification</Text></div>
      <Row className="full-width">
        <Input className="no-margin search-notification" name='name' placeholder='10' />
      </Row>
      <Col span={16} className="radio-align">
        <Radio.Group>
          <Radio value={1} className="radio-btn-font">min. before</Radio>
          <Radio value={2} className="radio-btn-font">h.</Radio>
          <Radio value={3} className="radio-btn-font">d.</Radio>
          <Radio value={4} className="radio-btn-font">week</Radio>
        </Radio.Group>
      </Col>
      <Divider></Divider>
      <Radio.Group>
        <Radio value={1} className="radio-btn-text">As a notification</Radio>
        <Radio value={2} className="radio-btn-font">By email</Radio>
      </Radio.Group>
      <Row className="btn-row">
        <Button type="primary" className="save"><span className="save-font" onClick={() => setOpen(false)}>Save</span></Button>
        {isOpen && (
          <ModalContainer />
        )}
      </Row>
    </div>
  )

  const popoverTwo = (
    <Col span={20} id='ReqSessionMobilePopoverTwo'>
      <Radio.Group onChange={handleChange}>
        <Radio value={1} className="radio-btn-font"><span className="radio-btn-font">Does not repeat</span></Radio>
        <Radio value={2} className="radio-btn-font"><span className="radio-btn-font">Daily</span></Radio>
        <Radio value={3} className="radio-btn-font"><span className="radio-btn-font">Weekly on Sunday</span></Radio>
        <Radio value={4} className="radio-btn-font"><span className="radio-btn-font">Monthly on the third Sunday</span></Radio>
        <Radio value={5} className="radio-btn-font"><span className="radio-btn-font">Every weekend </span></Radio>
        <Radio value={6} className="radio-btn-font"><span className="radio-btn-font">Custom...</span></Radio>
      </Radio.Group>
      <Row className="radio-margin"></Row>
    </Col>
  )

  return (
    <Col span={24}>
      <Row>
        <Col id='TutorSessionMobile'>
          <Row className="first-row">
            <Col span={24} className="border-main">
              <Col span={24} className="tutor-col">
                <Row>
                  <Col span={6} className="avatar-alignment" >
                    <Avatar size={70} />
                  </Col>
                  <Col span={18} className="padding-tutor-name">
                    <Row className="flex">
                      <Text className="tutor-name">Teagan L.</Text>
                    </Row>
                    <Row>
                      <Col className="flex location-col">
                        <EnvironmentOutlined />
                        <Text className="no-margin location-name-padding">Melbourne, Australia </Text>
                      </Col>
                    </Row>
                    <Row className="flex rating-row-margin">
                      <Col span={9}>
                        <div><Text className="rating-percent">100%</Text></div>
                        <div className="progress-margin"><Progress percent={80} showInfo={false} /></div>
                        <div className="job-margin"><Text className="job-success">Job Success</Text></div>
                      </Col>
                      <Col className="flex">
                        <img src="/icons/tutor-profile/badge.svg" className="badge-icon" alt="badge icon" />
                        <Text className="top-rated">TOP RATED</Text>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={22} className="message-btn-col">
                <Button type="primary" className="message-btn" block >Send A Message</Button>
              </Col>
              <Divider type="horizontal" />
              <Row>
                <Text className="req-session-font req-primary-color">Request a session</Text>
              </Row>
              <Row>
                <Text className="req-session-font req-secondary-color">with Teagan Lim</Text>
              </Row>
              <Divider className="no-margin" type="horizontal" />
              <Row className="availabilty-text-row">
                <div className="flex">
                  <Text className="availability-font">Availability</Text>
                </div>
              </Row>
              <Row>
                <Text className="availability-desc">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua.
                </Text>
                <div className="view-profile"><Text className="choose-time">Choose your Date & Time</Text></div>
                <Calendar onChange={onChange} value={value} />
              </Row>
              <Popover overlayClassName={isContryPopup ? "req-session-timezone-mobile-popover" : "req-session-mobile-popover"} content={popoverContent} trigger="click" visible={isOpen}>
                <div className="add-sesion-div"><Button type="text" className="add-session" onClick={() => {
                  setPopoverContent(popoverOne)
                  setIsContryPopup(false)
                  setOpen(!isOpen)
                  }}> + Add new session</Button></div>
                {isOpen && (
                  <ModalContainer />
                )}
              </Popover>
            </Col>
          </Row>
        </Col>
        <Col id='TutorSession' className="main-div-tutor background-tutor">
          <Row className="first-row">
            <Col span={24}>
              <Row>
                <CloseOutlined className="full-width close-icon" />
                <Col span={24}>
                  <Col span={22} className="session-col-margin">
                    <Input className="session-name" placeholder="Session 1" bordered={false} />
                    <Divider className="no-margin" />
                  </Col>
                  <Col>
                    <div className="flex clock-div"><ClockCircleOutlined className="time-clock" />
                      <div><Text className="card-date">Sunday, December 20</Text></div></div>
                    <div><Select bordered={false} showArrow={false} defaultValue="8:30am - 9:30am" onChange={handleChange} className="select-time">
                    </Select>
                    </div>
                  </Col>
                  <Col>
                    <div className="flex time-zone-div">
                      <img src="/icons/tutor-profile/earth.svg" alt="earth icon" />
                      <div><Text className="card-date">Time Zone</Text></div>
                    </div>
                  </Col>
                  <Col>
                    <div className="flex format-div">
                      <img src="/icons/tutor-profile/repeat.svg" alt="repeat icon" />
                      <Select bordered={false} showArrow={false} defaultValue="Does not repeat" onChange={handleChange} className="select-format">
                      </Select>
                    </div>
                  </Col>
                </Col>
              </Row>
              <Divider className="no-margin" />
              <Col className="flex">
                <EnvironmentOutlined className="icon-placement" />
                <Input className="session-details" placeholder="Add location" bordered={false} />
              </Col>
              <Divider className="no-margin" />
              <Col className="flex">
                <MessageOutlined className="icon-placement" />
                <Input className="session-details" placeholder="Add description" bordered={false} />
              </Col>
              <Divider className="no-margin" />
              <Col className="flex">
                <BellOutlined className="icon-placement" />
                <Input className="session-details" placeholder="Add notification" bordered={false} />
              </Col>
              <Divider className="no-margin" />
              <Row className="full-width"><Button type="primary" className="save-button" block>Save</Button></Row>
            </Col >
          </Row>
        </Col>
      </Row>
    </Col >
  )
};

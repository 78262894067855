import React from "react";
import moment from "moment";
import {Avatar, Col, Row, Typography} from "antd";

const {Text} = Typography;

function replaceWithBr(msg: string) {
    return msg.replace(/\n/g, "<br />")
}

export default ({author, content, createdAt, attachment}: any) => (
    <>
        <Col xs={23} sm={23} md={23} xl={22} xxl={22} lg={22}>
            <Row className="margin-conv-row">
                <div>
                    <Avatar size={{xs: 0, sm: 0, md: 0, lg: 51, xl: 51, xxl: 51}}
                            src={`${author?.avatar?.url ||
                            'https://react.semantic-ui.com/images/wireframe/image.png'}`}
                    />
                </div>
                <Col xs={20} sm={20} md={20} lg={21} xl={21} xxl={21} className="conv-col-align">
                    <Row>
                        <div>
                            <Text className="conversation-name">{author?.name || 'Anonymous'}</Text>
                        </div>

                        <div className="align-time">
                            <Text className="time">{moment(createdAt).calendar()}</Text>
                        </div>
                    </Row>
                    <Row>
                        <Col>
                            <div>
                                {attachment ? (
                                    <div style={{}}>
                                        {
                                            attachment.mimetype?.includes("pdf") ? (
                                                <object data={attachment?.url}
                                                        type={attachment.mimeType} width="100%" height="100%">
                                                </object>
                                            ) : (
                                                <img
                                                    src={attachment?.url}
                                                    alt="img"
                                                    width="65%"
                                                    height="30%"
                                                />
                                            )
                                        }
                                        <p>Link
                                            <a href={attachment?.url} target="_blank"> to the Attachment!</a>
                                        </p>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                            <p className="conversation"
                               dangerouslySetInnerHTML={{__html: content.replace(/\n/g, "<br />")}}/>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </Col>
    </>
);
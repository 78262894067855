/** @jsxRuntime classic */
/** @jsx jsx */
import { Fragment } from "react";
import { jsx, css } from "@emotion/core";

const MIN_WIDTH = `768px`;

export default (props: any) => (
  <Fragment>
    <div
      key="desktop"
      css={css`
        display: block;
        @media (min-width: ${MIN_WIDTH}) {
          display: none;
        }
      `}
    >
      {props.children[0]}
    </div>
    <div
      key="mobile"
      css={css`
        display: none;
        @media (min-width: ${MIN_WIDTH}) {
          display: block;
        }
      `}
    >
      {props.children[1]}
    </div>
  </Fragment>
);

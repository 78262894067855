import React, {useEffect, useState} from 'react';
import {POCKETNOTE_PERCENT, STRIPE_FIXED, TUTOR_COMMISSION} from "../common/utils";

const Label = ({children}: any) => (
    <label style={{width: '8em', display: 'inline-block'}}>{children}</label>
);

const Charge = ({
                    pocketnoteCommission,
                    pocketnotePercent,
                    stripePercent,
                    stripeFixed,
                    goal,
                    name,
                    callback,
    isNdis,
    ndisTotal,
                }: any) => {
    const commissionFromParent = (goal * pocketnotePercent)
    const toTutor = goal - (goal * pocketnoteCommission)
    const fromParent = isNdis ? ndisTotal : (goal + commissionFromParent + stripeFixed) / (1 - stripePercent)
    const charge = (goal + commissionFromParent + stripeFixed) / (1 - stripePercent)
    const toStripe = isNdis ? (ndisTotal * stripePercent + stripeFixed) : (charge * stripePercent + stripeFixed)
    const toPocketnote = isNdis ? (ndisTotal - toTutor - toStripe) : (commissionFromParent + (goal * pocketnoteCommission))

    useEffect(() => {
        if (isNdis) {
            callback({
                gross: ndisTotal,
                net: toTutor,
            });
        } else {
            callback({
                gross: charge,
                net: toTutor,
            });
        }

        return () => {
        };
    });

    return (
        <div>
            {name && <h5>{name} Card</h5>}
            <div>
                <Label>From parent</Label>
                <input readOnly value={fromParent}/>
            </div>
            <div>
                <Label>To Stripe</Label>
                <input readOnly value={toStripe}/>
            </div>
            <div>
                <Label>To Pocketnote</Label>
                <input readOnly value={toPocketnote}/>
            </div>
            <div>
                <Label>To Tutor</Label>
                <input readOnly={isNdis} value={toTutor}/>
            </div>
            <div>
                <Label>Total</Label>
                <input readOnly value={isNdis ? ndisTotal : (toStripe + (goal * pocketnotePercent) + goal)}/>
            </div>
        </div>
    );
};

export default (props: any) => {
    const [pocketnoteCommission] = useState(TUTOR_COMMISSION)
    const [stripeFixed, setStripeFixed] = useState(STRIPE_FIXED);
    const [pocketnotePercent, setPocketnotePercent] = useState(POCKETNOTE_PERCENT);
    const [hours, setHours] = useState(props.hours || 1);
    const [rate, setRate] = useState(props.customRate ?
        props.customRate : props.rate || 40);
    const [ndisTotal, setNdisTotal] = useState(null);
    const goal = (hours * rate);
    const [isNdis, setIsNdis] = useState(false);
    const schemes = [
        ['domestic', 0.0175],
        ['international', 0.029]
    ];
    return (
        <div>
            <div>
                <h3>Goal</h3>
                <div>
                    <Label>Hours</Label>
                    <input
                        type="number"
                        onChange={(e: any) => setHours(parseFloat(e.target.value) || 0)}
                        value={hours}
                    />
                </div>
                <div>
                    <Label>Rate {props.customRate != null ? "(Custom)" : ""}</Label>
                    <input
                        type="number"
                        onChange={(e: any) => setRate(parseFloat(e.target.value) || 0)}
                        value={rate}
                    />
                </div>
                {isNdis &&
                    <div>
                        <Label>NDIS Total</Label>
                        <input
                            type="number"
                            onChange={(e: any) => {
                                const value = parseFloat(e.target.value);
                                console.log('ndis', value);
                                setNdisTotal(value || 0)
                            }}
                            value={ndisTotal}
                        />
                    </div>}
            </div>
            <div>
                <h3>Fees</h3>
                <div style={{display: 'flex'}}>
                    <div>
                        <h5>Pocketnote</h5>
                        <div>
                            <Label>Percent</Label>
                            <input
                                type="number"
                                onChange={(e: any) =>
                                    setPocketnotePercent(parseFloat(e.target.value) || 0)
                                }
                                value={pocketnotePercent * 100}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div>
                        <h5>Stripe</h5>
                        <div>
                            <Label>Fixed</Label>
                            <input
                                type="number"
                                onChange={(e: any) =>
                                    setStripeFixed(parseFloat(e.target.value) || 0)
                                }
                                value={stripeFixed * 100}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Label>NDIS</Label>
                <input
                    type="checkbox"
                    onChange={(e: any) =>
                        setIsNdis(e.target.checked)
                    }
                    checked={isNdis}
                    readOnly={true}
                />
            </div>
            {goal ? (
                <>
                    <h3>Charges</h3>
                    <div style={{display: 'flex'}}>
                        {props.scheme ? (
                            <Charge
                                {...{
                                    pocketnoteCommission,
                                    pocketnotePercent,
                                    stripeFixed,
                                    goal,
                                    stripePercent: (schemes.find(
                                        ([name]: any) => name === props.scheme,
                                    ) as any)[1],
                                    callback: props.callback,
                                    isNdis,
                                    ndisTotal
                                }}
                            />
                        ) : (
                            schemes.map(([name, stripePercent]: any) => (
                                <Charge
                                    {...{
                                        pocketnoteCommission,
                                        pocketnotePercent,
                                        stripeFixed,
                                        goal,
                                        name,
                                        stripePercent,
                                        callback: props.callback,
                                        isNdis,
                                        ndisTotal
                                    }}
                                />
                            ))
                        )}
                    </div>
                </>
            ) : null}
        </div>
    );
};

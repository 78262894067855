import React from "react";

type TutorPresentProps = {
    name: string;
    subjects: string[]
    description: string
    imgSrc: string;
}
export default (props: TutorPresentProps) => {
    const {name, subjects, description, imgSrc} = props;

    return <div className={"flex flex-col flex-auto place-items-center" +
        " tutor-present-container md:col-span-1 border-solid border rounded border-inherit mt-14"}>
        <img className={"inline-block h-16 w-16 rounded-full ring-2 ring-white object-cover tutor-present-image"} src={imgSrc}/>
        <div className={"tutor-present-name pt-2"}>{name}</div>
        <div className={"tutor-present-subject"}>{subjects.slice(0, 1).join(", ")}</div>
        <div className={"tutor-present-desc pt-6 px-8"}>{description.length > 150 ?
            `${description.substring(0, 150)}...` : description}</div>
    </div>
}

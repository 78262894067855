import React from 'react';
import { css, keyframes } from '@emotion/core';
import { useAuth } from '../Auth';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Row, Col } from 'antd';
import { Button } from 'antd';
import { Typography } from 'antd';
import './Popup.less';
import { Input } from 'antd';
import { Formik, Form, Field } from 'formik';
const { Title, Paragraph } = Typography;

const shake = keyframes`
0% { transform: translate(1px, 1px) rotate(0deg); }
10% { transform: translate(-1px, -2px) rotate(-1deg); }
20% { transform: translate(-3px, 0px) rotate(1deg); }
30% { transform: translate(3px, 2px) rotate(0deg); }
40% { transform: translate(1px, -1px) rotate(1deg); }
50% { transform: translate(-1px, 2px) rotate(-1deg); }
60% { transform: translate(-3px, 1px) rotate(0deg); }
70% { transform: translate(3px, 1px) rotate(-1deg); }
80% { transform: translate(-1px, -1px) rotate(1deg); }
90% { transform: translate(1px, 2px) rotate(0deg); }
100% { transform: translate(1px, -2px) rotate(-1deg); }
`;

export function Login({ setIsLoginModalVisible }: any) {
  const auth = useAuth();
  const location: any = useLocation();
  const history = useHistory();

  return (
    <div className='background-secondary login-popup-container'>
      <Formik
        initialValues={{}}
        onSubmit={async (values, actions) => {
          try {
            await auth.login(values);
            setIsLoginModalVisible(false);
            if (location?.state?.from) {
              setTimeout(() => {
                history.push({
                  pathname: location?.state?.from?.pathname,
                  search: location?.state?.from?.search,
                });
              }, 1111);
            }
          } catch (e) {
            actions.setStatus(false);
            setTimeout(() => {
              actions.setStatus(null);
            }, 333);
          }
        }}
      >
        {(props) => (
          <div
            css={
              props.status === false &&
              css`
                  animation: ${shake} 0.3s ease 1;
                `
            }
          >
            <Form>
              <Row className='background-plain main-popup-container'>
                <Col className='align-center margin-top-24' span={24}>
                  <Title level={2}>Log In</Title>
                </Col>

                <Col className='align-center' span={24}>
                  <Paragraph className='heading-text'>Don't have an account? <Link to={'/join'} onClick={() => { setIsLoginModalVisible(false) }} className='link-text'>Sign up for free</Link></Paragraph>
                </Col>

                <Col className='margin-top-24' span={24}>
                  <Paragraph className='heading-text'><b>Email</b></Paragraph>
                  <Field
                    as={Input}
                    type="email"
                    name="email"
                    required
                    autoComplete="email"
                  />
                </Col>

                <Col className='margin-top-24' span={24}>
                  <Paragraph className='heading-text'><b>Password</b></Paragraph>
                  <Field
                    as={Input}
                    type="password"
                    name="password"
                    required
                    autoComplete="password"
                  />
                </Col>

                <Col className='margin-top-24' span={24}>
                  <Button htmlType='submit' type="primary" className='btn-primary full-width' size='large'>
                    Log In
                  </Button>
                </Col>
                <Col className='align-center margin-top-8' span={24}>
                  <Link onClick={() => { setIsLoginModalVisible(false) }} to={'/recover'} className='link-text'>Forget email or password?</Link>
                </Col>
              </Row>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
}

import React, {useEffect, useState} from "react";
import {Typography, Upload} from "antd";
import {toast} from "react-toastify";

const {Dragger} = Upload;
const {Text} = Typography;



export const UploadNotes = ({title, type, tutorNotes, setTutorNotes}) => {

    let newTutorNotes = {...tutorNotes}

    const [file, setFile] = useState(null as any)
    const [dataURL, setDataURL] = useState(null as any);


    const uploadNotes = (
        <div>
            {dataURL ? (
                <>
                    {/*<img width={250} height={220} src={dataURL}/>*/}
                    <embed src={`${dataURL}#page=1&zoom=20`} width={250} height={220} />
                </>
            ) : (
                <>
                    {/*<UploadOutlined/>*/}
                    <div>
                        <Text>Upload Notes</Text>
                    </div>


                </>
            )}

        </div>
    );

    useEffect(() =>{
        if (!newTutorNotes.sampleNotes) {
            setDataURL(null as any);
        }
    }, [newTutorNotes])



    const props = {
        name: 'file',
        multiple: false,
        action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
        showUploadList: false,
        accept: "application/pdf",
        beforeUpload(file) {


            newTutorNotes[type] = {mime: file.type, filename: file.name, title: title, uploadType: file.type, file: file}
            setDataURL(URL.createObjectURL(file));
            setTutorNotes(newTutorNotes);
            setFile(file)
            return false;
        },
        async handleClick() {
            if (!file) {
                toast.error('Please select a file!');
                return
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    return (
        <Dragger {...props}>
            {uploadNotes}
        </Dragger>
    )
}
import React, { useState } from "react";
import { LoadingContext } from "context/loading";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./styles.less";

export default function AppLoader(props: { children: any }): JSX.Element {
  const [loading, setLoading] = useState(false);

  // Memoazing the value for performance reasons

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      <div id="Loading">
        <Spin
          size="large"
          spinning={loading}
          indicator={<LoadingOutlined style={{ fontSize: 35 }} spin />}
        >
          {props.children}
        </Spin>
      </div>
    </LoadingContext.Provider>
  );
}

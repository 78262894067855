import LandingWhyPanel, {PanelProps} from "../../components/LandingTemplates/LandingWhyPanel";
import React from "react";
import ComponentContainer from "../../components/LandingTemplates/ComponentContainer";
import {
    homePageBecomeTutorRedirection,
    homePageBookTutorRedirection,
    homePageBuyNotesRedirection, homePageSellNotesTutorRedirection
} from "../Constants/utilities";
import LandingRow from "../../components/LandingTemplates/LandingRow";
import LandingPageBottomCta from "../Landing/LandingPageBottomCta";
import LandingBenefitsPanel from "../../components/LandingTemplates/LandingBenefitsPanel";
import TestimonialContainer, {TestimonialType} from "../../components/LandingTemplates/TestimonialContainer";
import SchoolFailsComponent from "../../components/LandingTemplates/SchoolFailsComponent";
import TutorsFromComponent from "../../components/LandingTemplates/TutorsFromComponent";
import BottomContactUs from "../../components/LandingTemplates/BottomContactUs";
import {TopEarners} from "../Home/TopEarners";

const whyPn: PanelProps[] = [
    {
        text: "The best online tutors in Australia",
        imgSrc: "icons/private-tutoring/why-pn-rating.png"
    },
    {
        text: "High school tutoring services near you",
        imgSrc: "icons/private-tutoring/why-pn-books.png"
    },
    {
        text: "100% refund guarantee on the first session, no questions asked",
        imgSrc: "icons/private-tutoring/why-pn-refund.png"
    },
    {
        text: "All tutors are verified and qualified",
        imgSrc: "icons/private-tutoring/why-pn-qualified.png"
    }
]

const benefitsPn: PanelProps[] = [
    {
        text: "At Pocketnote, our easy-to-use digital platform makes it simple to access a wide range of high-quality kindergarten, " +
            "primary and high-school tutors near you. Our platform lets you search for tutors based on your location, with the " +
            "additional option of organising tutoring via video call in order to find your perfect match.",
        imgSrc: "icons/private-tutoring/benefits-rating.png"
    },
    {
        text: "Our tutors are passionate about helping students succeed, and offer the knowledge, experience, and teaching skills" +
            " required to help you achieve your academic goals. With our online tutoring platform in Australia, " +
            "you can easily access your tutor from the comfort of your own home at times which suit your schedule.",
        imgSrc: "icons/private-tutoring/benefits-job-interview.png"
    },
    {
        text: "At Pocketnote, we are committed to providing the best possible online tutoring experience for our students. " +
            "Our platform is user-friendly and easy to navigate, making it simple to find and book a tutor.",
        imgSrc: "icons/private-tutoring/benefits-cross-platform.png"
    },
    {
        text: "We also offer flexible scheduling options, so you can schedule your tutoring sessions at a time that works for you.",
        imgSrc: "icons/private-tutoring/benefits-time.png"
    }
]

const testimonials: TestimonialType[] = [
    {
        imgSrc: "icons/private-tutoring/testimonial-1.jpg",
        name: "Brooke Couchman",
        text: "We were quickly connected with a tutor who has been really engaging, communicative, consistent and reliable. " +
            "Maths has become more of a pleasure, and marks at school have improved! " +
            "After having tried a few other tutoring platforms with no success, Pocketnote is miles ahead of the rest, highly recommend."
    },
    {
        imgSrc: "icons/private-tutoring/testimonial-2.jpg",
        name: "Jenny Watson",
        text: "I found this service to be professional and very easy to use. " +
            "Always willing to work with my child's needs and the team were great in helping me set up the process and find a tutor suitable for us. " +
            "Our tutor had a teaching style that worked well for us, was always professional and dedicated. Very happy and would recommend."
    },
    {
        imgSrc: "icons/private-tutoring/testimonial-3.jpg",
        name: "Helen Mansfield",
        text: "I cannot rave about the team at Pocketnote enough for the math tutoring service they provided for our daughter. " +
            "The team personally helped me select one of their tutors from a huge pool and guided me through the very easy process of logging " +
            "into the booking system and payment portal. " +
            "The whole process was so simple and our tutor did not disappoint. We are very pleased to recommend Pocketnote for tutors across all subjects."
    }
]

export default (props) => {

    return <ComponentContainer>
        <LandingRow
            isHero={true}
            heading={"Your One-Stop Destination for Private Tutoring in Australia"}
            subheading={"Welcome to Pocketnote, your one-stop solution for private tutoring in Australia. " +
                "Our platform instantly connects you with the best kindergarten, primary school and high-school " +
                "tutors nearby, so you can get the extra help you need to succeed. With Pocketnote, " +
                "it’s easier than ever to access the best online tutoring in Australia."}
            imgSrc={"icons/private-tutoring/hero-image.png"}
            primaryButton={{text: "Book Tutor", to: () => homePageBookTutorRedirection(props.user)}}
            secondaryButton={{text: "Become a Tutor", to: () => homePageBecomeTutorRedirection(props.user)}}
            tertiaryButton={{text: "Buy Notes", to: () => homePageBuyNotesRedirection(props.user)}}
            quaternaryButton={{text: "Sell Notes", to: () => homePageSellNotesTutorRedirection(props.user)}}
        />
        <LandingRow
            isHero={false}
            reverse={true}
            heading={"Find the Best Online Tutoring in Australia with Pocketnote"}
            subheading={"Are you struggling to keep up with your studies and are looking for high school tutoring services? " +
                "Do you need extra help to achieve the grades you want? Pocketnote is here to help. " +
                "Our platform offers high-quality online tutoring in Australia, connecting students with qualified and verified tutors." +
                "\n" +
                "At Pocketnote, we are so confident in the quality of our tutor’s abilities that you can enjoy a " +
                "100% refund guarantee for your first session, with no questions asked. " +
                "This lets you trial our services risk-free, and ensure that we are the right fit for your academic goals."}
            imgSrc={"icons/private-tutoring/video-with-plant.png"}
            primaryButton={{text: "Sign Up", to: () => homePageBookTutorRedirection(props.user)}}
        />
        <LandingRow
            isHero={false}
            reverse={false}
            heading={"Private Tutoring for Kindergarten, Primary School and High School Students"}
            subheading={"Our online tutoring service is available for kindergarten, primary school and high-school students. " +
                "Whether you need help with Maths, English, Science, Languages or any other subject, our tutors are here to help. " +
                "We also have NDIS-registered tutors available to support students with disabilities, ensuring that everyone has equal access to education." +
                "\n" +
                "We understand that every student has unique learning needs and goals. " +
                "That's why we offer private tutoring in Australia which is tailored to your specific requirements. " +
                "Our kindergarten, primary school and high school tutoring services cover a range of subjects, " +
                "from English and Maths to Physics and Chemistry. Our experienced tutors work with you to create a customised plan" +
                " that helps you achieve your academic goals."}
            imgSrc={"icons/private-tutoring/black-board.png"}
        />
        <LandingBenefitsPanel
            heading={"Pocketnote Private Online Tutoring Benefits"}
            imgSrc={"icons/private-tutoring/benefits.png"}
            panel={benefitsPn}
            primaryButton={{text: "Sign Up", to: () => homePageBookTutorRedirection(props.user)}}/>

        <LandingWhyPanel panel={whyPn}
                         primaryButton={{text: "Sign Up", to: () => homePageBookTutorRedirection(props.user)}}
                         heading={"Why Choose Pocketnote for Private Tutoring in Australia? "}
                         subheading={"If you're searching for a reliable, high-quality in-person and online tutoring service, look no further than Pocketnote. " +
                             "Sign up today to experience the huge academic benefits which Pocketnote can make to your studies, providing:"}/>

        <TestimonialContainer testimonials={testimonials}/>
        <SchoolFailsComponent/>
        <TutorsFromComponent/>
        <TopEarners/>

        <LandingPageBottomCta signUpText={"Don't let academic struggles hold you back - " +
            "sign up for Pocketnote today and access the best online tutoring in Australia."}/>
        <BottomContactUs user={props.user}/>
    </ComponentContainer>
}

import {useQuery} from '@apollo/client';
import gql from 'graphql-tag';

export default (props: any) => {
    const {data} = useQuery(
        gql`
            query Users($skip: Int, $take: Int, $sort: JSON, $filters: JSON) {
                users(skip: $skip, take: $take, sort: $sort, filters: $filters) {
                    data {
                        id
                        role
                        name
                        email
                        tel

                        stripeAccount
                        googlePlace

                        stripeAccountId
                        stripeCustomerId

                        createdAt
                        updatedAt
                        activeAt

                        wwcc
                        qualified
                        manualPayment
                        contacted
                        paymentMethod
                    }
                    meta {
                        total
                    }
                }
            }
        `,
        {
            variables: {
                ...props,
            },
        },
    );
    return {data: data?.users?.data || [], meta: data?.users?.meta || {}};
};
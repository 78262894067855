import moment from "moment";

/////////////// Functions For Returning Date List For Tables

export const getLast30Days = () => {
    const dates = [];
    const NUM_OF_DAYS = 30; // get last 12 dates.

    for (let i = NUM_OF_DAYS; i >= 1; i--) {
        let date = moment();
        let d = date.subtract(i, 'day').format('DD/MMM/YYYY');

        dates.push(d);
    }

    return dates
}

export const getLast10WeeksDateRange = () => {

    let datesIn10Weeks = getDatesFromLast10Weeks();

    let weeks = []

    for (let i = 0; i < 10; i++) {

        weeks.push(datesIn10Weeks[i] + "-" + datesIn10Weeks[i + 1])
    }

    return weeks
}

export const getLast12Months = () => {
    let months = [];
    let monthsRequired = 12

    for (let i = monthsRequired; i >= 1; i--) {
        months.push(moment().subtract(i, 'months').format('MMMM YYYY'))
    }

    return months
}

/////////////// Functions For Returning Required Dates

export const getDatesFromLast10Weeks = () => {

    let datesIn10Weeks = [];
    let weeksRequired = 10

    for (let i = weeksRequired; i >= 1; i--) {

        let dateFrom = moment().subtract(7 * i, 'd').format('DD/MMM/YYYY');
        datesIn10Weeks.push(dateFrom)
    }
    datesIn10Weeks.push(moment().format('DD/MMM/YYYY'))


    return datesIn10Weeks

}

export const getCurrentDate = () => {

    let date = moment();
    let currdate = date.format('DD/MMM/YYYY');

    return currdate
}

export const getDate30DaysAgo = () => {

    let date = moment();
    let dt30 = date.subtract(30, 'day').format('DD/MMM/YYYY');

    return dt30
}

export const getDate10WeeksAgo = () => {

    let dt10Weeks = moment().subtract(70, 'd').format('DD/MMM/YYYY');

    return dt10Weeks
}

export const getLastDayOfCurrMonth = () => {

    let beginMonth = moment().daysInMonth() + moment().format("/MMM/YYYY");
    return beginMonth
}

export const getFirstDayOf12MonthAgo = () => {

    let endMonth = moment().subtract(12, 'months').format("01/MMM/YYYY");
    return endMonth
}


/////////////// Functions For Computing Data sets

const checkDateBetweenRange = (from, to, date) => {

    let compareDate = moment(date, "DD/MMM/YYYY");
    let startDate = moment(from, "DD/MMM/YYYY");
    let endDate = moment(to, "DD/MMM/YYYY");

    let result = compareDate.isBetween(startDate, endDate)
    return result;

}


/////////////// Functions For Returning Formatted Data sets

export const DailyDataSet = (data) => {

    let arr = []
    data?.forEach(function (item) {

        let obj = {x: "", y: 0}

        obj.x = item.date
        obj.y = item.count

        arr.push(obj)

    })

    return arr
}

export const WeeklyDataSet = (data) => {

    let datesIn10Weeks = getLast10WeeksDateRange();
    let arr = []

    for (let i = 0; i <= datesIn10Weeks.length; i++) {

        const dateSplit = datesIn10Weeks[i]?.split('-') || [];

        let obj = {x: "", y: 0}

        data?.forEach(function (item) {

            obj.x = datesIn10Weeks[i]
            obj.y = checkDateBetweenRange(dateSplit[0], dateSplit[1], item.date) ? (obj.y + item.count) : obj.y
        })

        arr.push(obj)

    }

    return arr

}

export const MonthlyDataSet = (data) => {

    let months = getLast12Months()

    let arr = []

    for (let i = 0; i < months.length; i++) {

        let obj = {x: "", y: 0}

        data?.forEach(function (item) {

            obj.x = months[i]
            obj.y = (moment(item.date, "DD/MMM/YYYY").format('MMMM YYYY') === months[i]) ? (obj.y + item.count) : obj.y
        })

        arr.push(obj)

    }

    return arr;

}


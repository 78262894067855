import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { Button, Label } from '../components';
import { Link } from 'react-router-dom';

const PayoutsStatus = ({ user }: any) => {
  const enabled = user?.stripeAccount?.payouts_enabled;
  return (
    <div
      style={{
        background: 'hsla(0, 0%, 100%, 0.075)',
        borderRadius: 3,
        padding: '1em',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Label style={{ fontSize: 'larger' }}>Payouts</Label>
        {enabled ? <Label>Enabled</Label> : <Label>Disabled</Label>}
      </div>
      <div
        style={{
          margin: '1em',
        }}
      >
        <Link to="/settings/payouts">
          <Button positive>{enabled ? 'Manage' : 'Enable'} payouts</Button>
        </Link>
      </div>
    </div>
  );
};

export default ({ user }: any) => {
  const { data } = useQuery(
    gql`
      query User($id: ID!) {
        user(id: $id) {
          id
          role
          stripeAccount
        }
      }
    `,
    { variables: { id: user?.id } },
  );
  return data?.user ? (
    <div style={{ display: 'grid', gridTemplateColumns: `repeat(4, 1fr)` }}>
      {data?.user?.role === 'TUTOR' && <PayoutsStatus user={data.user} />}
    </div>
  ) : (
    <div></div>
  );
};

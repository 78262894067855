import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

export default (props: any) => {

  const { data } = useQuery(
    gql`
      query Events($skip: Int, $take: Int, $sort: JSON, $manualPayment: Boolean) {
        events(skip: $skip, take: $take, sort: $sort, manualPayment: $manualPayment) {
          data {
            id
            createdAt
            startDate
            endDate
            customRate
            everyWeek
            enrollments {
              id
              approved
              student {
                id
                name
              }
              payment {
                id
                charge
              }
            }
            owner {
              id
              name
              email
            }
            creator {
              id
            }
          }
          meta {
            total
          }
        }
      }
    `,
    {
      variables: {
        ...props,
      },
    },
  );
  return { data: data?.events?.data || [], meta: data?.events?.meta || {} };
};

// --- PAGES ---
import Menu from "pages/Menu";
import About from "pages/About";
import PrivacyPolicy from "pages/Legal/PrivacyPolicy";
import Faqs from "pages/Faqs";
import Session from "pages/Session";
import TermsOfService from "pages/Legal/TermsOfServices";
import TutorAgreement from "pages/Legal/TutorAgreement";
import {Login} from "pages/Login";
import Logout from "pages/Auth/Logout";
import {Recover} from "pages/Auth/Recover";
import Join from "pages/Join";
import Help from "pages/Help";
import FindTutor from "pages/Help/find-tutor";
import Emails from "pages/Emails";
import BuyNotes from "pages/Notes/Buy";

// --- LAYOUT ---
import AppLayout from "layout/AppLayout";

// --- ROUETS ---
import {Routes} from "./index";
import Library from "../pages/Notes/Library";
import Tutor from "../pages/Tutor";
import Explore from "../pages/Explore";
import PurchaseNotes from "pages/Notes/Purchase";
import MathTutoring from "pages/MathTutoring";
import EnglishTutoring from "pages/EnglishTutoring";
import NDISLanding from "../pages/NDISLanding";
import ExploreLanding from "../pages/Explore/ExploreLanding";
import PrivateTutoringLanding from "../pages/PrivateTutoring/PrivateTutoringLanding";
import NotesLanding from "../pages/Notes/NotesLanding";
import BlogLanding from "../pages/Blog/BlogLanding";
import BlogPostLanding from "../pages/Blog/BlogPostLanding";

export interface RouterParams {
  type?: string;
}

export const publicRoutes: Routes[] = [
  {
    path: "/",
    component: PrivateTutoringLanding,
    Layout: AppLayout,
  },
  {
    path: "/blog",
    component: BlogLanding,
    Layout: AppLayout,
  },
  {
    path: "/blog/:postSlug",
    component: BlogPostLanding,
    Layout: AppLayout,
  },
  {
    path: "/math-tutoring",
    component: MathTutoring,
    Layout: AppLayout,
  },
  {
    path: "/english-tutoring",
    component: EnglishTutoring,
    Layout: AppLayout,
  },
  {
    path: "/ndis-mentoring",
    component: NDISLanding,
    Layout: AppLayout,
  },
  {
    path: "/private-tutoring",
    component: PrivateTutoringLanding,
    Layout: AppLayout,
  },
  {
    path: "/menu",
    component: Menu,
    Layout: AppLayout,
  },
  {
    path: "/about",
    component: About,
    Layout: AppLayout,
  },
  // {
  //   path: "/about",
  //   component: Legal,
  //   Layout: AppLayout
  // },
  {
    path: "/terms-of-service",
    component: TermsOfService,
    Layout: AppLayout,
  },
  {
    path: "/tutor-agreement",
    component: TutorAgreement,
    Layout: AppLayout,
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
    Layout: AppLayout,
  },
  {
    path: "/faqs",
    component: Faqs,
    Layout: AppLayout,
  },
  {
    path: "/request-session",
    component: Session,
    Layout: AppLayout,
  },
  {
    path: "/login",
    component: Login,
    Layout: AppLayout,
  },
  {
    path: "/logout",
    component: Logout,
    Layout: AppLayout,
  },
  {
    path: "/recover",
    component: Recover,
    Layout: AppLayout,
  },
  {
    path: "/join/:type?",
    component: Join,
    Layout: AppLayout,
  },
  {
    path: "/join",
    component: Join,
    Layout: AppLayout,
  },
  {
    path: "/help",
    component: Help,
    Layout: AppLayout,
  },
  {
    path: "/help/find-tutor",
    component: FindTutor,
    Layout: AppLayout,
  },
  {
    path: "/emails",
    component: Emails,
    Layout: AppLayout,
  },
  {
    path: "/notes",
    component: NotesLanding,
    Layout: AppLayout,
  },
  {
    path: "/notes/buy",
    component: BuyNotes,
    Layout: AppLayout,
  },
  {
    path: "/notes/library",
    component: Library,
    Layout: AppLayout,
  },
  {
    path: "/tutors/:id",
    component: Tutor,
    Layout: AppLayout,
  },
  {
    path: "/explore",
    component: ExploreLanding,
    Layout: AppLayout,
  },
  {
    path: "/explore/tutors/:action?",
    component: Explore,
    Layout: AppLayout,
  },
  {
    path: "/notes/buy/:id",
    component: PurchaseNotes,
    Layout: AppLayout,
  },
];

import styled from '@emotion/styled';
import { NavLink as RRNavLink } from 'react-router-dom';

const LIGHT = 100;
const COLOR = 'black';

export const Container = styled.div`
  width: 100%;
  min-width: 50vmin;
  max-width: 640px;
  background: hsla(0, 0%, ${LIGHT}%, 0.9);
  padding: 0.75em;
  border-radius: 3px;
`;

export const Header = styled.h3`
  color: hsl(0, 66%, 66%);
  font-size: 24px;
  padding: 0.5em 0;
  border-bottom: 1px solid hsl(0, 0%, 100%, 0.25);
`;

export const Label = styled.label`
  display: block;
  border: none;
  margin-top: 0.5em;
  margin-bottom: 0.25em;
  text-transform: uppercase;
  font-size: larger;
  color: hsl(0, 66%, 66%);
`;

export const Textarea = styled.textarea`
  border-radius: 3px;
  padding: 1em;
  display: block;
  background: hsla(0, 0%, ${LIGHT}%, 0.9);
  margin-top: 0.25rem;
  margin-bottom: 1rem;
  width: 100%;
  font-size: 16px;
  min-height: 20vh;
  resize: none;
  border: 1px solid #ccc;
`;

export const Input = styled.input`
  border-radius: 3px;
  padding: 1em;
  display: block;
  color: ${COLOR};
  background: hsla(0, 0%, ${LIGHT}%, 0.9);
  border: 1px solid #ccc;
  margin-top: 0.25rem;
  margin-bottom: 1rem;
  width: 100%;
  font-size: 16px;
`;

export const Select = styled.select`
  font-size: 16px;
  border-radius: 3px;
  padding: 1em;
  display: block;
  border: 1px solid #ccc;
  margin: 0.25rem 0;
  width: 100%;
  height: 52px;
  line-height: 52px;
  background: #fff;
`;

export const NavLink = styled(RRNavLink)`
  color: hsla(0, 66%, 66%, 1);
  &.active {
    color: ${COLOR};
  }
  &:hover {
    color: ${COLOR};
  }
`;

export * from './Button';

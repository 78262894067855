import React from 'react';
import Desktop from './Desktop';


export default () => {

  return (
      <Desktop />
  );
};

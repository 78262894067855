import {Form, Formik} from "formik";
import {Button, Col, Divider, Input, Radio, Row, Space, Typography} from "antd";
import {CardCVCElement, CardExpiryElement, CardNumberElement} from "react-stripe-elements";
import React, {useState} from "react";
import {Elements, useStripe} from "../../Stripe";
import {useMutation} from "@apollo/client";
import {buyNotesAnonymously as buyNotesAnonymouslyMutation} from "../../common/mutations";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";


const {Title, Text} = Typography;

const validateEmail = (email: string) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

const NonLoggedInPaymentModal = (props: any) => {

    const {setShowNonLoggedInPaymentModal, setShowSuccessModal} = props.props

    let {id} = useParams() as any

    const [stripe]: any = useStripe();
    const [paymentOption, setPaymentOption] = useState<number>(1)
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("")

    const [buyNotesAnonymously] = useMutation(buyNotesAnonymouslyMutation)


    const createOptions = () => {
        return {
            style: {
                border: '1px solid',
                base: {
                    border: '1px solid',
                    fontSize: '20px',
                    color: '#415241',
                    letterSpacing: '15px',
                    fontFamily: 'Source Code Pro, monospace',
                    '::placeholder': {
                        color: '#aab7c4',
                    },
                    padding: '10px',
                },
                invalid: {
                    color: '#9e2146',
                },
            },
        };
    };
    return (
        <>
            <Formik
                initialValues={{}}
                onSubmit={async (values) => {
                    setIsButtonDisabled(true);

                    if (paymentOption == 1) {
                        const {token} = await stripe.createToken({});
                        try {
                            const result = await buyNotesAnonymously({
                                variables: {
                                    input: {
                                        email: email,
                                        tutorNotes: id,
                                        token: token.id

                                    }
                                }
                            })

                            setShowNonLoggedInPaymentModal(false)
                            setShowSuccessModal(true)

                        } catch (e) {
                            setIsButtonDisabled(false);
                            console.log("e ", e)
                            toast.error('Error while processing purchase.');
                        }
                    }
                }}
            >
                {(props) => (
                    <Form>
                        <Row>
                            <Col xs={1} sm={1} md={1} lg={0} xxl={0} xl={0}/>
                            <Col xs={23} sm={23} md={23} lg={21} xxl={21} xl={21} id='Payments'>
                                <Row className="payment-setting-align">
                                    <Title className="payment-method-text">Payment Details</Title>
                                </Row>

                                <Divider type="horizontal" className="margin-alignment"/>
                                <Row>
                                    <Col span={1}/>
                                    <Col span={22}>
                                        <Col>
                                            <div className="margin-bottom-9"><Title className="payment-method"
                                                                                    level={5}>How
                                                would you like to pay?</Title></div>
                                            <Col>
                                                <Radio.Group value={paymentOption} onChange={(event) => {
                                                    setPaymentOption(event.target.value)
                                                }}>
                                                    <Space direction="vertical">
                                                        <Radio value={1}><img
                                                            src="/icons/settings-account/settings-payments/card.svg"
                                                            className="margin-right-7" alt="card icon"/><span
                                                            className="radio-button-text">Credit or Debit Card</span></Radio>
                                                    </Space>
                                                </Radio.Group>
                                                <Row>
                                                    <Col xs={23} sm={23} md={23} lg={14} xxl={14} xl={14}>
                                                        {paymentOption == 1 ? (
                                                            <>
                                                                <Divider type="horizontal" className="divider-margin"/>
                                                                <Title className="card-info" level={5}>Credit card
                                                                    number
                                                                    *</Title>
                                                                <Row>
                                                                    <Col span={24}>
                                                                        <CardNumberElement
                                                                            {...createOptions}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col span={11}>
                                                                        <Title className="card-info" level={5}>Expiry
                                                                            date
                                                                            *</Title>
                                                                    </Col>
                                                                    <Col span={2}/>
                                                                    <Col span={11}>
                                                                        <Title className="card-info" level={5}>CVC
                                                                            *</Title>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="card-info-row vertical-spacing">
                                                                    <Col span={11}>
                                                                        <CardExpiryElement
                                                                            {...createOptions}
                                                                        />
                                                                    </Col>
                                                                    <Col span={11}>
                                                                        <CardCVCElement
                                                                            {...createOptions}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <Row className="vertical-spacing ">
                                                                    <Title className="card-info" level={5}>Enter Your
                                                                        Email*</Title>
                                                                    <Input className="select-width" value={email}
                                                                           onChange={(e: any) => setEmail(e.target.value)}
                                                                           status={validateEmail(email) ? "" : "error"}
                                                                           size='large'/>
                                                                </Row>
                                                                <Row>
                                                                    <Col span={24}>
                                                                        {isButtonDisabled ?
                                                                            <Button
                                                                                type='primary'
                                                                                size="large"
                                                                                block
                                                                                disabled={true}
                                                                                className="save-btn"><span
                                                                                className="upload-pic-text">processing</span></Button> :
                                                                            <Button
                                                                                htmlType="submit"
                                                                                type='primary'
                                                                                size="large"
                                                                                block
                                                                                disabled={!validateEmail(email)}
                                                                                className="save-btn"><span
                                                                                className="upload-pic-text">Buy Now</span></Button>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                        ) : null
                                                        }

                                                    </Col>
                                                </Row>
                                                <Divider type="horizontal" className="last-divider-margin"/>
                                            </Col>
                                        </Col>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </>

    )
}

export default (props: any) => (

    <Elements>
        <NonLoggedInPaymentModal props={props}/>
    </Elements>
);

import {ButtonProps, getSubheadingWithLineBreak} from "../../pages/Constants/utilities";
import {Link} from "react-router-dom";
import {Button} from "antd";
import React from "react";
import WhyPnPanelComponent from "./WhyPnPanelComponent";

export type PanelProps = {
    imgSrc: string;
    text: string;
}

type WhyPanelProps = {
    heading: string;
    subheading: string;
    primaryButton?: ButtonProps;
    secondaryButton?: ButtonProps;
    panel: PanelProps[];

}

export default (props: WhyPanelProps) => {
    const {heading, subheading, panel, primaryButton, secondaryButton} = props;

    return <div className={"md:grid md:grid-cols-2 md:px-20 gap-4 md:my-6 my-6 py-4 background-grey place-items-center px-4"}>

        <div className={"col-span-1"}>
            <div className="innovation-text-landing text-4xl my-3">{heading}</div>
            <div className="secondary-text-dmsans text-md my-3">{getSubheadingWithLineBreak(subheading)}</div>
            {primaryButton != null ?
                <Link to={primaryButton.to()}>
                    <Button type="primary" className={"landing-button landing-button-primary"}>
                        {primaryButton.text}
                    </Button></Link> : null}
            {secondaryButton != null ?
                <Link to={secondaryButton.to()}>
                    <Button className={"landing-button landing-button-secondary"}>
                        {secondaryButton.text}
                    </Button></Link> : null}
        </div>
        <div className={"col-span-1"}>
            <div className={"md:grid md:grid-cols-2 md:gap-4"}>
                {panel.map(p => <WhyPnPanelComponent imgSrc={p.imgSrc} text={p.text}/>)}
            </div>
        </div>



    </div>
}

import React, {useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Modal, Button, Checkbox} from 'antd';

// styles
import './styles.css'

export default ({updateEvent, rate, accept}) => {
    const [state, setState] = useState({checked: false, openModal: true})

    const handleSubmit = async () => {
        updateEvent()
        setState({...state, openModal: false})
    }

    const handleGoBack = () => {
        setState({...state, openModal: false})
    }

    const handleChange = ({target: {checked}}: { target: { checked: boolean } }) => {
        setState(s => ({...s, checked}))
    }

    return (
        <Modal
            centered
            className='staySafeModal'
            title='Stay Safe'
            visible={state.openModal}
            footer={[
                <Button key='back' onClick={handleGoBack}>
                    Cancel
                </Button>,
                <Button key='submit' disabled={!state.checked} type='primary' onClick={handleSubmit}>
                    Submit
                </Button>,
            ]}
        >

            {accept ? (
                <>
                    <ul>
                        <li>This event is at a custom negotiated rate, which might not be the same as your advertised
                            rate.
                        </li>
                        <li>Please confirm you accept this session for an hourly rate of ${rate}. If you don't agree,
                            please
                            message the parent to discuss this.
                        </li>
                    </ul>
                    <Checkbox onChange={handleChange}>I accept it.</Checkbox>
                </>
            ) : (
                <>
                    <ul>
                        Please confirm you are rejecting this session. This action will cancel the event
                    </ul>
                    <Checkbox onChange={handleChange}>I reject it.</Checkbox></>
            )
            }

        </Modal>
    )
}

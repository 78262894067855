import React, {useState} from 'react';
import {Modal, Button, Checkbox} from 'antd';

// styles
import './styles.css'

export default ({submit, header}) => {
    const [state, setState] = useState({checked: false, openModal: true})

    const handleSubmit = async () => {
        submit()
        setState({...state, openModal: false})
    }

    const handleGoBack = () => {
        setState({...state, openModal: false})
    }

    const handleChange = ({target: {checked}}: { target: { checked: boolean } }) => {
        setState(s => ({...s, checked}))
    }

    return (
        <Modal
            centered
            className='staySafeModal'
            title={header}
            visible={state.openModal}
            onCancel={handleGoBack}
            footer={[
                <Button key='back' onClick={handleGoBack}>
                    Cancel
                </Button>,
                <Button key='submit' disabled={!state.checked} type='primary' onClick={handleSubmit}>
                    Submit
                </Button>
            ]}
        >
            <>
                <ul>
                    Selecting PayPal means payment will not be automated. You will be sent an invoice from PayPal
                    following the session to make payment. Please make sure this is done promptly.
                </ul>
                <ul>
                    PayPal payments incur an additional 2.6% charge and a $5.50 fixed fee.
                </ul>
                <Checkbox onChange={handleChange}>I understand.</Checkbox>
            </>


        </Modal>
    )
}

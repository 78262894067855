import React from 'react';
import { Row } from 'antd';
import { Form } from 'formik';
import CommonForm from './CommonForm';
import './Desktop.less';

export default({ history }: any) => {

    return (
    <Form>
      <Row className='background-plain main-d-container'>
        <CommonForm history={history}/>
      </Row>
    </Form>
  );
}
import LandingRow from "../../components/LandingTemplates/LandingRow";
import ComponentContainer from "../../components/LandingTemplates/ComponentContainer";
import {homePageBecomeTutorRedirection, homePageBookTutorRedirection} from "../Constants/utilities";
import LandingWhyPanel, {PanelProps} from "../../components/LandingTemplates/LandingWhyPanel";
import FindYourTutorContainer from "../../components/LandingTemplates/FindYourTutorContainer";
import LandingPageBottomCta from "../Landing/LandingPageBottomCta";

const whyPn: PanelProps[] = [
    {
        text: "Access to a diverse range of experienced and knowledgeable tutors",
        imgSrc: "icons/explore/why-pn-rating.png"
    },
    {
        text: "Flexible scheduling to fit your busy schedule",
        imgSrc: "icons/explore/why-pn-time.png"
    },
    {
        text: "Customisable packages to suit individual learning needs",
        imgSrc: "icons/explore/why-pn-agreement.png"
    },
    {
        text: "Competitive pricing to fit any budget",
        imgSrc: "icons/explore/why-pn-price.png"
    },
    {
        text: "The ability to learn and grow alongside a community of like-minded students and tutors",
        imgSrc: "icons/explore/why-pn-chat.png"
    },
]

export default (props) => {
    return <ComponentContainer>
        <LandingRow
            isHero={true}
            heading={"Explore Tutors in Australia with Pocketnote"}
            subheading={"Your Gateway to Finding Australia's Top Tutors.\n" +
                "If you're searching for Australia’s top tutors to help you achieve academic success, Pocketnote is " +
                "here to help. Our platform lets you explore tutors in Australia and " +
                "connect with a wide range of experienced and knowledgeable educators, " +
                "making it easy to find the perfect match for your academic goals and learning style."}
            imgSrc={"icons/explore/landing-hero.png"}
            primaryButton={{text: "Sign Up", to: () => homePageBookTutorRedirection(props.user)}}
            secondaryButton={{text: "Become a Tutor", to: () => homePageBecomeTutorRedirection(props.user)}}
        />
        <LandingRow
            isHero={false}
            reverse={true}
            heading={"Why Pocketnote is the Best Place to Find Tutors in Australia?"}
            subheading={"At Pocketnote, we're committed to providing an exceptional tutoring experience for every one of " +
                "our students. All of our qualified tutors are verified before joining Pocketnote, ensuring that you'll " +
                "receive expert guidance and support from some of Australia’s top-performing students and teachers. " +
                "We also have NDIS-registered tutors available to support students with disabilities, " +
                "ensuring that everyone has equal access to education." +
                "\n" +
                "We're so confident in the quality of our tutors that we offer a 100% refund guarantee on the " +
                "first session, with no questions asked. This way, you can feel confident and comfortable trialling our services without any risk."}
            imgSrc={"icons/explore/why-pocketnote.png"}
            primaryButton={{text: "Sign Up", to: () => homePageBookTutorRedirection(props.user)}}
        />

        <LandingWhyPanel panel={whyPn}
                         primaryButton={{text: "Sign Up", to: () => homePageBookTutorRedirection(props.user)}}
                         heading={"Why Choose Pocketnote to Explore Tutors in Australia?"}
                         subheading={"With Pocketnote, you'll have the opportunity to work with Australia's top tutors and " +
                             "gain the support and guidance you need to succeed in your studies. Our easy-to-use platform makes it simple " +
                             "to find the perfect tutor for your unique needs, whether you're looking for assistance with Maths, Science, English, " +
                             "Humanities or any other subject." +
                             "\n" +
                             "Explore our wide range of expert tutors in Australia today and take the " +
                             "first step towards achieving your academic goals. Don't let your studies be a struggle - " +
                             "with Pocketnote, you'll have all of the customised support you need to find tutors in Australia " +
                             "who can help you reach your full potential."}/>
        <FindYourTutorContainer/>
        <LandingPageBottomCta signUpText={"Ready to connect with Australia’s top tutors who can help meet your learning needs? " +
            "Sign up for Pocketnote today and take your academic journey to the next level."}/>
    </ComponentContainer>
}

import gql from "graphql-tag";

export const unreadConversations = gql`
    query UnreadConversations {
      me {
        id
        unreadConversations {
          id
        }
      }
    }
  `;


export const GqlTotalEarningsForWeek = gql`
  query TotalEarningsForWeek{
  me {
      id
      role
      name
      email
      availability
      retentionRate
      responseRate
      responseTime
      rating
      city
      country
      pauseProfile
      avatar {
         id
         url
        }
      TotalEarningsForWeek
    }
  }
`

export const GqlUserBadge = gql`
  query getUserBadge($id: ID!){
    getUserBadge(id:$id){
      id
      badgeName
      iconName
    }
  }
`

export const GqlEarnings = gql`
  query RevenueChart{
    me{
      id
      RevenueChart{
          dates
          earnings
       }
    }
  }
`

import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

export default () => {
  const [readConversation] = useMutation(gql`
    mutation ReadConversation($user: ID!, $conversation: ID!) {
      readConversation(user: $user, conversation: $conversation)
    }
  `);

  return async (variables: any) => {
    try {
      return readConversation({
        variables,
        refetchQueries: ['UnreadConversations'],
      });
    } catch (e) {}
  };
};

import React from 'react';
import {NavLink} from 'react-router-dom';
import {Avatar, Col, Divider, Row, Typography} from "antd";
import moment from "moment";

const {Text} = Typography;

export default ({
                    others,
                    conversation,
                    activeConversation,
                    me,
                    unread,
                }: any) => {

    return (
        <NavLink
            to={`/conversations/${conversation.id}`}
            isActive={() => conversation.id === activeConversation?.id}
            activeStyle={{
                background: 'black',
                opacity: 1,
            }}
        >
            {others.map((user: any, i: number) => (
                <Row>
                    <div>
                        <Avatar size={51} className="avatar-align" src={`${user?.user.avatar?.url ||
                        'https://react.semantic-ui.com/images/wireframe/image.png'}`}/>
                    </div>

                    <Col span={17}>
                        <Row className="keep-spaced">
                            <div className="people-name-align">
                                <Text className="people-name">{user?.user.name.substring(0, 12)}</Text>

                            </div>

                            <div className="time-align">
                                <Text className="time">{moment(user?.updatedAt).format('hh:mm')}</Text>
                            </div>
                        </Row>

                        <div className="keep-spaced">
                            <div className="text-mesg-align">
                                <div className="text-mesg">
                                    {user.lastMessage ? user.lastMessage.substring(0, 30) : ''}
                                </div>
                            </div>
                            {unread && user?.lastMessageAuthor.id != me.id ? (
                                <div className="notification">
                                    <div className="number-alignment">
                                        <Text className="number">1</Text>
                                    </div>
                                </div>
                            ) : null}


                        </div>
                    </Col>
                    <Divider className="no-margin" type="horizontal"></Divider>
                </Row>
            ))}

        </NavLink>
    );
};

import React from "react";
import {Avatar as AntAvatar, Button, Col, Divider, Row, Typography,} from "antd";
import "./Desktop.less";
import styled from '@emotion/styled';
import {getCurrentUser} from '../common/queries'
import {useQuery} from "@apollo/client";
import TutorWork from "./TutorWork";
import TutorEducation from "./TutorEducation";
import TutorSubjects from "./TutorSubjects";
import {Link, useHistory, useParams} from 'react-router-dom';
import MessageButton from '../Messenger/MessageButton';
import {LANGUAGES} from "../../components/static";
import ReadMoreReact from 'read-more-react';
import TutorProfile from "./TutorProfile";
import {LeftOutlined} from "@ant-design/icons";
import TutorReviews from "./TutorReviews";
import BottomNavigation from '../../components/BottomNavigation'
import {uniqBy} from 'lodash';
import {hourlyServiceFee} from '../common/utils'

const {Text, Title} = Typography;
const Avatar = styled(AntAvatar)`
  background: hsl(0, 66%, 66%);
`


export default ({tutor}) => {

    let {id} = useParams() as any
    // const {data, loading} = useQuery(getTutor, {
    //     variables: {id},
    // });
    //
    // console.log("tutorData", data)

    const {data} = useQuery(getCurrentUser);
    const me = data?.me ? data?.me : JSON.parse(localStorage.getItem("user"));
    const Languages = LANGUAGES;
    const userLanguages = []
    Languages.map(lang => {
        if (tutor?.languages?.includes(lang.value)) {
            userLanguages.push(lang.value)
        }
    })


    // @ts-ignore
    let pocketnoteFee: any = hourlyServiceFee(tutor?.rate)
    const history = useHistory()
    return (
        <>
            <Col id="header-tutor" xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
                <Row className="keep-spaced">
                    <Col span={1} className="arrow-align">
                        <LeftOutlined onClick={() => {
                            history.goBack()
                        }}/>
                    </Col>
                    <Col span={1}/>
                    <Col span={10}>
                        <Title className="header-heading">Tutor profile</Title>
                    </Col>
                    <Col span={10}/>
                </Row>
            </Col>

            <Col id="TutorProfileDesktop">
                <Row className="row-height"/>
                <Row className="first-row">
                    <Col span={24} className="three-sided-border">
                        <Row>
                            <Col xs={1} sm={1} md={1} lg={2} xl={2} xxl={2}/>
                            <Col xs={23} sm={23} md={23} lg={22} xl={22} xxl={22} className="margin-top-11">
                                <Row>
                                    <Avatar size={{xs: 70, sm: 70, md: 70, lg: 122, xl: 122, xxl: 122}}
                                            src={tutor?.avatar?.url}>
                                        {tutor?.name.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}
                                    </Avatar>
                                    <Col xs={16} sm={16} md={16} lg={10} xl={10} xxl={10}
                                         className="padding-tutor-name">
                                        <Row className="flex" style={{alignItems: "center"}}>
                                            <Text className="tutor-name">{tutor?.name}</Text>
                                            <img
                                                className="tick-mark"
                                                src="/icons/tutor-profile/tickmark.svg"
                                                alt="tick icon"
                                            />
                                        </Row>
                                        {tutor?.city && tutor?.city &&
                                        <Row>
                                            <Col className="flex">
                                                <div><img src="/icons/tutor-profile/location-black-icon.svg"/></div>
                                                <Text
                                                    className="location-text">  {tutor?.city} {tutor?.country ? `, ${tutor?.country}` : ''}</Text>
                                            </Col>
                                        </Row>
                                        }
                                        <Row className="flex margin-top-10">
                                            <Col className="flex padding-top-5"
                                                 style={{justifyContent: "center", alignItems: "center"}}>
                                                {tutor?.badges?.length ? (
                                                    <>
                                                        <img alt="badge icon"
                                                             style={{marginLeft: '-15px'}}
                                                             src={`/${tutor?.badges[0]?.iconName}`}
                                                             width={50}
                                                             height={50}/>
                                                        <Text className="no-margin top-rated"
                                                              style={{fontSize: 18, fontWeight: 700}}>
                                                            {tutor?.badges[0]?.badgeName.toUpperCase()}
                                                        </Text>
                                                    </>
                                                ) : null}
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xs={0} sm={0} md={0} lg={4} xl={4} xxl={4}/>

                                    {(!me || me.role !== 'TUTOR') &&
                                    <Col xs={23} sm={23} md={23} lg={6} xl={6} xxl={6} className={'textAlign'}>
                                        <MessageButton  {...{me, tutor}} >
                                            {(props: any) => (
                                                <Button type="primary" className="message-btn" size="large"
                                                        block {...props}>
                                                    <span className={"text-button"}>
                                                        Message {tutor && tutor.name && tutor.name.split(' ')[0]}
                                                    </span>
                                                </Button>
                                            )}
                                        </MessageButton>

                                        <Link
                                            style={{width: '100%'}}
                                            to={me ? `/events/create/${tutor?.id}` : () => {
                                                window.localStorage.setItem('url', `/events/create/${tutor?.id}`)
                                                return `/login?/events/create/${tutor?.id}`
                                            }}
                                        >
                                            <Button className="session-request-btn" size="large" block>
                                                <span className="session-request-btn-text">Session Request</span>
                                            </Button>
                                        </Link>
                                    </Col>
                                    }
                                </Row>
                            </Col>
                        </Row>
                        <Divider type="horizontal"/>
                        <Row className="flex">
                            <Col xs={0} sm={0} md={0} lg={2} xl={2} xxl={2}/>
                            <Col xs={0} sm={0} md={0} lg={7} xl={7} xxl={7}>
                                <TutorProfile
                                    tutor={tutor}
                                    userLanguages={userLanguages}
                                />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={15} xl={15} xxl={15} className="vertical-border ">
                                <div className="padding-left-26">
                                    <Row>
                                        <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}
                                             className="flex qualification-adjustment">
                                            <Text className="width-100 common-heading">
                                                {tutor?.title}
                                            </Text>

                                        </Col>
                                        <Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1}/>
                                        <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}
                                             className="flex qualification-adjustment rate-adjustment ">
                                            { me && me.role === 'TUTOR' ? (
                                                <Text className="head-font">${tutor?.rate}.00/hr</Text>
                                            ) : (
                                                <Col>
                                                    <Row>
                                                        <Text className="head-font">${tutor?.rate}.00/hr</Text>
                                                    </Row>
                                                    <Row>
                                                        <Text className="service-fee-text">+Pocketnote
                                                            Fee ${pocketnoteFee} </Text>
                                                    </Row>
                                                </Col>

                                            )}

                                        </Col>
                                    </Row>
                                    <Row className="width-20">
                                        <div className="flex margin-neg-10">
                                            <div className="margin-neg-4">
                                                <img
                                                    src="/icons/tutor-profile/greentickmark.svg"
                                                    alt="verified icon"
                                                />
                                            </div>
                                            {tutor?.badges?.length &&
                                            <Text className="rate-100">{tutor?.badges[0]?.badgeName}</Text>
                                            }
                                        </div>
                                    </Row>
                                    <Row>
                                        {tutor?.biography &&
                                        <Text className="para-text" style={{whiteSpace: "pre-line"}}>
                                            <ReadMoreReact text={tutor?.biography.replace('<br/>', '\n')} min={200}
                                                           ideal={300} max={500}/>
                                        </Text>
                                        }

                                    </Row>
                                    <Row className="height-36"/>
                                </div>
                                <Divider type="horizontal"/>

                                <Row>
                                    <Col xs={1} sm={1} md={1} lg={0} xl={0} xxl={0}/>
                                    <Col xs={20} sm={20} md={20} lg={0} xl={0} xxl={0}>
                                        <TutorProfile
                                            tutor={tutor}
                                            userLanguages={userLanguages}
                                        />

                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={0} sm={0} md={0} lg={24} xl={24} xxl={24}>
                                        <TutorReviews
                                            feedback={tutor?.feedback}
                                        />
                                    </Col>
                                </Row>

                            </Col>
                            <Col span={2}/>
                        </Row>
                    </Col>
                </Row>
                {tutor?.feedback &&

                <Row className="first-row margin-top-10">
                    <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
                        <TutorReviews
                            feedback={tutor?.feedback}
                        />
                    </Col>
                </Row>

                }

                {tutor?.work?.length ? (
                    <TutorWork
                        workData={tutor?.work}
                    />
                ) : null}

                {tutor?.education?.length ? (
                    <TutorEducation
                        EducationData={tutor?.education}
                    />
                ) : null}
                {uniqBy(tutor?.skills, 'name')?.length ? (
                    <TutorSubjects
                        tutorSubjects={uniqBy(tutor?.skills, 'name')}
                    />
                ) : null}

                <Row className="row-height"/>
            </Col>
            <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
                <BottomNavigation role={me?.role}/>
            </Col>
        </>
    );
};

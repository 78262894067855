import gql from 'graphql-tag';
import { orderBy } from 'lodash';
import React from 'react';
import { useQuery } from '@apollo/client';
import ConversationListItem from './ConversationListItem';


export default ({ me, loading, activeConversation }: any) => {
  const { data } = useQuery(gql`
    query Conversations {
      me {
        id
        conversations {
          id
          updatedAt
          users {
            id
            name
            avatar {
              id
              url
            }
          }
          messages {
            author{
              id
              name
            }
            id
            content
          }
        }
      }
    }
  `);

  const conversations = data?.me?.conversations
    ? orderBy(data.me.conversations, ['updatedAt'], ['desc'])
    : [];
  const { data: unreadConversationsData } = useQuery(gql`
    query UnreadConversations {
      me {
        id
        unreadConversations {
          id
        }
      }
    }
  `);

  const unreadConversations =
    unreadConversationsData?.me?.unreadConversations || [];
  return me ? (
    <div className={"conversation-height"}>

      {conversations.map((conversation, i) => {
        const unread = unreadConversations.find(
          (unreadConversation: any) =>
            unreadConversation.id === conversation.id,
        );

        let others : any = [];
        (conversation?.users || []).map(user=>{
          if(user.id !== me.id){
            others.push({
              user:user,
              updatedAt: conversation.updatedAt,
              lastMessage: conversation?.messages?.slice(-1)[0]?.content || '',
              lastMessageAuthor : conversation?.messages?.slice(-1)[0]?.author || {}
            })
          }
        })

        return (
          <ConversationListItem key={i}
            {...{ me, others, conversation, activeConversation, unread }}
          />
        );
      })}
    </div>
  ) : (
      <div></div>
    );
};

import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Row, Col, Button, Typography, Input} from 'antd';

const {Text} = Typography;

export default ({filtering}: any) => {
    const history = useHistory();
    const [state, setState] = useState<{ name: string }>({name: ''});
    const [lessonTitle, setLessonTitle] = useState<string>('')

    const handleClick = () => {
        history.push({pathname: ``, search: `?name=${state.name}`})
    };

    const handleChange = (event: any) => {
        setState(s => ({...s, [event.target.name]: event.target.value}))
        event.persist()
    };

    return (

        <Row className="subject-row-desktop">
            <Col span={13} className="margin-top-12">
                <Text className="heading-font ">Name</Text>
                <Input prefix={<img src="/icons/explore/search-icon.svg"/>}
                       className="search-bar-desktop no-margin input-text"
                       name='name'
                       value={state.name}
                       placeholder='Search Name'
                       onChange={handleChange}
                       onKeyPress={(e) => {
                           if (e.key === 'Enter') {
                               handleClick()
                           }
                       }}
                />

            </Col>
            <Col span={6} className="margin-top-12">
                <Text className="heading-font"> Lesson Type</Text>
                <div className="flex">
                    <Button
                        className={lessonTitle ? "custom-btn-desktop no-margin" : "custom-focus-lessonTitle custom-btn-desktop no-margin"}
                        size="small"
                        onClick={(e) => {
                            setLessonTitle('ONLINE')
                            history.push(`/explore?lesson=ONLINE`)
                        }}
                    >Online</Button>
                    <Button
                        className={lessonTitle ? "second-custom-btn-desktop no-margin" : "custom-focus-lessonTitle second-custom-btn-desktop no-margin"}
                        onClick={(e) => {
                            setLessonTitle('IN_PERSON')
                            history.push(`/explore?lesson=IN_PERSON`)
                        }}
                        size="small">In-person</Button>
                </div>
            </Col>
            <Col>
                <Button className="search-btn" onClick={handleClick}>
                    <span className="search-btn-text">Search</span>
                </Button>
            </Col>
        </Row>
    )
}

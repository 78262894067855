import React from "react";
import Switch from "../Switch";
import Mobile from "./Mobile";
import Desktop from "./Desktop";

export default ({ user }: any) => (
  <Switch>
    {[
      <Mobile key="mobile" user={user} />,
      <Desktop key="desktop" user={user} />,
    ]}
  </Switch>
);

import React from 'react'
import {List, Typography, Row, Col} from "antd";
import '../TermsOfServices/Desktop.less'
import {Link} from 'react-router-dom'
import Question from "../TermsOfServices/Question";

export default ({intro, headLine, tutorAgreement1, main}) => {
    return (
        <Row id="FaqsDesktop">
            <Col span={1}/>
            <Col xs={0} sm={0} md={4} lg={4} xxl={4} className="terms">
                <Row>
                    <Link to="/terms-of-service">
                        <div className="terms-text">Terms & Conditions</div>
                    </Link>
                </Row>
                <Row className="row-height"/>
                <Row>
                    <Link to="/privacy-policy">
                        <div className="terms-text">Privacy Policy</div>
                    </Link>
                </Row>
                <Row className="row-height"/>
                <Row>
                    <Link to="/faqs">
                        <div className="terms-text">FAQs</div>
                    </Link>
                </Row>

            </Col>
            <Col xs={22} sm={22} md={16} lg={16} xxl={16} className="faq-col">
                <Row className="main-faq">
                    <div className="faq-heading">TUTOR AGREEMENT</div>
                </Row>
                <Row className="text-div">
                    <div>
                        <Col span={24} className="conv-col-overflow">
                            <Col span={24}>

                                <Question question={intro}/>
                                <div className="def-heading">DEFINITIONS</div>
                                <Question question={headLine}/>
                                {tutorAgreement1.map((item: any) =>
                                    Array.isArray(item) ?
                                        <List
                                            dataSource={item}
                                            renderItem={item => (
                                                <>
                                                    <Row>
                                                        <Col span={1}/>
                                                        <Col span={20}>
                                                            <List.Item>
                                                                <Typography.Text
                                                                    className="bullet-text margin-inner-bullet">{item["index"]}</Typography.Text>
                                                                <Typography.Text
                                                                    className="description">{item["description"]}</Typography.Text>
                                                            </List.Item>
                                                        </Col>

                                                    </Row>
                                                </>
                                            )}
                                        />
                                        :
                                        <Question answer={item}/>
                                )}

                                <Row className="row-30"/>


                                <ol>
                                    {main.map((item: any) =>
                                        <li className="headings"><span className="heading-text">{item.index}</span>
                                            <List
                                                dataSource={item.description}
                                                renderItem={item => (
                                                    Array.isArray(item) ? <List
                                                            dataSource={item}
                                                            renderItem={item => (
                                                                Array.isArray(item) ? <List
                                                                        dataSource={item}
                                                                        renderItem={item => (
                                                                            <List.Item>
                                                                                <Typography.Text
                                                                                    className="bullet-text margin-inner-bullet">{item["index"]}</Typography.Text>
                                                                                <Typography.Text
                                                                                    className="description">{item["description"]}</Typography.Text>
                                                                            </List.Item>
                                                                        )}
                                                                    /> :
                                                                    <Col span={23}>
                                                                        <List.Item className="">
                                                                            <Typography.Text
                                                                                className="main-bullet-ab">{item["index"]}</Typography.Text>
                                                                            <Typography.Text
                                                                                className="main-description-ab">{item["description"]}</Typography.Text>
                                                                        </List.Item>
                                                                    </Col>
                                                            )}
                                                        /> :
                                                        <List.Item>
                                                            <Typography.Text
                                                                className="main-bullet">{item["index"]}</Typography.Text>
                                                            <Typography.Text
                                                                className="main-description">{item["description"]}</Typography.Text>
                                                        </List.Item>
                                                )}
                                            />
                                        </li>
                                    )}
                                </ol>


                            </Col>

                        </Col>
                    </div>
                </Row>
            </Col>


        </Row>
    )
}

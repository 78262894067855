import React, { useState, useEffect } from 'react';
import Table from '../../Table';
import COLUMNS from './columns';
import useData from './useData';

export default (props: any) => {
  const [sortBy, setSortBy] = React.useState([{ id: 'startDate', desc: true }]);
  const [pageCount, setPageCount] = React.useState(2);
  const [page, setPage] = React.useState(0);
  const [_filters, setFilters] = React.useState([]);
  const [manualPayment, setManualPayment] = React.useState<boolean>(false)

  const [params, setParams] = useState({
    skip: 0,
    take: 8,
    sort: sortBy,
    manualPayment: manualPayment
  });

  const { data, meta } = useData(params);

  useEffect(() => {
    setPageCount(Math.ceil(meta.total / 32));
  }, [meta]);

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, sortBy, filters }) => {
      setParams({
        skip: pageSize * pageIndex,
        take: pageSize,
        sort: sortBy,
        manualPayment: manualPayment
      });
      setPage(pageIndex);
      setSortBy(sortBy);
      setFilters(filters);
    },
    [],
  );

  return (
    <Table
      data={data || []}
      columns={COLUMNS}
      fetchData={fetchData}
      pageCount={pageCount}
      pageIndex={page}
      sortBy={sortBy}
      manual={true}
      filters={_filters}
      manualPayment={manualPayment}
      setManualPayment={setManualPayment}
      setParams={setParams}
    />
  );
};

import gql from 'graphql-tag';

export const addEducationMutation = gql`
    mutation addEducation($instituteName:String!, $startDate:Int!, $endDate:Int, $majorSubject:String!, $grade:String, $degree:String, $id:ID!){
        addEducation(instituteName: $instituteName, startDate:$startDate, endDate:$endDate, majorSubject:$majorSubject,grade:$grade, degree:$degree id:$id){
            instituteName
            startDate
            endDate
            majorSubject
        }
    }
`

export const getEducationQuery = gql`
      query getEducationByUser($id: ID!) {
        getEducationByUser(id: $id){
            id
            instituteName
            startDate
            endDate
            majorSubject
            degree
            grade
        }
      }
    `

export const addWorkMutation = gql`
    mutation addWork($title:String!, $description:String!, $startDate:Int!, $endDate:Int, $id:ID!){
        addWork(title : $title, description: $description, startDate:$startDate, endDate : $endDate, id:$id){
            id
            title
            description
        }
    }
`

export const getWorkQuery = gql`
    query getWorkByUser($id:ID!){
        getWorkByUser(id : $id){
            id
            title
            description
            startDate
            endDate
        }
    
    }
`

export const deleteWorkMutation = gql`
    mutation deleteWork($id:ID!){
        deleteWork(id:$id)
    }
`

export const updateWorkMutation = gql`
    mutation updateWork($title : String!, $description : String!, $startDate:Int!, $endDate:Int!, $id:ID!){
        updateWork(title:$title, description:$description,startDate:$startDate,endDate:$endDate,id:$id){
            id
            title
            description
        }
    }
`

export const deleteEducationMutation = gql`
    mutation deleteEducation($id:ID!){
        deleteEducation(id:$id)
    }
`

export const updateEducationMutation = gql`
    mutation updateEducation($instituteName:String!, $startDate:Int!, $endDate:Int, $majorSubject:String!,$grade:String, $degree:String, $id:ID!){
        updateEducation(instituteName:$instituteName, startDate:$startDate, endDate:$endDate, majorSubject:$majorSubject,grade:$grade, degree:$degree id:$id){
            id
            majorSubject
            instituteName
            
        }
    }
`

export const addAvailabilityMutation = gql`
    mutation Availability($userId:ID!, $availabilities: [AvailabilityInput]!, $availabilityExclusions: [AvailabilityExclusion]) {
  upsertAvailability(userId:$userId, availabilities: $availabilities,availabilityExclusions: $availabilityExclusions)
}
`

export const updateAvailabilityMutation = gql`
    mutation Availability($availability:AvailabilityInput!,$availabilityId:ID!){
        updateAvailability(availability:$availability,availabilityId:$availabilityId)
    }
`

export const updateAvailabilityExclusionMutation = gql`
    mutation Availability($availabilityExclusion:AvailabilityExclusion!,$availabilityExclusionId:ID!){
        updateAvailabilityExclusion(availabilityExclusion:$availabilityExclusion,availabilityExclusionId:$availabilityExclusionId)
    }
`

export const deleteAvailabilityExclusion = gql`
    mutation Availability($availabilityExclusionId:ID!){
        deleteAvailabilityExclusion(availabilityExclusionId:$availabilityExclusionId)
    }
`
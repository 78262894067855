import React, {useEffect, useState} from "react";

import {GetStarted} from "./GetStarted";
import {CreateYourNotes} from "./CreateYourNotes";
import {useQuery} from "@apollo/client";
import {User} from "../../../@graphql-client/types";
import {getCurrentUser, getCurrentUserProfile} from "../../common/queries";
import {useLocation} from "react-router-dom";


export const SellNotes = (props: any) => {

    let loc = useLocation()


    const {loading: currentUserLoading, data: user} =
        useQuery<{ me: User }>(getCurrentUser);
    const {loading, data} = useQuery<{ me: User }>(getCurrentUserProfile);

    const [getStartedButtonState, setGetStartedButtonState] = useState<boolean>(false);



    return (


        (getStartedButtonState === true || loc.state) ?
            (
                <CreateYourNotes
                    tutor={data.me}
                    notes={loc.state ? loc.state : ""}
                    isButtonPressed={setGetStartedButtonState}
                />
            ) :
            (
                <GetStarted isButtonPressed={setGetStartedButtonState}/>
            )

    )
}
module.exports = {
  'Footer.Copyright': '© Pocketnote Pty Ltd 2020',
  'Home.Hero.Heading': 'Innovation in Tutoring',
  'Dashboard.Dashboard.MenuItem': 'Dashboard',
  'Dashboard.Dashboard.Header': 'Dashboard',
  'Dashboard.Events.MenuItem': 'Events',
  'Dashboard.Events.Header': 'Events',
  'Dashboard.Messages.MenuItem': 'Messages',
  'Dashboard.Messages.Header': 'Messages',
  'Dashboard.Settings.MenuItem': 'Settings',
  'Dashboard.Settings.Header': 'Settings',
  'Dashboard.Settings.Payment.MenuItem': 'Payment',
  'Dashboard.Settings.Payment.Header': 'Payment Settings',
  'Dashboard.Settings.Account.MenuItem': 'Account',
  'Dashboard.Settings.Students.MenuItem': 'Students',
  'Dashboard.Settings.Account.Header': 'Account Settings',
  'Dashboard.Settings.Profile.MenuItem': 'Profile',
  'Dashboard.Settings.Profile.Header': 'Profile Settings',
  'Dashboard.Settings.Profile.Profile.Header': 'Profile',
  'Dashboard.Settings.Profile.Profile.Text': 'Tell us a bit about yourself.',
  'Dashboard.Settings.Profile.Location.Header': 'Location',
  'Dashboard.Settings.Profile.Location.Text':
    "Where are you currently based? We'll use this information to match you with people nearby.",
  'Dashboard.Settings.Profile.Skills.Header': 'Skills',
  'Dashboard.Settings.Profile.Skills.Text':
    'Have a skill not listed here? You may enter a new one, but it will need to be approved first.',
  'Dashboard.Settings.Profile.Verification.Header': 'Verification',
  'Dashboard.Settings.Profile.Verification.Text':
    'We will use this to verify your skills and the information on your profile.',
  'Dashboard.Settings.Profile.Agreement.Header': 'Agreement',
  'Dashboard.Settings.Profile.Agreement.Text':
    'I, {name}, agree to the following',
};

import gql from 'graphql-tag';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';

export default ({ me, tutor, children }: any) => {
  const location = useLocation();
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const [upsertConversation] = useMutation(gql`
    mutation UpsertConversation($me: ID!, $them: ID!) {
      upsertConversation(me: $me, them: $them) {
        id
      }
    }
  `);
  return children({
    disabled,
    onClick: async () => {
      if (
        me?.role === 'ADMIN' &&
        !window.confirm(`Really message ${tutor?.name}?`)
      ) {
        return;
      }
      try {
        setDisabled(true);
        const result = await upsertConversation({
          variables: {
            me: me?.id,
            them: tutor?.id,
          },
        });
        history.push(`/conversations/${result.data.upsertConversation.id}`);
      } catch (e) {
        history.push({
          pathname: `/login`,
          search: `?from=${encodeURIComponent(location.pathname)}`,
          state: {
            from: {
              pathname: location.pathname,
              search: location.search,
            },
          },
        });
      }
    },
  });
};

import React from 'react';
import {Col, Divider, Row, Typography} from 'antd';
import './Mobile.less';
import {Link} from 'react-router-dom';
import {homePageBecomeTutorRedirection, homePageBookTutorRedirection} from '../Constants/utilities';

const {Paragraph} = Typography;

export default ({user}: any) => {
    return (
        <Row>
            <Col className='align-center img-center margin-top-48' span={24}>
                <div
                    style={{
                        backgroundImage: `url(/icons/pocketnote-logo.svg)`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: 212,
                        height: 40,
                    }}
                ></div>
            </Col>

            <Col className='align-center margin-top-36' span={24}>
                <Link to={homePageBookTutorRedirection(user)}><Paragraph className='text-spacing'>Book a
                    Tutor</Paragraph></Link>
                <Link to={homePageBecomeTutorRedirection(user)}><Paragraph className='text-spacing'>Become a
                    Tutor</Paragraph></Link>
                <Link to={'/math-tutoring'}><Paragraph className='text-spacing'>Maths Tutors</Paragraph></Link>
                <Link to={'/english-tutoring'}><Paragraph className='text-spacing'>English Tutors</Paragraph></Link>
                {/*<Link to={'/ndis-mentoring'}><Paragraph className='text-spacing'>NDIS Tutors</Paragraph></Link>*/}
                <Link to='/about'><Paragraph className='text-spacing'>About Us</Paragraph></Link>
                <a href={'mailto:support@pocketnote.com.au'}><Paragraph className='text-spacing'>Contact Us</Paragraph></a>
            </Col>
            <Divider style={{marginTop: 24}}/>


            <Col className='align-center' span={24}>
                <Link to='/terms-of-service'><Paragraph className='text-spacing privacy-policy'>Terms and
                    Conditions</Paragraph></Link>
                <Link to='/privacy-policy'> <Paragraph className='text-spacing privacy-policy'>Privacy
                    Policy</Paragraph></Link>
                <Link to='/faqs'> <Paragraph className='text-spacing privacy-policy'>FAQs</Paragraph></Link>
            </Col>

            <Col className='align-center margin-top-36' span={24}>
                <Paragraph className='text-spacing privacy-policy'>We make tutoring easy.</Paragraph>
                <Paragraph className="terms-con">Call us at: <a href={"tel:0467817086"}>0467 817 086</a></Paragraph>
            </Col>
        </Row>
    )
}

import React, {useState} from 'react';
import {Row, Col, Typography, Tooltip, Divider, Switch, Button, Popconfirm} from 'antd';
import {
    QuestionCircleOutlined,
    InfoCircleFilled,
    UserOutlined,
    CloseOutlined,
    CheckOutlined
} from '@ant-design/icons';
import RevenueChart from './RevenueChart'
import {
    unreadConversations,
    GqlTotalEarningsForWeek,
    GqlEarnings
} from './query'
import {GqlUserBadge} from '../common/queries'
import {useApolloClient, useMutation, useQuery} from "@apollo/client";
import {Rate} from 'antd';
import './Desktop.less';
import gql from "graphql-tag";
import {useHistory} from "react-router-dom";
import Session from './Session'
import BottomNavigation from '../../components/BottomNavigation'
import { Link } from 'react-router-dom';
import {toast} from "react-toastify";

const {Title, Text} = Typography;


export default () => {

    const client = useApolloClient();
    const history = useHistory()
    const totalWeekEarningData = useQuery(GqlTotalEarningsForWeek)
    const {data} = useQuery(unreadConversations)
    const me = (totalWeekEarningData.data?.me) || null
    const totalWeekEarnings = (totalWeekEarningData.data?.me && totalWeekEarningData.data?.me.TotalEarningsForWeek)
    const userId = me?.id
    const [switchLoading, setSwitchLoading] = useState<Boolean>(false)

    const [updateUser] = useMutation(
        gql`
                mutation UpdateUser($id: ID!, $pauseProfile: Boolean) {
                  updateUser(id: $id, pauseProfile: $pauseProfile) {
                    id
                    pauseProfile
                  }
                }
              `,
    );

    const [deleteAccountMutation] = useMutation(gql`
    mutation {
      deleteAccount {
        success
        message
      }
    }
  `)

    const userBadgesData = useQuery(GqlUserBadge, {
        variables: {id: userId}
    })
    const currBadge = (userBadgesData?.data && userBadgesData?.data.getUserBadge) || null
    let name = me?.name || null

    const userEarningsData = useQuery(GqlEarnings)
    const userEarnings = (userEarningsData.data?.me && userEarningsData.data?.me.RevenueChart) || null
    let dates = userEarnings?.dates
    let earnings = userEarnings?.earnings

    const updateProfilePauseStatus = async (value) => {
        setSwitchLoading(true)

        try {
            await updateUser({
                variables: {
                    id: me?.id,
                    pauseProfile: value,
                },
            });

            if (value === 'error') {
                toast.error('Profile paused, now your profile will not be listed on student search.');
            } else {
                toast.success('Profile resumed, now your profile will be listed on student search.')
            }

        } catch (e) {
            console.log("Error while updating pauseProfile status ", e)
            toast.error('Error while updating profile status.')
        } finally {
            setSwitchLoading(false)

        }
    }

    const deleteAccount = async () => {
        try {
            const result = await deleteAccountMutation()
            toast.info('Account deleted successfully.');
            localStorage.removeItem("session");
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            client.resetStore();
            history.push('/');

        } catch (error) {
            toast.error('Error while deleting account.');
            console.log("error while deleting user")
            console.log(error)
        }
    }
    const count = data?.me?.unreadConversations?.length || 0;
    const retentionInfo = <span>This measures your ability to retain students. It is a good way for you to know if you are getting repeat business and allows us to ensure tutors uphold Pocketnote’s quality standards. For example, if you had 3 students take sessions with you in the first month and only 2 come back for classes in the second month, your 1-month retention rate would be ⅔ = 66%.</span>;

    const badgesInfo = () => {
        return (
            <div>
                <Row>
                    <Col span={6}>
                        <img src={'top-rated-arrow.svg'} width={50} height={50} alt={""}/>
                    </Col>
                    <Col span={18}>
                        <Row>
                            <Col span={24}>
                                <Text className="full-width desc-session" style={{color: 'black', fontWeight: 500}}>
                                    Top Rated: Earn more
                                </Text>
                            </Col>
                            <Col span={24}>
                                <Text className="full-width desc-session" style={{color: 'black', fontWeight: 500}}>
                                    than $500
                                </Text>
                            </Col>
                        </Row>

                    </Col>
                </Row>
                <Row className="row-height"/>
                <Row>
                    <Col span={6}>
                        <img src={'rising-star-icon.svg'} width={50} height={50} alt={""}/>
                    </Col>
                    <Col span={18}>
                        <Row>
                            <Col span={24}>
                                <Text className="full-width desc-session" style={{color: 'black', fontWeight: 500}}>
                                    Rising Star: After your
                                </Text>
                            </Col>
                            <Col span={24}>
                                <Text className="full-width desc-session" style={{color: 'black', fontWeight: 500}}>
                                    first session
                                </Text>
                            </Col>
                        </Row>

                    </Col>
                </Row>
                <Row className="row-height"/>
                <Row>
                    <Col span={6}>
                        <img src={'pocketmaster-icon.svg'} width={50} height={50} alt={""}/>
                    </Col>
                    <Col span={18}>
                        <Row>
                            <Col span={24}>
                                <Text className="full-width desc-session" style={{color: 'black', fontWeight: 500}}>
                                    Pocketmaster: Earn
                                </Text>
                            </Col>
                            <Col span={24}>
                                <Text className="full-width desc-session" style={{color: 'black', fontWeight: 500}}>
                                    more than $3,000
                                </Text>
                            </Col>
                        </Row>

                    </Col>
                </Row>

            </div>
        )
    }

    return (
        <div>

            <Row id="MainRow">
                <Col xs={0} sm={0} md={2} lg={1} xxl={1}/>
                <Col xs={23} sm={23} md={20} lg={22} xxl={22} id={"DashboardDesktop"}>
                    <Row>
                        <Col xs={1} sm={1} md={2} lg={2} xxl={2}/>
                        <Col xs={22} sm={22} md={20} lg={20} xxl={20}>

                            <Row>
                                <Col xs={10} sm={16} md={0} lg={0} xxl={0}/>
                                <Col xs={9} sm={5} md={0} lg={0} xxl={0}>
                                    <Text className="pause-profile-btn">
                                        Pause Profile
                                    </Text>
                                </Col>
                                <Col xs={5} sm={3} md={0} lg={0} xxl={0}>
                                    <Switch
                                        loading={!!switchLoading}
                                        style={{maxWidth: 'max-content'}}
                                        checkedChildren={<CheckOutlined/>}
                                        unCheckedChildren={<CloseOutlined/>}
                                        checked={me?.pauseProfile}
                                        onChange={updateProfilePauseStatus}
                                    />
                                </Col>
                            </Row>
                            <Row className="row-height"/>
                            <Row>
                                {/*first line for name and rating */}
                                <Col xs={4} sm={3} md={2} lg={2} xxl={2}>
                                    <UserOutlined className={"user-outline-icon"}/>
                                </Col>
                                <Col xs={18} sm={10} md={6} lg={6} xxl={6}>
                                    <Title className={"tutor-name"}>{name}</Title>
                                </Col>
                                <Col xs={4} sm={4} md={0} lg={0} xl={0} xxl={0}/>
                                <Col xs={19} sm={19} md={5} lg={5} xxl={5} className={"margin-top-15"}>
                                    <Rate className="star-size" disabled value={me?.rating}/>
                                    <Text className="total-rating">&nbsp; {me?.rating}/5</Text>

                                </Col>
                                <Col xs={0} sm={0} md={5} lg={5} xxl={5}/>
                                <Col xs={0} sm={0} md={4} lg={4} xxl={4}>
                                    <Text className="pause-profile-btn">
                                        Pause Profile
                                    </Text>
                                </Col>
                                <Col xs={0} sm={0} md={1} lg={1} xxl={1}>
                                    <Switch
                                        loading={!!switchLoading}
                                        style={{maxWidth: 'max-content'}}
                                        checkedChildren={<CheckOutlined/>}
                                        unCheckedChildren={<CloseOutlined/>}
                                        checked={me?.pauseProfile}
                                        onChange={updateProfilePauseStatus}
                                    />
                                </Col>
                                {me?.city &&
                                <Col span={24}>
                                    <Row>

                                        <Col xs={4} sm={4} md={2} lg={2} xxl={2}/>
                                        <Col xs={19} sm={19} md={20} lg={22} xxl={22}
                                             className="flex mobile-margin-top">
                                            <div><img src="/icons/tutor-profile/location-black-icon.svg"/></div>
                                            <Text className="location-text"
                                            >
                                                {me?.city} {me?.country ? `, ${me?.country}` : null}
                                            </Text>
                                        </Col>

                                    </Row>
                                </Col>
                                }


                                <Col span={24}>
                                    <Row className="row-height"/>
                                    <Row className="row-height"/>
                                    <Row>
                                        <Col xs={0} sm={0} md={1} lg={1} xxl={1}/>
                                        {currBadge ? (
                                            <>
                                                <Col xs={20} sm={11} md={4} lg={4} xxl={4}>
                                                    <img alt={'#'} src={currBadge?.iconName} width={50}
                                                         height={50}/>
                                                    <Text className={"common-heading margin-left-15"}>
                                                        {currBadge?.badgeName}
                                                    </Text>
                                                </Col>
                                                <Col xs={4} sm={4} md={0} lg={0} xxl={0}>
                                                    <Tooltip placement="right" title={badgesInfo} color={'#c3cfd9'}>
                                                        <InfoCircleFilled className={"info-circle-styles"}/>
                                                    </Tooltip>
                                                </Col>
                                            </>
                                        ) : null}

                                        {me?.responseRate < 2 ? (
                                            <Col xs={20} sm={11} md={6} lg={6} xxl={6}>
                                                <img src={'chat-content.svg'} width={40} height={40}
                                                     style={{marginTop: '7px'}}
                                                     alt={'very responsive'}/>
                                                <Text className="common-heading margin-left-20">
                                                    Very responsive
                                                </Text>
                                            </Col>
                                        ) : null}


                                        <Col xs={20} sm={11} md={7} lg={7} xxl={7}>
                                            <img alt={'retention rate'} src={'retention-rate.svg'} width={40}
                                                 height={40}/>
                                            <Text className="common-heading margin-left-20">
                                                Retention Rate {me?.retentionRate}%

                                            </Text>
                                        </Col>
                                        <Col span={4}>
                                            <Tooltip placement="right" title={retentionInfo}>
                                                <QuestionCircleOutlined className={"info-circle-styles"}/>
                                            </Tooltip>
                                        </Col>

                                        <Col xs={0} sm={0} md={2} lg={2} xxl={2}>
                                            <Tooltip placement="right" title={badgesInfo} color={'#c3cfd9'}>
                                                <InfoCircleFilled className={"info-circle-styles"}/>
                                            </Tooltip>
                                        </Col>
                                    </Row>
                                </Col>

                                <Divider type="horizontal"/>

                                <Col span={24}>
                                    <Title className="sub-headings">Messages</Title>
                                </Col>
                                <Col span={24}>
                                    <Row className="row-height"/>
                                </Col>

                                <Col xs={4} sm={4} md={2} lg={2} xxl={2}>
                                    <img src={'chat-content.svg'} width={50} height={50} alt={'unread messages'}/>
                                </Col>
                                <Col xs={20} sm={20} md={22} lg={22} xxl={22}>
                                    <Text className="para-text mobile-margin-left">
                                        You have {count} unread conversations
                                    </Text>
                                </Col>
                                {/*<Col xs={0} sm={0} md={1} lg={1} xxl={1}/>*/}
                                <Col xs={20} sm={20} md={7} lg={7} xxl={7}>
                                    <img src={'response.svg'} width={50} height={50} alt={'unread messages'}/>
                                    <Text className="para-text margin-left-15">
                                        Response Rate : {me?.responseRate}%
                                    </Text>
                                </Col>
                                <Col xs={20} sm={20} md={15} lg={15} xxl={15}>
                                    <img src={'response-rate.svg'} width={50} height={50} alt={'response time'}/>
                                    <Text className="para-text margin-left-15">
                                        Response Time : {me?.responseTime} Hours
                                    </Text>
                                </Col>

                                <Divider type="horizontal"/>

                                <Col span={24}>
                                    <Row className="row-height"/>
                                    <Title className="sub-headings">Pocketmoney</Title>
                                </Col>

                                <Col xs={24} sm={24} md={0} lg={0} xxl={0}>
                                    <Row>
                                        <Col span={24}>
                                            <Row className="row-height"/>
                                            <Row className="row-height"/>
                                        </Col>

                                        <Col span={24}>
                                            <Text className="common-heading">
                                                Current week
                                                <Title
                                                    className="total-earnings color-primary align-center">${totalWeekEarnings}</Title>
                                            </Text>

                                        </Col>
                                    </Row>
                                </Col>

                                <Col xs={24} sm={24} md={16} lg={16} xxl={16}>
                                    <Row className="row-height"/>
                                    <RevenueChart
                                        dates={dates}
                                        earnings={earnings}
                                    />
                                </Col>

                                <Col xs={0} sm={0} md={8} lg={7} xxl={7}>
                                    <Row>
                                        <Col span={24}>
                                            <Row className="row-height"/>
                                            <Row className="row-height"/>
                                        </Col>

                                        <Col span={24}>
                                            <Text className="common-heading margin-left-15">
                                                Current week
                                                <Title className="total-earnings color-primary"
                                                >${totalWeekEarnings}</Title>
                                            </Text>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Row className="row-height"/>
                                            <Row className="row-height"/>
                                            <Row className="row-height"/>
                                        </Col>
                                    </Row>

                                </Col>
                                <Col span={3}/>

                                <Col span={24}>
                                    <Row className="row-height"/>
                                    <Title level={3} className="sub-headings">Sessions</Title>
                                </Col>

                                <Col span={24}>
                                    <div>
                                        {[
                                            ['Unapproved', 'unapprovedEvents'],
                                            ['Approved', 'approvedEvents', {reverse: true, readOnly: true}],
                                        ].map(([title, selector, props = {}]: any) => {
                                            return (
                                                <div key={title} style={{marginTop: 16}}>
                                                    <Session {...{...props, selector}} heading={title}/>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </Col>

                                <Col span={2}/>

                                <Divider type="horizontal"/>
                                <Col span={24}>
                                    <Title className="sub-headings">View public profile</Title>
                                </Col>
                                <Col span={24}>
                                    <Row className="row-height"/>
                                    <Link to={`/tutors/${me?.id}`}>
                                        <Button className="delete-btn " block size="large">
                                      <span className="delete-btn-text">
                                        Public Profile
                                      </span>
                                        </Button>
                                    </Link>
                                </Col>

                                <Divider type="horizontal"/>
                                <Col span={24}>
                                    <Title className="sub-headings">Delete Account</Title>
                                </Col>

                                <Col span={24}>
                                    <Row className="row-height"/>
                                    <Popconfirm
                                        okText="Yes"
                                        cancelText="No"
                                        onConfirm={deleteAccount}
                                        title="Are you sure to delete your account ?"
                                    >
                                        <Button className="delete-btn " block size="large">
                                      <span className="delete-btn-text">
                                        Delete Account
                                      </span>
                                        </Button>
                                    </Popconfirm>
                                </Col>
                                <Col span={24}>
                                    <Row className="row-height"/>
                                    <Row className="row-height"/>

                                </Col>

                            </Row>

                        </Col>

                    </Row>
                </Col>
                <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
                    <BottomNavigation role={me?.role}/>
                </Col>
            </Row>


        </div>

    )
}

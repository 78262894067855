import React from "react";
import { Col, Collapse, Row, Typography} from "antd";
import {MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";

const {Panel} = Collapse;
const {Title, Text} = Typography;

export const FAQ = () => {

    return (


        <>
            <Row>
                <Col xs={1} sm={1} md={1} lg={0} xxl={0} xl={0}/>

                <Col id="GettingStartedDesktop" xs={23} sm={23} md={23} lg={22} xxl={22} xl={22}
                     style={{
                         // border: "1px solid blue",
                         display: "flex",
                         justifyContent: "center",
                         alignItems: "center"
                     }}
                >
                    <Row className="faq-title">
                        <Col xs={24} sm={24} md={24} lg={24} xxl={24} xl={24}>
                            <Title className="create-notes-title">Frequently Asked Questions</Title>
                        </Col>

                    </Row>
                </Col>

                <Col xs={23} sm={23} md={23} lg={23} xxl={22} xl={22} id="NotesDesktopCollapse">
                    <Collapse
                        defaultActiveKey={["1"]}
                        expandIcon={({isActive}) =>
                            isActive ? (
                                <MinusCircleOutlined className="icon-specs"/>
                            ) : (
                                <PlusCircleOutlined className="icon-specs"/>
                            )
                        }
                        expandIconPosition="right"
                    >
                        <Panel header="Can anyone sell their notes?" key="0" className="collapse-background">
                            <Row className="row-height"/>
                            <Row>
                                <Col span={1}/>
                                <Col xs={22} sm={22} md={22} lg={20} xxl={20} xl={20}>

                                    <div style={{fontSize: '15px', lineHeight: 1.5, fontWeight: "lighter"}}>
                                        <p>Yes!</p>
                                        <p>As long as you've completed the subject, you can sell your notes. Simply
                                        sign up at Pocketnote and upload your notes. Only notes approved by our
                                            moderation team will be available for sale.
                                        </p>
                                    </div>
                                </Col>
                            </Row>

                        </Panel>
                    </Collapse>
                </Col>

                <Col xs={23} sm={23} md={23} lg={23} xxl={22} xl={22} id="NotesDesktopCollapse">
                    <Collapse
                        defaultActiveKey={["1"]}
                        expandIcon={({isActive}) =>
                            isActive ? (
                                <MinusCircleOutlined className="icon-specs"/>
                            ) : (
                                <PlusCircleOutlined className="icon-specs"/>
                            )
                        }
                        expandIconPosition="right"
                    >
                        <Panel header="Is this legal?" key="0" className="collapse-background">
                            <Row className="row-height"/>
                            <Row>
                                <Col span={1}/>
                                <Col xs={22} sm={22} md={22} lg={20} xxl={20} xl={20}>

                                    <div style={{fontSize: '15px', lineHeight: 1.5, fontWeight: "lighter"}}>
                                        <p>Yes.</p>
                                        <p>As long as you create an original piece of work, you are the legitimate
                                            author
                                            of this work and own the IP. You have the right to sell it. We do not accept
                                            any
                                            material that is not subject notes, such as assignments or exam
                                            responses.</p>
                                        <p>This is also a great resource and incredibly helpful for other students. A C+
                                            student can learn what it takes to be an A student!</p>
                                    </div>

                                </Col>
                            </Row>

                        </Panel>
                    </Collapse>
                </Col>

                <Col xs={23} sm={23} md={23} lg={22} xxl={22} xl={22} id="NotesDesktopCollapse">
                    <Collapse
                        defaultActiveKey={["1"]}
                        expandIcon={({isActive}) =>
                            isActive ? (
                                <MinusCircleOutlined className="icon-specs"/>
                            ) : (
                                <PlusCircleOutlined className="icon-specs"/>
                            )
                        }
                        expandIconPosition="right"
                    >
                        <Panel header="How much do I make?" key="0" className="collapse-background">
                            <Row className="row-height"/>
                            <Row>
                                <Col span={1}/>
                                <Col xs={22} sm={22} md={22} lg={20} xxl={20} xl={20}>

                                    <div style={{fontSize: '15px', lineHeight: 1.5, fontWeight: "lighter"}}>
                                        <p>You set the price of your notes. There is a 30% commission on the sale of the
                                            notes.</p>
                                    </div>
                                </Col>
                            </Row>

                        </Panel>
                    </Collapse>
                </Col>

                <Col xs={23} sm={23} md={23} lg={22} xxl={22} xl={22} id="NotesDesktopCollapse">
                    <Collapse
                        defaultActiveKey={["1"]}
                        expandIcon={({isActive}) =>
                            isActive ? (
                                <MinusCircleOutlined className="icon-specs"/>
                            ) : (
                                <PlusCircleOutlined className="icon-specs"/>
                            )
                        }
                        expandIconPosition="right"
                    >
                        <Panel header="How do I get paid?" key="0" className="collapse-background">
                            <Row className="row-height"/>
                            <Row>
                                <Col span={1}/>
                                <Col xs={22} sm={22} md={22} lg={20} xxl={20} xl={20}>

                                    <div style={{fontSize: '15px', lineHeight: 1.5, fontWeight: "lighter"}}>
                                        <p>Payment is processed through our payment gateway, Stripe. Payment will be
                                            processed immediately following the purchase of the notes!</p>
                                    </div>
                                </Col>
                            </Row>

                        </Panel>
                    </Collapse>
                </Col>

                <Col xs={23} sm={23} md={23} lg={22} xxl={22} xl={22} id="NotesDesktopCollapse">
                    <Collapse
                        defaultActiveKey={["1"]}
                        expandIcon={({isActive}) =>
                            isActive ? (
                                <MinusCircleOutlined className="icon-specs"/>
                            ) : (
                                <PlusCircleOutlined className="icon-specs"/>
                            )
                        }
                        expandIconPosition="right"
                    >
                        <Panel header="Can I sell essays and assignments?" key="0" className="collapse-background">
                            <Row className="row-height"/>
                            <Row>
                                <Col span={1}/>
                                <Col xs={22} sm={22} md={22} lg={20} xxl={20} xl={20}>

                                    <div style={{fontSize: '15px', lineHeight: 1.5, fontWeight: "lighter"}}>
                                        <p>No.</p>
                                        <p>This can result in someone else's essay being uploaded, resulting in
                                            plagiarism. Subject notes help you study and are not handed in for assessment.
                                        </p>
                                    </div>
                                </Col>
                            </Row>

                        </Panel>
                    </Collapse>
                </Col>

                <Col xs={23} sm={23} md={23} lg={22} xxl={22} xl={22} id="NotesDesktopCollapse">
                    <Collapse
                        defaultActiveKey={["1"]}
                        expandIcon={({isActive}) =>
                            isActive ? (
                                <MinusCircleOutlined className="icon-specs"/>
                            ) : (
                                <PlusCircleOutlined className="icon-specs"/>
                            )
                        }
                        expandIconPosition="right"
                    >
                        <Panel header="Can I sell exams?" key="0" className="collapse-background">
                            <Row className="row-height"/>
                            <Row>
                                <Col span={1}/>
                                <Col xs={22} sm={22} md={22} lg={20} xxl={20} xl={20}>

                                    <div style={{fontSize: '15px', lineHeight: 1.5, fontWeight: "lighter"}}>
                                        <p>No. </p>
                                        <p>This is not your original work.</p>
                                    </div>
                                </Col>
                            </Row>

                        </Panel>
                    </Collapse>
                </Col>

            </Row>

        </>
    )
}
import React from "react";
import StatsTab from "../StatsTab";
import {getSessionStats} from "../../../common/queries";
import {
    DailyDataSet,
    getCurrentDate,
    getDate10WeeksAgo,
    getDate30DaysAgo,
    getFirstDayOf12MonthAgo, getLastDayOfCurrMonth, MonthlyDataSet,
    WeeklyDataSet
} from "../TimeLimits";
import {useQuery} from "@apollo/client";


export const Sessions = () => {

    const {data: dailyDataRes} = useQuery(getSessionStats, {
        variables: {
            from: getDate30DaysAgo(),
            to: getCurrentDate()
        }
    });

    let dailyData: any = dailyDataRes?.sessionStats;


    // console.log("dailyData", dailyData)


    let unapprovedSessionsListDaily = dailyData ? DailyDataSet(dailyData.unapproved) : []
    // console.log("unapprovedSessionsListDaily", unapprovedSessionsListDaily)

    let approvedSessionsListDaily = dailyData ? DailyDataSet(dailyData.approved) : []
    // console.log("approvedSessionsListDaily", approvedSessionsListDaily)

    ///////////////////// Weekly Data

    const {data: weeklyDataRes} = useQuery(getSessionStats, {
        variables: {
            from: getDate10WeeksAgo(),
            to: getCurrentDate()
        }
    });

    let weeklyData: any = weeklyDataRes?.sessionStats;

    // console.log("weeklyData", weeklyData)

    let unapprovedSessionsListWeekly = weeklyData ? WeeklyDataSet(weeklyData.unapproved) : []
    // console.log("unapprovedSessionsListWeekly", unapprovedSessionsListWeekly)

    let approvedSessionsListWeekly = weeklyData ? WeeklyDataSet(weeklyData.approved) : []
    // console.log("approvedSessionsListWeekly", approvedSessionsListWeekly)


    //////////////////// Monthly Data

    const {data: monthlyDataRes} = useQuery(getSessionStats, {
        variables: {
            from: getFirstDayOf12MonthAgo(),
            to: getLastDayOfCurrMonth()
        }
    });

    let monthlyData: any = monthlyDataRes?.sessionStats;

    // console.log("monthlyData", monthlyData)

    let unapprovedSessionsListMonthly = monthlyData ? MonthlyDataSet(monthlyData.unapproved) : []
    // console.log("unapprovedSessionsListMonthly", unapprovedSessionsListMonthly)

    let approvedSessionsListMonthly = monthlyData ? MonthlyDataSet(monthlyData.approved) : []
    // console.log("approvedSessionsListMonthly", approvedSessionsListMonthly)

    return (

        <div>
            <StatsTab
                title='Number of Sessions'
                dataset1_title='Unapproved'
                dataset2_title='Approved'
                dailyDataset1={unapprovedSessionsListDaily}
                dailyDataset2={approvedSessionsListDaily}
                weeklyDataset1={unapprovedSessionsListWeekly}
                weeklyDataset2={approvedSessionsListWeekly}
                monthlyDataset1={unapprovedSessionsListMonthly}
                monthlyDataset2={approvedSessionsListMonthly}
            />
        </div>
    )

}
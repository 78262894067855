import React from 'react';
import {Link} from 'react-router-dom';
import {Card, Col, Row, Typography} from 'antd';
import './NotesCardMobile.less'
import {ProfileOutlined} from "@ant-design/icons";


const {Paragraph} = Typography;


const {Title, Text} = Typography;

export default ({id, title, sellPrice, info, semester, numberOfPages, subject, exam, yearLevel}: any) => {

    return (
        <>
            <Link to={`/notes/buy/${id}`}>
                <div id="NotesCardMobile">
                    <Card bordered={true} style={{width: "90%"}}>

                        <Row>

                            <Col span={18}>
                                <Title className="title-text">{title}</Title>
                            </Col>

                            <Col span={2}>

                            </Col>

                            <Col span={4}>
                                <Title className="price-text">${sellPrice}</Title>
                            </Col>

                        </Row>

                        <Row>
                            <Paragraph className="info-text">
                                {info}
                            </Paragraph>
                        </Row>

                        <Row>
                            {/*<Col span={6}>*/}
                            {/*    <div className="sold-heading-text">Sold</div>*/}
                            {/*    <div className="sold-text">{soldCount} times</div>*/}
                            {/*</Col>*/}

                            <Col span={24}>
                                <img src="/icons/explore/clock-icon.svg" className="icon-align-3"/>
                                <Text className="details-text ">{subject.name}, Semester {semester}, {exam}
                                    {yearLevel ? `, Year ${yearLevel}`: ""}</Text>
                            </Col>


                            <Col span={24}>
                                <ProfileOutlined/>
                                <Text className="details-text">{numberOfPages} pages</Text>
                            </Col>

                        </Row>

                    </Card>
                </div>
            </Link>
        </>
    )
}

import React from 'react';
import styled from '@emotion/styled';

// Components
import NoteInput from './NoteInput';

const Styles = styled.div`
  .Skill {
    display: block;
  }
`;
export default ({filtering}) => {
    return (
        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <div style={{flex: 1}}>
                    <NoteInput filtering={filtering}/>
                </div>
            </div>
        </div>
    )
};

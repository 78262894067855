import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {Link, useLocation, useHistory} from 'react-router-dom';
import './Mobile.less';

export default ({user, privateRoute}: any) => {
    const location = useLocation();
    const history = useHistory();
    if (privateRoute) return <></>
    else {
        return (
            <>

                {location.pathname.startsWith('/menu') ? (
                    <div
                        className={user ? '' : 'background-primary'}
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            padding: '0.5em',
                            paddingTop: '1rem'
                        }}
                    >
                        <FontAwesomeIcon
                            className={user ? 'color-primary' : 'color-secondary'}
                            style={{
                                width: 24,
                                height: 24,
                            }} icon={faTimes} onClick={() => history.goBack()}/>
                    </div>
                ) : (!(location.pathname.startsWith('/login') || location.pathname === '/join') &&
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '0.5em',
                            paddingTop: '1rem'
                        }}
                    >
                        <Link to="/">
                            <div
                                style={{
                                    backgroundImage: `url(/icons/pocketnote-logo.svg)`,
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    width: 133,
                                    height: 26,
                                }}
                            ></div>
                        </Link>
                        <Link to="/menu">
                            <div
                                style={{
                                    backgroundImage: `url(/icons/hamburger.svg)`,
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    width: 24,
                                    height: 24,
                                }}
                            >
                            </div>
                        </Link>
                    </div>
                )}
            </>
        );
    }

};

import React from "react";
import { BuyNotes } from "./Desktop";

export default () => {
    return (
        <div>
            <BuyNotes />
        </div>
    );
};

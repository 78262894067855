import React from 'react';
import gql from 'graphql-tag';
import {orderBy} from 'lodash';
import moment from 'moment';
import {useQuery} from '@apollo/client';
import EventListItem from './EventListItem';
import StaySafeModal from '../StaySafeModal';
import {FullScreenLoader} from '../common/Loader'
import {Col, Row, Typography} from "antd";
import BottomNavigation from '../../components/BottomNavigation'

const {Title} = Typography;

export default ({reverse = false, selector, readOnly, heading}: any) => {
    const {loading, data, refetch} = useQuery(gql`
    query Events {
      me {
     
        id
        role
        ${selector} {
        review {
            id
            title
            rating
            content
        }
        customRate
         everyWeek
          id
          description
          startDate
          endDate
          owner {
            id name
          }
          enrollments {
            id
            approved
            termsAccepted
            student {
              id
              name
            }
            parent {
              id
            }
          }
        }
      }
    }
  `);

    const events = (data?.me && data?.me[selector]) || [];
    return (
        <div>
            {events.length ? (
                <>
                    <StaySafeModal refetch={refetch}/>
                    <Row id="MainRow">
                        <Col sm={1} xs={1} md={1} lg={2} xl={2} xxl={2}/>
                        <Col  sm={22} xs={22} md={22} lg={20} xl={20} xxl={20} id='EventsDesktop'>
                            <Row className="event-setting-align">
                                <Title className="event-setting-title">{heading}</Title>
                            </Row>
                            <Row className="row-height"/>
                            {/*<Row className="row-height"/>*/}
                            <div className="events-history-overflow">
                                <Col>
                                    {loading ?
                                        <FullScreenLoader/> : orderBy(events, ['startDate'], [reverse ? 'desc' : 'asc']).map(
                                            ({id, owner, startDate, endDate, enrollments, review, everyWeek,customRate}) => {
                                                const day = moment(startDate);
                                                return (
                                                    <EventListItem
                                                        {...{
                                                            key: id,
                                                            id,
                                                            readOnly,
                                                            me: data?.me,
                                                            day,
                                                            owner,
                                                            startDate,
                                                            endDate,
                                                            enrollments,
                                                            heading,
                                                            review,
                                                            everyWeek,
                                                            customRate
                                                        }}
                                                    />
                                                );
                                            },
                                        )}
                                    <Row className="row-height"/>
                                    <Row className="row-height"/>
                                </Col>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
                            <BottomNavigation role={data?.me?.role}/>
                        </Col>
                    </Row>
                </>
            ) : null}


        </div>
    );
};

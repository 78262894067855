import { LineHeightOutlined } from '@ant-design/icons';
import React from 'react';
import { Header } from '../components';

export default () => (
  <div style={{ padding: '2em', height: 300 }}>
    <Header>Help</Header>
    <p>
      Please contact{' '}
      <a href="mailto:support@pocketnote.com.au">support@pocketnote.com.au</a>{' '}
      for support.
    </p>
  </div>
);

import React from 'react';
import {gql} from "graphql-tag";
import {useQuery, useMutation} from "@apollo/client";
import {message} from "antd";
import Desktop from './Desktop';
import {User} from "@graphql-client/types";
import {getCurrentUser, getSchools} from "pages/common/queries";
import {useGlobalLoading} from "hooks";
import {updateUser as updateUserMutation} from "../../common/mutations";
import {Formik, Form} from "formik";
import {LANGUAGES} from "../../../components/static";
import {toast} from "react-toastify";


export default () => {
    const {data} = useQuery(getSchools);
    let schools = data?.schools || []

    const {
        loading: currentUserLoading,
        data: user,
        refetch,
    } = useQuery<{ me: User }>(getCurrentUser);

    const [deleteUpload, {loading: deleteAvatarLoading}] = useMutation(gql`
    mutation deleteUpload($uploadId: ID!) {
      deleteUpload(uploadId: $uploadId)
    }
  `);
    const [updateUser, {loading: userUpdating}] =
        useMutation(updateUserMutation);

    useGlobalLoading([
        userUpdating,
        currentUserLoading,
        deleteAvatarLoading,
    ]);
    const actions = {
        handleDelete: async () => {

            try {
                await deleteUpload({
                    variables: {uploadId: user?.me?.avatar?.id},
                });
                toast.success('Profile picture deleted!');
            } catch (error) {
                message.error(error.message);
                toast.error('Unable to delete profile picture.');
            } finally {
                await refetch();
            }
        },
    }

    let userSubjects = []
    user?.me?.skills?.map(skill => {
        userSubjects.push(skill.name)
    })
    const Languages = LANGUAGES;
    const userLanguages = []
    Languages.map(lang => {
        if (user?.me?.languages?.includes(lang.value)) {
            userLanguages.push(lang.value)
        }
    })


    let dob = {}
    if (user?.me?.dob) {
        const [year, month, day] = user?.me?.dob
            .split('-')
            .map((v: any) => parseInt(v, 10));
        dob = {year, month, day};
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                id: user?.me?.id,
                name: user?.me?.name,
                city: user?.me?.city,
                country: user?.me?.country,
                skypeId: user?.me?.skypeId,
                gender: user?.me?.gender,
                tel: user?.me?.tel,
                rate: user?.me?.rate,
                biography: user?.me?.biography,
                title: user?.me?.title,
                school: user?.me?.school?.name,
                skills: userSubjects,
                languages: userLanguages,
                googlePlace: user?.me?.googlePlace,
                dob: dob,
                bankRoutingNumber: user?.me?.bankRoutingNumber,
                bankAccountNumber: user?.me?.bankAccountNumber,
                lessonTitle: user?.me?.lessonTitle
            }}
            onSubmit={async (values, actions) => {

                if (values.dob) {
                    let parts = ['year', 'month', 'day']
                        .map((k) => values.dob[k])
                        .map((v) => parseInt(v, 10));
                    if (parts.every((v) => isFinite(v))) {
                        values.dob = parts.join('-');
                    } else {
                        delete values.dob;
                    }
                }

                const schoolId = schools?.find(school => school.name === values.school)
                let sendValues = {}
                if (user?.me?.role === 'TUTOR') {

                    if (schoolId) {
                        const TutorSchool = {
                            school: schoolId.id
                        }
                        sendValues = {
                            ...values,
                            ...TutorSchool,
                        }
                    } else {
                        sendValues = values
                    }

                } else {
                    sendValues = values
                }
                try {
                    await updateUser({
                        variables: sendValues,
                    });
                    toast.success('Saved');

                } catch (error) {
                    message.error(error.message);
                    toast.error('Error');
                } finally {
                    await refetch();
                }

            }}
        >
            {(props) => (
                <Form>
                    {
                        <Desktop
                            user={user}
                            actions={actions}
                            props={props}
                            Languages={Languages}
                        />
                    }
                </Form>
            )}

        </Formik>
    );
};

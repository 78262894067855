import {Loader} from "../../pages/common/Loader";
import React, {useState} from "react";
import {useQuery} from "@apollo/client";
import gql from "graphql-tag";


export default () => {
    const {data, loading} = useQuery(
        gql`
            query Schools {
                schools(logo: true) {
                    id
                    name
                    slug
                    logo {
                        id
                        url
                    }
                    alumni {
                        id
                        name
                        avatar {
                            url
                        }
                        skills {
                            name
                        }
                    }
                }
            }
        `,
    );

    const [selectedUniversity, setSelectedUniversity] = useState(0);
    const schools = data?.schools?.length > 3 ? data.schools : [];

    return <div className={"my-6 px-4"}>
        {schools && schools[selectedUniversity] &&
            <>
                <div className='innovation-text-landing text-4xl'>
                    Tutors from
                </div>
                <div
                    className='font-bold primary-text crimson-font text-2xl mt-4'>{schools[selectedUniversity].name}</div>
            </>
        }

        {loading ? <Loader/> :
            schools && schools[selectedUniversity] && <>
                <div className={"school-container py-4 md:border-b-2"}>
                    {schools.map((school: any, index: number) => (
                        <img
                            className="school-logo md:my-4 mx-4 md:mx-6 self-center"
                            key={index}
                            alt={`school logo ${index}`}
                            src={school?.logo?.url}
                            style={{
                                opacity: selectedUniversity === index ? 1 : 0.5,
                                filter: selectedUniversity === index ? '' : 'grayscale(100%)'
                            }}
                            onClick={() => {
                                setSelectedUniversity(index);
                            }}
                        />
                    ))}
                </div>
            </>
        }
    </div>
}

import React, {useState, useEffect} from "react";
import {useQuery} from "@apollo/client";
import Desktop from "./Desktop";
import {getTutor} from "../common/queries";
import {FullScreenLoader} from "../common/Loader";
import "./index.less";
import {useParams} from "react-router-dom";

export default (): JSX.Element => {
    let {id} = useParams() as any
    const {data: {tutor} = {}, loading} = useQuery(getTutor, {
        variables: {id},
    });

    if (loading) return <FullScreenLoader/>;
    return (
        <Desktop key="desktop" tutor={tutor}/>
    );
};

import {useLocation} from 'react-router-dom';
import * as jose from "jose";

export const createTreeData = list => {
    const traverse = id => {
        return list
            .filter(item => {
                if (!id) return !item.parent;
                return item.parent?.id === id;
            })
            .map(item => {
                const node = {
                    key: item.id,
                    value: item.id,
                    name: item.name,
                    title: item.name,
                    label: item.name,
                };

                const children = traverse(item.id);
                if (children.length) node.children = children;
                return node;
            });
    };

    return traverse(null);
};

export const createAction = (action, variables) => {
    return async () => await action({variables});
};

export const Env = {
    fetch(key, fallback = '') {
        return process.env[key] || fallback;
    },
};

export const monthNames = ['Jan', 'Feb', 'March', 'Apr', 'May', 'June',
    'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];

export const useSearch = () => {
    return new URLSearchParams(useLocation().search);
}

export const STRIPE_FIXED = 0.3;
export const TUTOR_COMMISSION = 0.15;
export const POCKETNOTE_PERCENT = 0.20;
export const STRIPE_PERCENT = 0.0175;

const round = (v) => (Math.round(v * 100) / 100).toFixed(2);

export const hourlyRateCalculator = (tutorHourlyRate) => {
    const toPocketnote = tutorHourlyRate * POCKETNOTE_PERCENT;
    const charge = (tutorHourlyRate + toPocketnote + STRIPE_FIXED) / (1 - STRIPE_PERCENT);
    return Number(round(charge)).toFixed(2)
}

export const hourlyServiceFee = (tutorHourlyRate) => {
    return Number(round(hourlyRateCalculator(tutorHourlyRate) - tutorHourlyRate)).toFixed(2)
}

export function isPaymentSetup(me) {
    const card = me?.stripeCustomer?.sources?.data[0];
    if (me?.paymentMethod === 'STRIPE' && (me?.role === 'PARENT' || me?.role === 'STUDENT')) {
        if (card) return true
        else return false
    } else return true
}

export async function createJwtToken(data) {
    let utf8Encode = new TextEncoder();
    const key = await window.crypto.subtle.importKey(
        "raw",
        utf8Encode.encode("l3tm3in"),
        {name:"HMAC","hash":"SHA-256"},
        true,
        ["sign", "verify"]);

    const token = await new jose.SignJWT(data).setProtectedHeader({ alg: 'HS256' })
        .sign(key);
    return token;
}

import { useContext } from 'react';
import { MasqueradeContext } from '../Masquerade';
import * as mutations from '../common/mutations';
import { useApolloClient, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

export const useAuth = () => {
  const history = useHistory();
  const client = useApolloClient();
  const masquerade: any = useContext(MasqueradeContext);
  const [signinMutation] = useMutation(mutations.login);
  const [signupMutation] = useMutation(mutations.join);

  const store = window.localStorage;

  let prevURLForParent = store.getItem('url') ? store.getItem('url') : "/explore/tutors"

  const dispatch = async (session: any) => {
    window.localStorage.setItem('token', session.token);
    window.localStorage.setItem('session', JSON.stringify(session));
    window.localStorage.setItem('user', JSON.stringify(session.user));
    client.resetStore();

    if (session?.user?.role === 'ADMIN') {
      history.push('/users');
    } else if (session?.user?.role === 'TUTOR') {
      history.push('/dashboard');
    } else if (session?.user?.role === 'PARENT') {
      history.push(prevURLForParent);
    } else {
      history.push('/settings');
    }

    try {
      masquerade.dispatch({
        type: 'session',
        payload: session,
      });
    } catch (e) { }
  };

  const login = async (variables: any) => {
    const { data } = await signinMutation({ variables });
    await dispatch(data.login);
  };

  const join = async (variables: any) => {
    const { data } = await signupMutation({ variables });
    await dispatch(data.join);
  };

  return { login, join, dispatch };
};

import React from "react";
import {Button, Col, Row, Typography} from "antd";
import {Link} from "react-router-dom";
import "./LandingPageStyles.less";
import {homePageBecomeTutorRedirection, homePageBookTutorRedirection,} from "../Constants/utilities";
import LandingPageBottomCta from "./LandingPageBottomCta";

const {Paragraph} = Typography;

export default ({
                    user,
                    heroImage,
                    innovationText,
                    tutoringText,
                    parentText,
                    bookTutorBtnText,
                    becomeTutorBtnText,
                    whyChooseTitle,
                    whyChooseSubtitle,
                    signUpText,
                    choosePocketnoteSection,
                    children,
                    endForm
                }: any) => {
    return (
        <>
            <Row id="landingPageTemplateDesktop">
                <Col span={2}></Col>
                <Col span={8}>
                    <div className="justify-align-center">
                        <div className="innovation-text"> {innovationText}</div>
                        <div className="innovation-text"> {tutoringText}</div>
                        <Paragraph className="connect-text margin-top-30">
                            {" "}
                            {parentText}{" "}
                        </Paragraph>
                        <div className="flex margin-top-30">
                            <Link
                                className="margin-right-14"
                                to={homePageBookTutorRedirection(user)}
                            >
                                <Button type="primary" className="book-tutor">
                                    {bookTutorBtnText}
                                </Button>
                            </Link>
                            <Link to={homePageBecomeTutorRedirection(user)}>
                                <Button className="become-tutor">{becomeTutorBtnText}</Button>
                            </Link>
                        </div>
                    </div>
                </Col>
                <Col span={1}></Col>
                <Col span={13}>
                    <div
                        style={{
                            backgroundImage: `url(${heroImage})`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            width: 778,
                            height: 409,
                        }}
                    ></div>
                </Col>
            </Row>
            <div className="section-choose-us background-secondary">
                <div className={" grid grid-cols-5"}>
                    <div className={"col-span-1"}/>
                    <div className="col-span-3">
                        <div className="margin-top-30 margin-bottom-30">
                            <div className="fails-text landing-heading-text">
                                {whyChooseTitle}
                            </div>
                            {whyChooseTitle != null &&
                                <div className="mt-2 subtitle-text">{whyChooseSubtitle}</div>}
                        </div>
                    </div>
                    <div className={"col-span-1"}/>
                </div>
                <div className={"col-span-1"}/>
                <Row>
                    {choosePocketnoteSection.map((section, index) => (
                        <Col
                            key={index}
                            xs={24}
                            sm={24}
                            md={11}
                            lg={11}
                            xl={11}
                            xxl={11}
                            className="box-container"
                        >
                            <div>
                                <div
                                    style={{
                                        backgroundImage: section.icon,
                                        backgroundSize: "contain",
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center",
                                        width: 40,
                                        height: 50,
                                    }}
                                ></div>
                            </div>
                            <div>
                                <h5>{section.heading}</h5>
                            </div>
                            <div>{section.details}</div>
                        </Col>
                    ))}
                </Row>
            </div>
            {children}
            {endForm == null ?
                <LandingPageBottomCta user={user} signUpText={signUpText}/> : endForm}
        </>
    );
};

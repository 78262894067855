import React, { useState } from 'react';
import { Header, Button } from '../components';
import { useQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import Calculator from '../Calculator';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {useSearch} from "../common/utils";
import {toast} from "react-toastify";

const Enrollment = ({ id, callback }: any) => {
  const { data } = useQuery(
    gql`
      query Enrollment($id: ID!) {
        enrollment(id: $id) {
          id
          student {
            id
            name
          }
        }
      }
    `,
    { variables: { id } },
  );
  if (data?.enrollment) {
    callback(data?.enrollment);
  }
  return <div>{data?.enrollment?.student?.name}</div>;
};

export default () => {
  const query = useSearch();
  const enrollmentIds = Array.isArray(query.get("enrollments"))
                    ? [query.get("enrollments")]
                    : query.get("enrollments")?.split(",") || []
  const tutorId = query.get("tutor") || "";
  const payerId = query.get("payer") || "";
  const eventId = query.get("event") || "";

  const [enrollments, setEnrollments] = useState({} as any);
  const [amount, setAmount] = useState({ gross: 0, net: 0 });
  const callback = (enrollment: any) => {
    if (enrollments[enrollment.id]) {
      return;
    }
    setEnrollments({
      ...enrollments,
      [enrollment.id]: enrollment,
    });
  };

  const { data: eventData } = useQuery(
    gql`
      query Event($id: ID!) {
        event(id: $id) {
          id
          startDate
          endDate
          customRate
        }
      }
    `,
    { variables: { id: eventId } },
  );
  const event = eventData?.event;

  const start = moment(event?.startDate);
  const end = moment(event?.endDate);
  const interval = end.diff(start, 'hours', true);

  const { data: tutorData } = useQuery(
    gql`
      query Tutor($id: ID!) {
        user(id: $id) {
          id
          name
          rate
          stripeAccount
        }
      }
    `,
    { variables: { id: tutorId } },
  );

  const { data: payerData } = useQuery(
    gql`
      query Payer($id: ID!) {
        user(id: $id) {
          id
          name
          stripeCustomer
        }
      }
    `,
    { variables: { id: payerId } },
  );
  const payer = payerData?.user;
  const card = payer?.stripeCustomer?.sources?.data?.find(
    ({ id }: any) => id === payer?.stripeCustomer.default_source,
  );

  const [createPayment] = useMutation(gql`
    mutation CreatePayment(
      $enrollments: [ID!]!
      $customer: String!
      $transfer: JSON!
      $amount: Int!
    ) {
      createPayment(
        enrollments: $enrollments
        customer: $customer
        transfer: $transfer
        amount: $amount
      ) {
        id
        charge
      }
    }
  `);

  const gross = Math.round(amount.gross * 100) / 100;
  const net = Math.round(amount.net * 100) / 100;


  const history = useHistory();

  const rate = event?.customRate || tutorData?.user?.rate;

  return tutorData?.user ? (
    <div>
      <Header>Create Payment</Header>
      <hr />
      <div style={{ display: 'flex' }}>
        <div style={{ width: '100%' }}>
          <>
            <h4>Event</h4>
            <div>{moment(event?.startDate).format('LLLL')}</div>
            <div>{moment(event?.endDate).format('LLLL')}</div>
          </>
          <hr />
          <>
            <h4>Payer</h4>
            <div>{payerData?.user?.name}</div>
            <div style={{ display: 'flex' }}>
              <div>{card?.brand}</div>
              <div>{card?.last4}</div>
              <div>
                {card?.exp_month}/{card?.exp_year}
              </div>
            </div>
          </>
          <hr />
          <>
            <h4>Enrollments</h4>
            <div>
              {enrollmentIds.map((id: any) => (
                <Enrollment {...{ id, callback }} />
              ))}
            </div>
          </>
          <hr />
          <Button
            positive
            onClick={async () => {
              try {
                await createPayment({
                  variables: {
                    amount: Math.ceil(gross * 100),
                    enrollments: enrollmentIds,
                    customer: payer.stripeCustomer.id,
                    transfer: {
                      destination: tutorData?.user?.stripeAccount?.id,
                      amount: Math.floor(net * 100),
                      tutorId: tutorData?.user?.id
                    },
                  },
                });
                toast.success('Charged!');
                history.goBack();
              } catch (e) {
                toast.error(e.message);
              }
            }}
          >
            Charge {card?.last4} ${gross}
          </Button>
        </div>
        <div style={{ width: '100%' }}>
          <h4>Calculator</h4>
          <div>
            <Calculator
              rate={rate}
              customRate={event?.customRate}
              multiplier={enrollmentIds.length}
              hours={interval}
              callback={({ gross, net }: any) => {
                if (gross !== amount.gross || net !== amount.net) {
                  setAmount({ gross, net });
                }
              }}
              scheme={card?.country === 'AU' ? 'domestic' : 'international'}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
      <div>...</div>
    );
};

import React from 'react';
import {Row, Col} from 'antd';
import {Typography} from 'antd';
import './About.less';
import {Button} from 'antd';
import {Link} from 'react-router-dom';

const {Title, Paragraph} = Typography;

export default ({user}: any) => {
    return (
        <>
            <Row className='about-main-container'>
                <Col className='align-center left-container' span={12}>
                    <Title>We are Pocketnote</Title>
                    <Title level={5} style={{marginTop: 0, color: "#9B9B9B"}}>Innovation in tutoring</Title>
                    <Paragraph className='margin-top-24'>
                        Pocketnote is making tutoring easy. Our verified, qualified and flexible tutors ensure our
                        students receive the best possible learning experience in a way that is accessible, affordable
                        and more
                        convenient for everyone. It’s smart. It’s simple. It’s Pocketnote.
                    </Paragraph>
                    <Link to={user ? '/explore' : '/join/parent'}>
                        <Button className='margin-top-24' type='primary' size={"large"}>Find A Tutor</Button>
                    </Link>
                </Col>
                <Col className='img-center' span={12}>
                    <div
                        style={{
                            backgroundImage: `url(/icons/about-us/innovation-icon.svg)`,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            width: 1075,
                            height: 500,
                        }}
                    ></div>
                </Col>
                <Col className='left-container margin-top-12 padding-bottom-24' span={24}>
                    <Title level={5} style={{color: "#9B9B9B"}}>We’re here to help Australian students thrive.</Title>
                    <Paragraph className='margin-top-12'>
                        <p>Pocketnote is an online tutoring platform connecting students and parents with high-quality tutors,
                            providing personalised learning experiences to help schoolkids thrive in and out of the classroom.</p>

                        <p>We only accept the highest-quality tutors onto our curated roster,
                            vetting each tutor for their academic skills, teaching ability, passion and positive attitude.</p>

                        <p>Every student’s learning journey is unique, so our personalised approach ensures that each student
                            learns at their own pace and receives tutoring which is tailored to their strengths and challenges.</p>

                        <p>From Maths and English to Science and Languages, we cater from Primary School through to ATAR
                            to help students of all ages and education increase their confidence and abilities across a range of subjects.
                            We also have a range of NDIS-approved tutors experienced in working with children with specialised learning needs.
                        </p>

                        <p>With the ability to connect with and book tutors all through our online platform,
                            we make tutoring accessible and convenient with tutors available in-person and via video conferencing.
                        </p>
                    </Paragraph>
                </Col>
            </Row>

            <Row className='background-secondary about-bottom-container space-between'>
                <Col className='align-center' span={24}>
                    <Title><span className='primary-text'>The Pocketnote</span> Difference</Title>
                </Col>
                <Col span={1}>
                </Col>
                <Col span={6} className='align-center flex align-item-center flex-column'>
                    <div
                        className='margin-top-48'
                        style={{
                            backgroundImage: `url(/icons/about-us/review.svg)`,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            width: 44,
                            height: 44,
                        }}
                    ></div>
                    <Paragraph className='margin-top-24'>Compare qualifications, ratings and reviews to find the right
                        tutor for you.</Paragraph>
                </Col>
                <Col span={6} className='align-center flex align-item-center flex-column'>
                    <div
                        className='margin-top-48'
                        style={{
                            backgroundImage: `url(/icons/about-us/comment.svg)`,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            width: 44,
                            height: 44,
                        }}
                    ></div>
                    <Paragraph className='margin-top-24'>Collaborate with your tutor via Pocketnote’s free messaging
                        tool.</Paragraph>
                </Col>
                <Col span={6} className='align-center flex align-item-center flex-column'>
                    <div
                        className='margin-top-48'
                        style={{
                            backgroundImage: `url(/icons/about-us/pay.svg)`,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            width: 44,
                            height: 44,
                        }}
                    ></div>
                    <Paragraph className='margin-top-24'>Only pay for the time you need with
                        our automated Pocketnote Pay.
                        No subscriptions, no upfront payments.</Paragraph>
                </Col>
                <Col span={1}>
                </Col>
            </Row>
        </>
    )
}

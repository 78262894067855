import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, Row, Col, Typography, Divider, Input, Radio, Space, Select, Popconfirm, DatePicker} from 'antd';
import {useQuery, useMutation} from '@apollo/client';
import gql from 'graphql-tag';
import {Elements, useStripe} from '../../Stripe';
import {Formik, Form} from 'formik';
import {updatePaymentCardToken as updatePaymentCardTokenMutation} from '../../common/mutations';
import 'react-credit-cards/es/styles-compiled.css';
import './Desktop.less';
import {
    CardNumberElement,
    CardCVCElement,
    CardExpiryElement
} from "react-stripe-elements";
import StaySafeModal from "./StaySafeModal/StaySafeModal";
import {toast} from "react-toastify";

const {Title, Text} = Typography;


const PaymentSettings = ({user, refreshOnSave, ...props}: any) => {
    const [deleteStripeAccount] = useMutation(gql`
          mutation DeleteStripeAccount($id: ID!) {
            deleteStripeAccount(id: $id)
          }
        `);

    const [ChangePaymentMethod] = useMutation(gql`
    mutation ChangePaymentMethod($id: ID!, $method: String!) {
            ChangePaymentMethod(id: $id, method: $method)
          }
    `)

    const [openModal, setOpenModal] = useState<Boolean>(false)
    const [updatePaymentCardToken] = useMutation(updatePaymentCardTokenMutation);
    const [paymentOption, setPaymentOption] = useState<number>(1)

    const [stripe]: any = useStripe();

    const {data, refetch} = useQuery(
        gql`
      query User($id: ID!) {
        user(id: $id) {
          id
          stripeCustomer
          paymentMethod
        }
      }
    `,
        {variables: {id: user?.id}},
    );

    React.useEffect(() => {
        if (data?.user?.paymentMethod) {
            if (data?.user?.paymentMethod === 'PAYPAL') setPaymentOption(0)
        }

    }, [data])
    const card = data?.user?.stripeCustomer?.sources?.data[0];
    const createOptions = () => {
        return {
            style: {
                border: '1px solid',
                base: {
                    border: '1px solid',
                    fontSize: '20px',
                    color: '#415241',
                    letterSpacing: '15px',
                    fontFamily: 'Source Code Pro, monospace',
                    '::placeholder': {
                        color: '#aab7c4',
                    },
                    padding: '10px',
                },
                invalid: {
                    color: '#9e2146',
                },
            },
        };
    };
    const handleCardDelete = async () => {

        try {
            const result = await deleteStripeAccount({variables: {id: user?.id}});

            toast.success('Account deleted success');


        } catch (e) {
            console.log("error ", e)
            toast.error('Error while deleting card from stripe');
        } finally {
            refetch()
        }
    }
    // return data?.user ? (
    return (
        <Formik
            initialValues={{}}
            onSubmit={async (values) => {

                if (paymentOption == 1) {
                    if (data.user.stripeCustomer != null &&
                        data.user.stripeCustomer.sources?.data?.length > 0) {
                        const result = await ChangePaymentMethod({
                            variables: {
                                id: user?.id,
                                method: "STRIPE"
                            }
                        })

                        toast.success('Payment method added.');
                        refetch();
                        if (refreshOnSave) {
                            window.location.reload();
                        }
                        return;
                    }
                    const {token} = await stripe.createToken({});
                    try {
                        const result = await updatePaymentCardToken({variables: {id: user?.id, token: token.id}});

                        toast.success('Card saved.');
                        if (refreshOnSave) {
                            window.location.reload();
                        }

                    } catch (e) {
                        console.log("e ", e)
                        toast.error('Error while saving card.');
                    }
                } else {
                    //    handle here for paypal
                    try {
                        const result = await ChangePaymentMethod({
                            variables: {
                                id: user?.id,
                                method: "PAYPAL"
                            }
                        })

                        toast.success('Payment method added.');
                        if (refreshOnSave) {
                            window.location.reload();
                        }

                    } catch (error) {
                        console.log("error at paypal", error)
                        toast.error('Error while adding PayPal account.');
                    } finally {
                        refetch()
                    }
                }
            }}
        >
            {(props) => (
                <Form>
                    <Row>
                        <Col xs={1} sm={1} md={1} lg={0} xxl={0} xl={0}/>
                        <Col xs={23} sm={23} md={23} lg={21} xxl={21} xl={21} id='Payments'>
                            <Row className="payment-setting-align">
                                <Title className="payment-method-text">Payment Method</Title>
                            </Row>

                            <Divider type="horizontal" className="margin-alignment"/>
                            <Row>
                                <Col span={1}/>
                                <Col span={22}>

                                    {card && (
                                        <Row className="payment-method-row">
                                            <Col xs={0} sm={0} md={0} lg={6} xxl={6} xl={6} className="display-flex">
                                                <div className="margin-top-5"><img
                                                    src="/icons/settings-account/settings-payments/mastercard.svg"
                                                    alt="mastercard icon"/></div>
                                                <div className="margin-left-12">
                                                    <div className="full-width"><Text
                                                        className="mastercard-text">{card?.brand}</Text></div>
                                                    <div className="full-width"><Text
                                                        className="mastercard-text"> ****{card?.last4}</Text></div>
                                                </div>
                                            </Col>

                                            <Col xs={4} sm={4} md={4} lg={0} xxl={0} xl={0}>
                                                <div className="margin-top-5"><img
                                                    src="/icons/settings-account/settings-payments/mastercard.svg"
                                                    alt="mastercard icon"/></div>
                                            </Col>


                                            <Col xs={20} sm={20} md={20} lg={5} xxl={5} xl={5}>
                                                <div className="payee-div"><Text
                                                    className="payee-email">{user?.email}</Text></div>
                                            </Col>
                                            <Col xs={8} sm={8} md={8} lg={0} xxl={0} xl={0}/>
                                            <Col xs={16} sm={16} md={16} lg={9} xxl={9} xl={9}
                                                 className="payment-method-row">
                                                <Button className="edit-btn" size="large" onClick={() => {
                                                    setPaymentOption(1)
                                                    toast.info('Please enter details of new card and click save to update your card details');

                                                }}><span
                                                    className="edit-btn-text">Edit</span></Button>
                                                <Popconfirm
                                                    okText="Yes"
                                                    cancelText="No"
                                                    onConfirm={handleCardDelete}
                                                    title="Are you sure to delete your credit card?"
                                                >
                                                    <Button className="delete-btn" size="large"><span
                                                        className="delete-btn-text">Delete</span></Button>
                                                </Popconfirm>
                                            </Col>
                                        </Row>
                                    )}
                                    <Row>
                                        <Col>
                                            <Text className="add-payment"> + Add a payment method</Text>
                                        </Col>
                                    </Row>
                                    <Divider type="horizontal" className="add-payment-divider "/>
                                    <Col>
                                        <div className="margin-bottom-9"><Title className="payment-method" level={5}>How
                                            would you like to pay?</Title></div>
                                        <Col>
                                            <Radio.Group value={paymentOption} onChange={(event) => {
                                                setPaymentOption(event.target.value)
                                            }}>
                                                <Space direction="vertical">
                                                    <Radio value={1}><img
                                                        src="/icons/settings-account/settings-payments/card.svg"
                                                        className="margin-right-7" alt="card icon"/><span
                                                        className="radio-button-text">Credit or Debit Card</span></Radio>
                                                    <Radio value={0}>
                                                        <div className="margin-top-16"><img
                                                            src="/icons/settings-account/settings-payments/paypal.svg"
                                                            className="paypal-size" alt="paypal icon"/></div>
                                                    </Radio>
                                                </Space>
                                            </Radio.Group>
                                            <Row>
                                                <Col xs={23} sm={23} md={23} lg={14} xxl={14} xl={14}>
                                                    {paymentOption == 1 ? (
                                                        <>
                                                            <Divider type="horizontal" className="divider-margin"/>
                                                            <Title className="card-info" level={5}>Credit card
                                                                number
                                                                *</Title>
                                                            <Row className="row-height"/>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <CardNumberElement
                                                                        {...createOptions}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col span={11}>
                                                                    <Title className="card-info" level={5}>Expiry date
                                                                        *</Title>
                                                                </Col>
                                                                <Col span={2}/>
                                                                <Col span={11}>
                                                                    <Title className="card-info" level={5}>CVC
                                                                        *</Title>
                                                                </Col>
                                                            </Row>
                                                            <Row className="card-info-row vertical-spacing">
                                                                <Col span={11}>
                                                                    <CardExpiryElement
                                                                        {...createOptions}
                                                                    />
                                                                </Col>
                                                                <Col span={11}>
                                                                    <CardCVCElement
                                                                        {...createOptions}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row className="row-height"/>
                                                            <Row className="vertical-spacing ">
                                                                <Title className="card-info" level={5}>Cardholders
                                                                    name*</Title>
                                                                <Input className="select-width" value={user?.name}
                                                                       size='large'/>
                                                            </Row>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Button
                                                                        htmlType="submit"
                                                                        type='primary'
                                                                        size="large"
                                                                        block
                                                                        className="save-btn"><span
                                                                        className="upload-pic-text">Save</span></Button>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    ) : null
                                                    }

                                                </Col>
                                            </Row>
                                            <Divider type="horizontal" className="last-divider-margin"/>
                                            <Row className="add-payment-row">
                                                <Col xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}>

                                                    {paymentOption == 0 && (
                                                        <Button type='primary' size="large" block onClick={() => {
                                                            setOpenModal(true)
                                                        }}
                                                                className="save-btn no-margin"><span
                                                            className="upload-pic-text">Save</span></Button>
                                                    )}
                                                    {
                                                        openModal && <StaySafeModal header={"PayPal Payment"} submit={props.submitForm}/>
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Col>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
        // ) : (
        //
    );
};

export default ({props}: any) => (
    <Elements>
        <PaymentSettings {...props} />
    </Elements>
);
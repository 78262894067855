import React from 'react';
import { Col } from 'antd';
import { Typography } from 'antd';
import './Mobile.less';
const { Paragraph } = Typography;

export default ({ title, description, icon }: any) => {
  return (
    <>
      <Col className='align-center img-center' span={24}>
        <div
          style={{
            backgroundImage: `url(/${icon}.svg)`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            width: 44,
            height: 44,
          }}
        ></div>
      </Col>
      <Col className='align-center margin-top-24' span={24}>
        <Typography.Text className='align-center primary-text info-comp'>
          {title}
        </Typography.Text>
      </Col>
      <Col className='align-center align-para' span={20}>
        <Paragraph className="info-comp-para">{description}</Paragraph>
      </Col>
    </>
  );
}

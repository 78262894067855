import React from 'react';
import {useAuth} from '../../Auth';
import {useHistory} from 'react-router-dom';
import Desktop from './Desktop';



export default () => {
    const auth = useAuth();
    const history = useHistory();
    try {
        if (window.location.search) {
            const searchParams = new URLSearchParams(window.location.search);
            const session = searchParams.get("session");
            if (session) {
                const {token, user} = JSON.parse(session);
                auth.dispatch({token, user});
                history.push('/settings/security');
            }
        }
    } catch (e) {
    }


    return (
        <>
            <Desktop
                auth={auth}
                history={history}/>
        </>
    );
};

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Checkbox, Col, message, Row, Select, Slider, Typography, Input, Tag} from 'antd';
import {CaretDownFilled} from '@ant-design/icons';
import './Desktop.less';
import Tutors from './Tutors';
import {useHistory, useParams} from 'react-router-dom'
import {useSearch} from '../common/utils'
import {getSchools, getSkills, getTutors} from '../common/queries';
import gql from 'graphql-tag';
import {useMutation, useQuery} from '@apollo/client';
import LocationAccessModal from '../LocationAccessModal'
import Filters from './Filters';
import NameInput from './NameInput';
import Mobile from "./Mobile";
import BottomNavigation from '../../components/BottomNavigation'
import {Pagination} from 'antd';
import {useDebouncedCallback} from "use-debounce";
import {tagRender} from "../components/MultipleSelectTagRender";

export interface tutorSearchFilters {
    skills?: string[]
    gender?: string
    hourlyRange?: number[]
    availability?: any[]
    school?: string
    city?: string
    customLocation?: {
        longitude: number,
        latitude: number
    }
}

const {Text, Title, Paragraph} = Typography;



export default ({user, days}: any) => {

    const query = useSearch();
    const history = useHistory();
    const [location, setLocation] = useState(null as any);
    const [address, setAddress] = useState<any>();
    const [addressQuery, setAddressQuery] = useState(address?.googlePlace?.formatted_address);
    const [results, setResults]: any = useState([]);
    const [single, setSingle]: any = useState(null);
    const [hoverPlaceId, setHoverPlaceId] = useState(null);

    const [searchFilters, setSearchFilters] = useState<tutorSearchFilters>({
        skills: null,
        gender: null,
        hourlyRange: [0, 0],
        availability: null,
        school: null,
        city: null,
        customLocation: {
            longitude: null,
            latitude: null
        }
    })

    const [page, setPage] = useState(1)

    const PLACE_CACHE: any = {};

    const autocompleteService = useMemo(
        () => new window.google.maps.places.AutocompleteService(),
        [],
    );

    const placesService = useMemo(
        () =>
            new window.google.maps.places.PlacesService(
                document.createElement('div'),
            ),
        [],
    );

    const [lookup] = useDebouncedCallback(
        (addressQuery: any) => autocompleteService.getPlacePredictions(addressQuery, setResults),
        666,
    );

    useEffect(() => {
        lookup({
            input: addressQuery,
            componentRestrictions: {
                country: address?.country?.toLowerCase(),
            },
        });
        return () => {
        };
    }, [addressQuery, address, lookup]);

    const getPlace: any = useCallback(
        (placeId: any) =>
            new Promise((resolve) => {
                if (PLACE_CACHE[placeId]) {
                    resolve(PLACE_CACHE[placeId]);
                }
                placesService.getDetails({placeId}, (place: any) => {
                    PLACE_CACHE[placeId] = place;
                    resolve(place);
                });
            }),
        [placesService],
    );

    useEffect(() => {
        if (results?.length === 1) {
            let [result] = results;
            const hit = PLACE_CACHE[result.place_id];
            if (hit) {
                setSingle(hit);
            } else {
                getPlace(result.place_id).then(setSingle);
            }
        } else {
            setSingle(null);
        }
        return () => {
        };
    }, [results, getPlace]);

    useEffect(() => {
        if (address == "") {
            setSearchFilters({
                ...searchFilters,
                customLocation: {
                    longitude: null,
                    latitude: null
                }
            })
        }
    }, [address])

    const [addCurrentLocation] = useMutation(gql`
        mutation addCurrentLocation ($location: GeoLocationInput) {
            addCurrentLocation (location: $location) {
                success
                message
            }
        }
    `);

    const {refetch: refetchSearchTutor, loading: searchLoading, data: {searchTutor = []} = {}} = useQuery(gql`
        query SearchTutor($name: String!, $userId: String) {
            searchTutor(name: $name, userId: $userId) {
                id
                name
                rating
                rating
                biography
                title
                rate
                currency
                createdAt
                lessonTitle
                feedback {
                    rating
                }
                avatar {
                    id
                    url
                    thumb
                }
                distance
            }
        }
    `, {
        variables: {name: query.get('name'), userId: user?.me?.id}
    })


    const {
        refetch: refetchSkill,
        loading,
        data: {tutors: {data: tutors = [], meta = {}} = {}} = {}
    } = useQuery(getTutors, {
        variables: {
            page: page,
            lessonTitle: query.get('lesson'),
            ...searchFilters,
            userId: user?.me?.id
        },
    });

    const tutorsList = (!!searchTutor?.length && searchTutor) || (!!tutors?.length && tutors) || []; // sortBy(data?.tutors?.data || [], 'name');


    const {data: getSchoolData} = useQuery(getSchools);
    let schools = []
    getSchoolData?.schools?.map(school => {
        schools.push({id: school.id, value: school.name})
    });

    const {data: skills} = useQuery(getSkills);
    const subjects = []
    skills?.skills?.map(sub => {
        subjects.push({id: sub.id, value: sub.name})
    })


    const handleAddLocation = async (longitude: Number, latitude: Number) => {
        try {
            const {data: {addCurrentLocation: {success = false, message = ''} = {}} = {}} = await addCurrentLocation({
                variables: {location: {longitude, latitude}}
            })
            history.push({search: 'location-granted=true'});
            if (!!searchTutor.length) refetchSearchTutor();
            else if (!!tutors.length) refetchSkill();
            else if (!success) throw new Error(message)
        } catch (error) {
            message.error(error.message)
        }
    }

    useEffect(() => {
        if (address?.googlePlace?.formatted_address) {
            setAddressQuery(address.googlePlace?.formatted_address);
        }
    }, [address])

    const {action} = useParams() as any;

    const handleLocationClick = () => {
        navigator.geolocation.getCurrentPosition(async location => {
            await handleAddLocation(location.coords.longitude, location.coords.latitude)
        });
    }

    const buildResults = () => (
        <div
            style={{
                position: "absolute",
                zIndex: 10,
                backgroundColor: "white",
                border: "1px black solid",
                fontSize: '12px',
                fontFamily: 'Crimson Pro'
            }}
            onMouseLeave={() => {
                setHoverPlaceId(null);
            }}
        >
            {results.map((result: any) => (
                <div
                    style={{
                        border:
                            result.place_id === hoverPlaceId ? `1px solid green` : 'none',
                        fontSize: '12px',
                        fontFamily: 'Crimson Pro'
                    }}
                    onMouseOver={() => {
                        setHoverPlaceId(result.place_id);
                    }}
                    onClick={async () => {
                        setAddressQuery(null);
                        setAddress(result.description);
                        setResults([]);
                        let place = await getPlace(result.place_id);
                        setSearchFilters({
                            ...searchFilters, customLocation: {
                                longitude: place.geometry.location.lng(),
                                latitude: place.geometry.location.lat()
                            }
                        })
                    }}
                >
                    {result.description}
                </div>
            ))}
        </div>
    );

    return (
        <>
            <LocationAccessModal
                visible={query.has('location-permission')}
                onCancel={() => history.push({search: 'location-denied'})}
                handleAddLocation={handleAddLocation}
            />
            {action === 'filter' ? (
                    <Filters filtering={true}  {...{setLocation}} />
                ) :
                <div id='ExploreDesktop' className="main-div-desktop">
                    <Row>
                        <Col xs={0} sm={0} md={0} lg={2} xl={2} xxl={2}/>

                        <Col span={4} className="filter-main-box-desktop">
                            <Title className="heading-text filter-title-desktop">Filters</Title>
                            <div className="space-top">
                                <Text className=" heading-text hourly-rate-font-desktop">
                                    Hourly rate: <span className="hourly-rate-font">$10 - $100+</span>
                                </Text>
                                <Slider className="slider-line-color-desktop" range defaultValue={[20, 50]}
                                        onChange={(value) => {
                                            setPage(1)
                                            setSearchFilters({...searchFilters, hourlyRange: [value[0], value[1]]})
                                        }}
                                />
                            </div>
                            <Row className="availability-box">
                                <Text className=" availability-font-desktop heading-text">Availability</Text>
                                <Row className="min-content">
                                    <Checkbox.Group
                                        options={days}
                                        className="no-margin full-width "
                                        onChange={(value) => {
                                            setPage(1)
                                            setSearchFilters({...searchFilters, availability: value})
                                        }}
                                    />

                                </Row>
                            </Row>
                            <Row>
                                <div className="preferences-desktop margin-top-8">
                                    <Paragraph className="heading-font">Gender</Paragraph>
                                    <Select
                                        allowClear
                                        showSearch
                                        className="border-width heading-text"
                                        defaultValue=" No Preferences"
                                        options={[{label: 'Male', value: 'MALE'}, {
                                            label: 'Female',
                                            value: 'FEMALE'
                                        }, {label: 'Other', value: 'OTHER'}]}
                                        suffixIcon={<CaretDownFilled/>}
                                        onChange={(value, id) => {
                                            setPage(1)
                                            setSearchFilters({...searchFilters, gender: value})
                                        }}
                                    />

                                </div>
                                <div className="preferences-desktop margin-top-8">
                                    <Paragraph className="heading-font">School</Paragraph>
                                    <Select className="border-width heading-text"
                                            allowClear
                                            showSearch
                                            defaultValue=" No Preferences"
                                            suffixIcon={<CaretDownFilled/>}
                                            options={schools}
                                            onChange={(value) => {
                                                setPage(1)
                                                setSearchFilters({...searchFilters, school: value})
                                            }}
                                    />

                                </div>


                                <div className="preferences-desktop margin-top-8">
                                    <Paragraph className="heading-font">Subjects</Paragraph>
                                    <Select className="border-width heading-text"
                                            allowClear
                                            showSearch
                                            mode="multiple"
                                            tagRender={tagRender}
                                            options={subjects}
                                            suffixIcon={<CaretDownFilled/>}
                                            onChange={(name, obj) => {
                                                setPage(1);
                                                const skillIds = [];
                                                for (let skill of obj) {
                                                    skillIds.push(skill.value);
                                                }
                                                setSearchFilters({
                                                    ...searchFilters,
                                                    skills: skillIds
                                                })
                                            }}
                                    />

                                </div>

                                <div className="preferences-desktop margin-top-8">
                                    <Paragraph className="heading-font">Suburbs</Paragraph>
                                    <Input className="border-width heading-text"
                                           size="large"
                                           value={address}
                                           onChange={(e: any) => {
                                               setAddressQuery(e.target.value);
                                               setAddress(e.target.value);
                                               setResults([]);
                                           }}
                                           onBlur={() => {
                                               if (single) {
                                                   setAddressQuery(single.formatted_address);
                                               }
                                           }}
                                           placeholder="Search"
                                    />
                                    {results?.length > 1 ? (
                                        buildResults()
                                    ) : null}

                                </div>
                            </Row>
                        </Col>

                        <Col xs={0} sm={0} md={0} lg={16} xl={16} xxl={16} className="tutor-column-desktop">
                            <NameInput {...{setLocation}} />

                            <Row className="location-main-box-desktop">
                                <Col span={5}/>
                                <Col span={6} className="use-location-align" style={{cursor: 'pointer'}}
                                     onClick={handleLocationClick}>
                                    <div>
                                        <img src='/icons/explore/small-location-icon.svg'
                                             className="use-location-icon margin-location-icon"/>
                                        <Text className="use-location-text"> Use current location </Text>
                                    </div>
                                </Col>
                                <Col span={7} className="match-outer-box-desktop">
                                    <Row className="flex">
                                        <Text className="sort-text-desktop"> Sort:</Text>
                                        <Select className="border-width-desktop"
                                                defaultValue=" Best match"
                                                suffixIcon={<CaretDownFilled/>}
                                            // options={[{value: 'Rating'}, {value: 'Rate'}, {value: 'Billed hours'}]}
                                                options={[{value: 'Rate'}]}
                                        >
                                        </Select>
                                    </Row>
                                </Col>

                            </Row>
                            <Row className="row-height"/>
                            <Row>
                                <Col span={24}>
                                    <Pagination current={page} className={"pagination-styles"} showSizeChanger={false}
                                                pageSize={15} total={meta?.total}
                                                onChange={(page) => {
                                                    setPage(page)
                                                }}/>
                                </Col>
                            </Row>

                            <Row className="tutors-outer-container-desktop">
                                <Tutors
                                    {...{location}}
                                    loading={loading || searchLoading}
                                    tutors={tutorsList}
                                />

                            </Row>

                        </Col>
                        <Col span={2}/>
                        <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
                            <Mobile
                                days={days}
                                location={location}
                                searchLoading={searchLoading}
                                tutorsList={tutorsList}
                                loading={loading}
                                schools={schools}
                                subjects={subjects}
                                page={page}
                                setPage={setPage}
                                meta={meta}
                                setSearchFilters={setSearchFilters}
                                searchFilters={searchFilters}
                            />
                        </Col>


                    </Row>

                </div>
            }
            <Row>
                <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
                    <BottomNavigation role={'PARENT'}/>
                </Col>
            </Row>
        </>
    )
};

import React, {useEffect, useRef, useState} from "react";
import ComponentContainer from "../../components/LandingTemplates/ComponentContainer";
import {wordpressSite} from "../Constants/utilities";
import {Loader} from "../common/Loader";
import moment from "moment";
import {Link} from "react-router-dom";
import {Button} from "antd";
import BlogComponent from "./BlogComponent";
import CategoryPill from "./CategoryPill";
import BlogInput from "./BlogInput";
import SubscribeNewsLetter from "./BlogSubscribe";

export type BlogPost = {
    title: string;
    modified: string;
    content: string;
    excerpt: string;
    featured_image: string;
    category: string;
    slug: string;

}

export default (props) => {
    const [posts, setPosts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("All");
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState("");
    const [hero, setHero] = useState(null);


    const timeoutIdRef = useRef(null);
    const handleTermChange = (event) => {
        event.persist();
        clearTimeout(timeoutIdRef.current);

        timeoutIdRef.current = setTimeout(() => {
            handleChangeSearch(event.target.value);
        }, 500);
    };

    const fetchPosts = async () => {
        let url = `https://public-api.wordpress.com/rest/v1/sites/${wordpressSite}/posts?`
        if (search !== "") {
            url = `${url}&search=${search}`;
        }
        if (selectedCategory != "" && selectedCategory !== "All") {
            url = `${url}&category=${selectedCategory}`
        }
        await fetch(
            url,
            {
                method: "GET",
                headers: {
                    accept: "application/json",
                    "content-type": "application/json",
                },
            }
        )
            .then((res) => res.json())
            .then((response) => {
                console.log(response);
                response?.posts.forEach(p => {
                    p["excerpt"] = p.excerpt.replaceAll("[&hellip;]", "");
                })
                if (response?.posts.length == 0) {
                    setPosts([]);
                } else if (response?.posts?.length > 1) {
                    setPosts(response?.posts.slice(1));
                    setHero(response?.posts[0])
                } else {
                    setPosts(response?.posts);
                    setHero(response?.posts[0])
                }

                setLoading(false);
            });
    };

    const getCategories = async () => {
        await fetch(
            `https://public-api.wordpress.com/rest/v1/sites/${wordpressSite}/categories`,
            {
                method: "GET",
                headers: {
                    accept: "application/json",
                    "content-type": "application/json",
                },
            }
        )
            .then((res) => res.json())
            .then((response) => {
                setCategories(response?.categories);
            });
    };

    useEffect(() => {
        getCategories();
    }, []);

    useEffect(() => {
        fetchPosts();
    }, [selectedCategory, search]);

    const handleSelectCategory = (category) => {
        setSelectedCategory(category)
    };

    const handleChangeSearch = (search) => {
        setSearch(search);
    };


    return <ComponentContainer>
        <div className={"text-center text-4xl crimson-font font-bold heading-text my-4"}>Pocketnote Blog</div>
        {loading && <Loader/>}
        {!loading &&
            <>
                <div className={"grid md:grid-cols-2 gap-4 background-grey md:p-16 place-content-center p-4"}>
                    <div className={"col-span-1 self-center"}>
                        {hero.category &&
                            <div className={"text-2xl crimson-font font-bold heading-text"}>{hero.category}</div>}
                        <div className={"text-3xl crimson-font font-bold heading-text"}>{hero.title}</div>
                        <img className={"block md:hidden col-span-1 md:max-w-lg self-center mt-2"}
                             src={hero.featured_image}/>
                        <div className={"mt-4 secondary-text-dmsans"} dangerouslySetInnerHTML={{__html: hero.excerpt}}/>
                        <div
                            className={"text-xs crimson-font secondary-text-dmsans mt-2"}>{moment(hero.modified).format("DD/MM/YYYY")}</div>
                        <div className={"mt-2"}>
                            <Link to={`/blog/${hero.slug}`}>
                                <Button className={"landing-button landing-button-secondary"}>
                                    Read More
                                </Button>
                            </Link>
                        </div>
                    </div>

                    <img className={"hidden md:block col-span-1 md:max-w-lg self-center"} src={hero.featured_image}/>
                </div>
                <div className={"md:p-20 p-4"}>
                    <div className={"grid md:grid-cols-3 gap-4 place-content-center"}>
                        <div className={"block md:hidden md:col-span-1 md:block"}>
                            <BlogInput
                                placeholder="Search Blog"
                                onChange={handleTermChange}
                                showIcon={true}
                                type={"text"}
                            />
                            <div className={"my-4"}>
                                {categories.map(c => <CategoryPill text={c.name} selected={selectedCategory === c.name} onClick={() => setSelectedCategory(c.name)} />)}
                            </div>
                            <SubscribeNewsLetter />
                        </div>
                        <div className={"md:col-span-2"}>
                            <div className={"text-4xl crimson-font font-bold heading-text my-4"}>Explore</div>
                            <div className={"grid md:grid-cols-2 gap-4 place-content-center"}>
                                {posts.map(p => <BlogComponent
                                    content={p.content}
                                    title={p.title}
                                    slug={p.slug}
                                    modified={p.modified}
                                    excerpt={p.excerpt}
                                    featured_image={p.featured_image}
                                    category={p.category}
                                />)}
                            </div>
                        </div>
                        <div className={"hidden md:col-span-1 md:block"}>
                            <BlogInput
                                placeholder="Search Blog"
                                onChange={handleTermChange}
                                showIcon={true}
                                type={"text"}
                            />
                            <div className={"my-4"}>
                                {categories.map(c => <CategoryPill text={c.name} selected={selectedCategory === c.name} onClick={() => setSelectedCategory(c.name)} />)}
                            </div>
                            <SubscribeNewsLetter />
                        </div>

                    </div>
                </div>
            </>}

    </ComponentContainer>
}

import React from 'react';
import { useQuery } from '@apollo/client';
import { getSkills } from '../common/queries';
import { sortBy } from 'lodash';
import SkillLink from './SkillLink';

export default ({ style }: any) => {
  const { data } = useQuery(getSkills);
  const skills = sortBy(data?.skills || [], 'name').filter(
    ({ parent }: any) => !parent,
  );

  return (
    <div
      className="Skills"
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        background: '#E8EAED',
        ...(style || {}),
      }}
    >
      {skills.map((skill: any) => (
        <SkillLink key={skill.id} {...skill} />
      ))}
    </div>
  );
};

import React from 'react';
import { Spin } from 'antd';
import styled from '@emotion/styled';

const Container = styled.div`
  text-align: center;
  margin-top: 10%;
  min-width: 100vw;
  min-height: 100vh;
`

const LoaderContainer = styled.div`
  text-align: center;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FullScreenLoader = () => (
  <Container>
    <Spin size='large' />
  </Container>
)

export const Loader = () => (
  <LoaderContainer>
    <Spin />
  </LoaderContainer>
)


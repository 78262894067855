import React from 'react';
import {FieldArray} from 'formik';
import Tag from '../Tag';
import {Row, Col, Typography} from 'antd'

const {Text} = Typography

export default ({values, students = []}: any) => {
    return (
        <>
            <FieldArray
                name="students"
                render={arrayHelpers => (
                    <>
                        <Row>
                            <Col span={12}>
                                <Text className="width-100 common-heading">Enrolled</Text>
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={22} xl={22} xxl={22}>
                                        {(values.students || []).map((student: any, index: any) => (
                                            <Tag key={index} onClick={() => arrayHelpers.remove(index)} hue={120}>
                                                {student.name}
                                            </Tag>
                                        ))}
                                    </Col>
                                </Row>

                            </Col>
                            <Col span={12}>
                                <Text className="width-100 common-heading">Unenrolled</Text>
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={22} xl={22} xxl={22}>
                                        {(students || [])
                                            .filter(
                                                (student: any) =>
                                                    !(values.students || []).find(
                                                        ({id}: any) => id === student.id,
                                                    ),
                                            )
                                            .map((student: any, index: any) => (
                                                <Tag
                                                    key={index}
                                                    onClick={() => arrayHelpers.push(student)}
                                                    saturation={0}
                                                >
                                                    {student.name}
                                                </Tag>
                                            ))}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
                )}
            />
        </>
    );
};

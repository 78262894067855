import {Link} from "react-router-dom";
import {homePageBookTutorRedirection} from "../Constants/utilities";
import {Button} from "antd";
import React from "react";

export default (props) => {
    return <div style={{position: "relative"}} className='align-item-center w-full align-content-center'>
        <div
            className='find-tutor-form background-primary w-full padding-8'>
            <div className='align-center bottom-cta-title lg:px-48'>
                {props.signUpText}
            </div>
            <Link className={"my-4 align-center"} to={homePageBookTutorRedirection(props.user)}>
                <Button className="learn-btn">
                    <span className="learn-btn-text">Sign Up</span>
                </Button>
            </Link>
        </div>
        <div
            className={"hidden lg:block"}
            style={{
                backgroundImage: `url(/icons/math-tutor/signup-character.png)`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                minWidth: 200,
                height: 300,
                position: "absolute",
                right: "1.5em",
                top: "2.4em",

            }}
        ></div>
    </div>
}

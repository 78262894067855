import React from "react";
import {Books} from "../../pages/Constants/utilities";

export default () => {
    return <div className={"my-4 px-4"}>
        <div className="text-center innovation-text-landing text-4xl">
            {"Where school fails,"}
        </div>


        <div className="text-center innovation-text-landing text-4xl red-highlight">
            <span className="primary-text">{"we step in."}</span>
        </div>
        <div className={"grid grid-cols-1 md:grid-cols-8"}>
            <div className={"md:col-span-3 md:mt-20"}>
                <img className={"school-fails-image"} src={"icons/home-page/girl-icon.svg"}/>

            </div>
            <div className={"col-span-5 mt-12"}>
                <div className={"book-container"}>
                    {Books.map((book: any, index: number) => (
                        <div key={index}>
                            <div
                                className={"book-image"}
                                style={{
                                    backgroundImage: `url(/icons/books/${book.background})`
                                }}
                            />
                            <div className="book-text">{book.name}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
}

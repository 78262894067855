/** @jsxRuntime classic */
/** @jsx jsx */
import {jsx, css} from "@emotion/core";
import Desktop from "./Desktop";
import Mobile from "./Mobile";
import {Fragment} from "react";

const MIN_WIDTH = `768px`;

const Nav = (props: any): JSX.Element => {

    return (
        <Fragment>
            <div
                css={css`
          display: block;
          @media (min-width: ${MIN_WIDTH}) {
            display: none;
          }
        `}
            >
                <Mobile {...props} />
            </div>
            <div
                css={css`
          display: none;
          @media (min-width: ${MIN_WIDTH}) {
            display: block;
          }
        `}
            >
                <Desktop {...props} />
            </div>
        </Fragment>
    );
};

export default Nav;

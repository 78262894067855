import gql from 'graphql-tag';
import React from 'react';
import { useQuery } from '@apollo/client';
import Table from '../../Table';
import COLUMNS from './columns';

export default (props: any) => {
  const { data } = useQuery(
    gql`
      query Conversations {
        conversations {
          id
          createdAt
          users {
            id
            name
            email
          }
        }
      }
    `,
  );

  const conversations = data?.conversations || [];

  return (
    <Table
    
      data={conversations}
      columns={COLUMNS}
      initialState={{ sortBy: [{ id: 'createdAt', desc: true }] }}
    />
  );
};

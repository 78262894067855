import React, {useEffect, useRef, useState} from "react";
import {gql, useMutation, useQuery} from "@apollo/client";

import {Button, Col, message, Row} from "antd";
import {getSubjects} from "../../common/queries";


import {TitleRow} from "./TitleRow";
import {SubjectRow} from "./SubjectRow";
import {CreateRow} from "./CreateRow";
import {SellPriceRow} from "./SellPriceRow";

import "./CreateYourNotes.less";
import {examList, semesterList, yearLevel} from "../../../components/static";
import {addTutorNotesMutation, updateTutorNotesMutation} from "../../common/mutations";
import {toast} from "react-toastify";

export interface createYourNotesInterface {
    subject: any
    semester: string
    exam: string
    wordCount: number
    numberOfPages: number
    notesInfo: string
    title: string
    sellPrice: number
    sampleNotes: any
    notes: any
}


export const CreateYourNotes = ({tutor, notes, isButtonPressed}) => {

    let passedNotes: any = notes.notes

    const ref = useRef(null);
    const {data: subjectData} = useQuery(getSubjects)
    const [addTutorNotes] = useMutation(addTutorNotesMutation)
    const [updateTutorNotes] = useMutation(updateTutorNotesMutation)


    const [createUpload] = useMutation(gql`
        mutation CreateUpload($mime: String!, $filename: String, $uploadType: String, $private: Boolean) {
            createUpload(mime: $mime, filename: $filename, uploadType: $uploadType, private: $private) {
                id
                signedUrl
            }
        }
    `);

    let initialState = {} as createYourNotesInterface

    if (passedNotes) {
        isButtonPressed(true)
        initialState = {
            subject: passedNotes.subject,
            semester: passedNotes.semester,
            exam: passedNotes.exam,
            wordCount: passedNotes.wordCount,
            numberOfPages: passedNotes.numberOfPages,
            notesInfo: passedNotes.info,
            title: passedNotes.title,
            sellPrice: passedNotes.sellPrice,
            sampleNotes: null,
            notes: null
        };

    } else {
        initialState = {
            subject: "",
            semester: "",
            exam: "",
            wordCount: 0,
            numberOfPages: 0,
            notesInfo: "",
            title: "",
            sellPrice: 0,
            sampleNotes: null,
            notes: null
        };
    }


    const [tutorNotes, setTutorNotes] = useState<createYourNotesInterface>(initialState)

    let subjectList = []
    subjectData?.skills?.map(subject => {
        subjectList.push({id: subject.id, value: subject.name})
    });

    const uploadNotes = async (file, typeOfNotes: string, privateUpload: boolean) => {
        console.log("file", file)
        const {data} = await createUpload({
            variables: {mime: file.mime, filename: file.filename, uploadType: typeOfNotes, private: privateUpload},
        });

        const upload = data.createUpload;

        await fetch(upload.signedUrl, {
            method: 'PUT',
            body: file.file,
            headers: {
                "Content-Type": file.mime
            }
        });

        return upload
    }

    const uploadNotesData = async () => {

        if (!tutorNotes.subject || !tutorNotes.semester || !tutorNotes.exam || !tutorNotes.wordCount ||
            !tutorNotes.numberOfPages || !tutorNotes.notesInfo || !tutorNotes.title || !tutorNotes.sellPrice ||
            !tutorNotes.sampleNotes || !tutorNotes.notes) {
            toast.error('Please fill the entire form!');

            return
        }

        try {

            let sampleNotesUpload = await uploadNotes(tutorNotes.sampleNotes, "SAMPLE_NOTES", false)
            let notesUpload = await uploadNotes(tutorNotes.notes, "NOTES", true)

            if (passedNotes) {

                await updateTutorNotes({
                    variables: {
                        id: passedNotes.id,
                        input: {
                            tutor: tutor.id,
                            subject: tutorNotes.subject?.id,
                            semester: tutorNotes.semester,
                            exam: tutorNotes.exam,
                            wordCount: tutorNotes.wordCount,
                            numberOfPages: tutorNotes.numberOfPages,
                            info: tutorNotes.notesInfo,
                            title: tutorNotes.title,
                            sellPrice: tutorNotes.sellPrice,
                            sampleNotes: sampleNotesUpload.id,
                            notes: notesUpload.id
                        }
                    }
                })

            } else {
                await addTutorNotes({
                    variables: {
                        input: {
                            tutor: tutor.id,
                            subject: tutorNotes.subject?.id,
                            semester: tutorNotes.semester,
                            exam: tutorNotes.exam,
                            wordCount: tutorNotes.wordCount,
                            numberOfPages: tutorNotes.numberOfPages,
                            info: tutorNotes.notesInfo,
                            title: tutorNotes.title,
                            sellPrice: tutorNotes.sellPrice,
                            sampleNotes: sampleNotesUpload.id,
                            notes: notesUpload.id
                        }
                    }
                })
            }


            toast.success('Notes uploaded successfully!');

            setTimeout(() => {
                window.location.href = "/notes/my-notes"
            }, 1000);


        } catch (error) {
            message.error(error.message);
            toast.error('Error');
        }
    }


    return (
        <div style={{display: "flex", flexDirection: "column", alignSelf: "center", paddingBottom: 10}}>
            {/*Title Row*/}
            <TitleRow/>

            <SubjectRow
                subjectList={subjectList}
                semesterList={semesterList}
                examList={examList}
                tutorNotes={tutorNotes}
                yearLevel={yearLevel}
                setTutorNotes={setTutorNotes}
            />

            {/*create your notes row*/}
            <CreateRow
                tutorNotes={tutorNotes}
                setTutorNotes={setTutorNotes}
            />

            {/*Sell Price*/}
            <SellPriceRow
                tutorNotes={tutorNotes}
                setTutorNotes={setTutorNotes}
            />

            <Row>
                <Col xs={2} sm={2} md={2} lg={0} xxl={0} xl={0}/>
                <Col id="CreateNotesDesktop" xs={20} sm={20} md={20} lg={22} xxl={22} xl={22}>

                    <div style={{
                        // border: "1px solid blue",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        alignSelf: "center",
                    }}>
                        <Button
                            type="primary"
                            ref={el => {
                                if (el) {
                                    el.style.setProperty('display', 'flex', 'important');
                                    el.style.setProperty('width', '100%', 'important');
                                    el.style.setProperty('justify-content', 'center', 'important');
                                    el.style.setProperty('border-radius', '5px', 'important');
                                }
                            }}
                            onClick={
                                async () => {
                                    console.log("created notes", tutorNotes)
                                    await uploadNotesData()
                                }}
                        >
                            Upload
                        </Button>

                    </div>
                </Col>
            </Row>


        </div>
    )
}

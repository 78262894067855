import React from 'react'
import Chart from 'react-apexcharts'
import {Card} from 'antd';


export default ({dates, earnings}) => {
    let Revenue = {
        options: {
            chart: {
                id: 'revenue-chart'
            },
            responsive: [{
                breakpoint: 1000,
                options: {
                    chart: {
                        toolbar: {
                            show: false,
                            tools: {
                                download: false
                            }
                        },
                        id: 'revenue-chart'
                    }
                },
            }],
            title: {
                text: "Earnings",
            },
            xaxis: {
                categories: dates
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy'
                },
            },
            colors: ["#000"],
            markers: {
                size: 6,
            },
        },
        series: [{
            name: 'Earning',
            data: earnings
        }]
    }

    return (
        <>
            <Card>
                <Chart options={Revenue.options} series={Revenue.series} type="line" height={330} color={'black'}/>
            </Card>
        </>
    )
}

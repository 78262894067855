import React, {useState} from 'react';
import {Button, Input, message, Modal} from 'antd';
import {InfoCircleOutlined} from "@ant-design/icons";
import {useMutation} from "@apollo/client";
import {addSubjectMutation} from "../../common/mutations";
import {toast} from "react-toastify";


export const AddMissingSubjectModal = (props: any) => {

    const {isAddSubjectModalVisible, setIsAddSubjectModalVisible} = props

    const [subjectName, setSubjectName] = useState<string>('');

    const [addSubject] = useMutation(addSubjectMutation)


    const handleOk = async () => {

        if (!subjectName) {
            toast.error('Please write a Name for your subject !');
            return
        }

        try {

            await addSubject({
                variables: {
                    subjectName: subjectName
                }
            })
            toast.success('New subject created.');

        } catch (error) {
            message.error(error.message);
            toast.error('Error');
        }

        setIsAddSubjectModalVisible(false);
    };

    const handleCancel = () => {
        setIsAddSubjectModalVisible(false);
    };

    return (

        <Modal
            title="Add Subject"
            visible={isAddSubjectModalVisible}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Return
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk}>
                    Create
                </Button>,

            ]}
        >
            <div style={{
                display: "flex",
                flexDirection: "row",
                width: '30%',
                justifyContent: 'space-evenly',
                alignItems: "flex-start",
            }}>
                <InfoCircleOutlined style={{color: '#E26F6F', fontSize: 'large'}}/>
                <h4>Subject Name</h4>
            </div>


            <Input
                placeholder="Enter name of the Subject"
                className="select-width"
                size="large"
                value={subjectName}
                onChange={(e) => {
                    console.log(e.target.value, "here here", subjectName)
                    setSubjectName(e.target.value)
                }}
                maxLength={24}
            />


        </Modal>
    )
}
import React from "react";
import {Button} from "antd";
import {Link} from "react-router-dom";
import {useQuery} from "@apollo/client";
import gql from "graphql-tag";
import TutorPresent from "./TutorPresent";

export default (props) => {

    const {data, loading, error} = useQuery(
        gql`
            query getTopEarningTutors {
                topEarningTutors {
                    id
                    name
                    skills {
                        name
                    }
                    title
                    biography
                    avatar {
                        url
                    }
#                    badges {
#                        id
#                        badgeName
#                        iconName
#                    }
                }
            }
        `,
    );
    if (loading || error) {
        return null;
    }

    return <div>
        <div className={"md:grid md:grid-cols-2 md:px-20 gap-4 md:my-6 my-4 px-4"}>
            <div className="justify-self-start innovation-text-landing text-4xl my-3">Find Your Tutor</div>
            <Link to={"/explore/tutors"} className={"justify-self-end align-center"}>
                <Button type="primary" className={"landing-button landing-button-primary"}>
                    Show All
                </Button></Link>
        </div>
        <div className={"md:grid md:grid-cols-4 md:py-10 py-6 place-items-center grid grid-cols-1"}>
            {data?.topEarningTutors?.slice(0,4).map((tutor: any, index: number) => <TutorPresent
                name={tutor.name}
                imgSrc={tutor.avatar.url}
                subjects={tutor.skills.map(s => s.name)}
                description={tutor.biography}/>)}
        </div>

    </div>
}

import {Button, Col, Row, Typography} from "antd";
import React from "react";
import "./CreateYourNotes.less";

const {Title} = Typography;

export const TitleRow = () => {

    return (
        <Row>
            <Col xs={2} sm={2} md={2} lg={0} xxl={0} xl={0}/>

            <Col id="CreateNotesDesktop" xs={20} sm={20} md={20} lg={22} xxl={22} xl={22}>

                <Row style={{
                    // // border: "1px solid blue",

                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 50
                }}>

                    <Col xs={18} sm={18} md={18} lg={16} xxl={16} xl={16}>
                        <Title className="create-notes-title"> Let's create your notes</Title>
                    </Col>
                </Row>
            </Col>

            <Col xs={2} sm={2} md={2} lg={0} xxl={0} xl={0}/>
        </Row>
    )
}

import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Row, Col, Button, Typography, Input} from 'antd';
import './Desktop.less'

const {Text} = Typography;

export default ({updateSearchString}: any) => {
    const [searchString, setSearchString] = useState<string>(null);

    const handleClick = () => {
        updateSearchString(searchString);
    };

    const handleChange = (event: any) => {
        setSearchString(event.target.value)
        event.persist()
    };

    return (

        <Row className="subject-Row-desktop">
            <Col span={13} className="margin-top-12">
                <Text className="heading-font ">Search notes</Text>
                <Input prefix={<img src="/icons/explore/search-icon.svg"/>}
                       className="notes-search-bar-desktop no-margin input-text"
                       title='title'
                       value={searchString}
                       placeholder='Search Notes'
                       onChange={handleChange}
                       onKeyPress={(e) => {
                           if (e.key === 'Enter') {
                               handleClick()
                           }
                       }}
                />

            </Col>
            <Col span={10}>
                <Button className="notes-search-btn" onClick={handleClick}>
                    <span className="notes-search-btn-text">Search</span>
                </Button>
            </Col>
        </Row>
    )
}


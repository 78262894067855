import React from 'react';
import './Desktop.less';
import EventList from './EventList'


export default () => {
  return (
    <div>
      {[
        ['Unapproved', 'unapprovedEvents'],
        ['Approved', 'approvedEvents'],
        ['History', 'completedEvents', {reverse: true, readOnly: true}],
      ].map(([title, selector, props = {}]: any) => {
        return (
          <div key={title} style={{marginTop: 16}}>
            <EventList {...{...props, selector}} heading={title}/>
          </div>
        );
      })}
    </div>

  )
}

import {PanelProps} from "./LandingWhyPanel";

export default (props: PanelProps) => {
    const {imgSrc, text} = props;
    return <div className={"md:grid md:grid-cols-8 " +
        "place-content-center md:drop-shadow-sm " +
        "place-items-center px-2 md:py-4 benefits-panel-container md:border md:border-inherit"}>
        <img className={"md:col-span-1 md:self-start mx-auto my-4 md:my-0"} src={imgSrc}/>
        <span className={"md:col-span-7 text-left text-sm"}>{text}</span>
    </div>
}

import { Form, Formik } from 'formik';
import gql from 'graphql-tag';
import { sortBy } from 'lodash';
import React, { useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { deleteSkill as deleteSkillMutation } from '../common/mutations';
import { Button, Header } from '../components';
import CreateSkill from '../CreateSkill';
import Table from '../Table';

const Skills = ({ skills }: any) => {
  const [deleteSkill] = useMutation(deleteSkillMutation);

  const columns = useMemo(
    () =>
      [
        {
          Header: 'Name',
          accessor: 'name',
        },
        {
          Header: 'Parent',
          accessor: 'parent',
          Cell: ({ cell: { value } }: any) => <div>{value?.name}</div>,
        },
        {
          Header: 'Actions',
          accessor: (v: any) => v,
          Cell: ({ cell: { value }, row }: any) => {
            return (
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Button>Edit</Button>
                <Button
                  negative
                  onClick={async () => {
                    if (window.confirm('Really delete skill?')) {
                      await deleteSkill({
                        variables: { id: row.original.id },
                        refetchQueries: ['Skills'],
                      });
                    }
                  }}
                >
                  Delete
                </Button>
              </div>
            );
          },
        },
      ].filter(Boolean),
    [deleteSkill],
  );

  return (
    <Table
      data={sortBy(skills || [], 'name')}
      columns={columns}
      initialState={{}}
    />
  );
};

export default ({ user, ...props }: any) => {
  const { data } = useQuery(
    gql`
      query Skills {
        skills {
          id
          name
          parent {
            id
            name
          }
        }
      }
    `,
  );

  return data?.skills ? (
    <Formik initialValues={{}} onSubmit={async (values) => {}}>
      {(props) => (
        <Form>
          <div style={{ maxWidth: 768 }}>
            <Header>Subjects</Header>
            <Skills {...{ skills: data?.skills }} />
            <CreateSkill />
          </div>
        </Form>
      )}
    </Formik>
  ) : (
    <div></div>
  );
};

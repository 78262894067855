import React from 'react';
import {Button, Col, Row, Space, Spin, Typography} from 'antd';
import './Desktop.less';
import {useQuery} from "@apollo/client";
import {getAllUserNotes, getPrivateUrlForUserNotes} from "../../common/queries";
import apollo from "../../../apollo";
import {ColumnsType} from "antd/es/table";
import {EyeOutlined} from "@ant-design/icons";
import {Table} from "antd/es";

import Mobile from "./Mobile";


interface DataType {
    id: string;
    tutorNotes: any;
    boughtAt: any;
    title: string;
    subject: any;
    sellPrice: number;
    semester: string;
    exam: string;
    publish_date?: string;
    status: string;
    notes: any
    sampleNotes: any
    payment: any
}

const {Text, Title, Paragraph} = Typography;


export const NotesLibrary = ({user}: any) => {

    const {data: notesData, loading} = useQuery(getAllUserNotes);
    const columns: ColumnsType<DataType> = [
        {
            title: 'Purchased',
            dataIndex: 'boughtAt',
            key: 'boughtAt',
            render: (_, {boughtAt}) => <Text
                style={{fontWeight: "bolder"}}>{new Date(boughtAt).toLocaleDateString()}</Text>
        },
        {
            title: 'Tutor Name',
            dataIndex: 'tutorNotes.tutor',
            key: 'tutorNotes.tutor',
            render: (_, {tutorNotes}) => <Text style={{fontWeight: "bolder"}}>{tutorNotes?.tutor?.name}</Text>
        },
        {
            title: 'Notes Title',
            dataIndex: 'tutorNotes.title',
            key: 'tutorNotes.title',
            render: (_, {tutorNotes}) => <Text>{tutorNotes?.title}</Text>,
        },
        {
            title: 'Subject',
            dataIndex: 'tutorNotes.subject',
            key: 'tutorNotes.subject',
            render: (_, {tutorNotes}) => <Text>{tutorNotes?.subject?.name}</Text>,
        },
        {
            title: 'Price',
            dataIndex: 'tutorNotes.sellPrice',
            key: 'tutorNotes.sellPrice',
            render: (_, {tutorNotes}) => <Text>${tutorNotes?.sellPrice}</Text>,
        },

        {
            title: 'Semester',
            dataIndex: 'tutorNotes.semester',
            key: 'tutorNotes.semester',
            render: (_, {tutorNotes}) => <Text>{tutorNotes?.semester}</Text>,
        },
        {
            title: 'Exam',
            dataIndex: 'tutorNotes.exam',
            key: 'tutorNotes.exam',
            render: (_, {tutorNotes}) => <Text>{tutorNotes?.exam}</Text>,
        },
        {
            title: 'Year',
            dataIndex: 'tutorNotes.yearLevel',
            key: 'tutorNotes.yearLevel',
            render: (_, {tutorNotes}) => <Text>{tutorNotes?.yearLevel}</Text>,
        },
        {
            title: 'Paid',
            dataIndex: 'tutorNotes.payment',
            key: 'tutorNotes.payment',
            render: (_, {payment}) => <Text>{payment?.paid ? "Yes" : "No"}</Text>,
        },
        {
            title: 'Open',
            key: 'open',
            render: (_, record) => (
                <Space size="middle">

                    {record?.payment?.paid && <EyeOutlined onClick={() => {

                        onClickNotes(record.tutorNotes.id)
                    }} style={{color: "#1E93FF"}}/>}
                </Space>
            ),
        },
    ];


    const onClickNotes = async (id) => {
        const {data: urlObj} = await apollo.query({
            query: getPrivateUrlForUserNotes,
            variables: {
                id
            }
        });

        const url = urlObj.privateTempUrl;
        window.open(url, "_blank");

    }


    return (
        <>
            <Row>
                <Col xs={1} sm={1} md={1} lg={0} xxl={0} xl={0}/>

                <Col className="notes-library-desktop" xs={0} sm={0} md={23} lg={24} xxl={24} xl={24}>
                    <Row className="account-setting-align">
                        <Col xs={18} sm={18} md={18} lg={16} xxl={16} xl={16}>
                            <Title className="account-setting-title">Your Notes</Title>
                        </Col>

                    </Row>


                    {(!loading && notesData != null) ?
                        (
                            <Col className="notes-library-desktop table" xs={23} sm={23} md={23} lg={24} xxl={24}
                                 xl={24}>
                                <Table columns={columns} dataSource={notesData.userNotes}/>
                                <Row className="row-height"/>

                            </Col>
                        )
                        :
                        (
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>

                                <Spin size="large"/>
                            </div>

                        )
                    }
                </Col>

                <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
                    {
                        (!loading && notesData != null) ?
                            (
                                <Mobile
                                    tutorNotesList={notesData.userNotes}
                                    onClickNotes={onClickNotes}
                                />
                            )
                            :
                            (
                                <Spin size="large"/>
                            )
                    }
                </Col>
            </Row>

        </>
    )
};

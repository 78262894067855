import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
  RouteComponentProps,
} from "react-router-dom";
import { AuthContext } from "context/auth";
import messages from "./pages/locales/en_NZ/data";
import { StripeProvider } from "./pages/Stripe";
// import ScrollToTop from "./pages/ScrollToTop";
import "pages/App.less";

import { publicRoutes, privateRoutes, RouterParams } from "routes";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";

const AppRoute = ({
  Component,
  Layout,
  props,
  authenticated,
}: {
  Component: any;
  Layout?: any;
  props?: RouteComponentProps;
  authenticated?: boolean;
}) => {
  const history = useHistory();
  const storageUser = localStorage.getItem("user");
  const user = storageUser ? JSON.parse(storageUser) : null;

  useEffect(() => {
    if (authenticated && !user) {
      history.push("/");
    }
  }, [user, authenticated, history]);

  if (Layout) {
    return (
      <Layout {...props}>
        <Component {...props} user={user} />
      </Layout>
    );
  } else if (!Component) {
    return <Layout {...props} />;
  } else {
    return <Component {...props} />;
  }
};

const App = (): JSX.Element => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ width: "1299px", height: "fit-content" }}>
        <Switch>
          {publicRoutes.map((route, index) => (
            <Route
              exact
              key={index}
              path={route.path}
              render={(props: RouteComponentProps<RouterParams>) => (
                <AppRoute
                  props={props}
                  Layout={route.Layout}
                  Component={route.component}
                />
              )}
            />
          ))}
          {privateRoutes.map((route, index) => (
            <Route
              exact
              key={index}
              path={route.path}
              render={(props: RouteComponentProps) => (
                <AppRoute
                  props={props}
                  Layout={route.Layout}
                  Component={route.component}
                  authenticated={route.authenticated}
                />
              )}
            />
          ))}
        </Switch>
      </div>
    </div>
  );
};

export default (): JSX.Element => (
  <IntlProvider messages={messages} locale="en">
    <AuthContext.Provider value={true}>
      <Router>
        {/* <ScrollToTop /> */}
        <StripeProvider>
          <App />
        </StripeProvider>
      </Router>
    </AuthContext.Provider>
  </IntlProvider>
);

import OneTwoThreeComponent from "./OneTwoThreeComponent";

export type PropsFor123Component = {
    imgSrc: string;
    title: string;
    description: string;
    type: "1" | "2" | "3";
}
type PropsFor123 = {
    one: PropsFor123Component;
    two: PropsFor123Component;
    three: PropsFor123Component;
}
export default (props: PropsFor123) => {
    const {one, two, three} = props;
    return <div className={"grid grid-cols-1 md:grid-cols-3 md:px-20 md:gap-10 md:mt-12"}>
        <div className={"col-span-1"}>
            <OneTwoThreeComponent imgSrc={one.imgSrc} type={one.type} title={one.title} description={one.description}/>
        </div>
        <div className={"col-span-1"}>
            <OneTwoThreeComponent imgSrc={two.imgSrc} type={two.type} title={two.title} description={two.description}/>
        </div>
        <div className={"col-span-1"}>
            <OneTwoThreeComponent imgSrc={three.imgSrc} type={three.type} title={three.title} description={three.description}/>
        </div>
    </div>
}

import React from 'react';
import Tutor from './Tutor';
import {FullScreenLoader} from '../common/Loader';
import TutorMobile from "./TutorMobile";
import Switch from '../Switch'

export default ({loading, tutors}: any) => {

    return (
        <>
            {loading ? <FullScreenLoader/> : tutors.map((tutor: any) => {
                return (

                    <Switch>{[<TutorMobile key={tutor.id} {...tutor}/>, <Tutor key={tutor.id} {...tutor} />]}</Switch>
                )
            })}
        </>
    );
};

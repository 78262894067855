import {Button, Input} from "antd";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import {useMutation} from "@apollo/client";
import gql from "graphql-tag";
import {toast} from "react-toastify";
import TextArea from "antd/es/input/TextArea";
import {homePageBookTutorRedirection} from "../../pages/Constants/utilities";
import {HomePageQuery} from "../../pages/Home";
import InputWithTitle from "../InputWithTitle";

export default (props) => {
    const [homeQueryMessage] = useMutation(gql`
        mutation homePageQuery($input: HomePageQueryInput!) {
            homePageQuery(input: $input)
        }
    `);

    const [homePageQuery, setHomePageQuery] = useState<HomePageQuery>(null);

    const modifyHomePageQueryObj = (text: string, property: string) => {
        const newQuery = {...homePageQuery};
        newQuery[property] = text;
        setHomePageQuery(newQuery);
    };
    const sendQueryMessage = async () => {
        if (!validateMessage()) {
            return;
        }
        try {
            await homeQueryMessage({
                variables: {
                    input: homePageQuery,
                },
            });
            (window as any).dataLayer.push({event: "form_submit_general_enquiry"});

            toast.success("Message sent");
        } catch (error) {
            console.log("error at home page query ", error);
            toast.error("Error while sending message..");
        }
    };

    const validateMessage = (): boolean => {
        if (!homePageQuery) {
            toast.info("Please enter your message and details");
            return false;
        }
        if (homePageQuery.message == null || homePageQuery.message.trim() == "") {
            toast.info("Please enter your message");
            return false;
        }
        if (homePageQuery.email == null || homePageQuery.email.trim() == "") {
            toast.info("Please enter your email");
            return false;
        }
        if (homePageQuery.name == null || homePageQuery.name.trim() == "") {
            toast.info("Please enter your name");
            return false;
        }
        if (homePageQuery.phone == null || homePageQuery.phone.trim() == "") {
            toast.info("Please enter your phone");
            return false;
        }
        return true;
    };

    return <div className={"py-4 px-4 background-grey"}>
        <div className="md:px-24 md:grid md:grid-cols-2">
            <div className="col-span-1 justify-self-center self-center">
                <div className={"innovation-text-landing text-4xl md:text-6xl mb-4"}>
                    Ready to start learning?
                </div>
                <Link to={homePageBookTutorRedirection(props.user)}>
                    <Button type={"primary"} className={"landing-button"}>
                        Sign Up
                    </Button></Link>
            </div>
            <div className={"col-span-1 mt-4 md:w-4/5"}>
                <div className="any-questions">
                    OR if you have any questions, feel free to leave a
                </div>
                <div className="any-questions">
                    message and we'll get back to you shortly.
                </div>
                <InputWithTitle required
                                color={"black"}
                       onChange={(event) =>
                           modifyHomePageQueryObj(event.target.value, "name")}
                       title={"Name"}
                />
                <InputWithTitle required
                                color={"black"}
                                onChange={(event) =>
                           modifyHomePageQueryObj(event.target.value, "email")}
                       title={"Email"}
                />
                <InputWithTitle required
                                color={"black"}
                                onChange={(event) =>
                           modifyHomePageQueryObj(event.target.value, "phone")}
                       title={"Phone"}
                />
                <div className={"my-1 text-xs text-black"}>Message*</div>
                <textarea
                    className={"my-1 border w-full"}
                    onChange={(event) => {
                        modifyHomePageQueryObj(event.target.value, "message")
                    }}
                ></textarea>
                <Link  to={"#"}>
                    <button className={"w-full md:w-2/5 rounded h-10 background-primary"} onClick={sendQueryMessage}>
                        <span className="send-btn-text">Send</span>
                    </button>
                </Link>
            </div>

        </div>
    </div>
}

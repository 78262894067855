import React from 'react';
import {Field} from 'formik';
import {Label} from '../components';
import {Input, Row, Col, Typography} from 'antd'

const {Title} = Typography;

export default () => (
    <>
        <Row>
            <Col span={22}>
                <Row>
                    <Col span={24}>
                        <Row className="row-height"/>
                        <Title className="payout-info-title">Date of Birth</Title>
                    </Col>
                    <Col span={7}>
                        <Field
                            placeholder={"Day"}
                            className="select-width"
                            size="large" as={Input}
                            type="number"
                            name="dob.day"
                            min="1"
                               max="31"/>
                    </Col>
                    <Col span={1}/>
                    <Col span={7}>
                        <Field
                            placeholder={"Month"}
                            as={Input}
                            className="select-width"
                            size="large"
                            type="number"
                            name="dob.month"
                            min="1"
                               max="12"/>
                    </Col>
                    <Col span={1}/>
                    <Col span={8}>
                        <Field
                            placeholder={"Year"}
                            as={Input}
                            className="select-width"
                            size="large"
                            type="number"
                            name="dob.year"
                            min="1900"
                               max="2020"/>
                    </Col>
                </Row>
            </Col>
        </Row>

    </>
);

import gql from 'graphql-tag';
import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {useMutation, useQuery, useSubscription} from '@apollo/client';
import useStayScrolled from 'react-stay-scrolled';
import Composer from './Composer';
import Message from './Message';
import useReadConversation from './useReadConversation';
import EventMessage from './EventMessage';
import {Avatar, Col, Divider, Modal, Row, Spin, Typography} from 'antd';
import {LeftOutlined} from "@ant-design/icons";
import {CONVERSATION_QUERY, CONVERSATIONS_SUB} from "./queries";
import StaySafeModal from "./StaySafeModal/StaySafeModal";
import {useHistory} from 'react-router-dom';
import PaymentSettings from "../Settings/PaymentSettings";

const {Text, Title} = Typography;

export default ({id, me}: any) => {

    const [openModal, setOpenModal] = useState<Boolean>(false)
    const [state, setState] = useState({loading: true})
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const readConversation = useReadConversation();

    useEffect(() => {
        setState(s => ({...s, loading: true}))
        readConversation({user: me?.id, conversation: id})
            .then(() => {
            })
            .catch(() => {
            });
        return () => {
        };
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (state.loading)
            setTimeout(() => setState(s => ({...s, loading: false})), 4000)
    }, [state.loading])

    useEffect(() => {
        readConversation({user: me?.id, conversation: id})
            .then(() => {
            })
            .catch(() => {
            });
        return () => {
        };
        //eslint-disable-next-line
    }, [id, me]);

    const [draft, setDraft] = useState('');
    const [dynamic] = useState<any>([]);

    let {data} = useQuery(
        CONVERSATION_QUERY,
        {variables: {id}},
    );

    let {data: subData} = useSubscription(
        CONVERSATIONS_SUB,
        {variables: {id}},
    );


    let conversation = subData?.updatedConversation != null ? subData?.updatedConversation : data?.conversation || {};
    let messages = conversation?.messages || [];
    useEffect(() => {
        if (data && data?.conversation && data?.conversation?.messages.length === 0 && !state.loading) {
            setOpenModal(true)
        }
    }, [state.loading, data])
    messages = [...messages, ...dynamic];
    const otherUser = (conversation?.users || []).filter(user => user?.id !== me.id)

    const [sendMessage, {loading: sendMessageLoading}] = useMutation(gql`
        mutation SendMessage($conversation: ID!, $user: ID!, $content: String!, $attachment: ID) {
            sendMessage(conversation: $conversation, user: $user, content: $content, attachment: $attachment) {
                id
            }
        }
    `);

    const [createUpload] = useMutation(gql`
        mutation CreateUpload($mime: String!, $filename: String, $uploadType: String, $private: Boolean) {
            createUpload(mime: $mime, filename: $filename, uploadType: $uploadType, private: $private) {
                id
                signedUrl
            }
        }
    `);

    const scroller = useRef(null);
    const history = useHistory();

    const {stayScrolled} = useStayScrolled(scroller, {
        initialScroll: Infinity,
        inaccuracy: 0,
    });

    useLayoutEffect(() => {
        stayScrolled();
    }, [messages.length, stayScrolled]);

    const authors: any = {};
    conversation?.users?.forEach((user: any) => (authors[user.id] = user));

    return (
        <Col xs={23} sm={23} md={23} lg={24} xl={24} xxl={24} id="MessagesDesktop">
            {me && <Modal visible={showPaymentModal}
                          width={800}
                          footer={false}
                          onCancel={() => setShowPaymentModal(false)}>
                <PaymentSettings refreshOnSave user={me}/>
            </Modal>
            }
            <Row className="keep-spaced margin-row-20">

                <Col xs={1} sm={1} md={1} xl={0} lg={0} xxl={0} className="arrow-align">
                    <LeftOutlined onClick={() => {
                        history.goBack();
                    }}/>
                </Col>
                <Col xs={13} sm={13} md={13} lg={8} xl={8} xxl={8} className="tutor-name-align">
                    <div className="name-align">
                        <Title className="tutor-name">{otherUser[0]?.name}</Title>
                    </div>
                </Col>
                {openModal &&
                <StaySafeModal/>
                }

                <div className="tutor-name-align keep-spaced">
                    <div className="avatar-div-padding">
                        <Avatar size={51}
                                src={`${otherUser[0]?.avatar?.url ||
                                'https://react.semantic-ui.com/images/wireframe/image.png'}`}

                        />
                    </div>
                </div>
            </Row>
            <Divider className="divider-margin" type="horizontal"/>

            <div>
                <Row className="margin-conversation" align="bottom">
                    <Col span={24} className="conv-col-overflow" ref={scroller}>

                        {state.loading ?
                            <div style={{textAlign: 'center', margin: '20% auto'}}><Spin/></div> :

                            messages.map((message: any) =>
                                message.event ? (
                                    // null
                                    <EventMessage key={message.id} {...message} />
                                ) : (
                                    <Message
                                        key={message.id}
                                        {...message}
                                        author={authors[message?.author?.id]}
                                        attachment={message.attachment}
                                    />
                                ),
                            )}
                    </Col>
                </Row>
            </div>

            <Divider className="no-margin" type="horizontal"/>
            <Row className="back">
                <Col span={2}/>

                <Composer
                    me={me}
                    value={draft}
                    setValue={setDraft}
                    sendMessage={sendMessage}
                    createUpload={createUpload}
                    loading={sendMessageLoading}
                    conversation={conversation}
                    tutorId={otherUser[0]?.id}
                    setShowPaymentModal={setShowPaymentModal}
                />

            </Row>
        </Col>

    );
};

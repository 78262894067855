import React, {useEffect, useState} from 'react'
import {
    Row,
    Col,
    Collapse,
    Button,
    Typography, Input,
    TimePicker,
    Tooltip, message,
} from "antd"
import {
    MinusCircleOutlined,
    PlusCircleOutlined,
    InfoCircleOutlined
} from "@ant-design/icons";
import {useQuery, useMutation} from "@apollo/client";
import moment from "moment"
import {getTutorAvailability} from '../../common/queries'
import "./Desktop.less";
import {
    addAvailabilityMutation,
    updateAvailabilityMutation,
    updateAvailabilityExclusionMutation,
    deleteAvailabilityExclusion,
} from './query'
import "react-datetime/css/react-datetime.css";
import AvailabilityForm from "./AvailabilityForm";
import {orderBy} from 'lodash';
import {toast} from "react-toastify";

const {Panel} = Collapse;
const {Title, Text} = Typography

export interface availabilityInterface {
    id: string;
    name: string;
    day: number;
    start: string;
    end: string;
}

export interface availabilityExclusionInterface {
    id: number
    start: string
    end: string
}


const findDayName = (dayNumber) => {
    let dayName = ''
    switch (dayNumber) {
        case 0:
            dayName = 'Monday'
            break
        case 1:
            dayName = 'Tuesday'
            break
        case 2:
            dayName = 'Wednesday'
            break
        case 3:
            dayName = 'Thursday'
            break
        case 4:
            dayName = 'Friday'
            break
        case 5:
            dayName = 'Saturday'
            break
        case 6:
            dayName = 'Sunday'
            break
    }
    return dayName
}
const availabilityText = <span>Please set your availability in a week, this is recurring. The time you set for the day will be available for every week at same day. If you are not available please leave slot empty</span>;
const availabilityExclusionText =
    <span>If you want to exclude any time from availability, you can exclude from here</span>
export default ({user}) => {
    const [available, setAvailable] = useState<availabilityInterface[]>([
        {id: '', name: 'Monday', day: 0, start: '', end: ''},
        {id: '', name: 'Tuesday', day: 1, start: '', end: ''},
        {id: '', name: 'Wednesday', day: 2, start: '', end: ''},
        {id: '', name: 'Thursday', day: 3, start: '', end: ''},
        {id: '', name: 'Friday', day: 4, start: '', end: ''},
        {id: '', name: 'Saturday', day: 5, start: '', end: ''},
        {id: '', name: 'Sunday', day: 6, start: '', end: ''}
    ])
    const [availableExclusion, setAvailableExclusion] = useState<availabilityExclusionInterface[]>()

    const [addAvailability] = useMutation(addAvailabilityMutation)
    const {data: availability, loading, refetch} = useQuery(getTutorAvailability, {
        variables: {
            id: user?.id
        }
    })
    const [deleteAvailailityExclustion] = useMutation(deleteAvailabilityExclusion)
    const [updateAvailaility] = useMutation(updateAvailabilityMutation)
    const [updateAvailailityExclustion] = useMutation(updateAvailabilityExclusionMutation)


    useEffect(() => {

        //for availability
        let temp: availabilityInterface[] = []
        if (loading === false && availability?.getUserAvailability?.availabilities) {
            availability?.getUserAvailability?.availabilities.map(userAva => {

                temp.push({
                    id: userAva.id,
                    name: findDayName(userAva.day),
                    day: userAva.day,
                    start: userAva.start,
                    end: userAva.end
                })

            })
        }
        for (let i = 0; i <= 6; i++) {
            const checkEntry = temp.find(t => t.day === i)
            if (!checkEntry) {
                temp.push(
                    {id: '', name: findDayName(i), day: i, start: '', end: ''})
            }
        }

        setAvailable(orderBy(temp, ['day'], ['asc']))
        //for availability exclusion
        if (loading === false && availability?.getUserAvailability?.availabilityExclusions) {
            setAvailableExclusion(availability?.getUserAvailability?.availabilityExclusions)
        }

    }, [availability, loading])

    const saveAvailability = async () => {
        let availabilities = []
        let availabilityExclusions = []

        available.map(async newAvailability => {
            let flag = true
            availability?.getUserAvailability?.availabilities?.map(dbAvailability => {
                if (newAvailability.id === dbAvailability.id) flag = false
            })
            if (flag) {
                flag = false
                if (newAvailability.start) {
                    availabilities.push({
                        day: newAvailability.day,
                        start: newAvailability.start,
                        end: newAvailability.end
                    })
                }
            } else {
                try {

                    await updateAvailaility({
                        variables: {
                            availability: {
                                day: newAvailability.day,
                                start: newAvailability.start,
                                end: newAvailability.end
                            },
                            availabilityId: newAvailability.id
                        }
                    })
                } catch (error) {
                    message.error(error.message);
                    toast.error('Error while updating availability.');
                }
            }
        })

        //availability exclusion inserting and updating ...
        availableExclusion.map(async avaExcl => {
            let flag = true
            availability?.getUserAvailability?.availabilityExclusions?.map(dbAvaEx => {
                if (avaExcl.id === dbAvaEx.id) flag = false
            })
            if (flag) {
                availabilityExclusions.push({start: avaExcl.start, end: avaExcl.end})
            } else {
                //update from here
                try {
                    await updateAvailailityExclustion({
                        variables: {
                            availabilityExclusion: {start: avaExcl.start, end: avaExcl.end},
                            availabilityExclusionId: avaExcl.id
                        }
                    })

                } catch (error) {

                    message.error(error.message);
                    toast.error('Error while updating availability exclusions.');
                }
            }
        })

        try {
            const {data} = await addAvailability({
                variables: {
                    userId: user?.id,
                    availabilities: availabilities,
                    availabilityExclusions: availabilityExclusions
                }
            })
            toast.success('Added.');

        } catch (error) {
            console.log("error", error)
            toast.error('Error.');
        } finally {
            refetch()
        }
    }

    const onTimeChange = (time, day) => {
        let start = ''
        let end = ''
        if (time) {
            start = moment(time[0]).unix().toString()
            end = moment(time[1]).unix().toString()
        }

        let temp = []
        available.map(ava => {
            if (ava.day === day.day) {
                temp.push({id: ava.id, name: day.name, day: day.day, start: start, end: end})
            } else {
                temp.push(ava)
            }
        })
        setAvailable(temp)

    }

    const AddAvailabilityExclusion = () => {
        const temp: availabilityExclusionInterface = {
            id: availableExclusion.length ? availableExclusion[availableExclusion.length - 1].id + 1 : 0,
            start: '',
            end: ''
        }

        let newAvaExclusion: availabilityExclusionInterface[] = [...availableExclusion]
        newAvaExclusion.push(temp)
        setAvailableExclusion(newAvaExclusion)
    }

    const delAvaExclusion = async (id) => {
        const find = availability?.getUserAvailability?.availabilityExclusions?.find(ava => ava.id === id)
        if (find) {
            try {
                await deleteAvailailityExclustion({
                    variables: {
                        availabilityExclusionId: id
                    }
                })

                toast.success('Successfully deleted.');

            } catch (error) {
                message.error(error.message);
                toast.error('Unable to delete record.');
            } finally {
                refetch()
            }
        } else {
            refetch()
        }
    }

    return (
        <>
            <Col xs={23} sm={23} md={23} lg={18} xxl={18} xl={18} id={"AccountDesktopCollapse"}>
                <Collapse
                    defaultActiveKey={["1"]}
                    expandIcon={({isActive}) =>
                        isActive ? (
                            <MinusCircleOutlined className="icon-specs"/>
                        ) : (
                            <PlusCircleOutlined className="icon-specs"/>
                        )
                    }
                    expandIconPosition="right"
                >
                    <Panel header="Availability" key="0" className="collapse-background">
                        <Row className="row-height"/>
                        <Row className={"flex"}>
                            <Col span={2}/>
                            <Col span={20}>
                                <Row>
                                    <Col span={24}>
                                        <Row>
                                            <Col xs={14} sm={14} md={7} lg={7} xxl={7} xl={7}>
                                                <Title className="collapse-background" style={{color: '#E26F6F'}}>Set
                                                    Availability </Title>
                                                <Row className="row-height"/>
                                            </Col>
                                            <Col span={2}>
                                                <Tooltip title={availabilityText}>
                                                    <InfoCircleOutlined style={{color: '#E26F6F'}}/>
                                                </Tooltip>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xs={0} sm={0} md={0} lg={9} xxl={9} xl={9}>
                                        {available.map(day => (
                                            <Title className="days-name">{day.name}</Title>
                                        ))}

                                    </Col>
                                    <Col xs={0} sm={0} md={0} lg={3} xxl={3} xl={3}/>
                                    <Col xs={0} sm={0} md={0} lg={9} xxl={9} xl={9}>
                                        {available.map(day => (
                                            <TimePicker.RangePicker
                                                size={'large'}
                                                className="select-width"
                                                value={[day.start ? moment(moment.unix(Number(day.start)), 'HH:mm:ss') : null, day.end ? moment(moment.unix(Number(day.end)), 'HH:mm:ss') : null]}
                                                onChange={(time) => onTimeChange(time, day)}
                                            />
                                        ))}

                                    </Col>

                                    {/*    For mobile availability  */}
                                    {available.map(day => (
                                        <>
                                            <Col xs={24} sm={24} md={24} lg={0} xxl={0} xl={0}>
                                                <Title className="days-name">{day.name}</Title>
                                                <TimePicker.RangePicker
                                                    size={'large'}
                                                    className="select-width"
                                                    value={[day.start ? moment(moment.unix(Number(day.start)), 'HH:mm:ss') : null, day.end ? moment(moment.unix(Number(day.end)), 'HH:mm:ss') : null]}
                                                    onChange={(time) => onTimeChange(time, day)}
                                                />
                                            </Col>
                                        </>
                                    ))}

                                </Row>
                                <Row className="row-height"/>
                                <Row className="row-height"/>
                                <Row>

                                    <Col xs={24} sm={24} md={24} lg={17} xxl={17} xl={17}>
                                        <Row>
                                            <Col xs={22} sm={22} md={22} lg={14} xxl={14} xl={14}>
                                                <Title className="collapse-background"
                                                       style={{color: '#E26F6F'}}>
                                                    Set Availability Exclusion</Title>
                                                <Row className="row-height"/>
                                            </Col>
                                            <Col span={2}>
                                                <Tooltip title={availabilityExclusionText}>
                                                    <InfoCircleOutlined style={{color: '#E26F6F'}}/>
                                                </Tooltip>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xs={24} sm={24} md={24} lg={4} xxl={4} xl={4} className="textAlign">
                                        <Button type="primary" onClick={AddAvailabilityExclusion} size="large" block>
                                                              <span className="upload-pic-text">
                                                                    Add Exclusion +
                                                              </span>
                                        </Button>
                                        <Row className="row-height"/>
                                    </Col>

                                    <Col span={24}>
                                        {availableExclusion?.map(avaExclu => (
                                            <>
                                                <AvailabilityForm
                                                    avaExclu={avaExclu}
                                                    setAvaExcl={setAvailableExclusion}
                                                    availabilityExclusion={availableExclusion}
                                                    delAvaExclusion={delAvaExclusion}
                                                />
                                                <Row className="row-height"/>
                                            </>
                                        ))}

                                    </Col>
                                </Row>


                            </Col>
                            <Col span={2}/>
                        </Row>
                        <Row className="row-height"/>
                        <Row>
                            <Col xs={2} sm={2} md={2} lg={16} xxl={16} xl={16}/>
                            <Col xs={20} sm={20} md={20} lg={4} xxl={4} xl={4} className="textAlign">
                                <Button onClick={saveAvailability} type="primary" size="large" block>
                                          <span className="upload-pic-text">
                                            Save Availability
                                          </span>
                                </Button>
                            </Col>
                        </Row>
                        <Row className="row-height"/>
                    </Panel>
                </Collapse>
            </Col>
        </>
    )
}

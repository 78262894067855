import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Typography, Row, Col, Button, Checkbox, Input, Slider, Select, Popover} from 'antd';
import {DownOutlined, CaretDownFilled} from '@ant-design/icons';
import './Mobile.less';
import styled from '@emotion/styled';
import {useHistory} from "react-router-dom";
import Tutors from './Tutors';
import {Pagination} from 'antd';
import {useDebouncedCallback} from "use-debounce";
import {tagRender} from "../components/MultipleSelectTagRender";

const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
`;


const {Option} = Select;
const {Text} = Typography;

export default ({
                    location,
                    searchLoading,
                    tutorsList,
                    days,
                    loading,
                    schools,
                    subjects,
                    page,
                    setPage,
                    meta,
                    searchFilters,
                    setSearchFilters
                }: any) => {
    const history = useHistory();
    const [isOpen, setOpen] = useState(false);
    const [state, setState] = useState<{ name: string }>({name: ''});
    const [address, setAddress] = useState<any>();
    const [addressQuery, setAddressQuery] = useState(address?.googlePlace?.formatted_address);
    const [results, setResults]: any = useState([]);
    const [single, setSingle]: any = useState(null);
    const [hoverPlaceId, setHoverPlaceId] = useState(null);

    const handleChange = (event: any) => {
        setState(s => ({...s, [event.target.name]: event.target.value}))
        event.persist()
    };

    const PLACE_CACHE: any = {};

    const autocompleteService = useMemo(
        () => new window.google.maps.places.AutocompleteService(),
        [],
    );

    const placesService = useMemo(
        () =>
            new window.google.maps.places.PlacesService(
                document.createElement('div'),
            ),
        [],
    );

    const [lookup] = useDebouncedCallback(
        (addressQuery: any) => autocompleteService.getPlacePredictions(addressQuery, setResults),
        666,
    );

    useEffect(() => {
        lookup({
            input: addressQuery,
            componentRestrictions: {
                country: address?.country?.toLowerCase(),
            },
        });
        return () => {
        };
    }, [addressQuery, address, lookup]);

    const getPlace: any = useCallback(
        (placeId: any) =>
            new Promise((resolve) => {
                if (PLACE_CACHE[placeId]) {
                    resolve(PLACE_CACHE[placeId]);
                }
                placesService.getDetails({placeId}, (place: any) => {
                    PLACE_CACHE[placeId] = place;
                    resolve(place);
                });
            }),
        [placesService],
    );

    useEffect(() => {
        if (results?.length === 1) {
            let [result] = results;
            const hit = PLACE_CACHE[result.place_id];
            if (hit) {
                setSingle(hit);
            } else {
                getPlace(result.place_id).then(setSingle);
            }
        } else {
            setSingle(null);
        }
        return () => {
        };
    }, [results, getPlace]);

    useEffect(() => {
        if (address == "") {
            setSearchFilters({
                ...searchFilters,
                customLocation: {
                    longitude: null,
                    latitude: null
                }
            })
        }
    }, [address])

    const buildResults = () => (
        <div
            style={{
                position: "absolute",
                left: "33%",
                top:"75%",
                zIndex: 10,
                backgroundColor: "white",
                border: "1px black solid",
                fontSize: '8px',
                fontFamily: 'Crimson Pro'
            }}
            onMouseLeave={() => {
                setHoverPlaceId(null);
            }}
        >
            {results.map((result: any) => (
                <div
                    style={{
                        border:
                            result.place_id === hoverPlaceId ? `1px solid green` : 'none',
                        fontSize: '12px',
                        fontFamily: 'Crimson Pro'
                    }}
                    onMouseOver={() => {
                        setHoverPlaceId(result.place_id);
                    }}
                    onClick={async () => {
                        setAddressQuery(null);
                        setAddress(result.description);
                        setResults([]);
                        let place = await getPlace(result.place_id);
                        setSearchFilters({
                            ...searchFilters, customLocation: {
                                longitude: place.geometry.location.lng(),
                                latitude: place.geometry.location.lat()
                            }
                        })
                    }}
                >
                    {result.description}
                </div>
            ))}
        </div>
    );

    const content = (
        <Row>
            <Col span={14} className="filter-main-box-popover no-margin">
                <div>
                    <div className="hourly-rate-font-mobile">
                        Hourly rate: <span className="hourly-rate-font">$10 - $100+</span>
                    </div>
                    <Slider className="slider-line-color-mobile" range defaultValue={[20, 50]}
                            onChange={(value) => {
                                setPage(1)
                                setSearchFilters({...searchFilters, hourlyRange: [value[0], value[1]]})
                            }}
                    />
                </div>
                <Row className="availability-box">
                    <div className=" availability-font">Availability</div>
                    <Row>
                        <Checkbox.Group
                            options={days}
                            className="no-margin full-width "
                            onChange={(value) => {
                                setPage(1)
                                setSearchFilters({...searchFilters, availability: value})
                            }}
                        />
                    </Row>
                </Row>
            </Col>
            <Col span={24}>
                <Row>
                    <div className="preferences-popover margin-top-8">
                        <div className="prefs-font">Gender</div>
                        <Select
                            allowClear
                            showSearch
                            className="border-width heading-text"
                            defaultValue=" No Preferences"
                            options={[{label: 'Male', value: 'MALE'}, {
                                label: 'Female',
                                value: 'FEMALE'
                            }, {label: 'Other', value: 'OTHER'}]}
                            suffixIcon={<CaretDownFilled/>}
                            onChange={(value, id) => {
                                setPage(1)
                                setSearchFilters({...searchFilters, gender: value})
                            }}
                        />

                    </div>
                    <div className="preferences-popover margin-top-8">
                        <div className="prefs-font">School</div>
                        <Select className="border-width heading-text"
                                allowClear
                                showSearch
                                defaultValue=" No Preferences"
                                suffixIcon={<CaretDownFilled/>}
                                options={schools}
                                onChange={(value) => {
                                    setSearchFilters({...searchFilters, school: value})
                                }}
                        />
                    </div>

                    <div className="preferences-popover margin-top-8">
                        <div className="prefs-font">Subjects</div>
                        <Select className="border-width heading-text"
                                allowClear
                                showSearch
                                options={subjects}
                                mode="multiple"
                                tagRender={tagRender}
                                suffixIcon={<CaretDownFilled/>}
                                onChange={(name, obj) => {
                                    setPage(1);
                                    const skillIds = [];
                                    for (let skill of obj as any) {
                                        skillIds.push(skill.value);
                                    }
                                    setSearchFilters({
                                        ...searchFilters,
                                        skills: skillIds
                                    })
                                }}
                        />
                    </div>

                    <div className="preferences-popover margin-top-8">
                        <div className="prefs-font">Suburbs</div>
                        <Input
                               // size="large"
                               value={address}
                               onChange={(e: any) => {
                                   setAddressQuery(e.target.value);
                                   setAddress(e.target.value);
                                   setResults([]);
                               }}
                               onBlur={() => {
                                   if (single) {
                                       setAddressQuery(single.formatted_address);
                                   }
                               }}
                               placeholder="Search"
                        />
                        {results?.length > 1 ? (
                            buildResults()
                        ) : null}

                    </div>
                </Row>
                <Col span={24} className="padding-top-71">
                    <Button type="primary" onClick={() => {
                        setOpen(false)
                    }} className="btn-custom-popover">Back</Button>
                </Col>
            </Col>
        </Row>

    );

    return (
        <>
            <Row id='ExploreMobile'>
                <Col span={24}>
                    <Row className="subject-row-mobile">

                        <div className="color-mobile">
                            <Row>
                                <Col span={18}>
                                    <Input
                                        prefix={<img src="/icons/explore/search-icon.svg"/>}
                                        className="no-margin search-bar-mobile"
                                        name='name'
                                        placeholder='Search Name'
                                        onChange={handleChange}
                                    />
                                </Col>
                                <Col span={6}>
                                    <Button className="search-btn" onClick={() => {
                                        history.push({pathname: ``, search: `?name=${state.name}`})
                                    }
                                    }>
                                        <span className="search-btn-text">Search</span>
                                    </Button>
                                </Col>
                            </Row>
                        </div>

                    </Row>
                    <Col span={24} className="tutor-column-mobile">
                        <Row>
                            <Col span={11}>
                                <Button type="link" className="btn-link"
                                        onClick={(e) => {
                                            history.push(`/explore?lesson=ONLINE`)
                                        }}>Online</Button>
                            </Col>
                            <Col span={11}>
                                <Button type="link" className="btn-link"
                                        onClick={(e) => {
                                            history.push(`/explore?lesson=IN_PERSON`)
                                        }}
                                >In-person</Button>
                            </Col>
                        </Row>
                        <Row className="location-main-box-mobile">
                            <Col span={1}/>
                            <Col span={22}>

                                <div className="match-outer-box-mobile">
                                    <div>
                                        <Popover placement="topLeft" visible={isOpen} onVisibleChange={() => {
                                            setOpen(!isOpen)
                                        }} overlayClassName={"popover-filter"} content={content}
                                                 title="Filters" trigger="click">
                                            <Button type="primary" className="btn-custom"

                                                    icon={<img src="/icons/explore/filter-icon.svg"
                                                               className="filters-icon-align"/>}
                                                    onClick={() => setOpen(!isOpen)}><Text
                                                className="filters-font">Filters</Text></Button>
                                            {isOpen && (
                                                <>
                                                    <ModalContainer>
                                                    </ModalContainer>
                                                </>
                                            )}
                                        </Popover>
                                    </div>
                                    <div>
                                        <Select className="select" defaultValue=" Best match"
                                                suffixIcon={<DownOutlined/>}>
                                            <Option value="Rate">Rate</Option>
                                        </Select>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={24}>
                                <Pagination size={"small"} current={page} pageSize={15} className={"pagination-styles"}
                                            total={meta?.total}
                                            onChange={(page) => {
                                                setPage(page)
                                            }}/>
                            </Col>
                        </Row>

                        <Row className="tutor-main-container">
                            <Col span={24}>
                                <Tutors
                                    {...{location}}
                                    loading={loading || searchLoading}
                                    tutors={tutorsList}
                                />
                            </Col>


                        </Row>

                    </Col>
                </Col>

            </Row>
        </>
    )
};

/** @jsxRuntime classic */
/** @jsx jsx */
import {css, jsx} from '@emotion/core';
import moment from 'moment';
import {CheckCircleTwoTone} from '@ant-design/icons'
import styled from '@emotion/styled';
import MessageButton from '../Messenger/MessageButton';
import Tag from '../Tag';
import React from "react";
import {Avatar as AntAvatar, Row, Col, Button, Typography} from 'antd'

const {Text} = Typography
const Tags = ({children}: any) => (
    <div style={{display: 'flex', flexWrap: 'wrap', width: '100%'}}>
        {children}
    </div>
);

const Avatar = styled(AntAvatar)`
  background: hsl(0, 66%, 66%);
`

const StyledVerifiedContainer = styled.div`
  display: flex;
  p {
    margin: 0px 2px;
    margin-bottom: 0px;
    display: inline-block;
  }
  svg {
    font-size: 22px;
  };
`

export const Sidebar = ({tutor, me, request = true}: any) => {

    return (
        <div css={css`
        width: 100%;
        border-radius: 6px;
        // background: hsla(0, 0%, 0%, 0.125);
        padding: 0.5em;
        & > * {
          margin: 0.5rem 0;
        }
      `}

        >
            <Row className={'side-bar-center'}>
                <Avatar size={{xs: 70, sm: 70, md: 70, lg: 122, xl: 122, xxl: 122}} src={tutor?.avatar?.url}>
                    {tutor?.name.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}
                </Avatar>
                <Col xs={16} sm={16} md={16} lg={0} xl={0} xxl={0}
                     className="padding-tutor-name">
                    <Row className="flex" style={{alignItems: "center"}}>
                        <Text className="tutor-name">{tutor?.name}</Text>
                        <img
                            className="tick-mark"
                            src="/icons/tutor-profile/tickmark.svg"
                            alt="tick icon"
                        />
                    </Row>
                    {tutor?.city && tutor?.city &&
                    <Row>
                        <Col className="flex ">
                            <div><img src="/icons/tutor-profile/location-black-icon.svg"/></div>
                            <Text className="location-text">  {tutor?.city}, {tutor?.country}</Text>
                        </Col>
                    </Row>
                    }
                    <Row className="flex margin-top-10">
                        <Col className="flex padding-top-5"
                             style={{justifyContent: "center", alignItems: "center"}}>
                            {tutor?.badges?.length ? (
                                <div>
                                    <img alt="badge icon"
                                         style={{marginLeft: '-15px'}}
                                         src={`/${tutor?.badges[0]?.iconName}`}
                                         width={50}
                                         height={50}/>
                                    <Text className="no-margin top-rated"
                                          style={{fontSize: 18, fontWeight: 700}}>
                                        {tutor?.badges[0]?.badgeName.toUpperCase()}
                                    </Text>
                                </div>
                            ) : null}

                        </Col>
                    </Row>
                </Col>
                <Col xs={10} sm={10} md={10} lg={0} xl={0} xxl={0}/>

                {/*<Row className={'center-align'}>*/}
                {/*    <Col xs={0} sm={0} md={0} lg={24} xl={24} xxl={24}>*/}
                {/*        <Row className="margin-top-10"/>*/}
                {/*        <span className="date" style={{opacity: 0.5}}>*/}
                {/*            Joined {moment(tutor?.createdAt).format('MMM YYYY')}*/}
                {/*        </span>*/}
                {/*    </Col>*/}
                {/*    <Col xs={0} sm={0} md={0} lg={22} xl={22} xxl={22}>*/}
                {/*        <Row className="margin-top-10"/>*/}
                {/*        {tutor?.qualified && <StyledVerifiedContainer>*/}
                {/*            <CheckCircleTwoTone twoToneColor='#E26F6F'/>*/}
                {/*            <p>Verified Marks</p>*/}
                {/*        </StyledVerifiedContainer>}*/}
                {/*    </Col>*/}
                <Row className="margin-top-10"/>
                    {/*<Col xs={0} sm={0} md={0} lg={24} xl={24} xxl={24}>*/}
                    {/*    {tutor?.wwcc && <StyledVerifiedContainer>*/}
                    {/*        <CheckCircleTwoTone twoToneColor='#E26F6F'/>*/}
                    {/*        <p>Verified WWCC</p>*/}
                    {/*    </StyledVerifiedContainer>}*/}
                    {/*</Col>*/}
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <MessageButton {...{me, tutor}}>
                            {(props: any) => (
                                <Button  className="message-request-btn" size="large" block {...props}>
                          <span className="message-request-btn-text">
                            Message {tutor && tutor.name && tutor.name.split(' ')[0]}
                          </span>
                                </Button>
                            )}
                        </MessageButton>
                    </Col>

                {/*</Row>*/}
                <Row className="margin-top-10"/>
                <Row className="row-height"/>
                <Row>
                    <Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1}/>
                    <Col xs={0} sm={0} md={0} lg={24} xl={24} xxl={24}>
                        <Tags>
                            {(tutor?.skills || []).map(({name}: any, index: number) => (
                                <Tag  key={index} saturation={0}>{name}</Tag>
                            ))}
                        </Tags>
                    </Col>
                </Row>
                {/*<Row>*/}
                    {/*<Col xs={0} sm={0} md={0} lg={24} xl={24} xxl={24}>*/}
                    {/*    {tutor?.school && <p>Attended {tutor.school.name}</p>}*/}
                    {/*</Col>*/}
                {/*</Row>*/}


            </Row>
        </div>


    );
};

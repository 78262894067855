import gql from 'graphql-tag';
import React from 'react';
import {useMutation} from '@apollo/client';
import {Button} from '../components';
import {useHistory} from 'react-router-dom';


export default ({readOnly, me, enrollment: {id, student, approved, termsAccepted, parent}}: any) => {
  const history = useHistory();
  const buttonProps = {positive: approved, negative: !approved};
  const [updateEnrollment] = useMutation(gql`
    mutation UpdateEnrollment($id: ID!, $input: EnrollmentInput!) {
      updateEnrollment(id: $id, input: $input) {
        id
        approved
      }
    }
  `);

  const [upsertConversation] = useMutation(gql`
    mutation UpsertConversation($me: ID!, $them: ID!) {
      upsertConversation(me: $me, them: $them) {
        id
      }
    }
  `);

  return (
    <>
      <Button
        {...buttonProps}
        onClick={async () => {
          const result = await upsertConversation({variables: {me: me?.id, them: parent?.id}});
          history.push(`/conversations/${result.data.upsertConversation.id}`);
        }}
      >
        {student.name}
      </Button>
    </>
  );
};

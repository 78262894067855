import React from 'react';
import styled from '@emotion/styled';

// Components
import Skills from './Skills';
import NameInput from './NameInput';

const Styles = styled.div`
  .Skill {
    display: block;
  }
`;
export default ({filtering}) => {
    return (
        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <div style={{flex: 1}}>
                    <NameInput filtering={filtering} />
                </div>
            </div>
            <Styles>
                {filtering ? <Skills style={{flexDirection: 'column'}}/> : null}
            </Styles>
        </div>
    )
};

import React from "react";
import {BlogPost} from "./BlogLanding";
import moment from "moment/moment";
import {Link} from "react-router-dom";
import {Button} from "antd";
import "./BlogStyles.less"
import CategoryPill from "./CategoryPill";

export default (props: BlogPost) => {
    const {featured_image, title, modified, excerpt, category, slug} = props;
    return <div className={"blog-list-component"}>
        <CategoryPill text={category} selected={true} onClick={() => {}}/>
        <img className={"w-full object-cover h-48 rounded"} src={featured_image}/>
        <div className={"text-2xl crimson-font font-bold heading-text mt-2 blog-list-component-title"}>{title}</div>
        <div className={"mt-4 secondary-text-dmsans blog-list-component-excerpt"}>{getShortExcerpt(excerpt)}</div>
        <div className={"grid grid-cols-2"}>
            <div className={"mt-2"}>
                <Link  to={`/blog/${slug}`}>
                    <Button className={"landing-button landing-button-secondary"}>
                        Read More
                    </Button>
                </Link>
            </div>
            <div className={"text-xs crimson-font secondary-text-dmsans mt-2 self-center justify-self-end"}>{moment(modified).format("DD/MM/YYYY")}</div>
        </div>


    </div>

    function getShortExcerpt(excerpt) {
        try {
            const parser = new DOMParser();
            const htmlDoc = parser.parseFromString(excerpt, 'text/html');
            const text = htmlDoc.getElementsByTagName("body")[0].innerText.trim();

            if (text.length > 100) {
                return `${text.substring(0, 100)}...`
            }
            return text;
        } catch (e) {
            console.error(e);
            return "";
        }
    }
}

import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton, WhatsappIcon, WhatsappShareButton
} from "react-share";

const fillColor = "#E26F6F";
export default ({link}) => {
    return <div className={"flex flex-row mt-2 content-center"}>
        <div className={"text-lg crimson-font heading-text font-bold"}>Share This Blog</div>
        <TwitterShareButton url={link}>
            <TwitterIcon size={32} round={true} bgStyle={{fill: "white"}} iconFillColor={fillColor}/>
        </TwitterShareButton>

        <FacebookShareButton url={link}>
            <FacebookIcon size={32} round={true} bgStyle={{fill: "white"}} iconFillColor={fillColor}/>
        </FacebookShareButton>

        <LinkedinShareButton url={link}>
            <LinkedinIcon size={32} round={true} bgStyle={{fill: "white"}} iconFillColor={fillColor}/>
        </LinkedinShareButton>

        <WhatsappShareButton url={link}>
            <WhatsappIcon size={32} round={true} bgStyle={{fill: "white"}} iconFillColor={fillColor}/>
        </WhatsappShareButton>
    </div>
}

import {QRCodeSVG} from "qrcode.react";
import {Avatar as AntAvatar, Button, Typography} from "antd";
import React, {useRef, useState} from "react";
import styled from "@emotion/styled";
import {LANGUAGES} from "../../components/static";
import {hourlyServiceFee} from "../common/utils";
import {useReactToPrint} from 'react-to-print';


const {Text} = Typography;

const Avatar = styled(AntAvatar)`
  background: hsl(0, 66%, 66%);
`

export const TutorProfileQRCode = (props: any) => {

    const {tutorId, tutor} = props

    const componentRef = useRef();

    const userLanguages = []
    const Languages = LANGUAGES;

    Languages.map(lang => {
        if (tutor?.languages?.includes(lang.value)) {
            userLanguages.push(lang.value)
        }
    })

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,

    });

    return (
        <>
            <>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "5px"
                    }}>
                    <QRCodeSVG value={`https://pocketnote.com.au/tutors/` + tutorId}/>
                </div>
                <Button className="delete-btn" size="large" block onClick={() => {
                    handlePrint()
                }}>
                  <span className="delete-btn-text">
                    Share
                  </span>
                </Button>
            </>

            <div style={{display: "none"}}>
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "auto",
                    }}
                    ref={componentRef}
                >
                    <QRCodeSVG value={`https://pocketnote.com.au/tutors/` + tutorId}/>
                    {/*<p style={{*/}
                    {/*    fontSize: 18,*/}
                    {/*    maxWidth: 400,*/}
                    {/*    textAlign: "center"*/}
                    {/*}}>Hello my name is {tutor?.name}, scan the Code and find me at Pocketnote.com.au</p>*/}
                </div>
            </div>


            {/*<div style={{*/}
            {/*    width: "100%",*/}
            {/*    display: "flex" ,*/}
            {/*    justifyContent: "center",*/}
            {/*    marginBottom: "5px",*/}
            {/*}}*/}
            {/*     // id={"printable-div"}*/}
            {/*     ref={componentRef}*/}
            {/*>*/}

            {/*    <Col id="TutorProfileDesktop">*/}
            {/*        <Row className="row-height"/>*/}

            {/*        <Col span={24} className="three-sided-border">*/}
            {/*            <Row>*/}
            {/*                <Col xs={1} sm={1} md={1} lg={2} xl={2} xxl={2}/>*/}
            {/*                <Col xs={23} sm={23} md={23} lg={22} xl={22} xxl={22} className="margin-top-11">*/}
            {/*                    <Row>*/}
            {/*                        <Avatar size={{xs: 70, sm: 70, md: 70, lg: 122, xl: 122, xxl: 122}}*/}
            {/*                                src={tutor?.avatar?.url}>*/}
            {/*                            {tutor?.name?.match(/(\b\S)?/g)?.join("")?.match(/(^\S|\S$)?/g)?.join("")?.toUpperCase()}*/}
            {/*                        </Avatar>*/}
            {/*                        <Col xs={16} sm={16} md={16} lg={10} xl={10} xxl={10}*/}
            {/*                             className="padding-tutor-name">*/}
            {/*                            <Row className="flex" style={{alignItems: "center"}}>*/}
            {/*                                <Text className="tutor-name">{tutor?.name}</Text>*/}
            {/*                                <img*/}
            {/*                                    className="tick-mark"*/}
            {/*                                    src="/icons/tutor-profile/tickmark.svg"*/}
            {/*                                    alt="tick icon"*/}
            {/*                                />*/}
            {/*                            </Row>*/}
            {/*                            <Row className="flex margin-top-10">*/}
            {/*                                <Col className="flex padding-top-5"*/}
            {/*                                     style={{justifyContent: "center", alignItems: "center"}}>*/}
            {/*                                    {tutor?.badges?.length ? (*/}
            {/*                                        <>*/}
            {/*                                            <img alt="badge icon"*/}
            {/*                                                 style={{marginLeft: '-15px'}}*/}
            {/*                                                 src={`/${tutor?.badges[0]?.iconName}`}*/}
            {/*                                                 width={50}*/}
            {/*                                                 height={50}/>*/}
            {/*                                            <Text className="no-margin top-rated"*/}
            {/*                                                  style={{fontSize: 18, fontWeight: 700}}>*/}
            {/*                                                {tutor?.badges[0]?.badgeName.toUpperCase()}*/}
            {/*                                            </Text>*/}
            {/*                                        </>*/}
            {/*                                    ) : null}*/}
            {/*                                </Col>*/}
            {/*                            </Row>*/}

            {/*                            <Row>*/}
            {/*                                {tutor?.biography &&*/}
            {/*                                <Text style={{whiteSpace: "pre-line"}}>*/}
            {/*                                    <ReadMoreReact*/}
            {/*                                        text={tutor?.biography.replace('<br/>', '\n')}*/}
            {/*                                        min={200}*/}
            {/*                                        ideal={300} max={500}/>*/}
            {/*                                </Text>*/}
            {/*                                }*/}

            {/*                            </Row>*/}
            {/*                        </Col>*/}

            {/*                        <Col xs={0} sm={0} md={0} lg={4} xl={4} xxl={4}/>*/}

            {/*                    </Row>*/}
            {/*                </Col>*/}
            {/*            </Row>*/}
            {/*            <Divider type="horizontal"/>*/}

            {/*            <Row className="flex">*/}
            {/*                <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}/>*/}
            {/*                <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>*/}
            {/*                    <Row>*/}
            {/*                        <Text className="view-profile">Scan To Book Me Now</Text>*/}
            {/*                    </Row>*/}
            {/*                    <Row className="margin-top-30">*/}
            {/*                        <QRCodeSVG value={`https://pocketnote.com.au/tutors/` + tutorId}/>*/}
            {/*                    </Row>*/}
            {/*                </Col>*/}
            {/*                <Col xs={14} sm={14} md={14} lg={14} xl={14} xxl={14} className="vertical-border ">*/}
            {/*                    <div className="padding-left-26">*/}
            {/*                        <Row>*/}
            {/*                            <Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1}/>*/}
            {/*                            <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}*/}
            {/*                                 className="flex qualification-adjustment rate-adjustment ">*/}
            {/*                                <Col>*/}
            {/*                                    <Row>*/}
            {/*                                        <Text className="head-font">${tutor?.rate}.00/hr</Text>*/}
            {/*                                    </Row>*/}
            {/*                                    <Row>*/}
            {/*                                        <Text className="service-fee-text">+Pocketnote*/}
            {/*                                            Fee ${pocketnoteFee} </Text>*/}
            {/*                                    </Row>*/}
            {/*                                </Col>*/}

            {/*                            </Col>*/}
            {/*                        </Row>*/}
            {/*                        <Row>*/}
            {/*                            <Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1}/>*/}
            {/*                            <Col xs={22} sm={22} md={22} lg={22} xl={22} xxl={22}*/}
            {/*                                 className="flex qualification-adjustment rate-adjustment ">*/}
            {/*                                <Col>*/}
            {/*                                    <div className="flex padding-top-4px">*/}
            {/*                                        <img src="/icons/explore/laptop-icon.svg"*/}
            {/*                                             className="laptop-align-3"/>*/}
            {/*                                        <Text className="offers-font">  Offers Sessions: <span*/}
            {/*                                            className="online-font">{getLessonTitleToDisplay(tutor?.lessonTitle)}</span></Text>*/}
            {/*                                    </div>*/}
            {/*                                </Col>*/}

            {/*                            </Col>*/}
            {/*                        </Row>*/}
            {/*                    </div>*/}

            {/*                    <Divider type="horizontal"/>*/}

            {/*                    <Row>*/}
            {/*                        <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}/>*/}
            {/*                        <Col xs={22} sm={22} md={22} lg={22} xl={22} xxl={22}*/}
            {/*                             className="flex qualification-adjustment rate-adjustment ">*/}
            {/*                            <Col>*/}
            {/*                                <Row>*/}
            {/*                                    <Text className="view-profile">Subjects </Text>*/}
            {/*                                </Row>*/}
            {/*                                <Row className="row-height"/>*/}
            {/*                                <Row>*/}
            {/*                                    {tutor?.skills?.map(sub => (*/}
            {/*                                        <Tag style={{fontSize: '15px'}}>{sub.name}</Tag>*/}
            {/*                                    ))}*/}
            {/*                                </Row>*/}
            {/*                                <Row className="row-height"/>*/}
            {/*                            </Col>*/}
            {/*                            <Row className="row-height"/>*/}
            {/*                        </Col>*/}
            {/*                        <Row className="row-height"/>*/}
            {/*                    </Row>*/}

            {/*                </Col>*/}
            {/*            </Row>*/}
            {/*        </Col>*/}
            {/*        <Row className="row-height"/>*/}
            {/*    </Col>*/}
            {/*</div>*/}

        </>
    )
}

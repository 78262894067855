import {ButtonProps, getSubheadingWithLineBreak} from "../../pages/Constants/utilities";
import {Link} from "react-router-dom";
import {Button} from "antd";
import React from "react";
import BenefitsPnPanelComponent from "./BenefitsPnPanelComponent";

export type PanelProps = {
    imgSrc: string;
    text: string;
}

type BenefitsPanelProps = {
    heading: string;
    primaryButton?: ButtonProps;
    panel: PanelProps[];
    imgSrc: string;
}

export default (props: BenefitsPanelProps) => {
    const {heading, panel, primaryButton, imgSrc} = props;

    return <div className={"md:grid md:grid-cols-2 md:px-20 gap-4 md:my-6 my-6 py-4 place-items-center px-4"}>

        <div className={"col-span-1"}>
            <div className="innovation-text-landing text-4xl my-3">{heading}</div>
            {primaryButton != null ?
                <Link to={primaryButton.to()}>
                    <Button type="primary" className={"landing-button landing-button-primary"}>
                        {primaryButton.text}
                    </Button></Link> : null}
            <img className={"max-h-96 mt-6 mx-auto md:mx-0"} src={imgSrc}/>
        </div>
        <div className={"col-span-1"}>
            <div className={"md:grid md:grid-cols-1 md:gap-4"}>
                {panel.map(p => <BenefitsPnPanelComponent imgSrc={p.imgSrc} text={p.text}/>)}
            </div>
        </div>



    </div>
}

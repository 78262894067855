import React from 'react';
import { Row, Col } from 'antd';
import { Typography } from 'antd';
import { Button } from 'antd';
import { Input } from 'antd';
import { Field } from 'formik';
import './Mobile.less';
const { Title, Paragraph } = Typography;

export default ({ recoveryText, buttonText, spamText, props }: any) => {

  return (
    <>
      <Row className='recover-container'>
        <Col className='align-center' span={24}>
          <Title className='primary-text' level={2}> RESET PASSWORD </Title>
        </Col>

        <Col className='align-center margin-top-24' span={24}>
          <Paragraph> {recoveryText} </Paragraph>
        </Col>

        <Col className='margin-top-24' span={24}>
          <Field
            as={Input}
            type="email"
            name="email"
            required
            autoComplete="email"
          />
        </Col>

        <Col className='margin-top-12' span={24}>
          <Button htmlType='submit' type="primary" className='btn-primary full-width' size='large'>
            {buttonText}
          </Button>
        </Col>
        {props.status && (<Col className='align-center margin-top-8' span={24}>
          <Paragraph> {spamText} </Paragraph>
        </Col>
        )}
      </Row>
    </>
  )
}

import React from 'react'
import {Col, Collapse, Divider, Row, Typography} from "antd";
import {MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";

const {Panel} = Collapse;
const {Title, Paragraph} = Typography

export default ({EducationData}) => {
    return (
        <Row className="first-row margin-top-10">
            <Col span={24}>
                <Collapse
                    expandIcon={({isActive}) =>
                        isActive ? (
                            <MinusCircleOutlined className="icon-specs"/>
                        ) : (
                            <PlusCircleOutlined className="icon-specs"/>
                        )
                    }
                    expandIconPosition="right"
                >
                    <Panel header={"Education"} key="2" className="main-heading-collapase">
                        <Row>
                            <Col span={1}/>
                            <Col span={22}>
                                {EducationData?.map(section => (
                                    <>
                                        <Title level={5} className="main-heading-collapase">
                                            {section.instituteName}
                                        </Title>
                                        {section?.majorSubject &&
                                        <Paragraph className="para-collapse">
                                            Major Subject : {section.majorSubject}
                                        </Paragraph>
                                        }

                                        <Divider type="horizontal"/>
                                    </>
                                ))}
                            </Col>
                        </Row>
                    </Panel>
                </Collapse>
            </Col>
        </Row>
    )
}
import React from 'react';
import {Link} from 'react-router-dom';
import {Col, Divider, Row, Typography} from 'antd';
import {ProfileOutlined} from "@ant-design/icons";
import './Desktop.less'

const {Text, Paragraph} = Typography;

export default ({
                    id,
                    title,

                    sellPrice,
                    info,
                    tutor,
                    semester,
                    exam,
                    yearLevel,
                    numberOfPages,
                    soldCount,
                    subject
                }: any) => {
    return (

        <Row>

            <Col
                span={15}
                style={{

                    paddingTop: "3%",
                    paddingLeft: "5%",
                    paddingRight: "5%",
                    paddingBottom: "3%",


                    // border: "1px solid blue"
                }}
            >

                <Link to={`/notes/buy/${id}`}>
                    <Row>
                        <Paragraph className="notes-title">
                            {title}
                        </Paragraph>
                    </Row>

                    <Row>
                        <Paragraph style={{marginTop: "2%"}}>
                            {info?.length > 500 ? `${info?.substring(0, 500)}...`
                                : info}
                        </Paragraph>
                    </Row>
                </Link>

            </Col>

            <Col span={1} className="vertical-divider">
                <Divider type="vertical"/>
            </Col>

            <Col
                span={8}
                style={{

                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    paddingLeft: "3%",
                    marginTop: 15,

                    // border: "1px solid blue"
                }}

            >

                <Text className='sell-price-font'> ${sellPrice}</Text>

                {/*{soldCount > 0 && <div>*/}
                {/*    <Text>*/}
                {/*        <span className="sold-count">Sold {soldCount} times</span>*/}
                {/*    </Text>*/}
                {/*</div>}*/}

                {/*<Link to={`/tutors/${tutor?.id}`}>*/}
                {/*    <div className="padding-top-4px">*/}
                {/*        <img src="/icons/bottom-navigation/003-teacher-active.svg" className="icon-align-3"/>*/}
                {/*        <Text className="offer-font">*/}
                {/*            <span className="color-primary underline">*/}
                {/*                {tutor?.name?.split(" ")[1]}*/}
                {/*            </span>*/}

                {/*        </Text>*/}
                {/*    </div>*/}
                {/*</Link>*/}


                <div className="padding-top-4px">
                    <img src="/icons/explore/clock-icon.svg" className="icon-align-3"/>
                    <Text className="offer-font">{subject.name}, Semester {semester}, {exam}
                        {yearLevel ? `, Year ${yearLevel}`: ""}</Text>
                </div>

                <div className="padding-top-4px">
                    <ProfileOutlined/>
                    <Text className="offer-font">{numberOfPages} pages</Text>
                </div>


            </Col>

            <Divider type="horizontal"/>

        </Row>

    );
}

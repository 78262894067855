import React from 'react';
import { Col, Row } from 'antd';
import { Typography } from 'antd';
import './Question.less';
const { Paragraph } = Typography;

export default ({ question, answer }: any) => {
  return (
    <>
      <Row className="margin-14">
        <Col span={24}>
          <Paragraph className="starter">{question}</Paragraph>
        </Col>
        <Col span={22}>
          <>
            {
              Array.isArray(answer) ? answer.map(item => <Paragraph className="answer">{item}</Paragraph>) : <Paragraph className="answer">{answer}</Paragraph>
            }
          </>
        </Col>
      </Row>
    </>
  );
}

import React from 'react';
import { Modal, Button, Row, Col } from 'antd';
import {useHistory} from "react-router-dom";

export default ({ visible, onCancel, handleAddLocation }: any) => {
    const history = useHistory();

  const handleClick = () => {
    navigator.geolocation.getCurrentPosition(location => {
      handleAddLocation(location.coords.longitude, location.coords.latitude)
    });
  }

  return (
    <Modal destroyOnClose footer={false} visible={visible} onCancel={onCancel}>
      <p>
        Pocketnote requires location permission. Your current location will be requested and used to match you with the best tutors around you.
        Your data is secure with us and will not be shared with anyone for any purposes.
      </p>
      <Row>
        <Col span={12} style={{ textAlign: 'left' }}>
          <Button type='primary' onClick={handleClick}>Allow Location </Button>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Button onClick={onCancel}>Cancel</Button>
        </Col>
      </Row>
    </Modal>
  )
}

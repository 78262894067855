import BlogInput from "./BlogInput";
import {Button} from "antd";
import React, {useState} from "react";
import {useMutation} from "@apollo/client";
import gql from "graphql-tag";

const SubscribeNewsLetter = (): JSX.Element => {

    const [email, setEmail] = useState("");
    const [subscribed, setSubscribed] = useState(false);

    const [subscribe] = useMutation(gql`
        mutation Subscribe($subscribeInput: SubscribeInput!) {
            subscribe(subscribeInput: $subscribeInput)
        }
    `);

    async function sub() {
        await subscribe({
            variables: {
                subscribeInput: {
                    email,
                    recaptcha: ""
                }
            }
        })
        setSubscribed(true);
    }
    return (
        <div className={"flex flex-column w-full"}>
            {subscribed && <div className="crimson-font red-highlight text-xl w-full">
                Subscribed!
            </div>}
            {!subscribed && <>
                <div className="crimson-font red-highlight text-xl w-full">
                    Subscribe to our Newsletter!
                </div>
                <div className="secondary-text-dms ans heading-text mb-2">Be the first to know!</div>
                <BlogInput
                    placeholder={"Your Email"}
                    showIcon={false}
                    type={"email"}
                    onChange={(e) => {
                        const val = e.target.value;
                        console.log("email", val);
                        setEmail(val);
                    }}/>
                <Button type="primary" className="w-full my-2 rounded landing-button" onClick={sub}>
                    Subscribe
                </Button>
            </>}
        </div>
    );
};

export default SubscribeNewsLetter;

import React from 'react';

import Desktop from './Desktop';


export default (props:any) => {
  
  return (
    <>
        <Desktop props={props}/>
    </>
  );
};

import React from "react";
import {Route, Switch, Redirect} from "react-router-dom";
import {Row, Col, Typography} from "antd";
import {User} from "@graphql-client/types";
import Navigation from "./Navigation";
import {getCurrentUser, getCurrentUserProfile} from "pages/common/queries";
import {useQuery} from "@apollo/client";
import SettingsForm from "./SettingsForm";
import AccountSettings from "./AccountSettings/index";
import PasswordSettings from "./PasswordSettings/index ";
import slugify from "../slugify";
import Status from "./Status";
import PaymentSettings from "./PaymentSettings/index";
import {useGlobalLoading} from "hooks/useGlobalLoading";
import "./Desktop.less";
import "./index.less";
import BottomNavigation from '../../components/BottomNavigation'

let refetchQueries = [{query: getCurrentUserProfile}];

export default () => {

    const {loading: currentUserLoading, data: user} =
        useQuery<{ me: User }>(getCurrentUser);
    const {loading, data} = useQuery<{ me: User }>(getCurrentUserProfile);
    useGlobalLoading([loading, currentUserLoading]);

    return (
        <>
            <Col id="header-tutor" xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
                <Navigation user={user?.me}/>
            </Col>

            <Row id='MainRow'>
                <Col xs={0} sm={0} md={4} lg={4} xxl={4} xl={4}>
                    <Navigation user={user?.me}/>
                </Col>
                <Col xs={23} sm={23} md={23} lg={20} xxl={20} xl={20}>
                    <Switch>
                        {[
                            ...[
                                ["Security", <PasswordSettings/>],
                                // ["Payouts", <PayoutsSettings />],
                                [
                                    "Account",
                                    <AccountSettings/>,
                                    {injectSaveButton: false, accountDelete: true},
                                ],
                            ].map(([key, element, formProps = {}]: any) => [
                                key,
                                () => (
                                    <SettingsForm {...formProps}>
                                        {({values, ...props}: any) => {
                                            return React.cloneElement(element, {
                                                user: data?.me,
                                                refetchQueries,
                                                initialValues: data?.me || {},
                                                ...props,
                                                values: values || {},
                                            });
                                        }}
                                    </SettingsForm>
                                ),
                            ]),
                            ...[
                                ["Payment", PaymentSettings],
                                ["Status", Status],
                            ].map(([name, Component]) => [
                                name,
                                () => <Component key={slugify(name)}/>,
                            ]),
                        ].map(([name, constructor]: any) => (
                            <Route
                                key={slugify(name)}
                                exact
                                path={`/settings/${slugify(name)}`}
                                render={() => (
                                    <>
                                        {React.cloneElement(constructor(), {
                                            user: data?.me,
                                            refetchQueries,
                                            initialValues: data?.me,
                                        })}
                                    </>
                                )}
                            />
                        ))}
                        <Route
                            render={() => {
                                return <Redirect to="/settings/account"/>
                            }}
                        />
                    </Switch>
                </Col>
                <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
                    <BottomNavigation role={data?.me?.role}/>
                </Col>
            </Row>
        </>
    );
};

import gql from 'graphql-tag';

export const logout = gql`
  mutation logout {
    logout
  }
`;

export const login = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      user {
        id
        role
        name
        email
      }
      token
    }
  }
`;

export const forgotPassword = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

export const resetPassword = gql`
  mutation resetPassword($email: String!, $token: String!, $password: String!) {
    resetPassword(email: $email, token: $token, password: $password)
  }
`;

export const join = gql`
  mutation join(
    $name: String!
    $email: String!
    $tel: String!
    $password: String!
    $role: String!
  ) {
    join(role: $role, name: $name, email: $email, tel: $tel, password: $password) {
      user {
        id
        role
      }
      token
    }
  }
`;

export const updateUser = gql`
  mutation updateUser(
    $id: ID!
    $name: String
    $email: String
    $tel: String
    $avatar: ID
    $rate: Float
    $currency: String
    $password: String
    $confirmPassword: String
    $skills: [String!]
    $verificationDocumentFront: String
    $verificationDocumentBack: String
    # $verificationAdditionalDocumentFront: String
    # $verificationAdditionalDocumentBack: String
    $dob: String
    $bankRoutingNumber: String
    $bankAccountNumber: String
    $googlePlace: JSON
    $biography: String
    $achievements: String
    $experience: String
    $school: ID
    $availability: String
    $city:String
    $country:String
    $skypeId:String
    $gender:String
    $languages:[String]
    $title:String
    $lessonTitle:String
  ) {
    updateUser(
      id: $id
      dob: $dob
      bankRoutingNumber: $bankRoutingNumber
      bankAccountNumber: $bankAccountNumber
      name: $name
      email: $email
      tel: $tel
      avatar: $avatar
      rate: $rate
      currency: $currency
      password: $password
      confirmPassword: $confirmPassword
      skills: $skills
      verificationDocumentFront: $verificationDocumentFront
      verificationDocumentBack: $verificationDocumentBack
      # verificationAdditionalDocumentFront: $verificationAdditionalDocumentFront
      # verificationAdditionalDocumentBack: $verificationAdditionalDocumentBack
      googlePlace: $googlePlace
      biography: $biography
      achievements: $achievements
      experience: $experience
      school: $school
      availability: $availability
      city:$city
      country:$country
      skypeId:$skypeId
      gender:$gender
      languages:$languages
      title:$title
      lessonTitle:$lessonTitle
    ) {
      id
      name
      email
      tel
      rate
      experience
      biography
      availability
      dob
      bankRoutingNumber
      bankAccountNumber
      avatar {
        id
        url
      }
      skills {
        id
      }
      verificationDocumentFront
      verificationDocumentBack
      # verificationAdditionalDocumentFront
      # verificationAdditionalDocumentBack
      school {
        id
      }
    }
  }
`;

export const deleteUser = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;

export const deleteSkill = gql`
  mutation deleteSkill($id: ID!) {
    deleteSkill(id: $id)
  }
`;

export const deleteTutorNotes = gql`
  mutation deleteTutorNotes($id: ID!) {
    deleteTutorNotes(id: $id)
  }
`;

export const createSkill = gql`
  mutation createSkill($name: String!, $parent: ID) {
    createSkill(name: $name, parent: $parent) {
      id
    }
  }
`;

export const acceptMessageRequest = gql`
  mutation acceptMessageRequest($id: ID!) {
    acceptMessageRequest(id: $id) {
      id
    }
  }
`;

export const ignoreMessageRequest = gql`
  mutation ignoreMessageRequest($id: ID!) {
    ignoreMessageRequest(id: $id) {
      id
    }
  }
`;

export const sendMessage = gql`
  mutation sendMessage($conversation: ID!, $content: String!) {
    sendMessage(conversation: $conversation, content: $content) {
      id
    }
  }
`;

export const approveReview = gql`
  mutation approveReview($id: ID!) {
    approveReview(id: $id) {
      id
    }
  }
`;

export const createEvent = gql`
  mutation createEvent($input: CreateEventInput!) {
    createEvent(input: $input) {
      id
    }
  }
`;

export const joinEvent = gql`
  mutation joinEvent($id: ID!) {
    joinEvent(id: $id) {
      id
    }
  }
`;

export const createEnrollment = gql`
  mutation createEnrollment($input: EnrollmentInput!) {
    createEnrollment(input: $input) {
      id
    }
  }
`;

export const updatePaymentCardToken = gql`
  mutation updatePaymentCardToken($id: ID!, $token: String!) {
    updatePaymentCardToken(id: $id, token: $token) {
      id
    }
  }
`;

export const reviewTutor = gql`
  mutation reviewTutor($content: String!, $rating: Float!) {
    reviewTutor(content: $content, rating: $rating) {
      id
    }
  }
`;

export const readConversation = gql`
  mutation readConversation($conversation: ID!) {
    readConversation(id: $conversation) {
      id
    }
  }
`;

export const readNotifications = gql`
  mutation readNotifications($notifications: [ID!]!) {
    readNotifications(notifications: $notifications)
  }
`;

export const deleteSchool = gql`
  mutation deleteSchool($id: ID!) {
    deleteSchool(id: $id)
  }
`;

export const createSchool = gql`
  mutation createSchool($input: CreateSchoolInput!) {
    createSchool(input: $input) {
      id
      name
      slug
      logo {
        id
      }
    }
  }
`;

export const deleteStudent = gql`
  mutation deleteStudent($id: ID!) {
    deleteStudent(id: $id)
  }
`;

export const createStudent = gql`
  mutation createStudent($name: String!, $school: ID, $parent: ID!) {
    createStudent(name: $name, school: $school, parent: $parent) {
      id
      name
      email
    }
  }
`;

export const addSubjectMutation = gql`
    mutation addSubject($subjectName: String!) {
            addSubject(name: $subjectName) {
                id
            }
        }
`

export const addTutorNotesMutation = gql`
    mutation CreateTutorNotes($input: CreateTutorNotesInput!) {
            createTutorNotes(input: $input) {
                id
            }
        }
`

export const updateTutorNotesMutation = gql`
  mutation UpdateTutorNotes($id: ID!, $input: CreateTutorNotesInput!) {
    updateTutorNotes(id: $id, input: $input) {
      id
    }
  }
`

export const approveTutorNotes = gql`
  mutation approveTutorNotes($id: ID!) {
    approveTutorNotes(id: $id)
  }
`;

export const declineTutorNotes = gql`
  mutation declineTutorNotes($id: ID!, $message: String) {
    declineTutorNotes(id: $id, message: $message)
  }
`;

export const buyNotes = gql`
  mutation buyNotes($input: BuyNotesInput!) {
    buyNotes(input: $input) {
      id
    }
  }
`;

export const buyNotesAnonymously = gql`
  mutation buyNotesAnonymously($input: BuyNotesAnonymouslyInput!) {
    buyNotesAnonymously(input: $input) {
      id
    }
  }
`;

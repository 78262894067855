import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client'
import { Modal, Button, Checkbox, message } from 'antd';

// styles
import './styles.css'

export default ({ refetch }: { refetch: Function }) => {
  const history = useHistory();
  const [state, setState] = useState({ checked: false })
  const searchParams = new URLSearchParams(window.location.search);
  const id = searchParams.get("id");
  const [acceptTerms] = useMutation(gql`
    mutation acceptEnrollmentTerms($id: ID!) {
      acceptEnrollmentTerms(id: $id) {
        success
        message
      }
    }
  `)

  const handleSubmit = async () => {
    try {
      if (state.checked) {
        const { data: { acceptEnrollmentTerms: { success } }, errors } = await acceptTerms({ variables: { id }, refetchQueries: ['Events'] })
        if (success) handleGoBack()
        if (errors) throw new Error(errors.join(' '))
      }
    } catch (error) {
      message.error(error.message)
    }
  }

  const handleGoBack = () => {
    history.push({ search: '' })
  }

  const handleChange = ({ target: { checked } }: { target: { checked: boolean } }) => {
    setState(s => ({ ...s, checked }))
  }

  return (
    <Modal
      centered
      className='staySafeModal'
      title='Stay Safe'
      visible={!!id}
      footer={[
        <Button key='back' onClick={handleGoBack}>
          Cancel
        </Button>,
        <Button key='submit' disabled={!state.checked} type='primary' onClick={handleSubmit}>
          Submit
        </Button>,
      ]}
    >
      <ul>
        <li>Only make payment through Pocketnote, ensuring Payment Protection.</li>
        <li>Making a payment outside of Pocketnote violates your <Link to='terms-of-service'>user agreement</Link> and could result in legal action.</li>
      </ul>
      <Checkbox onChange={handleChange}>I understand Pocketnote's policies.</Checkbox>
    </Modal>
  )
}

import {Formik} from "formik";
import gql from "graphql-tag";
import {sortBy} from "lodash";
import React from "react";
import {useMutation, useQuery} from "@apollo/client";
import {deleteStudent as deleteStudentMutation} from "../common/mutations";
import CreateStudent from "../CreateStudent";
import {useGlobalLoading} from "hooks/useGlobalLoading";
import {User} from "@graphql-client/types";
import {Row, Col, Button, Popconfirm, Table, Space} from 'antd'
import {toast} from "react-toastify";


const Students = ({user}: { user: User }) => {

    const [deleteStudent, {loading}] = useMutation(deleteStudentMutation);
    useGlobalLoading([!!user, loading]);

    const handleDelete = async (row) => {
        try {
            await deleteStudent({
                variables: {id: row.id},
                refetchQueries: ["Students"],
            });
            toast.success("Student deleted successfully!");
        } catch (error) {
            toast.error("Student cannot be deleted because it is assigned in an event.");
        }
    }
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: text => <a className={"table-name-text"}>{text}</a>,
        },
        {
            title: 'Action',
            key: 'action',
            render: (row) => (
                <div style={{display: "flex", justifyContent: "space-around"}}>
                    <Popconfirm
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => handleDelete(row)}
                        title="Are you sure to delete this student ?"
                    >
                        <Button className="payout-delete-btn" htmlType='submit' size="large" block>
                                               <span className="payout-delete-btn-text">
                         Delete
                      </span>
                        </Button>
                    </Popconfirm>
                </div>
            ),
        },
    ]

    return (
        <Table columns={columns} dataSource={sortBy(user?.students || [], "name")} pagination={false}/>
    );
};

export default ({user}: { user: User }) => {
    const {loading, data} = useQuery(
        gql`
      query Students($id: ID!) {
        user(id: $id) {
          id
          students {
            id
            name
            school {
              id
              name
            }
          }
        }
      }
    `,
        {variables: {id: user?.id}}
    );

    useGlobalLoading([loading]);

    return (


        <Formik initialValues={{}} onSubmit={async (values) => {
        }}>
            {(props) => (
                <>
                    <Row>
                        <Col span={24} id="PayoutDesktop">
                            <Row>
                                <Col span={2}/>
                                <Col span={20}>

                                    <Students {...{user: data?.user}} />
                                    <Row className="row-height"/>
                                    <CreateStudent {...{user: data?.user}} />
                                </Col>

                            </Row>
                            <Col span={24}>
                                <Row className="row-height"/>
                                <Row className="row-height"/>
                            </Col>
                        </Col>
                        <Col span={24}>
                            <Row className="row-height"/>
                            <Row className="row-height"/>
                        </Col>
                    </Row>
                </>
            )}
        </Formik>
    );
};

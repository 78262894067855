import React from "react";
import {Link} from "react-router-dom";
import {useQuery} from "@apollo/client";
import gql from "graphql-tag";
import Switch from "../Switch";
import {Row, Col} from "antd";
import {Button} from "antd";
import {Typography} from "antd";

const {Text} = Typography;

const MessagesLinkDesktop = ({user}: any) => {
    const {data} = useQuery(gql`
    query UnreadConversations {
      me {
        id
        unreadConversations {
          id
          messages{
            content
            author{
              id
              name
            }
          }
          
        }
      }
    }
  `);
    const lastMessageAuthor = (data?.me?.unreadConversations) ? (data?.me?.unreadConversations[0]?.messages?.slice(-1)[0]?.author || {}) : {}
    const count = (lastMessageAuthor?.id != data?.me?.id) ? (data?.me?.unreadConversations?.length || 0) : 0;
    return (
        <Link to="/conversations"><span className="menu-link-text">Messages {count ? `(${count})` : null}</span></Link>
    );
};

const MessagesLinkMobile = ({user}: any) => {
    const {data} = useQuery(gql`
    query UnreadConversations {
      me {
        id
        unreadConversations {
          id
          messages{
              content
              author{
                  id
                  name
              }
          }
        }
      }
    }
  `);
    const lastMessageAuthor = (data?.me?.unreadConversations) ? (data?.me?.unreadConversations[0]?.messages?.slice(-1)[0]?.author || {}) : {}
    const count = (lastMessageAuthor?.id != data?.me?.id) ? (data?.me?.unreadConversations?.length || 0) : 0;
    return (
        <Link to="/conversations" className="text">Messages {count ? `(${count})` : null}</Link>
    );
};

const MobileNavLinkComponent = ({user, style}: any) => (
    <div className="menu-links" style={style}>
        {user?.role && (
            <div key={3} className="background">
                <Row style={{paddingTop: "30%"}} gutter={[16, 16]}>
                    {user?.role === "TUTOR" && (
                        <>
                            <Col key={"tutor_dashboard"} className="align-center" span={24}>
                                <Link to="/dashboard" className="text">Dashboard</Link>
                            </Col>
                            <Col key={"tutor_events"} className="align-center" span={24}>
                                <Link to="/events" className="text">Sessions</Link>
                            </Col>
                            <Col key={"tutor_notes"} className="align-center" span={24}>
                                <Link to="/notes/sell-your-notes" className="text">Sell Notes</Link>
                            </Col>
                            <Col key={"tutor_user"} className="align-center" span={24}>
                                <MessagesLinkMobile user={user} className="text"/>
                            </Col>
                            {/*<Col className="align-center" span={24}>*/}
                            {/*  <Link to="/settings/account" className="text">Account</Link>*/}
                            {/*</Col>*/}
                        </>
                    )}
                    {user?.role === "PARENT" && (
                        <>
                            <Col key={"parent_explore"} className="align-center" span={24}>
                                <Link to="/explore/tutors" className="text">Tutors</Link>
                            </Col>
                            <Col key={"parent_events"} className="align-center" span={24}>
                                <Link to="/events" className="text">Events</Link>
                            </Col>
                            {/*<Col key={"parent_events"} className="align-center" span={24}>*/}
                            {/*    <Link to="/notes/library" className="text">Notes Library</Link>*/}
                            {/*</Col>*/}
                            {/*<Col key={"parent_events"} className="align-center" span={24}>*/}
                            {/*    <Link to="/notes/buy" className="text">Buy Notes</Link>*/}
                            {/*</Col>*/}
                            <Col key={"parent_user"} className="align-center" span={24}>
                                <MessagesLinkMobile user={user} className="text"/>
                            </Col>
                        </>
                    )}
                    {user?.role === "ADMIN" && (
                        <>
                            <Col key={"admin_explore"} className="align-center" span={24}>
                                <Link to="/explore/tutors" className="text">Tutors</Link>
                            </Col>
                            <Col key={"admin_events"} className="align-center" span={24}>
                                <Link to="/events" className="text">Sessions</Link>
                            </Col>
                            <Col key={"admin_conversations"} className="align-center" span={24}>
                                <Link to="/conversations" className="text">Messages</Link>
                            </Col>
                        </>
                    )}
                    {user == null && (
                        <>
                            <Col key={"anon-about"} className="align-center" span={24}>
                                <Link to="/about" className="text">About Us</Link>
                            </Col>
                        </>
                    )}
                    {user && (
                        <Col className="align-center" span={24}>
                            <Link to="/settings" className="text">Settings</Link>
                        </Col>
                    )}
                    {user && (
                        <Col className="align-center" span={24}>
                            <Link to="/blog" className="text">Blog</Link>
                        </Col>
                    )}
                    {[
                        user && (
                            <Col className="align-center" span={24}>
                                <Link key="logout" to="/logout" className="text">Logout</Link>
                            </Col>
                        ),
                        null,
                    ]}
                </Row>
            </div>
        )}
    </div>
);

const DesktopNavLinkComponent = ({user, style}: any) => (
    <div className="Links" style={style}>
        {user?.role && (
            <>
                {user?.role === "TUTOR" && (
                    <>
                        <Link to="/dashboard"><span className="menu-link-text">Dashboard</span></Link>
                        <Link to="/events"><span className="menu-link-text">Sessions</span></Link>
                        <Link to="/notes/sell-your-notes"><span className="menu-link-text">Sell Notes</span></Link>
                        <MessagesLinkDesktop user={user}/>
                    </>
                )}
                {user?.role === "PARENT" && (
                    <>
                        {/*<Link to="/explore" className="clicked"><span className="menu-link-text">Tutors</span></Link>*/}
                        <Link to="/explore/tutors"><span className="menu-link-text">Tutors</span></Link>
                        <Link to="/events"><span className="menu-link-text">Sessions</span></Link>
                        {/*<Link to="/notes/library"><span className="menu-link-text">Notes Library</span></Link>*/}
                        <Link to="/notes/buy"><span className="menu-link-text">Buy Notes</span></Link>
                        <MessagesLinkDesktop user={user}/>
                    </>
                )}
                {user?.role === "ADMIN" && (
                    <>
                        <Link to="/dashboard/admin"><span className="menu-link-text">Dashboard</span></Link>
                        <Link to="/explore/tutors"><span className="menu-link-text">Tutors</span></Link>
                        <Link to="/users"><span className="menu-link-text">Users</span></Link>
                        <Link to="/events/admin"><span className="menu-link-text">Sessions</span></Link>
                        <Link to="/conversations/admin"><span className="menu-link-text">Messages</span></Link>
                        <Link to="/review-notes"><span className="menu-link-text">Review Notes</span></Link>
                    </>
                )}
                {user == null && (
                    <>
                        <Col key={"anon-about"} className="align-center" span={24}>
                            <Link to="/about" className="text">About Us</Link>
                        </Col>
                    </>
                )}

                {user && <Link to="/settings"><span className="menu-link-text">Settings</span></Link>}
                {user && <Link to="/blog"><span className="menu-link-text">Blog</span></Link>}
                <Switch>
                    {[
                        user && (
                            <Link key="logout" to="/logout">
                                Logout
                            </Link>
                        ),
                        null,
                    ]}
                </Switch>
            </>
        )}
    </div>
);

const Links = ({user}: any): JSX.Element => {
    return (
        <Switch>
            {[
                <React.Fragment key={5}>
                    {
                        <Switch key={1}>
                            {[
                                user ? (
                                    <MobileNavLinkComponent key={3} user={user}/>
                                ) : (
                                    <div key={3} className="homepage">
                                        <Row style={{paddingTop: "30%"}} gutter={[16, 16]}>
                                            <Col className="align-center" span={24}>
                                                <Link to="/explore">
                                                    <Text className="text">Explore Tutors</Text>
                                                </Link>
                                            </Col>
                                            <Col className="align-center" span={24}>
                                                <Link to="/notes">
                                                    <Text className="text">Buy Notes</Text>
                                                </Link>
                                            </Col>
                                            <Col className="align-center" span={24}>
                                                <Link to="/join/tutor">
                                                    <Text className="text">Sell Notes</Text>
                                                </Link>
                                            </Col>
                                            <Col className="align-center" span={24}>
                                                <Link to="/join/parent">
                                                    <Text className="text">Book a Tutor</Text>
                                                </Link>
                                            </Col>
                                            <Col className="align-center" span={24}>
                                                <Link to="/join/tutor">
                                                    <Text className="text">Become a Tutor</Text>
                                                </Link>
                                            </Col>
                                            <Col className="align-center" span={24}>
                                                <Link to="/blog">
                                                    <Text className="text">Blog</Text>
                                                </Link>
                                            </Col>
                                            <Col className="align-center" span={24}>
                                                <Link to={"/about"}>
                                                    <Text className="text">About Us</Text>
                                                </Link>
                                            </Col>
                                            <Col className="align-center" span={24}>
                                                <a href={"mailto:support@pocketnote.com.au"}>
                                                    <Text className="text">Contact Us</Text>
                                                </a>
                                            </Col>
                                            <Col className="align-center" span={24}>
                                                <Link to="/join">
                                                    <Button
                                                        className="box-shadow btn-secondary"
                                                        size="large"
                                                    >
                                                        Sign Up
                                                    </Button>
                                                </Link>
                                            </Col>
                                            <Col className="align-center" span={24}>
                                                <Link to="/login">
                                                    <Button
                                                        className="btn-primary"
                                                        type="primary"
                                                        size="large"
                                                    >
                                                        Login
                                                    </Button>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                                null,
                            ]}
                        </Switch>
                    }
                </React.Fragment>,
                <DesktopNavLinkComponent key={2} user={user}/>,
            ]}
        </Switch>
    );
};

export default Links;

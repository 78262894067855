import React from "react";
import {
    Row,
    Col,
    Typography,
} from "antd";
import {useHistory, useLocation} from 'react-router-dom';

import "./index.less";

const {Title} = Typography
const navigation = [
    {
        id: 1,
        heading: 'Dashboard',
        role: 'TUTOR',
        path: '/dashboard',
        img: '/icons/bottom-navigation/003-teacher.svg',
        activeimg: '/icons/bottom-navigation/003-teacher-active.svg',
        isActive: false,
    },
    {
        id: 2,
        heading: 'Tutors',
        role: 'PARENT',
        path: '/explore',
        img: '/icons/bottom-navigation/003-teacher.svg',
        activeimg: '/icons/bottom-navigation/003-teacher-active.svg',
        isActive: false
    },
    {
        id: 3,
        heading: 'Messages',
        role: 'BOTH',
        path: '/conversations',
        img: '/icons/bottom-navigation/001-chat.svg',
        activeimg: '/icons/bottom-navigation/messages-active.svg',
        isActive: false
    }, {
        id: 4,
        heading: 'Events',
        role: 'BOTH',
        path: '/events',
        img: '/icons/bottom-navigation/004-calendar.svg',
        activeimg: '/icons/bottom-navigation/004-calendar-active.svg',
        isActive: false
    },
    , {
        id: 5,
        heading: 'Profile',
        role: 'BOTH',
        path: '/settings',
        img: '/icons/bottom-navigation/005-profile.svg',
        activeimg: '/icons/bottom-navigation/profile-active.svg',
        isActive: false
    },

]

export default ({role}) => {
    const history = useHistory();
    const location = useLocation();

    return (
        <Col id="bottom-navigation">
            <Row className="keep-spaced">

                {navigation.map(item => {
                    let activeStyle = {}
                    let src = ''
                    item.isActive = location.pathname.includes(item.path);
                    if (item.isActive) {
                        activeStyle = {color: '#E26F6F'}
                        src = item.activeimg
                    } else {
                        src = item.img
                    }

                    return (
                        <>
                            {role === item.role || item.role === 'BOTH' ?
                                <>
                                    <Col span={1}/>
                                    <Col span={5}>
                                        <div onClick={() => {
                                            history.push(item.path)
                                        }}>
                                            <img src={src}/>
                                            <Title className={"title-heading"}
                                                   style={activeStyle}>{item.heading}</Title>
                                        </div>

                                    </Col>
                                </> : null
                            }

                        </>
                    )
                })}

            </Row>
        </Col>
    )
}

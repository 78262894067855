import {Field, Form, Formik} from "formik";
import React from "react";
import {useMutation} from "@apollo/client";
import {createStudent as createStudentMutation} from "../common/mutations";
import {useGlobalLoading} from "hooks/useGlobalLoading";
import {Typography, Button, Input} from "antd";
import {toast} from "react-toastify";

const {Text} = Typography

export default ({user}: any) => {
    const [createStudent, {loading}] = useMutation(createStudentMutation);

    useGlobalLoading([loading]);

    let initialValues = {
        name: "",
    };

    return (
        <Formik
            {...{initialValues}}
            onSubmit={async (values, actions) => {
                await createStudent({
                    variables: {...values, parent: user?.id},
                    refetchQueries: ["Students", "getCurrentUserProfile"],
                });
                actions.resetForm();
                toast.success("Saved!");
            }}
        >
            {({values}) => (
                <Form>
                    <Text className="width-100 common-heading" style={{color: '#E26F6F'}}>Create Students</Text>
                    <>
                        <Field
                            className="select-width"
                            placeholder={"Student Name"}
                            size="large"
                            as={Input}
                            name="name"
                            pattern=".+ .+"/>
                    </>
                    <Button htmlType="submit" type="primary" size="large" block>
                        <span className="payout-save-btn">
                        Create Student
                      </span>
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

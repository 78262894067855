import React, {useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Modal, Button, Checkbox} from 'antd';

// styles
import './styles.css'

export default () => {
    const history = useHistory();
    const [state, setState] = useState({checked: false, openModal: true})

    const handleSubmit = async () => {
        setState({...state, openModal: false})
    }

    const handleGoBack = () => {
        history.push('/explore')
    }

    const handleChange = ({target: {checked}}: { target: { checked: boolean } }) => {
        setState(s => ({...s, checked}))
    }

    return (
        <Modal
            centered
            className='staySafeModal'
            title='Stay Safe'
            visible={state.openModal}
            footer={[
                <Button key='back' onClick={handleGoBack}>
                    Cancel
                </Button>,
                <Button key='submit' disabled={!state.checked} type='primary' onClick={handleSubmit}>
                    Submit
                </Button>,
            ]}
        >
            <ul>
                <li>Only make payment through Pocketnote, ensuring Payment Protection.</li>
                <li>Making a payment outside of Pocketnote violates your <Link to='terms-of-service'>user
                    agreement</Link> and could result in legal action.
                </li>
            </ul>
            <Checkbox onChange={handleChange}>I understand Pocketnote's policies.</Checkbox>
        </Modal>
    )
}

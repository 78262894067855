import React from "react";
import {Avatar, Badge, Button, Card, Col, Divider, Row} from "antd";
import {UserOutlined} from "@ant-design/icons";

import './TopEarner.less'
import {Link} from "react-router-dom";

export const TopEarnersCard = ({tutor}) => {


    return (
        <div className="mr-4 tutor-card border-solid border rounded border-inherit p-4">

            <p className="avatar-container">
                <Avatar
                    size={100}
                    icon={<UserOutlined/>}
                    src={tutor.avatar?.url}
                />
            </p>


            <p className="name-and-price">
                <b>{tutor.name}</b>
            </p>

            <p className="name-and-price">
                <b>
                    ${tutor?.rate}/hr
                </b>
            </p>


            <p className="badges-container">
                {
                    tutor.badges.map((badge) =>

                        badge ?
                            (
                                <Row style={{ width: "100%"}}>

                                    <Col span={5}/>

                                    <Col span={5}>
                                        <img
                                            src={badge.iconName}
                                            style={{
                                                width: 25,
                                                height: 30
                                            }}
                                        />
                                    </Col>


                                    <Col span={12} >
                                        <span>{badge.badgeName}</span>
                                    </Col>

                                </Row>
                            )
                            :
                            (
                                <br/>
                            )
                    )
                }
            </p>


            <Divider/>


            <Link to={`/tutors/${tutor.id}`}>
                <Button type="primary" className="view-profile-btn">
                    View Profile
                </Button>
            </Link>

        </div>
    )


}

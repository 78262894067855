import React from 'react';

export default ({
                    hue = 0,
                    saturation = 0.5,
                    children,
                    style = {},
                    ...props
                }: any) => (
    <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: `1px solid hsl(${hue}, ${saturation * 100}%, 50%)`,
            color: `hsl(${hue}, ${saturation * 100}%, 50%)`,
            borderRadius: 16,
            padding: '2px 10px',
            marginRight: '0.5em',
            marginBottom: '14px',
            cursor: 'pointer',
            fontFamily: 'Montserrat',
            fontSize: '12px',
            fontWeight: '800',
            lineHeight: '21px',
            ...style,
        }}
        {...props}
    >
        {children}
    </div>
);

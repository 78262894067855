import React from "react";
import Switch from "../Switch";
import LandingDesktopTemplate from "../Landing/LandingDesktopTemplate";
import LandingMobileTemplate from "../Landing/LandingMobileTemplate";
import './styles.less';
import NdisForm from "./NdisForm";

const heroImage = "icons/ndis-landing/main-icon.png"
const innovationText = "Your NDIS-approved";
const tutoringText = "mentoring support";

const parentText = (
    <>
        At Pocketnote, we provide NDIS-approved mentoring services that go beyond academic support to help individuals
        with specialised learning needs.
        <br/> <br/> We believe that tutoring and mentoring should be accessible
        for all Australians, and our tutors customise their lessons towards a
        student’s academic goals, learning requirements, and life skill development needs.
        Our platform offers an array of benefits that make us stand out from other online tutoring services,
        making us the preferred choice for students and parents all over Australia.
    </>
);
const bookTutorBtnText = "Book Tutor";
const becomeTutorBtnText = "Become a Tutor";
const buyNotesBtnText = "Buy Notes";
const whyChooseTitle = "Why Choose Pocketnote For NDIS Services";
const whyChooseSubtitle = "Pocketnote is a registered NDIS provider that offers core and capacity building services in relation to social and community participation.";
const signUpText =
    "Sign up today and experience the benefits that our convenient digital portal has to offer. Access the best tutors in Australia and accelerate your child's education with Pocketnote.";
const choosePocketnoteSection = [
    {
        icon: "url(/icons/ndis-landing/rating.png)",
        heading: "Verified and NDIS Approved Tutors",
        details:
            "Pocketnote is NDIS-registered, ensuring that you receive the highest quality education and mentoring from the best in the field. We have a range of experienced tutors who specialise in working with children with different learning needs in a patient, empathetic and goal-oriented manner.",
    },
    {
        icon: "url(/icons/ndis-landing/qualified.png)",
        heading: "Personalised Experience",
        details:
            "At Pocketnote, we understand the importance of finding the right mentor who can provide the necessary guidance and support for academic and life skill development. That's why we offer a personalised experience where you can choose the mentor that best suits your needs.",
    },
    {
        icon: "url(/icons/ndis-landing/location.png)",
        heading: "Schedule lessons at any time and from any location",
        details:
            "Our platform offers the flexibility to schedule sessions at any time and from any location, making it easy for you to fit learning and mentoring into your busy lifestyle.",
    },
    {
        icon: "url(/icons/ndis-landing/teleworking.png)",
        heading: "Receive Help From The Comfort of Your Home",
        details:
            "Online mentoring also means that students can receive help from the comfort of their own home, without having to travel to a physical location. This is especially beneficial for individuals with disabilities, who may face additional challenges accessing physical tutoring and mentoring services.",
    },
    {
        icon: "url(/icons/ndis-landing/goals.png)",
        heading: "Tailored Learning and Mentoring Experience",
        details:
            "Mentoring with Pocketnote has many benefits for individuals with specialised learning needs and disabilities. Our unique platform offers personalised interaction between the student and mentor, resulting in a tailored learning and mentoring experience and ensuring that progression is made from session to session.",
    },
    {
        icon: "url(/icons/ndis-landing/refund.png)",
        heading: "Affordable Pricing and Refund Guarantee",
        details:
            "At Pocketnote, we are committed to providing the highest-quality education and mentoring at the most affordable price. We stand behind the quality of our services and offer a 100% refund guarantee on the first session, with no questions asked.",
    },
    {
        icon: "url(/icons/ndis-landing/multimedia.png)",
        heading: "Variety of Learning Styles",
        details:
            "In addition, online mentoring provides a variety of learning styles, including text, audio, and video, giving students the flexibility to choose the method that suits them best. By providing a tailored experience that caters to individual learning styles and life skill development needs, Pocketnote ensures that every student gets the most out of their mentoring sessions with our NDIS-registered tutors and mentors.",
    },
];

const tableHeaders = ["Support", "Registration Group", "Support Category Number", "Support Category", "Support Category Name", "Example of support by Pocketnote mentor"];
const tableContents = [
    {
        "support": "1",
        "registrationGroup": "0125",
        "categoryNo": "4",
        "category": "Core Supports",
        "categoryName": "Participation in community, social and civic activities",
        "example": "A Pocketnote mentor can provide peer-support, providing an opportunity for the participant to socially engage with others."
    },
    {
        "support": "2",
        "registrationGroup": "0106",
        "categoryNo": "9",
        "category": "Capacity Building",
        "categoryName": "Assistance in coordinating or managing life stages, transitions and supports (aka “support coordination”)",
        "example": "A Pocketnote mentor can provide peer-support, serving as a role model and offering encouragement and guidance to the participant."
    },
    {
        "support": "3",
        "registrationGroup": "0106",
        "categoryNo": "9",
        "category": "Capacity Building",
        "categoryName": "Assistance in coordinating or managing life stages, transitions and supports (aka “support coordination”)",
        "example": "A Pocketnote mentor can work with the participant to develop and practice skills such as budgeting, meal planning, and navigating public transportation to increase independence and community participation."
    },
    {
        "support": "4",
        "registrationGroup": "0106",
        "categoryNo": "9",
        "category": "Capacity Building",
        "categoryName": "Assistance in coordinating or managing life stages, transitions and supports (aka “support coordination”)",
        "example": "A Pocketnote mentor can assist a participant with skill development in daily living activities, such as grocery shopping and bill payment."
    }
]


const ndisTableComponent = <div>
    <div className={"fails-text heading-text my-12"}>NDIS Table</div>
    <div className={"lg:px-24"}>
            <div className={"border-bottom-1 grid grid-cols-6 gap-1 py-4"}>{tableHeaders.map((h) => <div
                className={"primary-text col-span-1 text-sm"}>{h}</div>)}</div>
            {tableContents.map(c => <div className={"border-bottom-1 grid grid-cols-6 gap-1 py-4"}>
                <div className={"col-span-1 text-sm table-text "}>{c.support}</div>
                <div className={"col-span-1 text-sm table-text"}>{c.registrationGroup}</div>
                <div className={"col-span-1 text-sm table-text"}>{c.categoryNo}</div>
                <div className={"col-span-1 text-sm table-text"}>{c.category}</div>
                <div className={"col-span-1 text-sm table-text"}>{c.categoryName}</div>
                <div className={"col-span-1 text-sm table-text"}>{c.example}</div>
            </div>)}

    </div>
</div>

export default ({user}: any) => {
    return (
        <>
            {/*<Switch>*/}
            {/*    {[*/}
            {/*        <LandingMobileTemplate*/}
            {/*            key="mobile"*/}
            {/*            user={user}*/}
            {/*            heroImage={heroImage}*/}
            {/*            innovationText={innovationText}*/}
            {/*            tutoringText={tutoringText}*/}
            {/*            parentText={parentText}*/}
            {/*            whyChooseTitle={whyChooseTitle}*/}
            {/*            whyChooseSubtitle={whyChooseSubtitle}*/}
            {/*            bookTutorBtnText={bookTutorBtnText}*/}
            {/*            becomeTutorBtnText={becomeTutorBtnText}*/}
            {/*            buyNotesBtnText={buyNotesBtnText}*/}
            {/*            signUpText={signUpText}*/}
            {/*            choosePocketnoteSection={choosePocketnoteSection}*/}
            {/*            endForm={NdisForm()}*/}
            {/*        >*/}
            {/*            /!*{ndisTableComponent}*!/*/}
            {/*        </LandingMobileTemplate>,*/}
            {/*        <LandingDesktopTemplate*/}
            {/*            key="desktop"*/}
            {/*            user={user}*/}
            {/*            heroImage={heroImage}*/}
            {/*            innovationText={innovationText}*/}
            {/*            tutoringText={tutoringText}*/}
            {/*            parentText={parentText}*/}
            {/*            bookTutorBtnText={bookTutorBtnText}*/}
            {/*            becomeTutorBtnText={becomeTutorBtnText}*/}
            {/*            signUpText={signUpText}*/}
            {/*            whyChooseTitle={whyChooseTitle}*/}
            {/*            whyChooseSubtitle={whyChooseSubtitle}*/}
            {/*            choosePocketnoteSection={choosePocketnoteSection}*/}
            {/*            endForm={NdisForm()}*/}
            {/*        >*/}
            {/*            /!*{ndisTableComponent}*!/*/}
            {/*        </LandingDesktopTemplate>,*/}
            {/*    ]}*/}
            {/*</Switch>*/}
            {NdisForm()}
        </>
    );
};

import React from "react";
import {
    Row,
    Col,
    Input,
    DatePicker,
    Card, Button
} from "antd";
import {
    MinusCircleOutlined,
} from "@ant-design/icons";
import "./Desktop.less";
import moment from 'moment';

const {TextArea} = Input;

export default ({setWork, work, workItem, deleteWorkRow}) => {

    const onEndChange = (date) => {
        setWork(
            work.map(item =>
                item.id === workItem.id
                    ? {...item, endDate: moment(date).unix()}
                    : item
            ))
    }
    const onStartChange = (date) => {
        setWork(
            work.map(item =>
                item.id === workItem.id
                    ? {...item, startDate: moment(date).unix()}
                    : item
            ))
    }

    const onInputChange = (key, value) => {
        setWork(
            work.map(item =>
                item.id === workItem.id
                    ? {...item, [key]: value}
                    : item
            ))
    }

    return (
        <Card>
            <Row>
                <Col xs={24} sm={24} md={24} lg={21} xxl={21} xl={21}>
                    <Input

                        placeholder="Job Title"
                        className="select-width"
                        size="large"
                        value={workItem.title}
                        onChange={(event) => onInputChange('title', event.target.value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col  xs={24} sm={24} md={24} lg={9} xxl={9} xl={9}>


                    <DatePicker
                        size={'large'}
                        className="select-width"
                        picker="month"
                        placeholder={"Start Date"}
                        defaultValue={moment(moment.unix(workItem.startDate).format('YYYY-MM'))}
                        onChange={onStartChange}
                    />

                </Col>
                <Col xs={0} sm={0} md={0} lg={3} xxl={3} xl={3}/>
                <Col xs={24} sm={24} md={24} lg={9} xxl={9} xl={9}>

                    <DatePicker
                        size={'large'}
                        className="select-width"
                        picker="month"
                        placeholder={"End Date"}
                        defaultValue={workItem.endDate?moment(moment.unix(workItem.endDate).format('YYYY-MM')):null}
                        onChange={onEndChange}
                    />
                </Col>
                <Col xs={0} sm={0} md={0} lg={2} xl={2} xxl={2}/>
                <Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1} className={"delete-icon"}>
                    <MinusCircleOutlined onClick={() => deleteWorkRow(workItem.id)} className="icon-specs"/>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={21} xxl={21} xl={21}>
                    <TextArea
                        placeholder="Description"
                        className="select-width"
                        size="large"
                        autoSize={{minRows: 3, maxRows: 5}}
                        value={workItem.description}
                        onChange={(event) => onInputChange('description', event.target.value)}
                    />
                </Col>

                <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}><Row className="row-height"/></Col>
                <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
                    <Button className="delete-btn" size="large" block onClick={() => deleteWorkRow(workItem.id)}>
                        <span className="delete-btn-text">Delete</span>
                    </Button>
                </Col>
            </Row>

        </Card>
    )
}
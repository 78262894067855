import React from 'react';
import Desktop from './Desktop';
import Mobile from './Mobile';
import Switch from '../Switch';

const students = [
    {
        question: 'How does Pocketnote work?',
        answer: `Pocketnote is simple. It takes a matter of seconds to create an account. This will allow you to
    message tutors and request sessions. If you request a session with a tutor and they accept,
    payment will be automatically processed at the end of the session!`
    },
    {
        question: 'Are the sessions online or face to face?',
        answer: `Either or! Almost all of our tutors offer face to face, as well as online. It’s up to you guys to
    decide what will work best.`
    },
    {
        question: 'How does payment work?',
        answer: [
            `Payment must be processed through Pocketnote. You will be charged automatically at the
      end of each approved session.`,
            `We accept Visa, Mastercard, Amex and Paypal. For card payments, payments are
      processed securely and safely through <a href="https://stripe.com/" target="_blank" ><u>Stripe</u></a>, one of the world’s most trusted and secure
      online payment processors.`,
            `Once you agree to a time with your tutor, request a session on the tutor’s profile for the time
      agreed. This will then appear on the tutor’s dashboard to approve. Payment is processed
      automatically at the end of an approved session.`,
            `For Paypal, you will be sent a Paypal invoice to pay at the end of the session. Please ensure this is paid promptly.`,
            `For both card payments and Paypal, if the session is not approved, payment will not be processed!`
        ]
    },
    {
        question: 'Can I give the tutor cash or pay them directly?',
        answer: `No. This is a breach of our Terms and Conditions. If it is suspected that payment is
    processed outside of the platform, both parties will be removed immediately without warning.
    If you have any questions regarding payment, please contact <a href="https://pocketnote.com.au/" target="_blank"><u>support@pocketnote.com.au</u></a>`
    },
    {
        question: 'Does your website have screening for tutors?',
        answer: `Yes. We screen our tutors for Working with Children Checks as well as Qualification checks.`
    },
    {
        question: 'Do I need to pay any fees?',
        answer: `There are no upfront costs or subscriptions. There is a small transaction fee when you
    request a session. A breakdown of costs will appear when you request a session with a tutor.`
    },
    {
        question: 'What if we agree to a different rate?',
        answer: `When requesting a session, there is a ‘Custom Rate’ field. Enter in the new rate agreed and this is what will be charged.`
    }

];

const tutors = [
    {
        question: 'Is Pocketnote free to join as a tutor?',
        answer: `Yes. It’s free to join Pocketnote and complete your own profile.`
    },
    {
        question: 'Are the sessions online or face to face?',
        answer: `Either or! It’s up to you and the parent or student to decide what’s best.`
    },
    {
        question: 'Where do I tutor from?',
        answer: `This is up to you! Sessions can be held online, at the student’s home, a nearby convenient
    location or your place. It’s up to you guys to decide what is best.`
    },
    {
        question: 'How much do I get paid per hour?',
        answer: `You set your own rate! If you want some guidance in determining your rate, feel free to reach
    out to <a href="https://pocketnote.com.au/" target="_blank"><u>support@pocketnote.com.au</u></a>`
    },
    {
        question: 'How does payment work?',
        answer: `If you accept a session, payment will automatically be processed at the end of the session.
    Payment usually takes 2-3 business days to appear in your account.`
    },
    {
        question: 'How do I accept a session?',
        answer: `All session requests will appear under Events in your Dashboard.`
    },
    {
        question: 'What do I pay Pocketnote?',
        answer: [
            `Tutors pay a flat 15% commission on Pocketnote. After a session has been completed,
      tutors receive the lesson payment, minus Pocketnote’s commission.`,
            `As part of our promise to you, there is a payment guarantee for all completed sessions,
      excluding free trial lessons. This includes sessions where it didn’t go to plan and the student
      takes advantage of our 100% refund guarantee.`,
        ]
    },
    {
        question: 'Can I take payment directly?',
        answer: `No. This is a breach of your Tutor Agreement. If it is suspected that you received payment
    outside of Pocketnote, you will be immediately removed from the platform.`
    },
    {
        question: 'What if we agree to a different rate?',
        answer: `When requesting a session on your profile, there is a ‘Custom Rate’ field. 
        Instruct the parent or student to enter in the new rate agreed and this is what will be charged.`
    }
]


export default () => {
    return (
        <Switch>{[
            <Mobile
                students={students}
                tutors={tutors}
            />,
            <Desktop
                students={students}
                tutors={tutors}
            />
        ]}</Switch>
    )
};





import React, {useState} from "react";
import {Card, Col, Modal, Row} from "antd";
import './Desktop.less'
import {useParams} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/client";
import {buyNotes as buyNotesMutation} from "../../common/mutations";
import {getCurrentUserProfile, getTutorNotes} from "../../common/queries";
import Title from "antd/es/typography/Title";
import PaymentSettings from "../../Settings/PaymentSettings";
import {isPaymentSetup} from "../../common/utils";
import NonLoggedInPaymentModal from "./NonLoggedInPaymentModal";

export default (props: any) => {

    let {id} = useParams() as any

    const [buyNotes] = useMutation(buyNotesMutation)
    const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [showNonLoggedInPaymentModal, setShowNonLoggedInPaymentModal] = useState(false);


    const {data: notesData} = useQuery(getTutorNotes, {
        variables: {
            id: id
        }
    })

    const {data: userData} = useQuery(getCurrentUserProfile);

    let notes: any = notesData?.tutorNotes

    const onPressBuyNow = async () => {
        if (userData?.me) {

            if (!isPaymentSetup(userData?.me)) {
                setShowPaymentModal(true);
            } else {
                await buyNotes({
                    variables: {
                        input: {
                            buyer: userData?.me?.id ? userData?.me?.id : "",
                            tutorNotes: notesData?.tutorNotes?.id
                        }
                    }
                })
                window.location.href = "/notes/library";
            }
        } else {
            setShowNonLoggedInPaymentModal(true)
        }

    }

    return (

        <Card
            title={
                (<Title className="title-text">{notes?.title}</Title>)
            }
            bordered={true}
            style={{width: "100%", height: "100%", margin: "auto"}}
        >

            {userData?.me && <Modal visible={showPaymentModal}
                                    width={800}
                                    footer={false}
                                    onCancel={() => setShowPaymentModal(false)}>
                <PaymentSettings refreshOnSave user={userData?.me}/>
            </Modal>
            }

            <Modal visible={showNonLoggedInPaymentModal}
                   width={800}
                   onCancel={() => setShowNonLoggedInPaymentModal(false)}
            >

                <Title className="modal-title-text">The full version of these notes will be sent to you via
                    email</Title>

                <NonLoggedInPaymentModal  tutorNotesId={id} setShowNonLoggedInPaymentModal={setShowNonLoggedInPaymentModal}
                                         setShowSuccessModal={setShowSuccessModal}/>
            </Modal>

            <Modal visible={showSuccessModal}
                   width={800}
                   footer={false}
                   onCancel={() => setShowSuccessModal(false)}
                   title={"Purchase Successful!"}
            >
                <p className="title-text">Thank you, we have sent you the notes by email. Please wait 5-10 minutes
                    to receive it.</p>
            </Modal>

            <Row id="SellNotesCard">
                <Col xs={23} sm={12} md={12} lg={12} xxl={12} xl={12} className="left-container">

                    {notes?.tutor?.name &&
                    <p className="label-text">Author:&nbsp;
                        <span style={{color: "#bcb5b5", paddingLeft: "2%"}}>{notes?.tutor?.name?.split(" ")[1]}</span>
                    </p>
                    }

                    <p className="label-text">Description</p>
                    <p className="info-text">{notes?.info}</p>

                    {notes?.tutor?.school?.name &&
                    <p className="label-text">School:&nbsp;
                        <span style={{color: "#bcb5b5", paddingLeft: "2%"}}>{notes?.tutor?.school?.name}</span>
                    </p>
                    }


                    <p className="label-text">Subject:
                        <span style={{color: "#bcb5b5", paddingLeft: "2%"}}>{notes?.subject?.name}</span>
                    </p>


                    <p className="label-text">Completed in:&nbsp;
                        <span style={{
                            color: "#bcb5b5",
                            paddingLeft: "2%"
                        }}>{new Date(notes?.createdAt).getFullYear()}</span>

                    </p>

                    <p className="label-text">Number of pages:
                        <span style={{color: "#bcb5b5", paddingLeft: "2%"}}>{notes?.numberOfPages}</span>
                    </p>


                    <p className="label-text">Word count:
                        <span style={{color: "#bcb5b5", paddingLeft: "2%"}}>{notes?.wordCount}</span>
                    </p>


                    {notes?.soldCount > 0 && <p className="label-text">Downloads:&nbsp;
                        <span className="value-text">{notes?.soldCount}</span>
                    </p>}

                </Col>

                <Col xs={23} sm={12} md={12} lg={12} xxl={12} xl={12} className="right-container">
                    <div className="preview-box">
                        <embed src={`${notes?.sampleNotes?.url}#page=1&zoom=20`} width={"100%"} height={"100%"}/>
                        <button
                            className="preview-button"
                            onClick={() => window.open(notes.sampleNotes?.url)}
                        >
                            View Preview
                        </button>
                    </div>

                    <div className="purchase-box">
                        <div className="price-div">
                            <span style={{fontSize: 24, fontWeight: "bold"}}>Price</span>
                            <span style={{
                                fontSize: 24,
                                fontWeight: "bold"
                            }}>${notes?.sellPrice}</span>
                        </div>


                        <button className="purchase-button" onClick={async () => await onPressBuyNow()}>
                                  <span className="upload-pic-text primary-text">
                                    Buy Now
                                  </span>
                        </button>
                    </div>
                </Col>
            </Row>
        </Card>
    )
}

import React from 'react';
import NotesCard from './NotesCard';
import NotesCardMobile from "./NotesCardMobile";

import Switch from '../../Switch'
import {FullScreenLoader} from "../../common/Loader";

export default ({loading, notesList: notesList}: any) => {

    return (
        <div style={{width: "100%"}}>
            {loading ? <FullScreenLoader/> : notesList?.map((notes: any) => {
                return (

                    <Switch>{[
                        <NotesCardMobile key={notes.id} {...notes}/>,
                        <NotesCard key={notes.id} {...notes} />,
                    ]}</Switch>
                )
            })}
        </div>
    );
};

import React from "react";
import {TestimonialType} from "./TestimonialContainer";


export default (props: TestimonialType) => {
    const {name, text, imgSrc} = props;

    return <div className={"flex flex-col flex-auto place-items-center" +
        " testimonial-present-container md:col-span-1 border-solid border rounded border-neutral-300 mt-14"}>
        <img className={"inline-block h-16 w-16 rounded-full ring-2 ring-neutral-400 object-cover tutor-present-image"} src={imgSrc}/>
        <div className={"testimonial-present-name pt-2"}>{name}</div>
        <div className={"testimonial-present-text pt-6 px-8"}>{text}</div>
    </div>
}

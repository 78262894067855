import { useEffect, useContext } from "react";
import { LoadingContext } from "context/loading";
import _ from "lodash";

/**
 * Handles global loading given an array of booleans.
 *
 * If any item in the array is true, it will show the loading screen
 * @param shouldLoadArray
 */
export function useGlobalLoading(shouldLoadArray: boolean[]): void {
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    if (_.includes(shouldLoadArray, true)) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    /**
     * Makes sure the loading always stops if a redirect
     * occurs with loading still active
     */
    return () => {
      setLoading(false);
    };
  }, [setLoading, shouldLoadArray]);
}

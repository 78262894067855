import {Col, Row, Tabs} from 'antd';
import React from 'react';
import {LineChart} from "./LineChart";

import {getLast30Days, getLast10WeeksDateRange, getLast12Months} from "./TimeLimits";


const {TabPane} = Tabs;

const onChange = (key: string) => {
    // console.log(key);
};


const StatsTab = (props: any) => {

    const {
        title, dataset1_title, dataset2_title,
        dailyDataset1, dailyDataset2,
        weeklyDataset1, weeklyDataset2,
        monthlyDataset1, monthlyDataset2
    } = props

    const dates = getLast30Days();

    const weeks = getLast10WeeksDateRange()

    const months = getLast12Months();


    return (
        <Tabs onChange={onChange} type="card">

            <TabPane tab="Daily" key="1">
                <Row>
                    <Col span={24}>
                        <LineChart
                            title={title + " (Daily)"}
                            dataset1_title={dataset1_title}
                            dataset2_title={dataset2_title}
                            labels={dates}
                            dataset1={dailyDataset1}
                            dataset2={dailyDataset2}
                        />
                    </Col>
                </Row>
            </TabPane>

            <TabPane tab="Weekly" key="2">
                <Row>
                    <Col span={24}>
                        <LineChart
                            title={title + " (Weekly)"}
                            dataset1_title={dataset1_title}
                            dataset2_title={dataset2_title}
                            labels={weeks}
                            dataset1={weeklyDataset1}
                            dataset2={weeklyDataset2}
                        />
                    </Col>
                </Row>
            </TabPane>


            <TabPane tab="Monthly" key="3">
                <Row>
                    <Col span={24}>
                        <LineChart
                            title={title + " (Monthly)"}
                            dataset1_title={dataset1_title}
                            dataset2_title={dataset2_title}
                            labels={months}
                            dataset1={monthlyDataset1}
                            dataset2={monthlyDataset2}
                        />
                    </Col>
                </Row>
            </TabPane>

        </Tabs>
    )
}


export default StatsTab;
import React from "react";
import StatsTab from "../StatsTab";
import {useQuery} from "@apollo/client";
import {getHoursStats} from "../../../common/queries";
import {
    getCurrentDate,
    getDate10WeeksAgo,
    getDate30DaysAgo,
    getFirstDayOf12MonthAgo, getLastDayOfCurrMonth,
    DailyDataSet, WeeklyDataSet, MonthlyDataSet
} from "../TimeLimits";


export const Hours = () => {
    

    const {data: dailyDataRes} = useQuery(getHoursStats, {
        variables: {
            from: getDate30DaysAgo(),
            to: getCurrentDate()
        }
    });

    let dailyData: any = dailyDataRes?.hoursStats;


    // console.log("dailyData", dailyData)

    let unapprovedHrsListDaily = dailyData ? DailyDataSet(dailyData.unapproved) : []
    // console.log("unapprovedHrsListDaily", unapprovedHrsListDaily)

    let approvedHrsListDaily = dailyData ? DailyDataSet(dailyData.approved) : []
    // console.log("approvedHrsListDaily", approvedHrsListDaily)

    ///////////////////// Weekly Data
    const {data: weeklyDataRes} = useQuery(getHoursStats, {
        variables: {
            from: getDate10WeeksAgo(),
            to: getCurrentDate()
        }
    });

    let weeklyData: any = weeklyDataRes?.hoursStats;

    // console.log("weeklyData", weeklyData)

    let unapprovedHrsListWeekly = weeklyData ? WeeklyDataSet(weeklyData.unapproved) : []
    // console.log("unapprovedHrsListWeekly", unapprovedHrsListWeekly)

    let approvedHrsListWeekly = weeklyData ? WeeklyDataSet(weeklyData.approved) : []
    // console.log("approvedHrsListWeekly", approvedHrsListWeekly)


    //////////////////// Monthly Data

    const {data: monthlyDataRes} = useQuery(getHoursStats, {
        variables: {
            from: getFirstDayOf12MonthAgo(),
            to: getLastDayOfCurrMonth()
        }
    });

    let monthlyData: any = monthlyDataRes?.hoursStats;


    // console.log("monthlyData", monthlyData)

    let unapprovedHrsListMonthly = monthlyData ? MonthlyDataSet(monthlyData.unapproved) : []
    // console.log("unapprovedHrsListMonthly", unapprovedHrsListMonthly)

    let approvedHrsListMonthly = monthlyData ? MonthlyDataSet(monthlyData.approved) : []
    // console.log("approvedHrsListMonthly", approvedHrsListMonthly)

    return (

        <div>
            <StatsTab
                title='Number of Hours'
                dataset1_title='Unapproved'
                dataset2_title='Approved'
                dailyDataset1={unapprovedHrsListDaily}
                dailyDataset2={approvedHrsListDaily}
                weeklyDataset1={unapprovedHrsListWeekly}
                weeklyDataset2={approvedHrsListWeekly}
                monthlyDataset1={unapprovedHrsListMonthly}
                monthlyDataset2={approvedHrsListMonthly}
            />
        </div>
    )
}
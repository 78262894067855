import {Col, Row, Typography} from "antd";
import React from "react";
import {InputNumber} from "antd/es";

const {Title} = Typography;

export const SellPriceRow = ({tutorNotes, setTutorNotes}) => {

    let newTutorNotes = {...tutorNotes}

    return (
        <Row>
            <Col xs={2} sm={2} md={2} lg={0} xxl={0} xl={0}/>

            <Col id="CreateNotesDesktop" xs={20} sm={20} md={20} lg={22} xxl={22} xl={22}>

                <Row>

                    <Col xs={24} sm={24} md={24} lg={24} xxl={24} xl={24}
                         style={{
                             // border: "1px solid blue",
                             display: "flex",
                             flexDirection: "column",
                             alignItems: "flex-start",
                             padding: 25
                         }}
                    >
                        <Title className="contact-info-title">Sell Price</Title>
                        <span style={{paddingTop: 5}}>Pricing options increase for notes with 6 or more pages</span>
                    </Col>


                    <Col xs={24} sm={24} md={24} lg={10} xxl={10} xl={10}
                         style={{
                             // border: "1px solid blue",
                             display: "flex",
                             flexDirection: "column",
                             alignItems: "flex-start",
                             padding: 15
                         }}

                    >

                        <InputNumber
                            defaultValue={0}
                            min={0}
                            placeholder="Price"
                            style={{ width: "95%", borderRadius: 5 }}
                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value!.replace(/\$\s?|(,*)/g, '')}
                            value={tutorNotes?.sellPrice}
                            onChange={(value) => {
                                newTutorNotes.sellPrice = value
                                setTutorNotes(newTutorNotes)
                            }}
                        />
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={24} xxl={24} xl={24}

                         style={{
                             // border: "1px solid blue",
                             display: "flex",
                             flexDirection: "column",
                             alignItems: "flex-start",
                             padding: 25
                         }}

                    >

                        <div>
                            I understand that Pocketnote:

                            <ul>
                                <li>claims no ownership rights or licences in my notes; and</li>
                                <li> will charge 30% commission on the sale of these notes.</li>
                            </ul>
                        </div>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={24} xxl={24} xl={24}

                         style={{
                             // border: "1px solid blue",
                             display: "flex",
                             flexDirection: "column",
                             alignItems: "flex-start",
                             padding: 25
                         }}
                    >

                        <div>
                            I certify that:

                            <ul>
                                <li>I am the author of these notes;</li>
                                <li>I will provide them to Pocketnote on an exclusive basis; and</li>
                                <li>I have read the <a href={"/tutor-agreement"} target={"_blank"}>Pocketnote full terms here.</a></li>
                            </ul>
                        </div>
                    </Col>

                </Row>
            </Col>

            <Col xs={2} sm={2} md={2} lg={0} xxl={0} xl={0}/>
        </Row>
    )
}

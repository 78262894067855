import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Button, Col, Collapse, Divider, Input, message, Radio, Row, Space, Tooltip, Typography, Upload} from "antd";
import {InfoCircleOutlined, MinusCircleOutlined, PlusCircleOutlined, UploadOutlined,} from "@ant-design/icons";
import "./Desktop.less";
import {useMutation} from '@apollo/client';
import gql from 'graphql-tag';
import {toast} from "react-toastify";

const {Dragger} = Upload;
const {Panel} = Collapse;
const {Title, Text} = Typography;

export default () => {

    const [dataURL, setDataURL] = useState(null as any);
    const [title, setTitle] = useState<string>('')
    const [file, setFile] = useState(null as any)
    const [uploadCheckBox, setUploadCheckBox] = useState<number>(0)
    const [idType, setIdType] = useState<string>("ID_FRONT")
    const [createUpload] = useMutation(gql`
        mutation CreateUpload($mime: String!, $filename: String, $title:String, $uploadType: String) {
            createUpload(mime: $mime, filename: $filename,title:$title, uploadType:$uploadType) {
                id
                signedUrl
            }
        }
    `);

    const [sendIdToStripe] = useMutation(gql`
        mutation SendIdToStripe($mime: String!, $uploadId: String!, $idType: String!) {
            sendIdToStripe(mime: $mime, uploadId:$uploadId, idType: $idType) 
        }
    `);

    const props = {
        name: 'file',
        multiple: false,
        action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
        showUploadList: false,
        accept: "image/*,application/pdf",
        beforeUpload(file) {
            setDataURL(URL.createObjectURL(file));
            setFile(file)
            return false;
        },
        async handleClick() {
            if (!title && uploadCheckBox) {
                toast.error('Please input a title !');
                return
            }
            if (!file) {
                toast.error('Please select a file.');
                return
            }

            try {
                const uploadDocType = uploadCheckBox === 0 ? "ID" : "CERTIFICATE";
                let uploadType;
                if (uploadDocType == "ID") {
                    uploadType = idType;
                } else {
                    uploadType = uploadDocType;
                }

                const {data} = await createUpload({
                    variables: {mime: file.type, filename: file.name, title: title, uploadType: uploadType},
                });
                const upload = data.createUpload;

                await fetch(upload.signedUrl, {
                    method: 'PUT',
                    body: file,
                });
                if (uploadDocType === "ID") {
                    await sendIdToStripe({
                        variables: {mime: file.type, uploadId: upload.id, idType: uploadType}
                    })
                }
                toast.success('Uploaded!');

            } catch (e) {
                console.log("error ", e)
                toast.error('Error!');
            } finally {
                console.log("uploaded .. ")
                setDataURL(null)
            }
            message.success(`${file.name} file uploaded successfully.`);
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };
    const verificationDocumentText = <span> This information is not publicly visible. Document must be a government-issued photo ID, such as a driver's licence or passport. Upload complete document, both front end and backend. Document must be valid, not expired. Document must in full colors. Document must be clear and readable</span>;
    const certificationText = <span> Upload a CV or transcript or other certifications</span>

    const titleInput = (event) => {
        setTitle(event.target.value)
    }

    const uploadButton = (
        <div>
            {dataURL ? (
                <>
                    <img width={250} height={220} src={dataURL}/>
                    {/*<embed src={`${dataURL}#page=1&zoom=20`} width={250} height={220} />*/}
                </>
            ) : (
                <>
                    <UploadOutlined/>
                    <div>
                        <Text>Drag and Drop here</Text>
                    </div>
                    <div>
                        <Text>or</Text>
                    </div>
                    <div className="read-more-margin ">
                        <Link to={"#"} className="text-decorate">
                            Browse files
                        </Link>
                    </div>
                </>
            )}

        </div>
    );

    return (
        <Col xs={23} sm={23} md={23} lg={18} xxl={18} xl={18} id="VerficationCollapse">
            <Collapse
                defaultActiveKey={["0"]}
                expandIcon={({isActive}) =>
                    isActive ? (
                        <MinusCircleOutlined className="icon-specs"/>
                    ) : (
                        <PlusCircleOutlined className="icon-specs"/>
                    )
                }
                expandIconPosition="right"
            >
                <Panel
                    header="Verification"
                    key="1"
                    className="collapse-background"
                >
                    <Row className="flex">
                        <Col span={2}></Col>
                        <Col span={21}>
                            <Row className="verify-des-row">
                                <Text className="submit-document-text full-width">
                                    Submit documents
                                </Text>
                                <Text className="verify-description">
                                    We need to verify your information. Please submit the documents below to process
                                    your
                                    application.
                                </Text>
                            </Row>
                            <Col  xs={23} sm={23} md={23} lg={15} xxl={15} xl={15} className="space-between">
                                <Row className="upload-row">
                                    <Col  xs={4} sm={4} md={4} lg={3} xxl={3} xl={3}>
                                        <img
                                            src="/icons/settings-account/photo-id.svg"
                                            className="icon-adjust"
                                            alt="id icon"
                                        />
                                    </Col>
                                    <Col  xs={17} sm={17} md={17} lg={19} xxl={19} xl={19} className="upload-text-div">
                                        <div>
                                            {" "}
                                            <Text className="step full-width">Step 1</Text>
                                        </div>
                                        <div>
                                            <Text className="photo-id">Photo ID</Text>
                                        </div>
                                    </Col>
                                    {/*<Col span={6}>*/}
                                    {/*    <UploadOutlined className="upload-icon"/>*/}
                                    {/*</Col>*/}
                                </Row>

                                <Row className="upload-row space-between">
                                    <Col xs={4} sm={4} md={4} lg={3} xxl={3} xl={3}>
                                        <img
                                            src="/icons/settings-account/selfie-icon.svg"
                                            className="icon-adjust"
                                            alt="selfie icon"
                                        />
                                    </Col>
                                    <Col xs={17} sm={17} md={17} lg={19} xxl={19} xl={19} className="upload-text-div">
                                        <div>
                                            <Text className="step full-width">Step 2</Text>
                                        </div>
                                        <div>
                                            <Text className="photo-id">Working with Children Check</Text>
                                        </div>
                                    </Col>
                                    {/*<Col span={6}>*/}
                                    {/*    <UploadOutlined className="upload-icon"/>*/}
                                    {/*</Col>*/}
                                </Row>

                                <Row className="upload-row space-between">
                                    <Col xs={4} sm={4} md={4} lg={3} xxl={3} xl={3}>
                                        <img
                                            src="/icons/settings-account/doc.icon.svg"
                                            className="icon-adjust"
                                            alt="doc icon"
                                        />
                                    </Col>
                                    <Col xs={17} sm={17} md={17} lg={19} xxl={19} xl={19} className="upload-text-div">
                                        <div>
                                            <Text className="step full-width">Step 3</Text>
                                        </div>
                                        <div>
                                            <Row>
                                                <Text className="photo-id">Add documents</Text>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Text className="accept-files">
                                                    CV, Academic Transcript and/or Certificate of Degree
                                                </Text>
                                            </Row>
                                        </div>
                                    </Col>
                                    {/*<Col span={6}>*/}
                                    {/*    <UploadOutlined className="upload-icon"/>*/}
                                    {/*</Col>*/}
                                </Row>
                                <Row>
                                    <Text className="accept-files">
                                        Accept file types: .pdf and .img
                                    </Text>
                                </Row>

                            </Col>
                            <Divider
                                className="divider-margin"
                                type="horizontal"
                            />
                            <Col xs={23} sm={23} md={23} lg={11} xxl={11} xl={11}>
                                <Row>

                                    <Radio.Group value={uploadCheckBox} onChange={(event) => {
                                        setUploadCheckBox(event.target.value)
                                    }}>
                                        <Space direction="vertical">
                                            <Radio value={0}>
                                                <Row>
                                                    <div className="margin-top-16">Verification Documents&emsp;
                                                        <Tooltip className="margin-top-16"
                                                                 title={verificationDocumentText}>
                                                            <InfoCircleOutlined style={{color: '#E26F6F'}}/>
                                                        </Tooltip>
                                                    </div>
                                                </Row>
                                            </Radio>
                                            <Radio value={1}>
                                                <span
                                                    className="radio-button-text">Certifications of degree/diploma&emsp;
                                                    <Tooltip className="margin-top-16" title={certificationText}>
                                                            <InfoCircleOutlined style={{color: '#E26F6F'}}/>
                                                        </Tooltip>
                                                </span>
                                            </Radio>
                                        </Space>
                                    </Radio.Group>
                                </Row>
                            </Col>
                            <Col xs={23} sm={23} md={23} lg={11} xxl={11} xl={11}>
                                {uploadCheckBox === 1 ? (
                                    <>
                                        <div className="title-div">
                                            <Text className="title">Title</Text>
                                        </div>
                                        <Input
                                            onChange={titleInput}
                                            placeholder="Certificate of Degree/Diploma"
                                            size="large"
                                            className="select-width"
                                        />
                                    </>
                                ) : null}
                                {uploadCheckBox === 0 && (
                                    <>
                                        <div className="title-div">
                                            <Text className="title">ID Type</Text>
                                        </div>
                                        <Radio.Group value={idType} onChange={(event) => {
                                            setIdType(event.target.value)
                                        }}>
                                            <Space direction="vertical">
                                                <Radio value={"ID_FRONT"}>
                                                    <Row>
                                                        <div className="margin-top-16">Photo ID: Front&emsp;
                                                        </div>
                                                    </Row>
                                                </Radio>
                                                <Radio value={"ID_BACK"}>
                                                    <Row>
                                                        <div className="margin-top-16">Photo ID: Back&emsp;
                                                        </div>
                                                    </Row>
                                                </Radio>
                                                <Radio value={"WWCC"}>
                                                    <Row>
                                                        <div className="margin-top-16">Working With Children Check&emsp;
                                                        </div>
                                                    </Row>
                                                </Radio>

                                            </Space>
                                        </Radio.Group>
                                    </>
                                )}

                                <div className="attach-docs-div">
                                    <Text className="attach-documents ">
                                        Attach Documents
                                    </Text>
                                </div>

                                <Dragger {...props}>
                                    {uploadButton}
                                </Dragger>
                                <Text className="accept-files">
                                    Accept file types: .pdf and .img
                                </Text>
                                <Button type="primary" size="large" block onClick={props.handleClick}>
                                    <span className="upload-pic-text">Upload</span>
                                </Button>
                            </Col>
                            <Row className="row-height"/>
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
            <Row className="row-height"/>
        </Col>
    )
}

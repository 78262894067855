import React from 'react';
import {Button, Col, Input, Row, Typography} from 'antd';
import './Desktop.less';
import {Field} from 'formik';
import Info from './Info';
import {Link, useHistory} from 'react-router-dom'

const {Text} = Typography;

export default ({role}: any) => {

    const history = useHistory()

    return (
        <>
            <Row className="margin-top-45">
                <Col md={24} lg={7} className='main-img-col'>
                    <div
                        style={{
                            backgroundImage: role !== 'tutor' ? `url(/icons/home-page/girl-icon.svg)` : `url(/icons/join/become-tutor/join-man-icon.svg)`,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            width: 761,
                            height: 413,
                        }}
                    >
                        <div
                            style={{
                                backgroundImage: `url(/icons/join/become-tutor/blur-layer.svg)`,
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                width: 346,
                                height: 285,
                                marginLeft: 379,
                            }}
                        />
                        <div
                            style={{
                                backgroundImage: `url(/icons/join/become-tutor/bottom-blur-layer.svg)`,
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                width: 84,
                                height: 246,
                            }}
                        />
                    </div>
                </Col>
                <Col md={24} lg={8} className="margin-top-26">
                    <Text className='align-center pocketnote-title'> Pocketnote</Text>
                    <Text className='align-center connects-title no-margin'> connects tutors</Text>
                    <Text className='align-center connects-title no-margin'> and students.</Text>
                </Col>

                <Col md={24} lg={9} className='padding-w-12 align-item-center align-content-center'>
                    <Row className='desktop-form background-primary border-radius-13  padding-8' gutter={16}>
                        <Col span={24}>
                            <Text className='align-center become-tutor-form'>
                                {role !== 'tutor' ? 'Book a Tutor' : 'Become a Tutor'}
                            </Text>
                        </Col>
                        <Col className="img-center" span={24}>
                            <Field as={Input} placeholder={"Name"} name="name" required pattern=".+ .+"/>
                        </Col>
                        <Col className='margin-top-10 img-center' span={24}>
                            <Field as={Input} type="tel" placeholder="Phone" name="tel" required pattern="[0-9]{10}"
                                   maxLength={10}/>
                        </Col>
                        <Col className='margin-top-10 img-center' span={24}>
                            <Field as={Input} type="email" name="email" required placeholder="Email"/>
                        </Col>
                        <Col className='margin-top-10 img-center' span={24}>
                            <Field
                                as={Input}
                                type="password"
                                name="password"
                                required
                                autoComplete="new-password"
                                placeholder="Password"
                            />

                        </Col>
                        <Col className='img-center' span={24}>
                            <Button htmlType='submit' className='box-shadow btn-secondary continue-btn margin-top-10'
                                    size='large'>
                                <span className="continue-btn-text">Continue</span></Button>
                        </Col>
                        <Col className='con-align img-center' span={20}>
                            {role == 'parent' ? (
                                <Text className="condition-text">
                                    By registering your account, you agree to our <Link to={"/terms-of-service"}><span
                                    className="con-underline">Terms and Conditions</span> </Link> and
                                    the <a href={'https://stripe.com/au/ssa'}> <span className="con-underline">Stripe Services Agreement.</span></a>
                                </Text>
                            ) : (
                                <Text className="condition-text">
                                    By registering your account, you agree to our <Link to={"/tutor-agreement"}><span
                                    className="con-underline">Services Agreement</span></Link> and
                                    the <a href={'https://stripe.com/en-au/connect-account/legal'}> <span
                                    className="con-underline"> Stripe Connected Account Agreement.</span></a>
                                </Text>
                            )}

                        </Col>
                        {/*<Col className='img-center' span={24}>*/}
                        {/*    <Button onClick={() => history.push('/explore')}*/}
                        {/*            className='box-shadow btn-secondary continue-btn' size='large'>*/}
                        {/*        <span className="continue-btn-text">Explore First</span></Button>*/}
                        {/*</Col>*/}
                    </Row>
                </Col>
            </Row>
            {role !== 'tutor' && (
                <>
                    <Row className="row-85"/>

                    <Row>
                        <Col className='align-center' md={16} lg={9}>
                            <Row>
                                <Col span={14} className='align-center img-end'>
                                    <div
                                        style={{
                                            backgroundImage: `url(/icons/join/online-img.svg)`,
                                            backgroundSize: 'contain',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'center',
                                            width: 75,
                                            height: 75,
                                            marginRight: 16,
                                        }}
                                    />
                                </Col>
                                <Col span={1}/>
                                <Col className='align-item-center' span={6}>
                                    <Text className='online-text'>Online</Text>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={14} className='align-center img-end'>
                                    <div
                                        style={{
                                            backgroundImage: `url(/icons/join/inperson-img.svg)`,
                                            backgroundSize: 'contain',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'center',
                                            width: 75,
                                            height: 75,
                                            marginTop: 20,
                                            marginRight: 16,
                                        }}
                                    />
                                </Col>
                                <Col span={1}/>
                                <Col className='align-item-center' span={7}>
                                    <Text className="online-text">In person</Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={2}/>
                        <Col className='align-center offering' md={24} lg={12}>
                            <Row>
                                <Col md={16} lg={20}>
                                    <Text className="offer-text">We offer you</Text>
                                </Col>
                            </Row>
                            <Row className='no-margin'>
                                <Col md={17} lg={20}>
                                    <Text className="types-text">2 types of tutoring</Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="row-60"></Row>
                </>
            )}
            <Row className='background-secondary info-box padding-bottom-48'>
                {role !== 'tutor' ?
                    <>
                        <Row className="row-120"></Row>
                        <Row className="spaced-item">
                            <Col span={1}></Col>
                            <Col span={5} className="">
                                <Info title='Verified'
                                      description='All tutors are ID checked and verified to work with students.'
                                      icon='icons/join/book-tutor/verified-icon'/>
                            </Col>
                            <Col span={5} className="">
                                <Info title='Qualified'
                                      description='Our tutors are highly qualified to provide an enriching learning experience.'
                                      icon='icons/join/book-tutor/qualified-icon'/>
                            </Col>
                            <Col span={5} className="">
                                <Info title='Flexible'
                                      description='Tutors are flexible to work around your personal schedule.'
                                      icon='icons/join/book-tutor/flexible-icon'/>
                            </Col>
                            <Col span={5} className="">
                                <Info title='Reviewed'
                                      description='Student feedback on the tutor profiles shows lesson ratings and student reviews.'
                                      icon='icons/join/book-tutor/reviewed-icon'/>
                            </Col>
                            <Col span={1}></Col>
                        </Row>
                        <Row className="row-80"></Row>
                    </>
                    :
                    <>
                        <Row className="row-120"></Row>
                        <Row className="spaced-item">
                            <Col span={1}></Col>
                            <Col span={5}>
                                <Info title='Your Area'
                                      description='Our location feature ensures you only work in the area of your choice.'
                                      icon='icons/join/become-tutor/area-icon'/>
                            </Col>
                            <Col span={5} className="">
                                <Info title='Your Schedule'
                                      description='We understand life is busy  so we believe it is essential you set your  own schedule. Work when you want. No boss. No office.'
                                      icon='icons/join/become-tutor/schedule-icon'/>
                            </Col>
                            <Col span={5} className="">
                                <Info title='Your Price'
                                      description='Earn what you deserve based on your qualifications and experience.'
                                      icon='icons/join/become-tutor/price-icon'/>
                            </Col>
                            <Col span={1}></Col>
                        </Row>
                        <Row className="row-40 "></Row>
                        <Row className="spaced-item full-width">
                            <Col span={4}></Col>
                            <Col span={5} className="">
                                <Info title='Your Convenience'
                                      description='No more chasing up students with automated payments.'
                                      icon='icons/join/become-tutor/convenience-icon'/>
                            </Col>
                            <Col span={5} className="">
                                <Info title='Your Connection' description='Tutoring online or in person'
                                      icon='icons/join/online-img'/>
                            </Col>
                            <Col span={4}></Col>
                        </Row>
                        <Row className="row-80 "></Row>
                    </>
                }
            </Row>
        </>
    );
};

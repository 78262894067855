import React from "react";
import Form from "./Form";
import CTA from "../Sections/CTA";
import { RouterParams } from "routes";
import { RouteComponentProps } from "react-router-dom";

import Switch from "../Switch";

export default (props: RouteComponentProps<RouterParams>) => {
  const role = props?.match?.params?.type;
  return role ? (
    <Switch>
      {[<Form key="mobile" role={role} />, <Form role={role} key="desktop" />]}
    </Switch>
  ) : (
    <CTA />
  );
};

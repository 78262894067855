import React from 'react';
import {Field} from 'formik';
import {Label} from '../components';
import {Input, Typography, Row, Col} from 'antd'

const {Title} = Typography

export default () => (
    <>
        <Row>
            <Col span={22}>
                <Row>
                    <Col span={24}>
                        <Row className="row-height"/>
                        <Title className="payout-info-title">Bank Account</Title>
                    </Col>
                    <Col xs={23} sm={23} md={23} lg={10} xxl={10} xl={10}>
                        <Field
                            placeholder={"BSB Number"}
                            as={Input}
                            name="bankRoutingNumber"
                            // required
                            pattern="[0-9]{6}"
                            size="large"
                            className="select-width"
                        />
                    </Col>
                    <Col xs={0} sm={0} md={0} lg={3} xxl={3} xl={3}/>
                    <Col xs={23} sm={23} md={23} lg={10} xxl={10} xl={10}>
                        <Field
                            placeholder={"Bank Account Number"}
                            as={Input}
                            name="bankAccountNumber"
                            // required
                            pattern="[0-9]{5,9}"
                            size="large"
                            className="select-width"
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    </>
);

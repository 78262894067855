import React, { useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { useHistory } from "react-router-dom";

export const Logout = () => {
  const client = useApolloClient();
  const history = useHistory();

  const logout = async () => {
    localStorage.removeItem("session");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem('url')
    client.resetStore();
    history.push("/");
  };

  useEffect(() => {
    logout();
    return () => {};
  });

  return <div></div>;
};

export default Logout;

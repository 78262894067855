/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css, keyframes } from '@emotion/core';
import { Formik } from 'formik';
import { useAuth } from '../Auth';
import { useLocation, useHistory } from 'react-router-dom';
import Mobile from './Mobile';
import Desktop from './Desktop';
import Switch from '../Switch';

const shake = keyframes`
0% { transform: translate(1px, 1px) rotate(0deg); }
10% { transform: translate(-1px, -2px) rotate(-1deg); }
20% { transform: translate(-3px, 0px) rotate(1deg); }
30% { transform: translate(3px, 2px) rotate(0deg); }
40% { transform: translate(1px, -1px) rotate(1deg); }
50% { transform: translate(-1px, 2px) rotate(-1deg); }
60% { transform: translate(-3px, 1px) rotate(0deg); }
70% { transform: translate(3px, 1px) rotate(-1deg); }
80% { transform: translate(-1px, -1px) rotate(1deg); }
90% { transform: translate(1px, 2px) rotate(0deg); }
100% { transform: translate(1px, -2px) rotate(-1deg); }
`;

export function Login({ user }: any) {
  const auth = useAuth();
  const location: any = useLocation();
  const history = useHistory();

  if (user) {
    history.push("/");
  }

  return (
    <Formik
      initialValues={{}}
      onSubmit={async (values, actions) => {
        try {
          await auth.login(values);
          if (location?.state?.from) {
            setTimeout(() => {
              history.push({
                pathname: location?.state?.from?.pathname,
                search: location?.state?.from?.search,
              });
            }, 1111);
          }
        } catch (e) {
          actions.setStatus(false);
          setTimeout(() => {
            actions.setStatus(null);
          }, 333);
        }
      }}
    >
      {(props) => (
        <div
          css={
            props.status === false &&
            css`
                  animation: ${shake} 0.3s ease 1;
                `
          }
        >
          <Switch >
            {[
              <Mobile history={history} />,
              <Desktop history={history}/>
            ]}</Switch>
        </div>
      )}
    </Formik>
  );
}

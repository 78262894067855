import React, {useEffect, useState} from 'react';
import {Col, message, Row, Select, Slider, Typography, Input} from 'antd';
import {CaretDownFilled} from '@ant-design/icons';
import './Desktop.less';
import {useHistory, useParams} from 'react-router-dom'
import gql from 'graphql-tag';
import {useMutation, useQuery} from '@apollo/client';

import {Pagination} from 'antd';
import {useSearch} from "../../common/utils";
import {getApprovedTutorNotes, getSubjects, getTutors} from "../../common/queries";
import LocationAccessModal from 'pages/LocationAccessModal';
import BottomNavigation from 'components/BottomNavigation';

import NoteInput from './NoteInput'
import Filters from "./Filters";
import Notes from "./Notes";
import Mobile from "./Mobile";
import {examList, semesterList} from "../../../components/static";


export interface tutorNotesSearchFilters {
    subjects?: string
    sellPrice?: number[]
    semester?: string
    exam?: string
    searchString: string
    // customLocation?: {
    //     longitude: number,
    //     latitude: number
    // }
}

const {Text, Title, Paragraph} = Typography;


export const BuyNotes = ({user}: any) => {

    const query = useSearch();
    const history = useHistory();
    const [location, setLocation] = useState(null as any);

    const [searchFilters, setSearchFilters] = useState<tutorNotesSearchFilters>({
        subjects: null,
        sellPrice: [0, 0],
        semester: null,
        exam: null,
        searchString: null,
    })

    const [page, setPage] = useState(1)


    const [addCurrentLocation] = useMutation(gql`
        mutation addCurrentLocation ($location: GeoLocationInput) {
            addCurrentLocation (location: $location) {
                success
                message
            }
        }
    `);

    ///////////////// ////////////////////////////// My Code

    const {data: getApprovedTutorNotesData, loading, refetch: refetchNotes} = useQuery(getApprovedTutorNotes, {
        variables: {
            ...searchFilters
        }
    });
    let approvedNotesList = []
    approvedNotesList = getApprovedTutorNotesData?.approvedTutorNotes


    const {data: subjectData} = useQuery(getSubjects)

    let subjectList = []
    subjectData?.skills?.map(subject => {
        subjectList.push({id: subject.id, value: subject.name})
    });


    ///////////////// ////////////////////////////// My Code


    const handleAddLocation = async (longitude: Number, latitude: Number) => {
        try {
            const {data: {addCurrentLocation: {success = false, message = ''} = {}} = {}} = await addCurrentLocation({
                variables: {location: {longitude, latitude}}
            })
            history.push({search: 'location-granted=true'});
            if (!!approvedNotesList.length) refetchNotes();
            else if (!success) throw new Error(message)
        } catch (error) {
            message.error(error.message)
        }
    }


    const {action} = useParams() as any;

    const handleLocationClick = () => {
        navigator.geolocation.getCurrentPosition(async location => {
            await handleAddLocation(location.coords.longitude, location.coords.latitude)
        });
    }

    return (
        <>
            <LocationAccessModal
                visible={query.has('location-permission')}
                onCancel={() => history.push({search: 'location-denied'})}
                handleAddLocation={handleAddLocation}
            />
            {action === 'filter' ? (
                    <Filters filtering={true}  {...{setLocation}} />
                ) :
                <div id='BuyNotesDesktop' className="main-div-desktop">
                    <Row>
                        <Col xs={0} sm={0} md={2} lg={2} xl={2} xxl={2}/>

                        <Col xs={0} sm={0} md={3} lg={3} xl={3} xxl={3} className="filter-main-box-desktop-buy-notes">
                            <Title className="heading-text filter-Title-desktop">Filters</Title>
                            <div className="space-top">
                                <Text className=" heading-text rate-font-desktop">
                                    Price range: <span className="notes-price-font">$10 - $200+</span>
                                </Text>
                                <Slider className="slider-line-color" range defaultValue={[0, 200]}
                                        onChange={(value) => {
                                            setPage(1)
                                            setSearchFilters({...searchFilters, sellPrice: [value[0], value[1]]})
                                        }}
                                />
                            </div>


                            <Row>

                                <div className="preferences-notes margin-top-8">
                                    <Paragraph className="heading-font">Subject</Paragraph>
                                    <Select className="border-width heading-text"
                                            allowClear
                                            showSearch
                                            defaultValue="No Preferences"
                                            options={subjectList}
                                            suffixIcon={<CaretDownFilled/>}
                                            onChange={(name, id) => {
                                                setPage(1)
                                                setSearchFilters({...searchFilters, subjects: name})
                                            }}
                                    />

                                </div>

                                <div className="preferences-notes margin-top-8">
                                    <Paragraph className="heading-font">Semester</Paragraph>
                                    <Select className="border-width heading-text"
                                            allowClear
                                            showSearch
                                            defaultValue=" No Preferences"
                                            suffixIcon={<CaretDownFilled/>}
                                            options={semesterList}
                                            onChange={(value) => {
                                                setPage(1)
                                                setSearchFilters({...searchFilters, semester: value})
                                            }}
                                    />
                                </div>

                                <div className="preferences-notes margin-top-8">
                                    <Paragraph className="heading-font">Exam</Paragraph>
                                    <Select className="border-width heading-text"
                                            allowClear
                                            showSearch
                                            defaultValue="No Preferences"
                                            suffixIcon={<CaretDownFilled/>}
                                            options={examList}
                                            onChange={(value) => {
                                                setPage(1)
                                                setSearchFilters({...searchFilters, exam: value})
                                            }}
                                    />
                                </div>


                            </Row>
                        </Col>

                        <Col xs={0} sm={1} md={1} lg={1} xl={1} xxl={1}/>


                        <Col xs={0} sm={0} md={16} lg={16} xl={16} xxl={16} className="tutor-column-desktop">
                            <NoteInput updateSearchString={(str) => setSearchFilters({...searchFilters, searchString: str})} {...{setLocation}} />

                            <Row className="location-box-desktop">
                                <Col span={5}/>
                                <Col span={6} className="location-align" style={{cursor: 'pointer'}}
                                     onClick={handleLocationClick}>
                                    <div>
                                        <img src='/icons/explore/small-location-icon.svg'
                                             className="location-icon location-icon-margin"/>
                                        <Text className="location-text"> Use current location </Text>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="notes-outer-container-desktop">


                                {
                                    approvedNotesList ?
                                        (
                                            <Notes
                                                {...{location}}
                                                loading={loading}
                                                notesList={approvedNotesList}
                                            />
                                        )
                                        :
                                        (
                                            <></>
                                        )
                                }

                            </Row>

                        </Col>
                        <Col span={2}/>
                        <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
                            <Mobile
                                location={location}
                                loading={loading}

                                page={page}
                                setPage={setPage}
                                setSearchFilters={setSearchFilters}
                                searchFilters={searchFilters}

                                approvedNotesList={approvedNotesList}
                                subjectList={subjectList}
                                semesterList={semesterList}
                                examList={examList}

                            />
                        </Col>


                    </Row>

                </div>
            }
            <Row>
                <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
                    <BottomNavigation role={'PARENT'}/>
                </Col>
            </Row>
        </>
    )
};

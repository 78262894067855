import React, {useState} from 'react';
import moment from 'moment';
import {sortBy} from 'lodash';
import EnrollmentTile from './EnrollmentTile';
import {useHistory} from 'react-router-dom';
import {useMutation} from '@apollo/client';
import gql from 'graphql-tag';
import {Col, Divider, Row, Typography, Button, Modal, Input, Rate, message, Popconfirm} from "antd";
import {TutorReviewMutation} from '../common/queries'
import StaySafeModal from "./StaySafeModal/StaySafeModal";
import {toast} from "react-toastify";

const desc = ['terrible', 'bad', 'normal', 'good', 'wonderful'];

const {Text} = Typography;
const {TextArea} = Input


export default ({
                    id,
                    me,
                    owner,
                    day,
                    startDate,
                    endDate,
                    enrollments,
                    readOnly,
                    heading,
                    review,
                    everyWeek,
                    customRate
                }: any) => {

    const [openModal, setOpenModal] = useState<Boolean>(false)
    const [isModalVisible, setIsModalVisible] = React.useState(false);

    let approvedFlag = true
    if (heading === 'History') {
        const checkApproved = enrollments.filter(enroll => enroll.approved === false)
        approvedFlag = !checkApproved.length
    }
    const acceptEvent: boolean = heading == 'Unapproved';


    const history = useHistory();
    const [upsertConversation] = useMutation(gql`
    mutation UpsertConversation($me: ID!, $them: ID!) {
      upsertConversation(me: $me, them: $them) {
        id
      }
    }
  `);

    const [updateEnrollment] = useMutation(gql`
    mutation UpdateEnrollment($id: ID!, $input: EnrollmentInput!) {
      updateEnrollment(id: $id, input: $input) {
        id
        approved
      }
    }
  `);

    const [cancelRecurring] = useMutation(gql`
    mutation cancelRecurringEvent($id:ID!){
        cancelRecurringEvent(id:$id)
    }
    `)

    const [tutorReview] = useMutation(TutorReviewMutation)

    let spacing = 3
    if (heading != "History" || (!review && approvedFlag && me?.role != 'TUTOR' && heading == "History")) {
        spacing = 7
    } else {
        spacing = 3
    }
    const updateEvent = (status: boolean) => {
        enrollments.map(async student => {
            const id = student.id
            if (!student.termsAccepted) {
                history.push({search: `id=${id}`})
            }
            else {
                await updateEnrollment({
                    variables: {id, input: {approved: status},},
                    refetchQueries: ['Events']
                });
            }

        })
    }

    const OpenForReview = () => {
        setIsModalVisible(true);
    };

    const onOk = async () => {
        setIsModalVisible(false);

        if (!reviewTile) {
            toast.error('Error, Please input Review Title ..');
        } else if (!reviewDescription) {

            toast.error('Error, Please input Review Description..');

        } else {


            try {
                await tutorReview({
                        variables: {
                            content: reviewDescription,
                            rating: rating,
                            reviewee: owner?.id,
                            reviewer: me?.id,
                            title: reviewTile,
                            eventId: id
                        },
                        refetchQueries: ['Events']
                    }
                )

                toast.success('Review Saved');


            } catch (error) {
                // message.error(error.message);
                toast.error('Error, You cannot update the event..');
            }

        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const cancelRecurringEvents = async () => {
        try {
            await cancelRecurring({
                variables: {
                    id
                },
                refetchQueries: ['Events']
            })
            toast.success('All upcoming recurring events are cancelled.');

        } catch (error) {
            console.log("error while cancelling all recurring upcoming event ", error)
            toast.error('An error occurred.');
        }
    }
    const [rating, setRating] = useState<number>(review?.rating || 5)
    const [reviewTile, setReviewTitle] = useState<string>(review?.title || '')
    const [reviewDescription, setReviewDescription] = useState<string>(review?.content || '')

    return (
        <>
            <Row className="row-height"/>
            <Row className="flex">
                <Col span={1}/>
                <Col span={22}>
                    <Row className="req-block space-flex">
                        <Col span={24}>
                            {/*<Row className="row-height"/>*/}

                            {heading != "History" && everyWeek &&
                            <Row>
                                <Col span={22} style={{marginLeft: '15px'}}>
                                    <Row>
                                        <Col xs={0} sm={0} md={0} lg={3} xl={3} xxl={3}
                                             className={"recurring-text-col"}>
                                            <Text className={"recurring-text"}>RECURRING</Text>

                                        </Col>
                                        <Col xs={0} sm={0} md={0} lg={16} xl={16} xxl={16}>
                                            <Popconfirm
                                                okText="Yes"
                                                cancelText="No"
                                                onConfirm={cancelRecurringEvents}
                                                title="Are you sure to cancel all recurring events ?"
                                            >
                                                <Button className="cancel-recurring" ghost size="small">
                                                    <span className="cancel-recurring-btn-text">Cancel</span>
                                                </Button>
                                            </Popconfirm>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            }
                        </Col>


                        <Col xs={24} sm={24} md={24} lg={4} xl={4} xxl={4} className="main-title-margin">
                            {sortBy(enrollments, ({student: {id}}: any) => id).map(
                                (enrollment: any) => (
                                    <EnrollmentTile {...{enrollment, me, readOnly}} />
                                ),
                            )}

                        </Col>
                        <Col xs={22} sm={22} md={22} lg={8} xl={8} xxl={8}>
                            <Row>
                                <Col xs={0} sm={0} md={0} lg={18} xl={18} xxl={18} className="space-flex-header">
                                    <div><Text className="sesion-font">Session 1</Text></div>
                                    <div><Text className="sesion-font">Date&Time</Text></div>
                                    {customRate &&
                                    <div><Text className="sesion-font">Rate</Text></div>
                                    }
                                </Col>

                                {/*for mobile heading .. */}
                                <Col xs={1} sm={1} md={1} lg={0} xl={0} xxl={0}/>
                                <Col xs={22} sm={22} md={22} lg={0} xl={0} xxl={0}>
                                    <Row>
                                        {/*For tutor name */}
                                        <Divider className="divider-margin " type="horizontal"/>
                                        <Row className="row-height"/>
                                        <Col span={12}>
                                            <div><Text className="sesion-font">Session 1</Text></div>
                                        </Col>
                                        <Col span={12}>
                                            <div><Text className="full-width desc-session">
                                                {owner?.name}{" "} tutor</Text></div>
                                            <Row className="row-height"/>
                                        </Col>

                                        {/*For date and time */}

                                        <Divider className="divider-margin " type="horizontal"/>
                                        <Row className="row-height"/>
                                        <Col span={12}>
                                            <div><Text className="sesion-font">Date&Time</Text></div>
                                        </Col>
                                        <Col span={12}>
                                            <div><Text className="desc-session">{day.format('dddd')}</Text></div>
                                            <div><Text
                                                className="desc-session">{day.format('MMM')} - {day.format('D')}</Text>
                                            </div>
                                            <div><Text
                                                className="desc-session">{moment(startDate).format('HH:mm')} - {moment(endDate).format('HH:mm')}</Text>
                                            </div>
                                            <Row className="row-height"/>
                                        </Col>

                                        {/*    for rate if any */}
                                        {customRate &&
                                        <>
                                            <Divider className="divider-margin " type="horizontal"/>
                                            <Row className="row-height"/>
                                            <Col span={12}>
                                                <div><Text className="sesion-font">Rate</Text></div>
                                            </Col>
                                            <Col span={12}>
                                                <div><Text className="desc-session">${customRate}</Text></div>
                                                <Row className="row-height"/>
                                            </Col>
                                        </>
                                        }

                                        {/*    for recurring events */}
                                        {heading != "History" && everyWeek &&
                                        <>
                                            <Divider className="divider-margin " type="horizontal"/>
                                            <Row className="row-height"/>
                                            <Col span={12}>
                                                <Row className="row-height-recurring"/>
                                                <Text className={"recurring-text"}>RECURRING</Text>
                                            </Col>
                                            <Col span={12}>
                                                <Popconfirm
                                                    okText="Yes"
                                                    cancelText="No"
                                                    onConfirm={cancelRecurringEvents}
                                                    title="Are you sure to cancel all recurring events ?"
                                                >
                                                    <Button className="cancel-recurring" block size="small">
                                                        <span className="cancel-recurring-btn-text">Cancel</span>
                                                    </Button>
                                                </Popconfirm>
                                            </Col>
                                        </>
                                        }

                                    </Row>
                                </Col>
                            </Row>


                            <Row className="space-flex-header">
                                <Divider className="divider-margin " type="horizontal"/>
                                <Col span={8}>

                                    <div><Text className="full-width desc-session">
                                        {owner?.name}{" "} tutor</Text></div>
                                </Col>
                                <Col span={1}/>
                                <Col span={8}>
                                    <div><Text className="desc-session">{day.format('dddd')}</Text></div>
                                    <div><Text className="desc-session">{day.format('MMM')} - {day.format('D')}</Text>
                                    </div>
                                    <div><Text
                                        className="desc-session">{moment(startDate).format('HH:mm')} - {moment(endDate).format('HH:mm')}</Text>
                                    </div>

                                </Col>
                                {customRate &&
                                <>
                                    <Col span={2}>
                                        <div><Text className="desc-session">${customRate}</Text></div>
                                    </Col>
                                    <Col span={5}/>
                                </>
                                }

                                <Col span={1}/>
                            </Row>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={spacing} xl={spacing} xxl={spacing} className={"text-align"}>
                            {heading != "History" ? (
                                me?.role === 'TUTOR' ? (

                                    <>
                                        <Button type='primary' size="large" className="accept-btn"
                                                onClick={() => {
                                                    if (customRate && acceptEvent) {
                                                        setOpenModal(true)
                                                    } else {
                                                        updateEvent(acceptEvent)
                                                    }
                                                }
                                                }>
                                            <span
                                            className="accept-text"

                                        >{heading == 'Unapproved' ? 'Accept' : 'Reject'}</span></Button>
                                        {openModal &&
                                        <StaySafeModal
                                            updateEvent={() => updateEvent(acceptEvent)}
                                            rate={customRate}
                                            accept={acceptEvent}
                                        />
                                        }
                                    </>

                                ) : (
                                    <Button type='primary' size="large" className="accept-btn" onClick={() => {
                                        history.push(`/events/${id}`)
                                    }}><span
                                        className="accept-text"

                                    >Edit</span></Button>

                                )
                            ) : (
                                approvedFlag ? (
                                    me?.role != 'TUTOR' && !review && heading == "History" ? (
                                        <Button type='primary' size="large" className="accept-btn"
                                                onClick={OpenForReview}><span
                                            className="accept-text"
                                        >Review</span></Button>
                                    ) : (
                                        <img src="/icons/events/checkout.svg" className="outlined-margin"
                                             alt="checkout icon"/>
                                    )


                                ) : (
                                    <img src="/icons/events/unapproved.svg" className="outlined-margin"
                                         alt="checkout icon"/>
                                )
                            )}
                        </Col>
                        <Col span={24}>
                            <Row className="row-height"/>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Modal title="Your Review" className="accept-text" visible={isModalVisible} onOk={onOk}
                   onCancel={handleCancel}>
                <Row>
                    <Col span={2}/>
                    <Col span={20}>
                        <span>
                        <Rate tooltips={desc} onChange={(value) => {
                            setRating(value)
                        }} value={rating}/>
                            {rating ? <span className="ant-rate-text">{desc[rating - 1]}</span> : ''}
                      </span>
                        <Row className="row-height"/>
                        <Input
                            size="large"
                            value={reviewTile}
                            placeholder="Title (Limit 50 characters)"
                            maxLength={50}
                            style={{fontWeight: 'bold', borderRadius: '5px'}}
                            onChange={(event) => {
                                setReviewTitle(event.target.value)
                            }}
                        />
                        <Row className="row-height"/>
                        <TextArea
                            placeholder={"Description (Limit 500 characters)"}
                            onChange={(event) => {
                                setReviewDescription(event.target.value)
                            }}
                            value={reviewDescription}
                            style={{borderRadius: '5px'}}
                            autoSize={{minRows: 4, maxRows: 6}}
                            allowClear={true}
                            maxLength={500}
                        />
                    </Col>
                </Row>

            </Modal>

        </>

    )
};

import React from 'react';
// import { useMutation } from '@apollo/client';
// import gql from 'graphql-tag';
import { sortBy } from 'lodash';
import EnrollmentTile from '../Events/EnrollmentTile';

export default ({ event, me }: any) => {
  // const [deleteEnrollment] = useMutation(gql`
  //   mutation DeleteEnrollment($id: ID!) {
  //     deleteEnrollment(id: $id)
  //   }
  // `);

  return (
    <div style={{ display: 'flex', width: '60%' }}>
      {sortBy(event?.enrollments || [], ({ student: { id } }: any) => id).map(
        (enrollment: any) => (
          <div style={{ margin: '0.5em' }}>
            <EnrollmentTile {...{ enrollment, me, readOnly: false }} />
          </div>
        ),
      )}
    </div>
  );
};

import {Input} from "antd";

type InputWithTitleProps = {
    title: string;
    placeholder?: string;
    required: boolean;
    color?: "black" | "white";
    onChange?: (a: any) => void;
}

export default (props: InputWithTitleProps) => {
    function getColorClass() {
        switch (props.color) {
            case "black":
                return "text-black";
            case "white":
            default:
                return "text-white";
        }
    }
    return <div className={`w-full ${getColorClass()}`}>
        <div className={"my-1 text-xs"}>{props.title}{props.required ? "*": ""}</div>
        <Input {...props}/>
    </div>
}

import {Field, Form, Formik} from 'formik';
import React from 'react';
import moment from 'moment';
import {useQuery, gql, useMutation} from '@apollo/client';
import {useHistory} from 'react-router-dom';
import ParentEnrollments from './ParentEnrollments';
import TutorEnrollments from './TutorEnrollments';
import {useParams} from 'react-router-dom';
import {getCurrentUser} from '../common/queries'
import './index.less'
import {Typography, Row, Col, Input, Button, DatePicker, TimePicker} from "antd";
import {LeftOutlined} from "@ant-design/icons";


const combine = (date: moment.Moment, time: string) => {
    const [hour, minute] = time.split(':').map((v) => parseInt(v, 10));
    return moment(date).set({hour, minute});
};
const {Text, Title} = Typography
const {TextArea} = Input
export default () => {

    const {data: currentUser} = useQuery(getCurrentUser);
    const me = currentUser?.me ? currentUser?.me : JSON.parse(localStorage.getItem("user"));
    const {id} = useParams() as any;
    const history = useHistory();

    const disabledDate = (current: any) => {
        return current && current < moment().startOf("day")
    }

    const [updateEvent] = useMutation(gql`
    mutation UpdateEvent($id: ID!, $input: UpdateEventInput!) {
      updateEvent(id: $id, input: $input) {
        id
      }
    }
  `);

    const {data} = useQuery(
        gql`
      query Event($id: ID!) {
        event(id: $id) {
          id
          startDate
          endDate
          description
          createdAt
          updatedAt
          enrollments {
            id
            approved
            createdAt
            updatedAt
            student {
              id
              name
            }
          }
        }
      }
    `,
        {variables: {id}},
    );

    let initialValues = {
        description: data?.event?.description || '',
        date: moment(data?.event?.startDate).startOf('day'),
        startTime: moment(data?.event?.startDate).format('HH:mm'),
        endTime: moment(data?.event?.endDate).format('HH:mm'),
    };

    return data?.event ? (
        <Formik
            {...{initialValues}}
            onSubmit={async (values) => {
                await updateEvent({
                    variables: {
                        id,
                        input: {
                            description: values.description,
                            startDate: combine(values.date, values.startTime).toISOString(
                                true,
                            ),
                            endDate: combine(values.date, values.endTime).toISOString(true),
                        },
                    },
                });
                history.push('/events');
            }}
        >
            {({values, setFieldValue}: any) => (
                <Form>
                    <>
                        <Col id="header-tutor" xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
                            <Row className="keep-spaced">
                                <Col span={1} className="arrow-align">
                                    <LeftOutlined onClick={() => {
                                        history.goBack()
                                    }}/>
                                </Col>
                                <Col span={1}/>
                                <Col span={10}>
                                    <Title className="header-heading">Edit event</Title>
                                </Col>
                                <Col span={10}/>
                            </Row>
                        </Col>


                        <Row id={"MainRow"}>
                            <Col sm={0} xs={0} md={0} lg={2} xl={2} xxl={2}/>
                            <Col xs={23} sm={23} md={23} lg={20} xl={20} xxl={20} id={"UpdateEventDesktop"}>
                                <Row className="row-height"/>
                                {/*<Row className="row-height"/>*/}
                                <Row>
                                    <Col sm={1} xs={1} md={1} lg={2} xl={2} xxl={2}/>
                                    <Col xs={22} sm={22} md={22} lg={20} xl={20} xxl={20}>
                                        {/*    starts here */}
                                        <Row>
                                            <Col sm={14} xs={14} md={14} lg={6} xl={6} xxl={6}>
                                                <Text className={"view-profile"}>Update Event</Text>
                                            </Col>
                                            <Col span={12}/>
                                            <Col sm={0} xs={0} md={0} lg={6} xl={6} xxl={6}>

                                                <Button
                                                    type="primary"
                                                    size="large"
                                                    block
                                                    htmlType="submit"
                                                >
                                                <span className="upload-pic-text">
                                                 Save
                                              </span>
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row className="row-height"/>
                                        <Row className="row-height"/>
                                        <Row>
                                            <Col sm={24} xs={24} md={24} lg={6} xl={6} xxl={6}>

                                                <DatePicker
                                                    size={'large'}
                                                    className="select-width"
                                                    format='DD-MM-YYYY'
                                                    defaultValue={values.date ? values.date : null}
                                                    disabledDate={disabledDate}
                                                    onChange={(date) => {
                                                        if (date) {
                                                            setFieldValue('date', moment(date));
                                                        }
                                                    }}
                                                />

                                            </Col>
                                            <Col span={1}/>
                                            <Col sm={24} xs={24} md={24} lg={6} xl={6} xxl={6}>

                                                <TimePicker
                                                    format="h:mm A"
                                                    use12Hours
                                                    size="large"
                                                    placeholder={"start Time"}
                                                    className="select-width"
                                                    defaultValue={values.startTime ? moment(values.startTime, 'hh:mm') : null}
                                                    // disabledMinutes={getDisabledMinutes}
                                                    // disabledHours={getDisabledHours}
                                                    onChange={(time) => {
                                                        if (time) {
                                                            setFieldValue('startTime', time.format('HH:mm'))
                                                        }
                                                    }}
                                                />

                                            </Col>
                                            <Col span={1}/>
                                            <Col sm={24} xs={24} md={24} lg={6} xl={6} xxl={6}>
                                                <TimePicker
                                                    format="h:mm A"
                                                    use12Hours
                                                    defaultValue={values.endTime ? moment(values.endTime, 'hh:mm') : null}
                                                    placeholder={"End Time"}
                                                    size="large"
                                                    className="select-width"
                                                    // disabledMinutes={getDisabledMinutes}
                                                    // disabledHours={getDisabledHours}
                                                    onChange={(time) => {
                                                        if (time) {
                                                            setFieldValue('endTime', time.format('HH:mm'))
                                                        }
                                                    }}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="row-height"/>
                                        {/*<Row className="row-height"/>*/}
                                        <Row>
                                            <Col xs={24} sm={24} md={24} lg={20} xl={20} xxl={20}>
                                                <Field
                                                    required
                                                    name="description"
                                                    as={TextArea}
                                                    label="Description"
                                                    placeholder="I need help with..."
                                                    size="large"
                                                    autoSize={{minRows: 4, maxRows: 6}}
                                                    className="select-width"
                                                />
                                            </Col>

                                        </Row>
                                        <Row className="row-height"/>
                                        <Row className="row-height"/>

                                        <Col xs={24} sm={24} md={24} lg={22} xl={22} xxl={22}>
                                            <div>
                                                <Text className="width-100 common-heading"
                                                      style={{color: '#E26F6F'}}>ENROLLMENTS</Text>
                                                {me?.role === 'PARENT' ? (
                                                    <ParentEnrollments event={data?.event} me={me}/>
                                                ) : null}
                                                {me?.role === 'TUTOR' ? (
                                                    <TutorEnrollments event={data?.event} me={me}/>
                                                ) : null}
                                            </div>
                                        </Col>


                                        {/*    ends here*/}

                                    </Col>

                                    <Col sm={1} xs={1} md={1} lg={0} xl={0} xxl={0}/>
                                    <Col sm={1} xs={1} md={1} lg={0} xl={0} xxl={0}/>
                                    <Col sm={22} xs={22} md={22} lg={0} xl={0} xxl={0}>

                                        <Button
                                            type="primary"
                                            size="large"
                                            block
                                            htmlType="submit"
                                        >
                                                <span className="upload-pic-text">
                                                 Save
                                              </span>
                                        </Button>
                                    </Col>

                                </Row>
                                <Row className="row-height"/>
                                <Row className="row-height"/>
                            </Col>


                            <Col span={24}>
                                <Row className="row-height"/>
                                <Row className="row-height"/>
                            </Col>
                        </Row>
                    </>
                </Form>
            )}
        </Formik>
    ) : (
        <div>...</div>
    );
};

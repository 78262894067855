import React, { useState } from 'react';
import { Typography, Row, Col, Button, Input, Select, Avatar, Divider, Progress, Popover } from 'antd';
import { MessageOutlined, CloseOutlined } from '@ant-design/icons';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import './Desktop.less';

const { Text, Title } = Typography;

export default ({ id, subjects }: { id: string; subjects: string[] }) => {

  const [isOpen, setOpen] = useState(false);
  const { Option } = Select;

  function handleChange(value: any) {
    console.log(`Selected: ${value}`);
  }
  const content = (
    <Col span={24} id='SessionDesktopPopover'>
      <Row className="background-color-40">
        <CloseOutlined className="full-width close-icon" />
        <Row className="full-width">
          <Col span={3}></Col>
          <Col span={19}>
            <Input className="session-name" placeholder="Session 1" bordered={false} />
            <Divider className="no-margin" />
          </Col>
        </Row>
        <Row className="full-width">
          <Col span={19} className="flex">
            <Button type="text" className="btn-color">Session</Button>
            <Button type="text" className="btn-color reminder-align">Reminder</Button>
          </Col>
        </Row>
        <Col span={21}>
          <Row className="clock-align full-width">
            <img src="/icons/session/popover-clock.svg" className="clock" />
            <div><Text className="card-date">Sunday, February 20</Text></div>
            <Select bordered={false} showArrow={false} defaultValue="8:30am - 9:30am" onChange={handleChange} className="selection">
              <Option value="8:30am - 9:30am" className="time-font">8:30am - 9:30am</Option>
              <Option value="10:30am - 11:30am" className="time-font">10:30am - 11:30am</Option>
              <Option value="12:30am - 1:30pm" className="time-font">12:30am - 1:30pm</Option>
              <Option value="1:30am - 2:30pm" className="time-font">1:30am - 2:30pm</Option>
            </Select>
          </Row>
          <Row>
            <Col span={2}></Col>
            <Select bordered={false} showArrow={false} defaultValue="Does not repeat" onChange={handleChange} className="first-selection">
              <Option value="daily" className="range-font">Daily</Option>
              <Option value="weekly-on-sunday" className="range-font">Weekly on Sunday</Option>
              <Option value="monthly-on-the-third-Sunday" className="range-font">Monthly on the third Sunday</Option>
              <Option value="every-weekend " className="range-font custom-width">Every weekend </Option>
              <Option value="custom" className="range-font custom-width">Custom... </Option>
            </Select>
            <div><Text className="time-zone">Time Zone</Text></div>
          </Row>
        </Col>
      </Row>
      <Divider className="no-margin" />
      <Col className="flex">
        <img src="/icons/session/location-icon.svg" className="padding-16" />
        <Input className="session-name" bordered={false} />
      </Col>
      <Divider className="no-margin" />
      <Col className="flex">
        <MessageOutlined className="padding-16" />
        <Input className="session-name" bordered={false} />
      </Col>
      <Divider className="no-margin" />
      <Row className="full-width row-flex"><Button className="save-btn-align"><span className="save-btn">Save</span></Button></Row>
    </Col >
  );
  return (
    <Col id='TutorSessionDesktop' className="main-div-tutor background-tutor flex">
      <Row className="first-row">
        <Col span={24} className="border-main flex">
          <Col span={8} className="border-right">
            <div className="padding-26">
              <Avatar size={130} className="avatar-alignment" />
              <Button type="primary" className="message-btn">Send A Message</Button>
              <Row className="margin-top-20">
                {/*<Col>*/}
                {/*  <div><Text className="rating-percent">100%</Text></div>*/}
                {/*  <div className="margin-top-17"><Progress percent={80} showInfo={false} /></div>*/}
                {/*  <div className="margin-top-6"><Text className="job-success">Job Success</Text></div>*/}
                {/*</Col>*/}
                <Col className="badge-icon-align">
                  <img src="/icons/tutor-profile/badge.svg" alt="badge icon" className="badge-icon" />
                  <Text className="top-rated">TOP RATED</Text>
                </Col>
              </Row>
              <Row className="subject-row">
                {
                  subjects.map((item: String) =>
                    <div className="subject-style"><Text className="subject-font">{item}</Text></div>
                  )
                }
              </Row>
            </div>
          </Col>
          <Col span={16}>
            <Row className="flex margin-20">
              <Text className="req-session-font">Request a session <span className="req-secondary-color">with Teagan Lim</span></Text>
            </Row>
            <Divider className="no-margin" type="horizontal" />
            <Col span={20} className="margin-both-24">
              <div><Text className="availability-font">Availability</Text></div>
              <Text className="availability-para">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
                ut aliquip ex ea commodo consequat.
              </Text>
              <div className="choose-time-div">
                <Text className="choose-time">Choose your Date & Time</Text>
                <Calendar />
                <Popover content={content} trigger="click" placement="rightBottom" overlayClassName={"popover-session"}>
                  <div><Button type="text" className="add-session" onClick={() => setOpen(!isOpen)}>+  Add new session</Button></div>
                </Popover >
              </div>
            </Col>
          </Col>
        </Col>
      </Row>
      <Row className="cost-row">
        <Col span={24} className="border-cost">
          <Title className="cost-font">Cost</Title>
          <Row >
            <Col span={4}></Col>
            <Text className="cost-elements">Tuition</Text>
            <Col span={9} ><Divider dashed className="color-white"></Divider></Col>
            <Text className="cost-elements">$15</Text>
            <Col span={4}></Col>
          </Row>
          <Row >
            <Col span={4}></Col>
            <Text className="cost-elements">Fee</Text>
            <Col span={11} ><Divider dashed className="color-white"></Divider></Col>
            <Text className="cost-elements">$15</Text>
            <Col span={4}></Col>
          </Row>
          <Row >
            <Col span={4}></Col>
            <Text className="cost-elements total-text">Total</Text>
            <Col span={11}><Divider dashed className="color-white"></Divider></Col>
            <Text className="cost-elements total-text">$15</Text>
            <Col span={4}></Col>
          </Row>
          <Row className="btn-center">
            <Button className="req-button"><span className="req-btn-text">Request a Session</span></Button>
          </Row>
        </Col>
      </Row>
    </Col >
  )
};

import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
// import { useHistory } from 'react-router-dom';
import { createSkill as createSkillMutation } from '../common/mutations';
import { getSkills } from '../common/queries';
import { Button, Header, Input, Label, Select } from '../components';

export default ({ user }: any) => {
  // const history = useHistory();
  const [createSkill] = useMutation(createSkillMutation);

  const { data } = useQuery(getSkills);
  const skills = data?.skills || [];

  let initialValues = {
    name: '',
    parent: user?.id,
  };

  return (
    <Formik
      {...{ initialValues }}
      onSubmit={async (values) => {
        await createSkill({
          variables: values,
          refetchQueries: ['Skills'],
        });
        // history.push('/skills');
      }}
    >
      {() => (
        <Form>
          <Header>Create Skill</Header>
          <>
            <Label>Name</Label>
            <Field as={Input} name="name" />
          </>
          <>
            <Label>Parent</Label>
            <Field as={Select} name="parent">
              <option></option>
              {skills.map(({ id, name }: any) => (
                <option value={id}>{name}</option>
              ))}
            </Field>
          </>
          <Button type="submit">Save</Button>
        </Form>
      )}
    </Formik>
  );
};

import React from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import moment from 'moment';
export default ({ id }: any) => {
  const { data } = useQuery(
    gql`
      query User($id: ID!) {
        user(id: $id) {
          id
          uploads {
            id
            url
            createdAt
          }
        }
      }
    `,
    { variables: { id } },
  );

  const uploads = data?.user?.uploads || [];

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
      }}
    >
      {uploads.map((upload: any) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-around',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <a href={upload.url} target="_blank" rel="noopener noreferrer">
            <div
              style={{
                backgroundImage: `url(${upload.url})`,
                width: 256,
                height: 256,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            ></div>
          </a>
          <div>{moment(upload.createdAt).fromNow()}</div>
        </div>
      ))}
    </div>
  );
};

/// <reference types="@types/googlemaps" />
import {Field} from 'formik';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDebouncedCallback} from 'use-debounce';
import {Input} from 'antd'

const PLACE_CACHE: any = {};

const placeToStripe = ({address_components}: any) => {
    const parts: any = {};
    address_components.forEach((component: any) => {
        parts[component.types[0]] = component.short_name;
    });
    const {
        street_number,
        route,
        locality,
        administrative_area_level_1,
        country,
        postal_code,
    } = parts;
    return {
        line1: `${street_number} ${route}`,
        city: locality,
        state: administrative_area_level_1,
        postal_code,
        country,
    };
};

export default ({values, setFieldValue}: any) => {

    const [query, setQuery] = useState(values.googlePlace?.formatted_address);
    const [results, setResults]: any = useState([]);
    const [hoverPlaceId, setHoverPlaceId] = useState(null);
    const [single, setSingle]: any = useState(null);

    const autocompleteService = useMemo(
        () => new window.google.maps.places.AutocompleteService(),
        [],
    );
    const placesService = useMemo(
        () =>
            new window.google.maps.places.PlacesService(
                document.createElement('div'),
            ),
        [],
    );

    const [lookup] = useDebouncedCallback(
        (query: any) => autocompleteService.getPlacePredictions(query, setResults),
        666,
    );

    const getPlace: any = useCallback(
        (placeId: any) =>
            new Promise((resolve) => {
                if (PLACE_CACHE[placeId]) {
                    resolve(PLACE_CACHE[placeId]);
                }
                placesService.getDetails({placeId}, (place: any) => {
                    PLACE_CACHE[placeId] = place;
                    resolve(place);
                });
            }),
        [placesService],
    );

    useEffect(() =>{
        if (values.googlePlace?.formatted_address) {
            setQuery(values.googlePlace?.formatted_address);
        }
    }, [values])

    useEffect(() => {
        lookup({
            input: query,
            componentRestrictions: {
                country: values?.country?.toLowerCase(),
            },
        });
        return () => {
        };
    }, [query, values, lookup]);

    useEffect(() => {
        if (results?.length === 1) {
            let [result] = results;
            const hit = PLACE_CACHE[result.place_id];
            if (hit) {
                setSingle(hit);
            } else {
                getPlace(result.place_id).then(setSingle);
            }
        } else {
            setSingle(null);
        }
        return () => {
        };
    }, [results, getPlace]);

    const buildResults = () => (
        <div
            style={{
                position: "absolute",
                zIndex: 10,
                backgroundColor: "white",
                border: "1px black solid",
                fontSize: '12px',
                fontFamily: 'Crimson Pro'
            }}
            onMouseLeave={() => {
                setHoverPlaceId(null);
            }}
        >
            {results.map((result: any) => (
                <div
                    style={{
                        border:
                            result.place_id === hoverPlaceId ? `1px solid green` : 'none',
                        fontSize: '12px',
                        fontFamily: 'Crimson Pro'
                    }}
                    onMouseOver={() => {
                        setHoverPlaceId(result.place_id);
                    }}
                    onClick={async () => {
                        setQuery(result.description);
                        setResults([]);
                        let place = await getPlace(result.place_id);
                        const {city} = placeToStripe(place);
                        setFieldValue("city", city)
                        setFieldValue('googlePlace', place);
                    }}
                >
                    {result.description}
                </div>
            ))}
        </div>
    );

    return (
        <>
            <Input
                className="select-width"
                size="large"
                placeholder={"Enter your address"}
                value={query}
                onChange={(e: any) => {
                    setQuery(e.target.value);
                    setResults([]);
                    setFieldValue('googlePlace', null);
                }}
                onBlur={() => {
                    if (single) {
                        const {city} = placeToStripe(single);
                        setQuery(single.formatted_address);
                        setFieldValue('googlePlace', single);
                        setFieldValue('city', city);

                    }
                }}
            />
            {results?.length > 1 ? (
                buildResults()
            ) : null}
        </>
    );
};

const Place = ({place, style = {}}: any) => {
    const {line1, city, state, postal_code} = placeToStripe(place);
    return (
        <div {...{style}}>
            <Field as={Input} value={line1}/>
            <div
                style={{
                    display: 'grid',
                    width: '100%',
                    gridGap: '1em',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                }}
            >
                <div style={{width: '100%'}}>
                    <Field as={Input} value={city}/>
                </div>
                <div style={{width: '100%'}}>
                    <Field as={Input} value={state}/>
                </div>
                <div style={{width: '100%'}}>
                    <Field as={Input} value={postal_code}/>
                </div>
            </div>
        </div>
    );
};

const Address = ({style = {}}: any) => {
    return (
        <div {...{style}}>
            <Field as={Input} name="address.line1"/>
            <div
                style={{
                    display: 'grid',
                    width: '100%',
                    gridGap: '1em',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                }}
            >
                <div style={{width: '100%'}}>
                    <Field as={Input} name="address.city"/>
                </div>
                <div style={{width: '100%'}}>
                    <Field as={Input} name="address.state"/>
                </div>
                <div style={{width: '100%'}}>
                    <Field as={Input} name="address.postal_code"/>
                </div>
            </div>
        </div>
    );
};

import {faAddressCard, faBan, faEye, faMask, faMoneyBill,} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Form, Formik} from 'formik';
import gql from 'graphql-tag';
import moment from 'moment';
import React, {useEffect, useMemo, useState} from 'react';
import {useMutation} from '@apollo/client';
import {Link, useHistory} from 'react-router-dom';
import parseHtml from 'html-react-parser'
import {Button} from '../../components';
import Table from '../../Table';
import useData from './useData';
import {createJwtToken} from "../../common/utils";


const CheckCell = ({user, field, value}: any) => {
    let q = `
  mutation UpdateUser($id: ID!, $${field}: Boolean) {
    updateUser(id: $id, ${field}: $${field}) {
      id
      ${field}
    }
  }
`;
    const [updateUser] = useMutation(gql(q), {refetchQueries: ['Users']});
    return (
        <input
            type="checkbox"
            checked={value}
            onChange={async (e: any) => {
                await updateUser({
                    variables: {
                        id: user?.id,
                        [field]: e.target.checked,
                    },
                });
            }}
        ></input>
    );
};

const Users = (props: any) => {
        const history = useHistory();

        // const [filters] = useState([
        //   FILTERS.SHADOW,
        //   FILTERS.ADMIN,
        //   FILTERS.DEMO,
        //   FILTERS.BAN,
        // ]);

        const columns = useMemo(
            () =>
                [
                    {
                        disableSortBy: true,
                        disableFilters: true,
                        Header: 'Actions',
                        accessor: (v: any) => v,
                        Cell: ({cell: {value: user}}: any) => {
                            const [banUser] = useMutation(
                                gql`
                mutation BanUser($id: ID!, $reason: String) {
                  banUser(id: $id, reason: $reason) {
                    id
                    ban
                  }
                }
              `,
                                {refetchQueries: ['Users']},
                            );
                            return (
                                <div style={{display: 'flex', justifyContent: 'space-around'}}>
                                    <Link to={`/users/${user.id}`}>
                                        <Button style={{margin: 0, padding: '0 2px', maxWidth: '35px'}}>
                                            <FontAwesomeIcon icon={faEye}/>
                                        </Button>
                                    </Link>
                                    <Link to={`/tutors/${user.id}`}>
                                        <Button style={{margin: 0, padding: '0 2px', maxWidth: '35px'}}>
                                            <FontAwesomeIcon icon={faAddressCard}/>
                                        </Button>
                                    </Link>
                                    <Button
                                        style={{margin: 0, padding: '0 2px', maxWidth: '35px'}}
                                        onClick={async () => {
                                            const token = await createJwtToken({id: user.id, name: user.name, email: user.email});
                                            localStorage.setItem('token', token);
                                            history.push('/settings/account');
                                            (window as any).APOLLO.resetStore();
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faMask}/>
                                    </Button>
                                    <Button
                                        style={{margin: 0, padding: '0 2px', maxWidth: '35px'}}
                                        onClick={async () => {
                                            const reason = window.prompt('Why?');
                                            if (!reason) {
                                                return;
                                            }
                                            await banUser({variables: {id: user.id, reason}});
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faBan}/>
                                    </Button>
                                    {user.stripeAccountId ? (
                                        <a
                                            href={`https://dashboard.stripe.com/connect/accounts/${user.stripeAccountId}`}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            <Button style={{margin: 0, padding: '0 2px', maxWidth: '35px'}}>
                                                <FontAwesomeIcon icon={faMoneyBill}/>
                                            </Button>
                                        </a>
                                    ) : user.stripeCustomerId ? (
                                        <a
                                            href={`https://dashboard.stripe.com/customers/${user.stripeCustomerId}`}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            <Button style={{margin: 0, padding: '0 2px', maxWidth: '35px'}}>
                                                <FontAwesomeIcon icon={faMoneyBill}/>
                                            </Button>
                                        </a>
                                    ) : (
                                        <Button style={{margin: 0, padding: '0 2px', maxWidth: '35px'}}>
                                            <FontAwesomeIcon icon={faMoneyBill}/>
                                        </Button>
                                    )}
                                </div>
                            );
                        },
                    },
                    {
                        Header: 'Role',
                        accessor: 'role',
                        disableFilters: true,
                        Cell: ({cell: {value}, row}: any) => {
                            const [updateUser] = useMutation(
                                gql`
                mutation UpdateUser($id: ID!, $role: String!) {
                  updateUser(id: $id, role: $role) {
                    id
                    role
                  }
                }
              `,
                            );
                            return (
                                <select
                                    value={value}
                                    onChange={async (e) => {
                                        await updateUser({
                                            variables: {
                                                id: row.original.id,
                                                role: e.target.value,
                                            },
                                        });
                                    }}
                                >
                                    <option value="ADMIN">ADMIN</option>
                                    <option value="TUTOR">TUTOR</option>
                                    <option value="PARENT">PARENT</option>
                                </select>
                            );
                        },
                    },
                    // {
                    //   Header: 'Avatar',
                    //   accessor: 'avatar',
                    //   Cell: ({ cell: { value }, row }: any) =>
                    //     value ? (
                    //       <a href={value?.url}>
                    //         <div
                    //           style={{
                    //             width: 32,
                    //             height: 32,
                    //             background: `url(${value?.url})`,
                    //             backgroundSize: 'contain',
                    //             backgroundPosition: 'center',
                    //             backgroundRepeat: 'no-repeat',
                    //           }}
                    //         ></div>
                    //       </a>
                    //     ) : (
                    //       <></>
                    //     ),
                    // },
                    {
                        Header: 'Name',
                        accessor: 'name',
                        Cell: ({cell: {value}, row}: any) => (
                            <div style={{color: /.* .*/.test(value) ? 'green' : 'red'}}>
                                {value}
                            </div>
                        ),
                    },
                    {
                        Header: 'Email',
                        accessor: 'email',
                        Cell: ({cell: {value}}: any) => (
                            <a style={{color: 'hsl(0, 0%, 33%)'}} href={`mailto:${value}`}>
                                {value}
                            </a>
                        ),
                    },
                    {
                        Header: 'Phone',
                        accessor: 'tel',
                        Cell: ({cell: {value}}: any) => (
                            <a style={{color: 'hsl(0, 0%, 33%)'}} href={`tel:${value}`}>
                                {value}
                            </a>
                        ),
                    },
                    {
                        Header: 'Suburbs',
                        accessor: 'googlePlace',
                        Cell: ({cell: {value}}: any) => (
                            <div>
                                {value && value.adr_address && parseHtml(value.adr_address)}
                            </div>
                        ),
                    },
                    {
                        Header: 'WWCC',
                        accessor: 'wwcc',
                        Cell: ({cell: {value}, row}: any) => (
                            <CheckCell user={row.original} field={'wwcc'} value={value}/>
                        ),
                        disableFilters: true,
                    },
                    {
                        Header: 'Qualified',
                        accessor: 'qualified',
                        Cell: ({cell: {value}, row}: any) => (
                            <CheckCell user={row.original} field={'qualified'} value={value}/>
                        ),
                        disableFilters: true,
                    },
                    {
                        Header: 'Contacted',
                        accessor: 'contacted',
                        Cell: ({cell: {value}, row}: any) => (
                            <CheckCell user={row.original} field={'contacted'} value={value}/>
                        ),
                        disableFilters: true,
                    },
                    {
                        Header: 'Payment',
                        accessor: (row) => {
                            if (row.role == "TUTOR") return "N/A"
                            if (row.stripeAccount != null || row.paymentMethod == "PAYPAL") {
                                return "Yes"
                            }
                            return "No"
                        },
                        Cell: ({cell: {value}}: any) => (
                            <div>
                                {value}
                            </div>
                        ),
                        disableFilters: true,
                    },
                    {
                        Header: 'Manual Payment',
                        accessor: 'manualPayment',
                        Cell: ({cell: {value}, row}: any) => (
                            <CheckCell user={row.original} field={'manualPayment'} value={value}/>
                        ),
                        disableFilters: true,
                    },
                    {
                        Header: 'Created',
                        accessor: 'createdAt',
                        Cell: ({cell: {value}}: any) => (
                            <div>{moment(value).fromNow(true)}</div>
                        ),
                        disableFilters: true,
                    },
                    {
                        Header: 'Active',
                        id: 'activeAt',
                        accessor: ({activeAt, createdAt}: any) => activeAt || createdAt,
                        Cell: ({cell: {value}, row}: any) => {
                            return <div>{value && moment(value).fromNow(true)}</div>;
                        },
                        disableFilters: true,
                    },
                ].filter(Boolean),
            //eslint-disable-next-line
            [],
        );

        const [sortBy, setSortBy] = React.useState([]);
        const [pageCount, setPageCount] = React.useState(2);
        const [page, setPage] = React.useState(0);
        const [_filters, setFilters] = React.useState([]);
        const [params, setParams]: any = useState({
            skip: 0,
            take: 8,
            sort: sortBy,
            filters: _filters
        });

        let {data, meta} = useData(params);

        useEffect(() => {
            setPageCount(Math.ceil(meta.total / 32));
        }, [meta]);

        const fetchData = React.useCallback(
            ({pageSize, pageIndex, sortBy, filters}) => {
                setParams({
                    skip: pageSize * pageIndex,
                    take: pageSize,
                    sort: sortBy,
                    filters,
                });
                setFilters(filters);
                setPage(pageIndex);
                setSortBy(sortBy);
            },
            [],
        );

        return (
            <Table
                data={data || []}
                columns={columns}
                fetchData={fetchData}
                pageCount={pageCount}
                pageIndex={page}
                sortBy={sortBy}
                manual={true}
                filters={_filters}
            />
        );
    }
;

export default ({user, ...props}: any) => {
    return (
        <div style={{overflow: "scroll"}}>
            <Formik initialValues={{}} onSubmit={async (values) => {
            }}>
                {(props) => (
                    <Form>
                        <div style={{width: '100%'}}>
                            <Users/>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

import React from "react";
import {
    Row,
    Col,
    Input,
    DatePicker,
    Card, TimePicker, Button
} from "antd";
import {
    MinusCircleOutlined,
} from "@ant-design/icons";
import "./Desktop.less";
import moment from 'moment';


export default ({avaExclu, setAvaExcl, availabilityExclusion, delAvaExclusion}) => {

    const onDateChange = (time) => {

        let startDate = avaExclu.start
        let endDate = avaExclu.start
        if (time) {

            const startTime = moment.unix(Number(avaExclu.start)).format("hh:mm")
            startDate = moment(moment(time[0]).format('DD-MM-YYYY') + '' + startTime, 'DD/MM/YYYY HH:mm').unix().toString()
            // console.log("start date", startDate)

            const endTime = moment.unix(Number(avaExclu.end)).format("hh:mm")

            endDate = moment(moment(time[1]).format('DD-MM-YYYY') + '' + endTime, 'DD/MM/YYYY HH:mm').unix().toString()
        }
        setAvaExcl(
            availabilityExclusion.map(ava =>
                ava.id === avaExclu.id
                    ? {...ava, start: startDate, end: endDate}
                    : ava
            )
        )
    }

    const onTimeChange = (time) => {

        let startTime = avaExclu.start
        let endTime = avaExclu.start
        if (time) {
            console.log("")
            const startDate = moment.unix(Number(avaExclu.start)).format("DD-MM-YYYY")
            startTime = moment(startDate + '' + moment(time[0]).format('hh:mm'), 'DD/MM/YYYY HH:mm').unix().toString()
            // console.log("start date", startDate)

            const endDate = moment.unix(Number(avaExclu.end)).format("DD-MM-YYYY")
            endTime = moment(endDate + '' + moment(time[1]).format('hh:mm'), 'DD/MM/YYYY HH:mm').unix().toString()
        }
        setAvaExcl(
            availabilityExclusion.map(ava =>
                ava.id === avaExclu.id
                    ? {...ava, start: startTime, end: endTime}
                    : ava
            )
        )

    }

    return (
        <Card>
            <Row>
                <Col  xs={24} sm={24} md={24} lg={9} xxl={9} xl={9}>

                    <DatePicker.RangePicker
                        format={'DD-MM'}
                        size={'large'}
                        className="select-width"
                        value={[avaExclu.start ? moment(moment.unix(Number(avaExclu.start))) : null, avaExclu.end ? moment(moment.unix(Number(avaExclu.end))) : null]}

                        onChange={onDateChange}
                    />

                </Col>
                <Col xs={0} sm={0} md={0} lg={3} xxl={3} xl={3}/>
                <Col xs={24} sm={24} md={24} lg={9} xxl={9} xl={9}>

                    <TimePicker.RangePicker
                        size={'large'}
                        className="select-width"
                        format={'hh:mm'}
                        value={[avaExclu.start ? moment(moment.unix(Number(avaExclu.start))) : null, avaExclu.end ? moment(moment.unix(Number(avaExclu.end))) : null]}
                        onChange={onTimeChange}
                    />
                </Col>
                <Col xs={0} sm={0} md={0} lg={2} xxl={2} xl={2}/>
                <Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1}  className="delete-icon">
                    <MinusCircleOutlined onClick={() => delAvaExclusion(avaExclu.id)} className="icon-specs"/>
                </Col>
                <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}><Row className="row-height"/></Col>
                <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0} >
                    <Button className="delete-btn" size="large" block onClick={() => delAvaExclusion(avaExclu.id)}>
                        <span className="delete-btn-text">Delete</span>
                    </Button>
                </Col>
            </Row>

        </Card>
    )
}
import React from 'react';
import moment from 'moment';

const Label = ({ children }: any) => (
  <div
    style={{
      opacity: 0.5,
      marginRight: '1em',
    }}
  >
    {children}
  </div>
);

const Field = ({ children }: any) => (
  <div
    style={{
      display: 'flex',
    }}
  >
    {children}
  </div>
);

export default ({ id, event }: any) => (
  <div>
    <div style={{ fontWeight: 'bold' }}>
      {event?.creator?.name} requested a session
    </div>
    <Field>
      <Label>Time</Label>
      <div>
        {moment(event?.startDate).format('ll')}{' '}
        {moment(event?.startDate).format('LT')} -{' '}
        {moment(event?.endDate).format('LT')}
      </div>
    </Field>
    <Field>
      <Label>Students</Label>
      <div style={{ display: 'flex' }}>
        {event.enrollments?.map((enrollment: any) => (
          <div style={{ marginRight: '0.25em' }}>
            {enrollment?.student?.name}
          </div>
        ))}
      </div>
    </Field>
    <Field>
      <Label>Message</Label>
      <div>{event.description}</div>
    </Field>
      <br/>
  </div>
);

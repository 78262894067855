import React from "react";
import Desktop from "./Desktop";
import { useQuery } from "@apollo/client";
import { User } from "../../@graphql-client/types";
import { getCurrentUser, getCurrentUserProfile } from "../common/queries";

const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
export default () => {
  const { loading: currentUserLoading, data: user } = useQuery<{ me: User }>(
    getCurrentUser
  );
  const { loading, data } = useQuery<{ me: User }>(getCurrentUserProfile);

  return <Desktop user={user} days={days} />;
};

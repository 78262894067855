import React from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { orderBy } from 'lodash';
import moment from 'moment';

export default ({ ...props }: any) => {
  const { data } = useQuery(
    gql`
      query Clicks {
        clicks {
          id
          path
          user {
            id
            name
          }
          createdAt
        }
      }
    `,
    { pollInterval: 333 },
  );
  const clicks = orderBy(data?.clicks || [], ['createdAt'], ['desc']);
  return (
    <div style={{ width: 768 }}>
      {clicks.map((click: any) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div>{click.path}</div>
          <div>{click.user?.name}</div>
          <div>{moment(click.createdAt).fromNow(true)}</div>
        </div>
      ))}
    </div>
  );
};

import styled from '@emotion/styled';
import {HTMLAttributes, ClassAttributes} from 'react';

const VARIANTS: any = {
    positive: {
        // borderColor: '#21ba45',
        color: '#21ba45',
    },
    negative: {
        // borderColor: '#db2828',
        color: '#db2828',
    },
    disabled: {
        filter: 'saturate(0)',
        opacity: 0.5,
        cursor: 'not-allowed',
    },
};

const collectVariants = (props: any): any =>
    Object.entries(props)
        .filter(([k, v]) => v !== false)
        .reduce(
            (p, [key]) => ({
                ...p,
                ...(VARIANTS[key] || {}),
            }),
            {},
        );

type ButtonProps = HTMLAttributes<HTMLButtonElement> &
    ClassAttributes<HTMLButtonElement> & {
    positive?: boolean;
    negative?: boolean;
    disabled?: boolean;
};

const Button = styled('button')<ButtonProps>({}, props => ({
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 18,
    fontWeight: 'bolder',
    cursor: 'pointer',
    fontFamily: 'Crimson Pro',
    // borderRadius: 3,
    // borderWidth: 1,
    // borderStyle: 'solid',
    padding: '1em',
    border: 'none',
    color: 'hsl(0, 0%, 66%)',
    // borderColor: 'hsl(0, 0%, 66%)',
    backgroundColor: 'transparent',
    '&:focus': {
        outline: 'none',
    },
    '&:hover': {
        ...((props.disabled ? {} : {filter: 'brightness(90%)'}) as any),
    },
    ...(collectVariants(props) as any),
}));

export {Button};

import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { State } from './types'

import Mobile from './Mobile';
import Desktop from './Desktop';
import { getTutor } from 'pages/common/queries';
import { FullScreenLoader } from 'pages/common/Loader';
import Switch from 'pages/Switch';
import './index.less';

const subjects = [
  'Science',
  'English',
  'Physics',
  'Mathematics',
  'Chemistry'
]


export default ({ id }: {id:string; }): JSX.Element => {
  const { data: { tutor } = {}, loading } = useQuery(getTutor, { variables: { id } });
  const [state, setState] = useState<State>({ expanded: false, subString: '', paragraph: '', availability: [] })

  useEffect(() => {
    if (tutor?.biography?.length) {
      const indices: number[] = [];
      for (let i = 0; i < tutor?.biography.length; i++) {
        if (tutor?.biography[i] === '.') {
          indices.push(i);
        }
      }
      setState(s => ({
        ...s,
        subString: tutor?.biography.substring(0, indices[2] + 1),
        paragraph: tutor?.biography,
        availability: tutor?.availability ? tutor?.availability?.split('|') : null
      }))
    } else {
      setState(s => ({ ...s, subString: tutor?.biography, paragraph: tutor?.biography, availability: tutor?.availability }))
    }
  }, [tutor])

  if (loading)
    return (
      <FullScreenLoader />
    )

  return (
    <>
      <Switch>{[
        <Mobile
          id={id}

        />
        ,
        <Desktop
          id={id}
          subjects={subjects} />
      ]}</Switch>
    </>
  );
};

import {useParams} from "react-router";
import React, {useEffect, useState} from "react";
import ComponentContainer from "../../components/LandingTemplates/ComponentContainer";
import {Loader} from "../common/Loader";
import {wordpressSite} from "../Constants/utilities";
import moment from "moment/moment";
import CategoryPill from "./CategoryPill";
import ShareBlogComponent from "./ShareBlogComponent";
import BlogComponent from "./BlogComponent";
import SubscribeNewsLetter from "./BlogSubscribe";

export default () => {
    const params: { postSlug: string } = useParams();
    const [currentPost, setCurrentPost] = useState(null);
    const [relatedPosts, setRelatedPosts] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchPost = async () => {
        await fetch(
            `https://public-api.wordpress.com/rest/v1/sites/${wordpressSite}/posts/slug:${params.postSlug}`,
            {
                method: "GET",
                headers: {
                    accept: "application/json",
                    "content-type": "application/json",
                },
            }
        )
            .then((res) => res.json())
            .then((response) => {
                setCurrentPost(response);
                setLoading(false);
                fetchRelatedPosts(response.category);
            });
    };

    const fetchRelatedPosts = async (category) => {
        let url = `https://public-api.wordpress.com/rest/v1/sites/${wordpressSite}/posts?number=3`;
        if (category != null && category !== "") {
            url = `${url}&category=${category}`
        }
        await fetch(
            url,
            {
                method: "GET",
                headers: {
                    accept: "application/json",
                    "content-type": "application/json",
                },
            }
        )
            .then((res) => res.json())
            .then((response) => {
                setRelatedPosts(response?.posts);
            });
    };

    useEffect(() => {
        if (params.postSlug !== null) {
            fetchPost();
        }
    }, [params]);


    return <ComponentContainer>
        {loading && <Loader/>}
        {!loading &&
            <div className={"grid gap-4 place-content-center p-4"}>
                <div className={"col-span-1 self-center"}>
                    <div
                        className={"w-full md:w-3/5 text-4xl crimson-font font-bold heading-text text-center m-auto"}>{currentPost.title}</div>
                    <div className={"flex flex-row justify-center content-center my-2"}>
                        <CategoryPill text={currentPost.category} onClick={() => {
                        }} selected={true}/>
                        <div
                            className={"text-xs crimson-font secondary-text-dmsans mt-2 self-center"}>
                            {moment(currentPost.modified).format("DD/MM/YYYY")}
                        </div>
                    </div>
                    <img className={"col-span-1 w-full max-h-[32rem] object-cover self-center mt-2"}
                         src={currentPost.featured_image}/>
                    <div className={"grid md:grid-cols-3 grid-cols-1 gap-4 mt-4"}>
                        <div className={"col-span-1 md:col-span-2 mt-4 secondary-text-dmsans"}>
                            <div className={"border-b-2 border-inherit"} dangerouslySetInnerHTML={{__html: currentPost.content}}/>
                            <ShareBlogComponent link={`https://pocketnote.com.au/blog/${currentPost.slug}`}/>
                        </div>
                        <div className={"col-span-1"}>
                            <SubscribeNewsLetter />
                        </div>
                    </div>
                    {relatedPosts.length > 0 && <div>
                        <div className={"text-4xl crimson-font font-bold heading-text mt-8"}>
                            Similar Posts
                        </div>
                        <div className={"grid grid-cols-1 md:grid-cols-3 gap-4 mt-4 mb-4"}>
                            {relatedPosts.map(r => <BlogComponent
                                title={r.title}
                                modified={r.modified}
                                content={r.content}
                                excerpt={r.excerpt}
                                featured_image={r.featured_image}
                                category={r.category}
                                slug={r.slug} />)}
                        </div>
                    </div>}

                </div>
            </div>}
    </ComponentContainer>
}

import React from "react";
import Switch from "../Switch";
import LandingDesktopTemplate from "../Landing/LandingDesktopTemplate";
import LandingMobileTemplate from "../Landing/LandingMobileTemplate";

const heroImage = "icons/math-tutor/main-icon.png";
const innovationText = "Your One-Stop";
const tutoringText = "Destination for Maths Tutoring in Australia";
const parentText =
  "Finding the right fit for Maths tutoring in Australia can be a daunting task, but Pocketnote makes this process simple and hassle-free with our convenient online portal. Our online tutoring service makes it easy to find the right Maths tutor for you or your child,  whether you're looking for online tutoring for Maths or a private Maths tutor near you for in-person sessions.";
const bookTutorBtnText = "Book Tutor";
const becomeTutorBtnText = "Become a Tutor";
const buyNotesBtnText = "Buy Notes";
const whyChooseTitle = "Why Choose Pocketnote to Find Maths Tutors in Australia";
const signUpText =
  "Sign up today and experience the benefits that our convenient digital portal has to offer. Access the best tutors in Australia and accelerate your child's education with Pocketnote.";
const choosePocketnoteSection = [
  {
    icon: "url(/icons/math-tutor/rating.png)",
    heading: "Verified and Qualified Tutors",
    details:
      "At Pocketnote, we believe in providing only the highest-quality education to our students. That's why all of our tutors are verified and qualified, ensuring that your child receives top-class tutoring from the best in the field.",
  },
  {
    icon: "url(/icons/math-tutor/tools.png)",
    heading: "Find the Best Maths Tutor in Australia",
    details:
      "Don't wait any longer to find the best Maths tutor in Australia or a HSC, QCE, IB, WACE, SACE, and VCE Maths tutor near you.",
  },
  {
    icon: "url(/icons/math-tutor/books.png)",
    heading: "Primary, High School, ATAR Maths Tutors and More",
    details:
      "From kindergarten to primary school and high-school to ATAR, our Maths tutors can help students of all ages and educational levels increase their confidence and abilities. We also have a range of NDIS-approved tutors who are experienced in working with children with specialised learning needs.",
  },
  {
    icon: "url(/icons/math-tutor/refund.png)",
    heading: "100% Refund Guarantee",
    details:
      "We stand behind the quality of our services and offer a 100% refund guarantee on the first session, with no questions asked. We are proud of our five-star Google rating, which makes us the most trusted platform for online tutoring in Australia.",
  },
  {
    icon: "url(/icons/math-tutor/time-is-money.png)",
    heading: "Flexible Scheduling and Affordable Pricing",
    details:
      "Our platform offers the flexibility to schedule sessions at any time and from any location, making it easy for you to fit learning into your busy lifestyle. Online tutoring also means that students can receive help from the comfort of their own home, without having to travel to a physical location. To make the experience even more seamless, we offer affordable pricing options which make us accessible to all Australians.",
  },
  {
    icon: "url(/icons/math-tutor/goals.png)",
    heading: "Personalised Learning Experience",
    details:
      "Pocketnote provides a personalised learning experience that caters to individual learning styles and is customised for your academic goals. Our digital platform offers Maths tutoring online in a variety of learning styles, including text, audio, and video, giving students the flexibility to choose the method that suits them best. Whether your child is a visual or auditory learner, our online tutoring ensures that each student gets the attention and guidance they need to succeed in Maths.",
  },
];

export default ({ user }: any) => {
  return (
    <>
      <Switch>
        {[
          <LandingMobileTemplate
            key="mobile"
            user={user}
            heroImage={heroImage}
            innovationText={innovationText}
            tutoringText={tutoringText}
            parentText={parentText}
            whyChooseTitle={whyChooseTitle}
            bookTutorBtnText={bookTutorBtnText}
            becomeTutorBtnText={becomeTutorBtnText}
            buyNotesBtnText={buyNotesBtnText}
            signUpText={signUpText}
            choosePocketnoteSection={choosePocketnoteSection}
          />,
          <LandingDesktopTemplate
            key="desktop"
            user={user}
            heroImage={heroImage}
            innovationText={innovationText}
            tutoringText={tutoringText}
            parentText={parentText}
            bookTutorBtnText={bookTutorBtnText}
            becomeTutorBtnText={becomeTutorBtnText}
            signUpText={signUpText}
            whyChooseTitle={whyChooseTitle}
            choosePocketnoteSection={choosePocketnoteSection}
          />,
        ]}
      </Switch>
    </>
  );
};

import React, {useState} from "react";
import {ColumnsType} from "antd/es/table";
import {Button, Col, Row, Space, Tag, Typography, Modal, Spin} from "antd";
import {Table} from "antd/es";
import {CheckOutlined, CloseOutlined, DeleteOutlined, EyeOutlined} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import {getAllTutorNotes, getPrivateUrlForUserNotes} from "../../common/queries";
import {useMutation, useQuery} from "@apollo/client";
import {
    approveTutorNotes as approveTutorNotesMutation,
    declineTutorNotes as declineTutorNotesMutation, deleteTutorNotes as deleteTutorNotesMutation
} from "../../common/mutations";
import apollo from "../../../apollo";
import {FullScreenLoader} from "../../common/Loader";


const {Title, Text} = Typography;


interface DataType {
    id: string;
    tutor: any;
    title: string;
    subject: any;
    sellPrice: number;
    semester: string;
    exam: string;
    publish_date?: string;
    status: string;
    notes: any
    sampleNotes: any
}


const statusColor = (status: string) => {
    // console.log("here here", status)
    let color = (status === 'APPROVED') ? "#62AC61" : "#FF3C3C";

    return (
        <Text style={{color: color}}>
            {status}
        </Text>
    );

}

let notesURLs = []


export const ReviewNotes = () => {

    const [notesId, setNotesId] = useState<string>('')
    const [declineMsg, setDeclineMsg] = useState<string>('')



    const [approveNotes] = useMutation(approveTutorNotesMutation)
    const [declineNotes] = useMutation(declineTutorNotesMutation)


    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isDeclineModalVisible, setIsDeclineModalVisible] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

    const [deleteNotes] = useMutation(deleteTutorNotesMutation);
    const {data: tutorNotesData, loading, refetch} = useQuery(getAllTutorNotes)
    // console.log("Data of tutor notes", tutorNotesData.allTutorNotes)

    if (loading) return <FullScreenLoader/>;


    let tutorNotesList = tutorNotesData?.allTutorNotes || [];

    const columns: ColumnsType<DataType> = [
        {
            title: 'Tutor Name',
            dataIndex: 'tutor',
            key: 'tutor',
            render: (_, {tutor}) => (

                <>
                    {
                        tutor ?
                            (
                                <Text style={{fontWeight: "bolder"}}>{tutor.name}</Text>
                            )
                            :
                            (
                                <></>
                            )
                    }
                </>
            )
        },
        {
            title: 'Notes Title',
            dataIndex: 'title',
            key: 'title',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Subject',
            dataIndex: 'subject',
            key: 'subject',
            render: (_, {subject}) => (

                <>
                    {
                        subject ?
                            (
                                <Text style={{fontWeight: "bolder"}}>{subject.name}</Text>
                            )
                            :
                            (
                                <></>
                            )
                    }
                </>
            )
        },
        {
            title: 'Price',
            dataIndex: 'sellPrice',
            key: 'sellPrice',
            render: (_, {sellPrice}) => (

                <>
                    {
                        sellPrice ?
                            (
                                <Text style={{fontWeight: "bolder"}}>${sellPrice}</Text>
                            )
                            :
                            (
                                <></>
                            )
                    }
                </>
            )
        },
        {
            title: 'Semester',
            dataIndex: 'semester',
            key: 'semester',
        },
        {
            title: 'Exam',
            dataIndex: 'exam',
            key: 'exam',
        },
        // {
        //     title: 'Published',
        //     dataIndex: 'createdAt',
        //     key: 'createdAt',
        // },
        {
            title: 'Status by Pocketnote',
            key: 'status',
            dataIndex: 'status',
            render: (_, {status}) => (
                <>
                    {
                        statusColor(status)
                    }
                </>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    {/*<a>Invite {record.title}</a>*/}
                    {/*<a>Delete</a>*/}

                    <EyeOutlined onClick={() => {

                        notesURLs = []
                        notesURLs.push(record?.sampleNotes.url)
                        notesURLs.push(record?.id)

                        console.log(notesURLs, "notesURLs")
                        showModal()
                    }} style={{color: "#1E93FF"}}/>

                    <DeleteOutlined onClick={
                        () => {
                            // console.log("hello I am delete!!!!", record)

                            setNotesId(record.id)
                            showDeleteModal()

                        }} style={{color: "#FF3C3C"}}/>

                    {
                        (record.status === "PENDING") ?
                            (
                                <>
                                    <CheckOutlined style={{color: "#79B778"}} onClick={
                                        async () => {
                                            await approveNotes({
                                                variables: {id: record.id}
                                            })
                                            refetch()
                                        }}/>

                                    <CloseOutlined style={{color: "#FF3C3C"}} onClick={
                                        () => {
                                            setNotesId(record.id)
                                            showDeclineModal()
                                        }}/>
                                </>
                            )
                            :
                            (<></>)
                    }


                </Space>
            ),
        },
    ];

    const showDeleteModal = () => {
        setIsDeleteModalVisible(true);
    };

    const handleDeleteOk = async () => {

        await deleteNotes({
            variables: {id: notesId},
        });
        setIsDeleteModalVisible(false);
        setNotesId('')
        refetch()
    }


    const handleDeleteCancel = () => {
        setIsDeleteModalVisible(false);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const showDeclineModal = () => {
        setIsDeclineModalVisible(true);
    };

    const handleDeclineOk = async () => {
        setIsDeclineModalVisible(false);
        await declineNotes({
            variables: {id: notesId, message: declineMsg}
        })
        setNotesId('')
        setDeclineMsg('')
        refetch()

    };

    const handleDeclineCancel = () => {
        setIsDeclineModalVisible(false);
    };

    return (

        <>

            <Modal
                title="Delete Notes"
                visible={isDeleteModalVisible}
                onOk={handleDeleteOk}
                onCancel={handleDeleteCancel}>

                <h3>Are you sure you want to delete these notes?</h3>
            </Modal>

            <Row>
                <Col xs={1} sm={1} md={1} lg={0} xxl={0} xl={0}/>
                <Col id="AccountDesktop" xs={23} sm={23} md={23} lg={22} xxl={22} xl={22}>
                    <Row className="account-setting-align">
                        <Col xs={18} sm={18} md={18} lg={16} xxl={16} xl={16}>
                            <Title className="account-setting-title">Review Notes</Title>
                        </Col>

                        <Col xs={0} sm={0} md={0} lg={6} xxl={6} xl={6} style={{paddingBottom: "1%"}}>
                            <Button type="primary" size="large" block ghost>
                              <span>
                                Filter
                              </span>
                            </Button>
                        </Col>

                    </Row>


                    {!loading ?
                        (
                            <Col id="AccountDesktop" xs={23} sm={23} md={23} lg={22} xxl={22} xl={22}>
                                <Table columns={columns} dataSource={tutorNotesList}/>
                                <Row className="row-height"/>

                            </Col>
                        )
                        :
                        (
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>

                                <Spin size="large"/>
                            </div>

                        )
                    }
                </Col>
            </Row>

            <Modal
                title="View Notes"
                visible={isModalVisible}
                onOk={async () => await handleOk}
                onCancel={handleCancel}
                width={700}
            >

                <Row>
                    <Col span={12} style={{textAlign: "center"}}>
                        <a onClick={() => {
                            window.open(notesURLs[0])
                        }}>Show Sample Notes</a>

                    </Col>

                    <Col span={12} style={{textAlign: "center"}}>
                        <a onClick={async () => {
                            const {data: urlObj} = await apollo.query({
                                query: getPrivateUrlForUserNotes,
                                variables: {
                                    id: notesURLs[1]
                                }
                            });

                            const url = urlObj.privateTempUrl;
                            window.open(url, "_blank");
                        }}>Show Full Notes</a>

                    </Col>
                </Row>
            </Modal>

            <Modal title="Decline Modal" visible={isDeclineModalVisible} onOk={handleDeclineOk}
                   onCancel={handleDeclineCancel}>

                <h3>Are you sure you want to Decline this notes approval request?</h3>
                <p>If Yes, Please write a reason/explanation for the tutor (optional)</p>
                <TextArea
                    rows={4}
                    maxLength={200}
                    placeholder="maxLength is 200"
                    value={declineMsg}
                    onChange={(e) => {
                        console.log("e.target.value", e.target.value)
                        let message = e.target.value
                        setDeclineMsg(message)
                    }}
                />
            </Modal>


        </>
    )
}

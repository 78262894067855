import React from 'react';
import styled from '@emotion/styled';
import {useFilters, usePagination, useSortBy, useTable,} from 'react-table';
import matchSorter from 'match-sorter';
import {faSortAlphaDownAlt, faSortAlphaUp,} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {DefaultColumnFilter} from './filters';

const Styles = styled.div`
  table {
    width: 100%;
    border-spacing: 0;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 2px;
      border: none;
    }
    td {
      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
    tr:nth-child(even) {
      background: hsla(0, 0%, 0%, 0.05);
    }
    tr:hover {
      background: hsla(0, 0%, 0%, 0.1);
    }
  }
  .pagination {
    padding: 0.5rem;
  }
`;

function fuzzyTextFilterFn(rows: any, id: any, filterValue: any) {
    return matchSorter(rows, filterValue, {
        keys: [(row: any) => row.values[id]],
    });
}

fuzzyTextFilterFn.autoRemove = (val: any) => !val;

function Table({
                   columns,
                   data,
                   initialState = {},
                   pageCount: controlledPageCount,
                   fetchData,
                   pageIndex: controlledPageIndex,
                   sortBy: controlledSortBy,
                   filters: controlledFilters,
                   manual = false,
                   manualPagination = true,
                   manualSortBy,
                   manualPayment,
                   setManualPayment,
                   setParams,
               }: any) {


    const filterTypes = React.useMemo(
        () => ({
            fuzzyText: fuzzyTextFilterFn,
            text: (rows: any, id: any, filterValue: any) => {
                return rows.filter((row: any) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            },
        }),
        [],
    );

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        [],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        // rows,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        // preGlobalFilteredRows,
        // setGlobalFilter,
        state: {
            pageIndex,
            pageSize,
            sortBy,
            // groupBy,
            // expanded,
            filters,
            // selectedRowIds,
            // globalFilter,
        },
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            filterTypes,
            initialState: {
                pageIndex: controlledPageIndex,
                pageSize: 32,
                sortBy: controlledSortBy || [],
                filters: controlledFilters || [],
                ...initialState,
            },
            manualPagination: manualPagination ?? manual,
            pageCount: controlledPageCount,
            manualSortBy: manualSortBy ?? manual,
            manualFilters: true,
            autoResetSortBy: false,
            autoResetPage: false,
        } as any,
        useFilters,
        useSortBy,
        usePagination,
    ) as any;

    React.useEffect(() => {
        fetchData && fetchData({pageIndex, pageSize, sortBy, filters});
    }, [fetchData, pageIndex, pageSize, sortBy, filters]);

    return (
        <>
            {/* <div>{pageIndex}</div> */}
            {manual && (
                <div className="pagination">
                    <button
                        type="button"
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                    >
                        {'<<'}
                    </button>
                    {' '}
                    <button
                        type="button"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                    >
                        {'<'}
                    </button>
                    {' '}
                    <button
                        type="button"
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                    >
                        {'>'}
                    </button>
                    {' '}
                    <button
                        type="button"
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                    >
                        {'>>'}
                    </button>
                    {' '}
                    <span>
            Page{' '}
                        <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
                    <span>
            | Go to page:{' '}
                        <input
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={(e) => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                gotoPage(page);
                            }}
                            style={{width: '100px'}}
                        />
          </span>{' '}
                    <select
                        value={pageSize}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {[pageSize, 10, 20, 30, 40, 50, 256, 512].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>

                    {setManualPayment != null && <div>
                    <span>

           {' '} Manual Payment:
                    <input
                        type="checkbox"
                        checked={manualPayment}
                        onChange={(e: any) => {
                            setManualPayment(e.target.checked)
                            setParams(
                                {
                                    skip: pageSize * pageIndex,
                                    take: pageSize,
                                    sort: sortBy,
                                    manualPayment: e.target.checked
                                }
                            )
                        }}
                    ></input>
                    </span>{' '}
                    </div>}
                </div>
            )}
            {/* <div>
        <pre>
          <code>{JSON.stringify(state.filters, null, 2)}</code>
        </pre>
      </div> */}
            <table {...getTableProps()}>
                <thead>
                {headerGroups.map((headerGroup: any) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column: any) => (
                            <>
                                <th>
                                    <div {...column.getHeaderProps(column.getSortByToggleProps())}
                                         style={{display: 'flex'}}>
                                        <div>{column.render('Header')}</div>
                                        {column.isSorted && (
                                            <div style={{margin: '0 0.25em'}}>
                                                {column.isSortedDesc ? (
                                                    <FontAwesomeIcon icon={faSortAlphaDownAlt}/>
                                                ) : (
                                                    <FontAwesomeIcon icon={faSortAlphaUp}/>
                                                )}
                                            </div>
                                        )}
                                    </div>

                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>

                            </>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row: any, i: any) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell: any) => {
                                return (
                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                );
                            })}
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </>
    );
}

export default (props: any) => (
    <Styles>
        <Table {...props} />
    </Styles>
);

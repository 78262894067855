import React from "react";
import {Card, Row, Col, Typography, Space, Button} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";

import './Mobile.less'

const {Title, Text} = Typography;

export default (props: any) => {

    const {tutorNotesList, onClickNotes} = props
    return (
        <div id="YourNotesMobile">
            <Card bordered={true} style={{width: "100%"}}>
                <Title className="title-text">Your Notes</Title>
            </Card>

            {
                tutorNotesList?.map(item => <Card key={item.id} bordered={true} style={{width: "100%", marginBottom: 2}}>
                        <Row>
                            <Col span={12}>
                                <p className="label-text">Purchased</p>
                            </Col>

                            <Col span={12}>
                                <p className="item-text">{new Date(item?.boughtAt).toLocaleDateString()}</p>
                            </Col>

                            <Col span={12}>
                                <p className="label-text">Tutor Name</p>
                            </Col>

                            <Col span={12}>
                                <p className="item-text">{item?.tutorNotes.tutor.name}</p>
                            </Col>

                            <Col span={12}>
                                <p className="label-text">Notes Title</p>
                            </Col>

                            <Col span={12}>
                                <p className="item-text">{item?.tutorNotes.title}</p>
                            </Col>

                            <Col span={12}>
                                <p className="label-text">Subject</p>
                            </Col>

                            <Col span={12}>
                                <p className="item-text">{item?.tutorNotes.subject.name}</p>
                            </Col>

                            <Col span={12}>
                                <p className="label-text">Price</p>
                            </Col>

                            <Col span={12}>
                                <p className="item-text">${item?.tutorNotes.sellPrice}</p>
                            </Col>

                            <Col span={12}>
                                <p className="label-text">Semester</p>
                            </Col>

                            <Col span={12}>
                                <p className="item-text">{item?.tutorNotes.semester}</p>
                            </Col>

                            <Col span={12}>
                                <p className="label-text">Exam</p>
                            </Col>

                            <Col span={12}>
                                <p className="item-text">{item?.tutorNotes.exam}</p>
                            </Col>

                            <Col span={12}>
                                <p className="label-text">Year</p>
                            </Col>

                            <Col span={12}>
                                <p className="item-text">{item?.tutorNotes.yearLevel}</p>
                            </Col>

                            <Col span={12}>
                                <p className="label-text">Paid</p>
                            </Col>

                            <Col span={12}>
                                <p className="item-text">{item?.payment.paid ? "Yes" : "No"}</p>
                            </Col>

                            <Col span={2}/>

                            {item?.payment.paid && <Col span={20}>
                                <Button type="primary" className="view-notes-button" onClick={ () => onClickNotes(item?.tutorNotes.id)}>
                                    View Notes
                                </Button>
                            </Col>}

                        </Row>

                    </Card>
                )
            }
        </div>
    )
}
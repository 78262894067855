import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


export const LineChart = (props: any) => {

    const {title, dataset1_title, dataset2_title, labels, dataset1, dataset2} = props

    let options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: title,
            },
        },
    };


    let data = {
        labels,
        datasets: [
            {
                label: dataset1_title,
                data: dataset1 ,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgb(252, 153, 153)',
                cubicInterpolationMode: "monotone" as any,
            },
            {
                label: dataset2_title,
                data: dataset2 ,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                cubicInterpolationMode: "monotone" as any,
            },
        ],
    };


    return <Line options={options} data={data} />;
}
import {PanelProps} from "./LandingWhyPanel";

export default (props: PanelProps) => {
    const {imgSrc, text} = props;
    return <div className={"grid grid-cols-4 grid-rows-1 md:grid-cols-1 " +
        "md:grid-rows-2 place-content-center drop-shadow-sm " +
        "place-items-center p-4 why-panel-container"}>
        <img className={"col-span-1 md:row-span-1"} src={imgSrc}/>
        <span className={"col-span-3 md:row-span-1 text-center text-sm"}>{text}</span>
    </div>
}

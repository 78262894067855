import gql from "graphql-tag";
import {sortBy} from "lodash";
import React, {createContext, useEffect, useReducer, useState} from "react";
import {useQuery} from "@apollo/client";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEject} from "@fortawesome/free-solid-svg-icons";
import createAdminApolloClient from "../../createAdminApolloClient";
import {createJwtToken} from "../common/utils";

const apollo = createAdminApolloClient();

export const MasqueradeUUID = "4269D484-C027-488C-894E-EA0E24A268DE";
export const MasqueradeContext: any = createContext({
  state: {},
  dispatch: () => {},
});

const initialState = {};

let reducer = (state: any, action: any) => {
  switch (action.type) {
    case "clear":
      return initialState;
    case "session":
      if (action.payload.user.role !== "ADMIN") {
        return state;
      }
      localStorage.setItem(MasqueradeUUID, action.payload.token);
      return { ...state, session: action.payload };
    default:
      return state;
  }
};

export function MasqueradeProvider(props: any) {
  let [state, dispatch] = useReducer(reducer, initialState);
  let value: any = { state, dispatch };
  return (
    <MasqueradeContext.Provider value={value}>
      {props.children}
    </MasqueradeContext.Provider>
  );
}

export default function Masquerade(props: any) {
  const [visible, setVisible] = useState(
    localStorage.getItem("masquerade") !== "false"
  );

  useEffect(() => {
    const onKeyDown = (e: any) => {
      if (e.key !== "`") {
        return;
      }
      const value = !visible;
      setVisible(value);
      localStorage.setItem("masquerade", JSON.stringify(value));
    };
    window.addEventListener("keydown", onKeyDown);
    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  });

  const skip = !localStorage.getItem(MasqueradeUUID);

  const { data } = useQuery(
    gql`
      query Masqueradees {
        me {
          id
          role
          masqueradees {
            id
            role
            name
            avatar {
              id
              url
            }
          }
        }
      }
    `,
    {
      skip,
      client: apollo,
    }
  );

  const users = sortBy(data?.me?.masqueradees || [], ({ role }) =>
    ["ADMIN", "TUTOR", "PARENT"].indexOf(role)
  );

  return (
    <div
      style={{
        display: visible && !skip ? "flex" : "none",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
        }}
      >
        {users.map(({ id, email, name, avatar }, index) => (
          <div
            key={index}
            onClick={async () => {
              const token = await createJwtToken({ id, name, email });
              localStorage.setItem("token", token);
              (window as any).APOLLO.resetStore();
            }}
            style={{
              cursor: "pointer",
              width: 64,
              height: 64,
              backgroundImage: avatar ? `url(${avatar?.url})` : "none",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                background: "white",
                color: "black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                opacity: 0.5,
                fontSize: "32px",
              }}
            >
              {name
                .split(" ")
                .map((n: any) => n[0])
                .join("")}
            </div>
          </div>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 64,
          width: 64,
          opacity: 0.125,
          cursor: "pointer",
        }}
        onClick={() => {
          localStorage.clear();
          window.location.reload();
        }}
      >
        <FontAwesomeIcon icon={faEject} size={"3x"}></FontAwesomeIcon>
      </div>
    </div>
  );
}

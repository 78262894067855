import React from "react";
import Nav from "pages/Nav";
import Footer from "pages/Footer";
import { useGlobalLoading } from "hooks/useGlobalLoading";
import { User } from "@graphql-client/types";
import { useQuery } from "@apollo/client";
import { getCurrentUser } from "pages/common/queries";

export interface AppLayoutProps {
  children: any;
}

const AppLayout: React.FC<AppLayoutProps> = ({ children }: AppLayoutProps) => {
  const { loading, data } = useQuery<{ me: User }>(getCurrentUser);
  const user = data?.me ? data?.me : JSON.parse(localStorage.getItem("user"));

  useGlobalLoading([!!user && loading]);

  return (
    <>
      <Nav privateRoute={false} user={user} />
      {children}
      <Footer user={user} />
    </>
  );
};

export const PrivateRoutesLayout: React.FC<AppLayoutProps> = ({ children }: AppLayoutProps) => {
    const { loading, data } = useQuery<{ me: User }>(getCurrentUser);
    const user = data?.me ? data?.me : JSON.parse(localStorage.getItem("user"));

    useGlobalLoading([!!user && loading]);

    return (
        <>
            <Nav privateRoute={true} user={user} />
            {children}
            <Footer user={user} />
        </>
    );
};




export default AppLayout;

import React from 'react';
import {Button, Col, Input, Row, Typography} from 'antd';
import './Mobile.less';
import {Field} from 'formik';
import {Link, useHistory} from 'react-router-dom';
import Info from './Info';

const {Title, Paragraph} = Typography;

export default ({role, props}: any) => {
    const history = useHistory()

    return (
        <>
            <Row>
                <Col span={24}>
                    <Title className='align-center primary-text margin-top-24' level={3}> Pocketnote</Title>
                </Col>
                <Col span={24}>
                    <Title className='align-center' level={3}> connects tutors</Title>
                </Col>
                <Col span={24}>
                    <Title className='align-center' level={3}> and students.</Title>
                </Col>

                <Col span={24} className='margin-top-36 img-center'>
                    <div
                        style={{
                            backgroundImage: role !== 'tutor' ? `url(/icons/home-page/girl-icon.svg)` : `url(/icons/home-page/man-icon.svg)`,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            width: 368,
                            height: 220,
                        }}
                    ></div>
                </Col>

            </Row>

            {props.status === false && (
                <div className='error'>
                    <Paragraph>We had trouble creating a new account for you.</Paragraph>
                    <Paragraph>An account may already exist with this email.</Paragraph>
                    <Paragraph> Try <Link to="/login">signing in</Link> instead.</Paragraph>
                </div>
            )}
            <Row className='mobile-form background-primary no-margin border-radius-6 padding-bottom-48'>

                <Col span={24}>
                    <Title className='align-center secondary-text margin-top-24' level={3}>
                        {role !== 'tutor' ? 'Book a Tutor' : 'Become a Tutor'}
                    </Title>
                </Col>
                <Col span={1}/>
                <Col className='margin-top-24 ' span={22}>
                    <Field as={Input} name="name" className={"border-radius"} required pattern=".+ .+"
                           placeholder="Full name"/>
                </Col>
                <Col span={1}/>
                <Col span={1}/>
                <Col className='margin-top-24' span={22}>
                    <Field as={Input} type="tel" name="tel" required className={"border-radius"} pattern="[0-9]{10}"
                           maxLength={10}
                           placeholder="Phone"/>
                </Col>

                <Col span={1}/>
                <Col span={1}/>
                <Col className='margin-top-24' span={22}>
                    <Field as={Input} type="email" name="email" required className={"border-radius"}
                           placeholder="Email"/>
                </Col>

                <Col span={1}/>
                <Col span={1}/>
                <Col className='margin-top-24' span={22}>
                    <Field
                        className={"border-radius"}
                        as={Input}
                        type="password"
                        name="password"
                        required
                        autoComplete="new-password"
                        placeholder="Password"
                    />
                </Col>

                <Col span={1}/>
                <Col span={1}/>
                <Col className='margin-top-24' span={22}>
                    <Button htmlType='submit' className='box-shadow btn-secondary full-width' size='large'>
                        {role !== 'tutor' ? 'Find a Tutor' : 'Become a Tutor'}</Button>
                </Col>
                <Col span={1}/>
                <Col span={1}/>
                {/*{*/}
                {/*    role !== 'tutor' ? (*/}
                {/*        <Col className='margin-top-24' span={22}>*/}
                {/*            <Button onClick={() => history.push('/explore')} className='box-shadow btn-secondary full-width' size='large'>*/}
                {/*                Explore First</Button>*/}
                {/*        </Col>*/}
                {/*    ) : (<></>)*/}
                {/*}*/}
            </Row>

            <Row className='margin-top-24'>
                <Col className='margin-top-24 align-center' span={24}>
                    <Title level={3}>We offer you</Title>
                    <Title level={3} className='primary-text no-margin'>2 types of tutoring</Title>
                </Col>
            </Row>

            <Row className='margin-top-24'>
                <Col className='align-center img-center' span={8} offset={4}>
                    <div
                        style={{
                            backgroundImage: `url(/icons/join/online-img.svg)`,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            width: 75,
                            height: 75,
                        }}
                    ></div>
                </Col>
                <Col className='align-center align-item-center' span={12}>
                    <Title level={4} className='primary-text no-margin'>Online</Title>
                </Col>

                <Col className='align-center img-center margin-top-24' span={8} offset={4}>
                    <div
                        style={{
                            backgroundImage: `url(/icons/join/inperson-img.svg)`,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            width: 75,
                            height: 75,
                        }}
                    ></div>
                </Col>
                <Col className='align-center align-item-center' span={12}>
                    <Title level={4} className='primary-text no-margin'>In person</Title>
                </Col>
            </Row>

            <Row className='margin-top-36 padding-top-verified background-secondary info-box padding-bottom-48'>

                {role !== 'tutor' ?
                    <>
                        <Info title='Verified'
                              description='All tutors are ID checked and verified to work with students.'
                              icon='icons/join/book-tutor/verified-icon'/>
                        <Col span={24}>
                            <Row className="row-height"/>
                            <Row className="row-height"/>
                        </Col>
                        <Info title='Qualified'
                              description='Our tutors are highly qualified to provide an enriching learning experience.'
                              icon='icons/join/book-tutor/qualified-icon'/>
                        <Col span={24}>
                            <Row className="row-height"/>
                            <Row className="row-height"/>
                        </Col>
                        <Info title='Flexible' description='Tutors are flexible to work around your personal schedule.'
                              icon='icons/join/book-tutor/flexible-icon'/>
                        <Col span={24}>
                            <Row className="row-height"/>
                            <Row className="row-height"/>
                        </Col>
                        <Info title='Reviewed'
                              description='Student feedback on the tutor profiles shows lesson ratings and student reviews.'
                              icon='icons/join/book-tutor/reviewed-icon'/>
                    </>
                    :
                    <>
                        <Info title='Your Area'
                              description='Our location feature ensures you only work in the area of your choice.'
                              icon='icons/join/become-tutor/area-icon'/>
                        <Col span={24}>
                            <Row className="row-height"/>
                            <Row className="row-height"/>
                        </Col>
                        <Info title='Your Schedule'
                              description='We understand life is busy  so we believe it is essential you set your  own schedule. Work when you want. No boss. No office.'
                              icon='icons/join/become-tutor/schedule-icon'/>
                        <Col span={24}>
                            <Row className="row-height"/>
                            <Row className="row-height"/>
                        </Col>
                        <Info title='Your Price'
                              description='Earn what you deserve based on your qualifications and experience.'
                              icon='icons/join/become-tutor/price-icon'/>
                        <Col span={24}>
                            <Row className="row-height"/>
                            <Row className="row-height"/>
                        </Col>
                        <Info title='Your Convenience'
                              description='No more chasing up students with automated payments.'
                              icon='icons/join/become-tutor/convenience-icon'/>
                    </>
                }
            </Row>
            {/*<Footer/>*/}
        </>
    );
}

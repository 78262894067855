import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import { Typography } from 'antd';
import { Button } from 'antd';
import { Avatar } from 'antd';
import { useHistory } from 'react-router-dom';
import './Mobile.less';
const { Title, Paragraph } = Typography;

export default () => {
  const history = useHistory();

  return (
    <div className='background-secondary full-height signup-container'>
      <Row className='background-plain main-container'>
        <Col onClick={() => history.goBack()} span={2}>
          <Avatar className='direction' src="/icons/direction-icons/left-arrow.svg" />
        </Col>
        <Col className='align-center margin-top-36' span={24}>
          <Title level={3}>Create a free account to continue</Title>
        </Col>
        <Col className='align-center margin-top-12' span={24}>
          <Paragraph className='heading-text'>Already have an account? <Link to={'/login'} className='link-text'>Log in</Link></Paragraph>
        </Col>

        <Col className='align-center margin-top-48' span={24}>
          <Paragraph>By signing up, I agree to Pocketnote’s
                <Link to={'/legal'} className='link-text'> terms of use </Link>
                and <a rel="noopener noreferrer" href="https://stripe.com/connect-account/legal" className='link-text' target="_blank"> Stripe Connected </a>
                privacy policy.
              </Paragraph>
        </Col>

        <Col className='align-center margin-top-24' span={24}>
          <Link to='/join/parent'>
            <Button type="primary" size='large'>Join as Student</Button>
          </Link>
        </Col>

        <Col className='align-center margin-top-24' span={24}>
          <Link to='/join/tutor'>
            <Button size='large'>Join as Tutor</Button>
          </Link>
        </Col>

      </Row>
    </div>
  )
}

import React from "react";
import {
    Row,
    Col,
    Typography,
    Input,
    Select,
    Tag
} from "antd";
import "./Desktop.less";
import {InfoCircleOutlined} from "@ant-design/icons";
import {Field} from 'formik';
import {useQuery} from "@apollo/client";
import {getSchools, getSkills} from "../../common/queries";
import {tagRender} from "../../components/MultipleSelectTagRender";
import {TUTOR_COMMISSION} from "../../common/utils";

const {Title, Text} = Typography;
const {TextArea} = Input


export default ({props}) => {

    const {data} = useQuery(getSchools);
    let schools = []
    data?.schools?.map(school => {
        schools.push({id: school.id, value: school.name})
    });

    const {data: skills} = useQuery(getSkills);
    const subjects = [];
    skills?.skills?.map(sub => {
        subjects.push({value: sub.name})
    })

    return (
        <Row>
            <Title className="contact-info-title">Public Profile</Title>
            <Col span={24}>
                <Field
                    as={TextArea}
                    name="title"
                    autoSize={{minRows: 2, maxRows: 2}}
                    placeholder="Title (only 96 characters allowed)"
                    className="select-width"
                    size="large"
                    maxlength="96"
                />
            </Col>
            {/*<Col span={24}>*/}
            {/*    <Select*/}
            {/*        onChange={(value => {*/}
            {/*            props.setFieldValue("skills", value)*/}
            {/*        })}*/}
            {/*        showArrow*/}
            {/*        mode="multiple"*/}
            {/*        tagRender={tagRender}*/}
            {/*        placeholder="Subjects"*/}
            {/*        className="select-width"*/}
            {/*        size="large"*/}
            {/*        options={subjects}*/}
            {/*        value={props.values.skills ? props.values.skills : null}*/}
            {/*    />*/}
            {/*</Col>*/}
            <Col xs={24} sm={24} md={24} lg={11} xxl={11} xl={11}>
                <Field
                    name={'rate'}
                    as={Input}
                    type={"number"}
                    placeholder="Hourly Rate"
                    className="select-width"
                    min={0}
                />
            </Col>
            <Col span={2}/>
            <Col xs={24} sm={24} md={24} lg={0} xxl={0} xl={0}><Row className="row-height2"/></Col>
            <Col xs={24} sm={24} md={24} lg={0} xxl={0} xl={0} className={"service-fee-info"}>
                <Row>
                    <Col span={2} className={"info-col"}>
                        <InfoCircleOutlined style={{color: '#E26F6F', fontSize: 'xx-large'}}/>
                    </Col>
                    <Col span={2}/>
                    <Col span={20} style={{marginTop: '-8px', lineHeight: 'normal'}}>
                        <Row>
                            <Col span={24}>
                                <Text className="pocketnote-description">
                                    Pocketnote service charges of 15% are applied on this rate
                                </Text>
                                <Col span={24}><Row className="row-height"/><Row className="row-height2"/></Col>
                                <Col span={20} style={{marginTop: '-11px', lineHeight: 'normal'}}>
                                    <Text className="pocketnote-description">
                                        You will receive:
                                    </Text>
                                </Col>
                                <Col span={24}><Row className="row-height2"/></Col>
                                <Col span={20} style={{marginTop: '-11px', lineHeight: 'normal'}}>
                                    <Text className="pocketnote-description">
                                        ${props.values.rate} - 15% = ${Number(props.values.rate - props.values.rate * TUTOR_COMMISSION)}
                                    </Text>
                                </Col>
                            </Col>
                        </Row>

                    </Col>

                </Row>

            </Col>
            <Col  xs={24} sm={24} md={24} lg={11} xxl={11} xl={11}>
                <Select
                    onChange={(value => {
                        props.setFieldValue("skills", value)
                    })}
                    showArrow
                    mode="multiple"
                    tagRender={tagRender}
                    placeholder="Subjects"
                    className="select-width"
                    size="large"
                    options={subjects}
                    value={props.values.skills ? props.values.skills : null}
                />

            </Col>
            <Col span={24}><Row className="row-height2"/></Col>
            <Col xs={0} sm={0} md={0} lg={11} xxl={11} xl={11} className={"service-fee-info"}>
                <Row>
                    <Col span={2} className={"info-col"}>
                        <InfoCircleOutlined style={{color: '#E26F6F', fontSize: 'xx-large'}}/>
                    </Col>
                    <Col span={2}/>
                    <Col span={20} style={{marginTop: '-8px', lineHeight: 'normal'}}>
                        <Row>
                            <Col span={24}>
                                <Text className="pocketnote-description">
                                    Pocketnote service charges of 15% are applied on this rate
                                </Text>
                                <Col span={24}><Row className="row-height"/><Row className="row-height2"/></Col>
                                <Col span={20} style={{marginTop: '-11px', lineHeight: 'normal'}}>
                                    <Text className="pocketnote-description">
                                        You will receive:
                                    </Text>
                                </Col>
                                <Col span={24}><Row className="row-height2"/></Col>
                                <Col span={20} style={{marginTop: '-11px', lineHeight: 'normal'}}>
                                    <Text className="pocketnote-description">
                                        ${props.values.rate} - 15% = ${Number(props.values.rate - props.values.rate * TUTOR_COMMISSION)}
                                    </Text>
                                </Col>
                            </Col>
                        </Row>

                    </Col>

                </Row>


            </Col>
            <Col span={13}/>
            <Col span={24}>
                <Field
                    as={TextArea}
                    name="biography"
                    autoSize={{minRows: 4, maxRows: 6}}
                    placeholder="Biography"
                    className="select-width"
                    size="large"
                />
            </Col>

            {/*<Col span={24}>*/}
            {/*    <Field*/}
            {/*        as={TextArea}*/}
            {/*        name="achievements"*/}
            {/*        autoSize={{minRows: 4, maxRows: 6}}*/}
            {/*        placeholder="Achievements"*/}
            {/*        className="select-width"*/}
            {/*        size="large"*/}
            {/*    />*/}
            {/*</Col>*/}
        </Row>
    )
}

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    JSON: any;
    Upload: any;
};

export enum AuthenticationStrategy {
    Local = 'LOCAL',
    Google = 'GOOGLE'
}

export type Click = {
    __typename?: 'Click';
    id?: Maybe<Scalars['ID']>;
    path?: Maybe<Scalars['String']>;
    user?: Maybe<User>;
    createdAt?: Maybe<Scalars['String']>;
};

export type Conversation = {
    __typename?: 'Conversation';
    id?: Maybe<Scalars['ID']>;
    event?: Maybe<Event>;
    users?: Maybe<Array<Maybe<User>>>;
    summary?: Maybe<Message>;
    messages: Array<Message>;
    createdAt?: Maybe<Scalars['String']>;
    updatedAt?: Maybe<Scalars['String']>;
    time?: Maybe<Scalars['String']>;
};

export type CreateEnrollmentInput = {
    event: Scalars['ID'];
    student: Scalars['ID'];
    parent: Scalars['ID'];
};

export type CreateEventInput = {
    description: Scalars['String'];
    tutor: Scalars['String'];
    startDate: Scalars['String'];
    endDate: Scalars['String'];
    students?: Maybe<Array<Scalars['ID']>>;
    parent?: Maybe<Scalars['ID']>;
};

export type CreateSchoolInput = {
    name: Scalars['String'];
    slug?: Maybe<Scalars['String']>;
    logo?: Maybe<Scalars['ID']>;
};

export type Enrollment = {
    __typename?: 'Enrollment';
    id?: Maybe<Scalars['ID']>;
    approved?: Maybe<Scalars['Boolean']>;
    event?: Maybe<Event>;
    student?: Maybe<Student>;
    parent?: Maybe<User>;
    termsAccepted?: Maybe<Scalars['Boolean']>;
    payment?: Maybe<Payment>;
    createdAt?: Maybe<Scalars['String']>;
    updatedAt?: Maybe<Scalars['String']>;
};

export type EnrollmentInput = {
    approved?: Maybe<Scalars['Boolean']>;
};

export type Event = {
    __typename?: 'Event';
    id?: Maybe<Scalars['ID']>;
    owner?: Maybe<User>;
    rate?: Maybe<Scalars['Int']>;
    cost?: Maybe<Scalars['Int']>;
    price?: Maybe<Scalars['Int']>;
    name?: Maybe<Scalars['String']>;
    isFull?: Maybe<Scalars['Boolean']>;
    endDate?: Maybe<Scalars['String']>;
    startDate?: Maybe<Scalars['String']>;
    completed?: Maybe<Scalars['Boolean']>;
    description?: Maybe<Scalars['String']>;
    capacity?: Maybe<Scalars['Int']>;
    conversation?: Maybe<Conversation>;
    enrollments?: Maybe<Array<Enrollment>>;
    createdAt?: Maybe<Scalars['String']>;
    updatedAt?: Maybe<Scalars['String']>;
    creator?: Maybe<User>;
};

export type EventList = {
    __typename?: 'EventList';
    data?: Maybe<Array<Maybe<Event>>>;
    meta?: Maybe<PageMeta>;
};

export type File = {
    __typename?: 'File';
    id?: Maybe<Scalars['ID']>;
    url?: Maybe<Scalars['String']>;
    path?: Maybe<Scalars['String']>;
    filename?: Maybe<Scalars['String']>;
    mimetype?: Maybe<Scalars['String']>;
    encoding?: Maybe<Scalars['String']>;
    createdAt?: Maybe<Scalars['String']>;
    updatedAt?: Maybe<Scalars['String']>;
    thumb?: Maybe<Scalars['String']>;
    signedUrl?: Maybe<Scalars['String']>;
};

export type GeoLocationInput = {
    latitude: Scalars['Float'];
    longitude: Scalars['Float'];
};


export type Jwt = {
    __typename?: 'JWT';
    token?: Maybe<Scalars['String']>;
};

export type Message = {
    __typename?: 'Message';
    id?: Maybe<Scalars['ID']>;
    author?: Maybe<User>;
    content?: Maybe<Scalars['String']>;
    conversation?: Maybe<Conversation>;
    event?: Maybe<Event>;
    createdAt?: Maybe<Scalars['String']>;
};

export type Mutation = {
    __typename?: 'Mutation';
    logout?: Maybe<Scalars['Boolean']>;
    recover?: Maybe<Scalars['Boolean']>;
    login?: Maybe<Session>;
    createClick?: Maybe<Click>;
    sendMessage: Message;
    deleteMessage?: Maybe<Scalars['Boolean']>;
    upsertConversation: Conversation;
    deleteConversation?: Maybe<Scalars['Boolean']>;
    createEnrollment: Enrollment;
    acceptEnrollmentTerms?: Maybe<Result>;
    updateEnrollment: Enrollment;
    deleteEnrollment: Scalars['Boolean'];
    createEvent?: Maybe<Event>;
    updateEvent?: Maybe<Event>;
    deleteEvent?: Maybe<Scalars['Boolean']>;
    createRoom?: Maybe<Room>;
    readNotifications?: Maybe<Scalars['Boolean']>;
    createPayment: Payment;
    deleteStripeAccount?: Maybe<Scalars['Boolean']>;
    readConversation?: Maybe<Scalars['Boolean']>;
    approveReview?: Maybe<Review>;
    reviewTutor?: Maybe<Review>;
    deleteSchool?: Maybe<Scalars['Boolean']>;
    createSchool: School;
    updateSchool: School;
    deleteSkill?: Maybe<Scalars['Boolean']>;
    createSkill?: Maybe<Skill>;
    deleteStudent?: Maybe<Scalars['Boolean']>;
    createStudent?: Maybe<Student>;
    createUpload?: Maybe<File>;
    join?: Maybe<Session>;
    banUser?: Maybe<User>;
    updateUser?: Maybe<User>;
    deleteUser?: Maybe<Scalars['Boolean']>;
    updateTutorSkills?: Maybe<User>;
    updatePaymentCardToken?: Maybe<User>;
    addCurrentLocation?: Maybe<Result>;
    deleteAccount?: Maybe<Result>;
};


export type MutationRecoverArgs = {
    email: Scalars['String'];
};


export type MutationLoginArgs = {
    email: Scalars['String'];
    password: Scalars['String'];
};


export type MutationCreateClickArgs = {
    path: Scalars['String'];
    user?: Maybe<Scalars['ID']>;
};


export type MutationSendMessageArgs = {
    conversation: Scalars['ID'];
    user: Scalars['ID'];
    content: Scalars['String'];
};


export type MutationDeleteMessageArgs = {
    id: Scalars['ID'];
};


export type MutationUpsertConversationArgs = {
    me: Scalars['ID'];
    them: Scalars['ID'];
};


export type MutationDeleteConversationArgs = {
    id: Scalars['ID'];
};


export type MutationCreateEnrollmentArgs = {
    input: CreateEnrollmentInput;
};


export type MutationAcceptEnrollmentTermsArgs = {
    id: Scalars['ID'];
};


export type MutationUpdateEnrollmentArgs = {
    id: Scalars['ID'];
    input: EnrollmentInput;
};


export type MutationDeleteEnrollmentArgs = {
    id: Scalars['ID'];
};


export type MutationCreateEventArgs = {
    input: CreateEventInput;
};


export type MutationUpdateEventArgs = {
    id: Scalars['ID'];
    input: UpdateEventInput;
};


export type MutationDeleteEventArgs = {
    id: Scalars['ID'];
};


export type MutationCreateRoomArgs = {
    event: Scalars['ID'];
};


export type MutationReadNotificationsArgs = {
    notifications: Array<Scalars['ID']>;
};


export type MutationCreatePaymentArgs = {
    enrollments: Array<Scalars['ID']>;
    customer: Scalars['String'];
    amount: Scalars['Int'];
    transfer: Scalars['JSON'];
};


export type MutationDeleteStripeAccountArgs = {
    id: Scalars['String'];
};


export type MutationReadConversationArgs = {
    user: Scalars['ID'];
    conversation: Scalars['ID'];
};


export type MutationApproveReviewArgs = {
    id: Scalars['ID'];
};


export type MutationReviewTutorArgs = {
    content: Scalars['String'];
    rating: Scalars['Float'];
};


export type MutationDeleteSchoolArgs = {
    id: Scalars['ID'];
};


export type MutationCreateSchoolArgs = {
    input: CreateSchoolInput;
};


export type MutationUpdateSchoolArgs = {
    id: Scalars['ID'];
    input: UpdateSchoolInput;
};


export type MutationDeleteSkillArgs = {
    id: Scalars['ID'];
};


export type MutationCreateSkillArgs = {
    name: Scalars['String'];
    parent?: Maybe<Scalars['ID']>;
};


export type MutationDeleteStudentArgs = {
    id: Scalars['ID'];
};


export type MutationCreateStudentArgs = {
    name: Scalars['String'];
    email?: Maybe<Scalars['String']>;
    school?: Maybe<Scalars['ID']>;
    parent: Scalars['ID'];
};


export type MutationCreateUploadArgs = {
    mime: Scalars['String'];
    filename?: Maybe<Scalars['String']>;
};


export type MutationJoinArgs = {
    role: Scalars['String'];
    name: Scalars['String'];
    email: Scalars['String'];
    tel: Scalars['String'];
    password: Scalars['String'];
    students?: Maybe<Array<Maybe<StudentItem>>>;
};


export type MutationBanUserArgs = {
    id: Scalars['ID'];
    reason?: Maybe<Scalars['String']>;
};


export type MutationUpdateUserArgs = {
    id: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    tel?: Maybe<Scalars['String']>;
    password?: Maybe<Scalars['String']>;
    confirmPassword?: Maybe<Scalars['String']>;
    dob?: Maybe<Scalars['String']>;
    bankAccountNumber?: Maybe<Scalars['String']>;
    bankRoutingNumber?: Maybe<Scalars['String']>;
    googlePlace?: Maybe<Scalars['JSON']>;
    rate?: Maybe<Scalars['Float']>;
    currency?: Maybe<Scalars['String']>;
    avatar?: Maybe<Scalars['ID']>;
    skills?: Maybe<Array<SkillInput>>;
    verificationDocumentFront?: Maybe<Scalars['String']>;
    verificationDocumentBack?: Maybe<Scalars['String']>;
    biography?: Maybe<Scalars['String']>;
    experience?: Maybe<Scalars['String']>;
    school?: Maybe<Scalars['ID']>;
    availability?: Maybe<Scalars['String']>;
    achievements?: Maybe<Scalars['String']>;
    wwcc?: Maybe<Scalars['Boolean']>;
    qualified?: Maybe<Scalars['Boolean']>;
    subscription?: Maybe<Scalars['JSON']>;
    role?: Maybe<Scalars['String']>;

};


export type MutationDeleteUserArgs = {
    id: Scalars['ID'];
};


export type MutationUpdateTutorSkillsArgs = {
    id: Scalars['ID'];
    skills: Array<Scalars['ID']>;
};


export type MutationUpdatePaymentCardTokenArgs = {
    id: Scalars['ID'];
    token: Scalars['String'];
};


export type MutationAddCurrentLocationArgs = {
    location?: Maybe<GeoLocationInput>;
};

export type Notification = {
    __typename?: 'Notification';
    id?: Maybe<Scalars['ID']>;
    payload?: Maybe<Scalars['JSON']>;
    subject?: Maybe<Scalars['String']>;
    triggerName?: Maybe<Scalars['String']>;
};

export type PageMeta = {
    __typename?: 'PageMeta';
    page?: Maybe<Scalars['Int']>;
    total?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
};

export type Payment = {
    __typename?: 'Payment';
    id?: Maybe<Scalars['ID']>;
    charge?: Maybe<Scalars['JSON']>;
};

export type Query = {
    __typename?: 'Query';
    clicks?: Maybe<Array<Maybe<Click>>>;
    conversation: Conversation;
    conversations: Array<Conversation>;
    enrollments: Array<Enrollment>;
    enrollment: Enrollment;
    events: EventList;
    event: Event;
    unreadMessages?: Maybe<Array<Maybe<Message>>>;
    recentMessages?: Maybe<Array<Maybe<Message>>>;
    messages?: Maybe<Array<Maybe<Message>>>;
    notifications?: Maybe<Array<Maybe<Notification>>>;
    unreadNotifications?: Maybe<Array<Maybe<Notification>>>;
    stripeAccounts?: Maybe<Scalars['JSON']>;
    receipts?: Maybe<Event>;
    invoices?: Maybe<Array<Maybe<Event>>>;
    reviews?: Maybe<Array<Maybe<Review>>>;
    school?: Maybe<School>;
    schools?: Maybe<Array<Maybe<School>>>;
    skills?: Maybe<Array<Maybe<Skill>>>;
    skill?: Maybe<Skill>;
    student?: Maybe<Student>;
    students?: Maybe<Array<Maybe<Student>>>;
    me?: Maybe<User>;
    getCurrentUser?: Maybe<User>;
    users?: Maybe<UserList>;
    user?: Maybe<User>;
    tutor?: Maybe<User>;
    tutors?: Maybe<UserList>;
    emails: Array<Scalars['String']>;
    searchTutor?: Maybe<Array<Maybe<User>>>;
};


export type QueryConversationArgs = {
    id: Scalars['ID'];
};


export type QueryEnrollmentArgs = {
    id: Scalars['ID'];
};


export type QueryEventsArgs = {
    sort?: Maybe<Scalars['JSON']>;
    skip?: Maybe<Scalars['Int']>;
    take?: Maybe<Scalars['Int']>;
};


export type QueryEventArgs = {
    id: Scalars['ID'];
};


export type QueryMessagesArgs = {
    conversation: Scalars['ID'];
};


export type QueryStripeAccountsArgs = {
    orphaned?: Maybe<Scalars['Boolean']>;
};


export type QuerySchoolArgs = {
    slug: Scalars['String'];
};


export type QuerySchoolsArgs = {
    logo?: Maybe<Scalars['Boolean']>;
};


export type QuerySkillArgs = {
    id: Scalars['ID'];
};


export type QueryStudentArgs = {
    id: Scalars['ID'];
};


export type QueryStudentsArgs = {
    school?: Maybe<Scalars['Int']>;
};


export type QueryUsersArgs = {
    sort?: Maybe<Scalars['JSON']>;
    skip?: Maybe<Scalars['Int']>;
    take?: Maybe<Scalars['Int']>;
    filters?: Maybe<Scalars['JSON']>;
};


export type QueryUserArgs = {
    id: Scalars['ID'];
};


export type QueryTutorArgs = {
    id: Scalars['ID'];
};


export type QueryTutorsArgs = {
    skills?: Maybe<Array<Scalars['ID']>>;
    location?: Maybe<Scalars['JSON']>;
    page?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    demo?: Maybe<Scalars['Boolean']>;
};


export type QuerySearchTutorArgs = {
    name: Scalars['String'];
};

export type Read = {
    __typename?: 'Read';
    id?: Maybe<Scalars['ID']>;
};

export type Result = {
    __typename?: 'Result';
    success: Scalars['Boolean'];
    message?: Maybe<Scalars['String']>;
};

export type Review = {
    __typename?: 'Review';
    id?: Maybe<Scalars['ID']>;
    rating?: Maybe<Scalars['Float']>;
    reviewee?: Maybe<User>;
    reviewer?: Maybe<User>;
    content?: Maybe<Scalars['String']>;
    isApproved?: Maybe<Scalars['Boolean']>;
};

export type Room = {
    __typename?: 'Room';
    id: Scalars['ID'];
    token: Scalars['String'];
};

export type School = {
    __typename?: 'School';
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    slug?: Maybe<Scalars['String']>;
    logo?: Maybe<File>;
    events?: Maybe<Array<Maybe<Event>>>;
    createdAt?: Maybe<Scalars['String']>;
    updatedAt?: Maybe<Scalars['String']>;
};

export type Session = {
    __typename?: 'Session';
    user?: Maybe<User>;
    token?: Maybe<Scalars['String']>;
};

export type Skill = {
    __typename?: 'Skill';
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    parent?: Maybe<Skill>;
    children?: Maybe<Array<Maybe<Skill>>>;
};

export type SkillInput = {
    id?: Maybe<Scalars['ID']>;
};

export type Student = {
    __typename?: 'Student';
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    school?: Maybe<School>;
};

export type StudentItem = {
    name: Scalars['String'];
    email: Scalars['String'];
    school: Scalars['ID'];
};

export type Subscription = {
    __typename?: 'Subscription';
    messageSent?: Maybe<Message>;
    notificationReceived?: Maybe<Message>;
};


export type SubscriptionMessageSentArgs = {
    conversation: Scalars['ID'];
};

export type UpdateEventInput = {
    description?: Maybe<Scalars['String']>;
    startDate?: Maybe<Scalars['String']>;
    endDate?: Maybe<Scalars['String']>;
};

export type UpdateSchoolInput = {
    id?: Maybe<Scalars['ID']>;
    logo?: Maybe<Scalars['ID']>;
};


export type User = {

    __typename?: 'User';
    id?: Maybe<Scalars['ID']>;
    avatar?: Maybe<File>;
    name?: Maybe<Scalars['String']>;
    dob?: Maybe<Scalars['String']>;
    rating?: Maybe<Scalars['Float']>;
    email?: Maybe<Scalars['String']>;
    tel?: Maybe<Scalars['String']>;
    role?: Maybe<UserRole>;
    skills?: Maybe<Array<Maybe<Skill>>>;
    country?: Maybe<Scalars['String']>;
    students?: Maybe<Array<Maybe<Student>>>;
    biography?: Maybe<Scalars['String']>;
    experience?: Maybe<Scalars['String']>;
    achievements?: Maybe<Scalars['String']>;
    rate?: Maybe<Scalars['Float']>;
    currency?: Maybe<Scalars['String']>;
    longitude?: Maybe<Scalars['String']>;
    latitude?: Maybe<Scalars['String']>;
    conversations: Array<Conversation>;
    events: Array<Event>;
    unapprovedEvents: Array<Event>;
    approvedEvents: Array<Event>;
    completedEvents: Array<Event>;
    createdAt?: Maybe<Scalars['String']>;
    updatedAt?: Maybe<Scalars['String']>;
    activeAt?: Maybe<Scalars['String']>;
    stripeAccount?: Maybe<Scalars['JSON']>;
    stripeCustomer?: Maybe<Scalars['JSON']>;
    bankAccountNumber?: Maybe<Scalars['String']>;
    bankRoutingNumber?: Maybe<Scalars['String']>;
    googlePlace?: Maybe<Scalars['JSON']>;
    masqueradees: Array<User>;
    verificationDocumentFront?: Maybe<Scalars['String']>;
    verificationDocumentBack?: Maybe<Scalars['String']>;
    school?: Maybe<School>;
    availability?: Maybe<Scalars['String']>;
    shadow?: Maybe<Scalars['Boolean']>;
    masquerade?: Maybe<Scalars['Boolean']>;
    demo?: Maybe<Scalars['Boolean']>;
    stripeAccountId?: Maybe<Scalars['String']>;
    stripeCustomerId?: Maybe<Scalars['String']>;
    ban?: Maybe<Scalars['String']>;
    unreadConversations: Array<Conversation>;
    uploads: Array<File>;
    wwcc?: Maybe<Scalars['Boolean']>;
    qualified?: Maybe<Scalars['Boolean']>;
    distance?: Maybe<Scalars['Float']>;
    feedback?: Maybe<Array<Maybe<Review>>>;

    city?: Maybe<Scalars['String']>;
    gender?: Maybe<Scalars['String']>;
    skypeId?: Maybe<Scalars['String']>;
    languages?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    lessonTitle?: Maybe<Scalars['String']>;
    paymentMethod?: Maybe<Scalars['String']>;
};

export type UserList = {
    __typename?: 'UserList';
    data?: Maybe<Array<Maybe<User>>>;
    meta?: Maybe<PageMeta>;
};

export enum UserRole {
    Tutor = 'TUTOR',
    Admin = 'ADMIN',
    Parent = 'PARENT',
    Student = 'STUDENT',
    Teacher = 'TEACHER',
    Principal = 'PRINCIPAL'
}

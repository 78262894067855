import React from "react";
import {Button} from "antd";
import {Link} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {getApprovedTutorNotes} from "../../pages/common/queries";
import NotesPresent from "./NotesPresent";

export default (props) => {

    const {data: getApprovedTutorNotesData, loading, error} = useQuery(getApprovedTutorNotes, {
        variables: {
            limit: 3
        }
    });

    if (loading || error) {
        return null;
    }

    return <div className={"md:my-6 my-4 px-4"}>
        <div className={"md:grid md:grid-cols-2 gap-4"}>
            <div className="justify-self-start innovation-text-landing text-4xl my-3">Find Notes on Pocketnote</div>
            <Link to={"/notes/buy"} className={"justify-self-end align-center"}>
                <Button type="primary" className={"landing-button landing-button-primary"}>
                    See All
                </Button></Link>
        </div>
        <div className={"md:grid md:grid-cols-3 md:py-10 py-6 place-items-center grid grid-cols-1 gap-4"}>
            {getApprovedTutorNotesData?.approvedTutorNotes?.map((note: any, index: number) => <NotesPresent
                title={note.title}
                description={note.info}
                sellPrice={note.sellPrice}
                name={note.subject?.name}
                semester={note.semester}
                exam={note.exam}
                yearLevel={note.yearLevel}
                numberOfPages={note.numberOfPages}
                id={note.id}
            />)}
        </div>
    </div>
}

import React from "react";

export const wordpressSite = "pocketnoteapp.wordpress.com";

export function homePageBookTutorRedirection(user: any) {
  if (!user) {
    return "/join/parent";
  }

  if (user?.role === "ADMIN") {
    return "/users";
  } else if (user?.role === "TUTOR") {
    return "/settings/account";
  } else if (user?.role === "PARENT") {
    return "/explore/tutors";
  } else {
    return "/explore";
  }
}

export function homePageBecomeTutorRedirection(user: any) {
  if (!user) {
    return "/join/tutor";
  }

  return "/settings";
}

export function homePageSellNotesTutorRedirection(user: any) {
  if (!user) {
    return "/join/tutor";
  }
  if (user?.role === "TUTOR") {
    return "/notes/sell-your-notes"
  }

  return "/join/tutor";
}

export function homePageSignupRedirection(user: any) {
  if (!user) {
    return "/join";
  }

  return "/settings";
}

export function homePageBuyNotesRedirection(user: any) {
  if (!user) {
    return "/notes";
  }

  return "/notes/buy";
}

export function getSubheadingWithLineBreak(subheading: string) {
  return subheading.split("\n").map(h => <p>{h}</p>)
}

export type ButtonProps = {
  text: string;
  to: () => string;
}

export const Books = [
  {
    name: "Mathematics",
    background: "math.svg",
  },
  {
    name: "English",
    background: "english.svg",
  },
  {
    name: "Science",
    background: "science.svg",
  },
  {
    name: "Languages",
    background: "language.svg",
  },
  {
    name: "Humanities & Social Sciences",
    background: "social.svg",
  },
  {
    name: "Music",
    background: "music.svg",
  },
  {
    name: "Arts",
    background: "arts.svg",
  },
  {
    name: "Technologies",
    background: "technologies.svg",
  },
  {
    name: "UCAT, SAT, OTHERS..",
    background: "ucat.svg",
  },
];


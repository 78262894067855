import React from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { updateUser as updateUserMutation } from '../common/mutations';
import {toast} from 'react-toastify';

export default ({ children, injectSaveButton = true, accountDelete, ...props }: any) => {
  const history = useHistory()
  const [updateUser] = useMutation(updateUserMutation);
  const [deleteAccount] = useMutation(gql`
    mutation {
      deleteAccount {
        success
        message
      }
    }
  `)
  const initialValues = {
    ...props.initialValues,
    school: props.initialValues?.school?.id,
  };
  if (initialValues.dob) {
    const [year, month, day] = initialValues.dob
      .split('-')
      .map((v: any) => parseInt(v, 10));
    initialValues.dob = { year, month, day };
  }
  return (
    <Formik
        enableReinitialize={true}
      {...{ initialValues}}
      onSubmit={async (values) => {

        const variables = { ...values };

        if (values.dob) {
          let parts = ['year', 'month', 'day']
            .map((k) => values.dob[k])
            .map((v) => parseInt(v, 10));
          if (parts.every((v) => isFinite(v))) {
            variables.dob = parts.join('-');
          } else {
            delete variables.dob;
          }
        }

        if (values.skills) {
          variables.skills = values.skills.map(({ name }: any) => name);
        }
        [
          'avatar',
          'verificationDocumentFront',
          'verificationDocumentBack',
          'verificationAdditionalDocumentFront',
          'verificationAdditionalDocumentBack',
        ].forEach((key) => {
          delete variables[key];
        });
        try {
          accountDelete ? await deleteAccount() : await updateUser({ variables })
          toast.success('Saved');
          if (accountDelete) {
            localStorage.clear();
            history.push('/login');
          }
        } catch (e) {
          toast.error('Error');
        }
      }}
    >
      {(props) => (
        <Form>
          {/* <pre>{JSON.stringify(props.values, null, 2)}</pre> */}
          {children(props)}

        </Form>
      )}
    </Formik>
  );
};

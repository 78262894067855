import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { MasqueradeUUID } from "./pages/Masquerade";
import {setContext} from "@apollo/client/link/context";

export default () => {
  const httpLink = createUploadLink({
    uri: process.env.REACT_APP_API_HTTP_ENDPOINT,
  });

  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem(MasqueradeUUID);
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  return new ApolloClient({
    link: authLink.concat(httpLink as any) as any,
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
        returnPartialData: true,
      },
      query: {
        fetchPolicy: "no-cache",
      },
    },
  });
};

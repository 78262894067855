import React from "react";
import StatsTab from "../StatsTab";
import {getUserSignupStats} from "../../../common/queries";
import {useQuery} from "@apollo/client";
import {
    getCurrentDate,
    getDate10WeeksAgo,
    getDate30DaysAgo,
    DailyDataSet,
    WeeklyDataSet,
    getFirstDayOf12MonthAgo,
    getLastDayOfCurrMonth, MonthlyDataSet
} from "../TimeLimits";

export const Signups = () => {

    const {data: dailyDataRes} = useQuery(getUserSignupStats, {
        variables: {
            from: getDate30DaysAgo(),
            to: getCurrentDate()
        }
    });
    let dailyData : any = dailyDataRes?.userSignupStats;

    // console.log("dailyData", dailyData)


    let tutorSignupListDaily = dailyData ? DailyDataSet(dailyData.TUTOR) : []
    // console.log("tutorSignupListDaily", tutorSignupListDaily)

    let parentSignupListDaily = dailyData ? DailyDataSet(dailyData.PARENT) : []
    // console.log("parentSignupListDaily", parentSignupListDaily)

    ///////////////////// Weekly Data

    const {data: weeklyDataRes} = useQuery(getUserSignupStats, {
        variables: {
            from: getDate10WeeksAgo(),
            to: getCurrentDate()
        }
    });
    let weeklyData : any = weeklyDataRes?.userSignupStats;

    // console.log("weeklyData", weeklyData)

    let tutorSignupListWeekly = weeklyData ? WeeklyDataSet(weeklyData.TUTOR) : []
    // console.log("tutorSignupListWeekly", tutorSignupListWeekly)

    let parentSignupListWeekly = weeklyData ? WeeklyDataSet(weeklyData.PARENT) : []
    // console.log("parentSignupListWeekly", parentSignupListWeekly)


    //////////////////// Monthly Data

    const {data: monthlyDataRes} = useQuery(getUserSignupStats, {
        variables: {
            from: getFirstDayOf12MonthAgo(),
            to: getLastDayOfCurrMonth()
        }
    });
    let monthlyData : any = monthlyDataRes?.userSignupStats;

    // console.log("monthlyData", monthlyData)

    let tutorSignupListMonthly = monthlyData ? MonthlyDataSet(monthlyData.TUTOR) : []
    // console.log("tutorSignupListMonthly", tutorSignupListMonthly)

    let parentSignupListMonthly = monthlyData ? MonthlyDataSet(monthlyData.PARENT) : []
    // console.log("parentSignupListMonthly", parentSignupListMonthly)

    return (

        <div>
            {
                dailyData ? (
                    <StatsTab
                        title='Number of Signups'
                        dataset1_title='Tutors'
                        dataset2_title='Parents'
                        dailyDataset1={tutorSignupListDaily}
                        dailyDataset2={parentSignupListDaily}
                        weeklyDataset1={tutorSignupListWeekly}
                        weeklyDataset2={parentSignupListWeekly}
                        monthlyDataset1={tutorSignupListMonthly}
                        monthlyDataset2={parentSignupListMonthly}

                    />
                ) : <></>
            }
        </div>
    )
}
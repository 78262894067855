import gql from "graphql-tag";

const fields = gql`
    fragment ConversationFields on Conversation {
        id
        users {
            id
            name
            avatar {
                id
                url
            }
        }
        messages {
            id
            createdAt
            content
            attachment {
                id
                url
                mimetype
            }
            author {
                id
            }
            event {
                id
                description
                startDate
                endDate
                enrollments {
                    id
                    student {
                        id
                        name
                    }
                }
                creator {
                    id
                    name
                    avatar {
                        id
                        url
                    }
                }
            }

        }
    }
`;
export const CONVERSATION_QUERY = gql`
    ${fields}
    query Conversation($id: ID!) {
        conversation(id: $id) {
            ...ConversationFields
        }
    }
`;

export const CONVERSATIONS_SUB = gql`
    ${fields}
    subscription Conversation($id: ID!) {
        updatedConversation(id: $id) {
            ...ConversationFields
        }
    }
`

import React from "react";
import {NotesLibrary} from "./Desktop";


export default () => {

    return (

        <div>
            <NotesLibrary/>
        </div>
    )
}
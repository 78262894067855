import React from "react";
import ReactDOM from "react-dom";
import {ApolloProvider} from "@apollo/client";

import * as serviceWorker from "./serviceWorker";
import apollo from "./apollo";
import App from "./App";
import {MasqueradeProvider} from "./pages/Masquerade";
import AppLoadingIndicator from "components/AppLoadingIndicater";
import 'react-toastify/dist/ReactToastify.css';

// Styles
import "./index.less";
import {ToastContainer} from "react-toastify";

(window as any).APOLLO = apollo;

ReactDOM.render(
    <AppLoadingIndicator>
        <MasqueradeProvider>
            <ApolloProvider client={apollo as any}>
                <App/>
                <ToastContainer autoClose={4000} hideProgressBar={true}/>
            </ApolloProvider>
        </MasqueradeProvider>
    </AppLoadingIndicator>,
    document.getElementById("root")
);

serviceWorker.unregister();

// serviceWorker.register({
//   onRegister: (registration: ServiceWorkerRegistration) => {
//     // registration.addEventListener('')
//     navigator.serviceWorker.addEventListener('message', function (event) {
//       console.log('Received a message from service worker: ', event.data);
//     });

//     function subscribeUser() {
//       registration.pushManager
//         .subscribe({
//           userVisibleOnly: true,
//           applicationServerKey: urlB64ToUint8Array(
//             'BJWay5QGMoKMWw3stKnklq9itQbdnwmyuDH4_G5lGeF77OrcmNnCVhwmgVpRTDc63t4eBE7ltyj0m8bxT1CtJVs',
//           ),
//         })
//         .then(async (subscription: any) => {
//           const out = await apollo.mutate({
//             mutation: gql`
//               mutation UpdateUser($id: ID!, $subscription: JSON!) {
//                 updateUser(id: $id, subscription: $subscription) {
//                   id
//                 }
//               }
//             `,
//             variables: {
//               id: '6b526b88-22e4-43d8-bb03-d2cd9754d5f2',
//               subscription: subscription.toJSON(),
//             },
//           });
//           // console.log(out);
//           // console.log(subscription.toJSON());
//           // console.log('User is subscribed.');
//           // updateSubscriptionOnServer(subscription);
//           // isSubscribed = true;
//           // updateBtn();
//         })
//         .catch(console.error);
//     }

//     registration.pushManager
//       .getSubscription()
//       .then(async (subscription: any) => {
//         await subscription?.unsubscribe();

//         subscribeUser();

//         // console.log(subscription);
//         // isSubscribed = !(subscription === null);

//         // updateSubscriptionOnServer(subscription);

//         // if (isSubscribed) {
//         //   console.log('User IS subscribed.');
//         // } else {
//         //   console.log('User is NOT subscribed.');
//         // }

//         // updateBtn();
//       });
//   },
// });

// function urlB64ToUint8Array(base64String: any) {
//   const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
//   const base64 = (base64String + padding)
//     .replace(/\-/g, '+')
//     .replace(/_/g, '/');

//   const rawData = window.atob(base64);
//   const outputArray = new Uint8Array(rawData.length);

//   for (let i = 0; i < rawData.length; ++i) {
//     outputArray[i] = rawData.charCodeAt(i);
//   }
//   return outputArray;
// }

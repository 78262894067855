import React from "react";
import {TopEarnersCard} from "./TopEarnersCard";
import {Tabs} from "antd";
import {useQuery} from "@apollo/client";
import gql from "graphql-tag";
import {Loader} from "../common/Loader";


export const TopEarners = (props: any) => {

    const {TabPane} = Tabs;

    const {data, loading} = useQuery(
        gql`
            query getTopEarningTutors {
                topEarningTutors {
                    id
                    name
                    rate
                    achievements
                    avatar {
                        url
                    }
                    badges {
                        id
                        badgeName
                        iconName
                    }
                }
            }
        `,
    );


    return (
        <div className={"my-6 px-4"}>
            <div className="innovation-text-landing text-4xl text-center">Featured Tutors</div>
            {loading ? <Loader/> :

                <div className={"featured-tutors-container py-4"}>
                    {data?.topEarningTutors?.map((tutor: any, index: number) =>
                            <TopEarnersCard key={tutor.id} tutor={tutor}/>
                        )
                    }
                </div>

            }
        </div>

    )
}

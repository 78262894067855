import gql from 'graphql-tag';

export const getCurrentUser = gql`
  query getCurrentUser {
    me {
      id
      name
      role
      email
      longitude
      latitude
      avatar {
        id
        url
        filename
      }
      city
      paymentMethod
      stripeCustomer
      country
      skypeId
      googlePlace
      tel
      gender
      languages
      title
      biography
      achievements
      rate
      dob
      bankRoutingNumber
      bankAccountNumber
      lessonTitle
      school{
        id
        name
      }
      skills{
        id
        name
      }
      badges {
        id
        badgeName
        type
        iconName
      }
    }
  }
`;

export const getCurrentUserProfile = gql`
  query getCurrentUserProfile {
    me {
      id
      name
      dob
      role
      email
      tel
      country
      biography
      achievements
      experience
      availability
      rate
      currency
      paymentMethod
      stripeCustomer
      school {
        id
      }
      skills {
        id
        name
      }
      students {
        id
        name
        email
        school {
          id
          name
        }
      }
      avatar {
        id
        url
        filename
      }
      googlePlace
      bankRoutingNumber
      bankAccountNumber
      verificationDocumentFront
      verificationDocumentBack
      # verificationAdditionalDocumentFront
      # verificationAdditionalDocumentBack
    }
  }
`;

export const getAdminUserProfile = gql`
  query getAdminUserProfile($id: ID!) {
    user(id: $id) {
      id
      name
      role
      email
      city
      state
      country
      biography
      achievements
      experience
      addressLine1
      addressLine2
      rate
      currency
      school {
        id
      }
      skills {
        id
        name
      }
      avatar {
        id
        url
      }
    }
  }
`;

export const getUsers = gql`
  query Users {
    users {
      id
      name
      role
      email
    }
  }
`;

export const getContacts = gql`
  query Contacts {
    contacts {
      id
      name
      role
      email
    }
  }
`;

export const getEvents = gql`
  query Events {
    events {
      id
      description
      owner {
        id
        name
      }
      enrollments {
        id
      }
    }
  }
`;

export const getReviews = gql`
  query Reviews {
    reviews {
      id
      rating
      content
      isApproved
      reviewee {
        name
      }
      reviewer {
        name
      }
    }
  }
`;

export const getSkills = gql`
  query getSkills {
    skills {
      id
      name
      parent {
        id
      }
      children {
        id
      }
    }
  }
`;

export const getMessages = gql`
  query Messages($conversation: ID!) {
    me {
      id
    }
    messages(conversation: $conversation) {
      id
      content
      conversation {
        id
      }
      author {
        id
        avatar {
          id
          url
        }
      }
    }
  }
`;

export const getEvent = gql`
  query getEvent($id: ID!) {
    me {
      id
    }
    event(id: $id) {
      id
      name
      rate
      cost
      startDate
      endDate
      description
      conversation {
        id
      }
      owner {
        id
        name
      }
    }
  }
`;

export const getTutors = gql`
  query getTutors(
    $skills: [ID!]
    $location: JSON
    $page: Int
    $pageSize: Int
    $demo: Boolean
    $gender:String
    $lessonTitle:String
    $hourlyRange:[Int]
    $availability:[String]
    $school:String
    $city:String
    $customLocation:loc
    $userId:String
  ) {
    tutors(
      skills: $skills
      location: $location
      page: $page
      pageSize: $pageSize
      demo: $demo
      gender:$gender
      lessonTitle:$lessonTitle
      hourlyRange:$hourlyRange
      availability:$availability
      school:$school
      city:$city
      customLocation:$customLocation
      userId:$userId
    ) {
      data {
        id
        name
        rating
        biography
        lessonTitle
        experience
        title
        rate
        currency
        createdAt
        gender
        city
        avatar {
          id
          url
          thumb
        }
        feedback{
            id
            rating
            content
            
        }
        
        skills {
        id
        name
      }
        distance
      }
      meta {
        page
        total
        pageSize
      }
    }
  }
`;

export const getTutor = gql`
  query getTutor($id: ID!) {
    tutor(id: $id) {
      id
      name
      rating
      biography
      city
      country
      languages
      responseTime
      achievements
      experience
      availability
      rate
      wwcc
      qualified
      currency
      createdAt
      updatedAt
      title
      school {
        id
        name
      }
      skills {
        id
        name
      }
      education {
        id
        instituteName
        startDate
        endDate
        majorSubject
      }
      work {
        id
        title
        description
        startDate
        endDate
      }
      badges {
        id
        badgeName
        iconName
      }
      avatar {
        id
        url
      }
      feedback {
        id
        rating
        content
        title
        reviewer {
          id
          name
        }
      }
    }
  }
`;

export const getMetrics = gql`
  query getMetrics {
    metrics {
      xAxisLabel
      yAxisLabel
      type
      data {
        x
        y
      }
    }
  }
`;

export const getNotifications = gql`
  query getNotifications {
    notifications {
      id
      subject
      payload
      triggerName
    }
  }
`;

export const getRecentMessages = gql`
  query getRecentMessages {
    recentMessages {
      id
      content
      conversation {
        id
      }
    }
  }
`;

export const getUnread = gql`
  query getUnread {
    unreadMessages {
      id
    }
    unreadNotifications {
      id
    }
  }
`;

export const getSubjects = gql`
  query getSubjects {
    skills {
      id
      name
    }
  }
`;

export const getAllTutorNotes = gql`
query getAllTutorNotes{
  allTutorNotes {
    id
    tutor {
      id
      name
    }
    subject{
      id
      name
    }
    semester
    exam
    wordCount
    numberOfPages
    title
    info
    sellPrice
    soldCount
    status
    createdAt
    updatedAt
    notes{
      id
      url
      path
      filename
      mimetype
      encoding
      createdAt
  \t}
    sampleNotes{
      id
      url
      path
      filename
      mimetype
      encoding
      createdAt
  \t}
  }
}
`;

export const getAllUserNotes = gql`
  query userNotes {
    userNotes {
      id
      boughtAt
      payment {
        paid
      }
      tutorNotes {
        id
        subject {
          name
        }
        tutor {
          name
        }
        semester
        exam
        yearLevel
        title
        sellPrice
        notes {
          signedUrl
          url
          privateTempUrl
        }
      }
    }
  }
`

export const getPrivateUrlForUserNotes = gql`
    query privateTempUrl($id: ID!) {
      privateTempUrl(id: $id)
    }
`
export const getCurrentTutorsNotes = gql`
    query getTutorNotesByUserId($tutorId: String!){
      tutorNotesByUserId(tutorId: $tutorId) {
        id
        tutor {
          id
          name
        }
        subject{
          id
          name
        }
        semester
        exam
        wordCount
        numberOfPages
        title
        info
        status
        sellPrice
        soldCount
        createdAt
        updatedAt
        notes{
          id
          url
          path
          filename
          mimetype
          encoding
          createdAt
      \t}
        sampleNotes{
          id
          url
          path
          filename
          mimetype
          encoding
          createdAt
      \t}
      }
    }
`

export const getApprovedTutorNotes = gql`
query getApprovedTutorNotes (
  $subjects: String
  $exam: String
  $semester: String
  $searchString: String
  $sellPrice:[Int]
  $limit: Int

){
  approvedTutorNotes (
    subjects: $subjects
    exam: $exam
    semester: $semester
    sellPrice: $sellPrice
    searchString: $searchString
    limit: $limit
  ) {
    id
    tutor {
      id
      name
    }
    subject{
      id
      name
    }
    semester
    exam
    yearLevel
    wordCount
    numberOfPages
    title
    info
    sellPrice
    soldCount
    status
    createdAt
    updatedAt
    notes{
      id
      url
      path
      filename
      mimetype
      encoding
      createdAt
  \t}
    sampleNotes{
      id
      url
      path
      filename
      mimetype
      encoding
      createdAt
  \t}
  }
}
`;

export const getTutorNotes = gql`
query get($id: ID!){
  tutorNotes(id: $id) {
    id
    tutor {
      id
      name
      school {
        name
      }
    }
    subject{
      id
      name
    }
    semester
    exam
    wordCount
    numberOfPages
    title
    info
    sellPrice
    soldCount
    status
    createdAt
    updatedAt
    notes{
      id
      url
      path
      filename
      mimetype
      encoding
      createdAt
  \t}
    sampleNotes{
      id
      url
      path
      filename
      mimetype
      encoding
      createdAt
  \t}
  }
}
`;

export const getSchools = gql`
  query getSchools {
    schools {
      id
      name
      slug
      logo {
        id
        url
      }
    }
  }
`;

export const getSchool = gql`
  query getSchool($slug: String!) {
    school(slug: $slug) {
      id
      name
      slug
      logo {
        id
        url
      }
      events {
        id
        name
        description
      }
    }
  }
`;

export const getStudents = gql`
  query getStudents($school: ID) {
    students(school: $school) {
      id
      name
      email
      school {
        name
      }
    }

    schools {
      id
      name
    }
  }
`;

export const GqlUserBadge = gql`
  query getUserBadge($id: ID!){
    getUserBadge(id:$id){
      id
      badgeName
      iconName
    }
  }
`

export const getEducationQuery = gql`
      query getEducationByUser($id: ID!) {
        getEducationByUser(id: $id){
            id
            instituteName
            startDate
            endDate
            majorSubject
        }
      }
    `

export const getWorkQuery = gql`
    query getWorkByUser($id:ID!){
        getWorkByUser(id : $id){
            id
            title
            description
            startDate
            endDate
        }
    
    }
`
export const getTutorAvailability = gql`
    query UserAvailability($id: ID!) {
         getUserAvailability(id: $id) {
            availabilities {
              id
              start
              end
              day
            }
            availabilityExclusions {
              id
              start
              end
            }
          }
       }
`

export const TutorAvailabilityForParents = gql`
    query TutorAvailability($tutorId: ID!, $userId: ID!) {
    getTutorAvailability(tutorId: $tutorId, userId: $userId) {
    date
        times{
            start
            end
        }
  }
}
`

export const TutorReviewMutation = gql`
    mutation Review($content: String!, $rating: Int!, $reviewee:ID!, $reviewer:ID!, $title:String!,$eventId:ID!){
       reviewTutor(content:$content, rating:$rating, reviewee:$reviewee, reviewer:$reviewer, title:$title,eventId:$eventId){
        id
        rating
        content
        title
       }
    }
`

export const getUserSignupStats = gql `
  query userSignupStats($from: String!, $to: String!) {
    userSignupStats(from: $from, to: $to) {
      TUTOR {
        date
        count
      },
      PARENT {
        date
        count
      }
    }
  }
`

export const getSessionStats = gql `
    query sessionStats($from: String!, $to: String!) {
        sessionStats(from: $from, to: $to) {
            unapproved {
                date
                count
            },
            approved {
                date
                count
            }
        }
    }
`

export const getHoursStats = gql `
    query hoursStats($from: String!, $to: String!) {
        hoursStats(from: $from, to: $to) {
            unapproved {
                date
                count
            },
            approved {
                date
                count
            }
        }
    }
`

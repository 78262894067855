import React from 'react'
import Desktop from "./Desktop";

const intro = 'The Company provides the Pocketnote digital platform to book Tutoring sessions. This Agreement sets out the terms and conditions on which Tutors can undertake Sessions through the Pocketnote digital platform.'
const headLine = 'The following terms are used regularly throughout this Agreement and have a particular meaning:'
const tutorAgreement1 = [
    `(a) ABN  means Australian Business Number.`,
    `(b) ACN means Australian Company Number.`,
    `(c) Agreement means this Tutor Agreement. `,
    `(d) Booking means a booking for a Session made via Pocketnote.`,
    `(e) Booking Confirmation means a notification that a Booking has been confirmed.`,
    `(f) Booking Fee means the fee charged to the Tutor by the Company for each Session undertaken by the Tutor, as agreed in writing from time-to-time by the parties.`,
    `(g) Business Day means a day (other than a Saturday, Sunday or public holiday) on which banks are open for general banking business in the Tutor’s Location.`,
    `(h) Company means Pocketnote Pty Ltd ACN 637 568 677.`,
    `(i) Confidential Information means any written or verbal information that:`,
    [
        {
            index: '(i)',
            description: `Is about each party’s business or affairs;`
        },
        {
            index: '(ii)',
            description: `Is about the conduct of each party under this Agreement and the during the term of this Agreement;`
        },
        {
            index: '(iii)',
            description: `A party informs the other party that it considers it confidential and/or proprietary;`
        },
        {
            index: '(iv)',
            description: `A party would reasonably consider to be confidential in the circumstances; and`
        },
        {
            index: '(v)',
            description: `Is personal information within the meaning of the Privacy Act.but does not include information that a party can establish:`
        },
        {
            index: '(vi)',
            description: `Was in the public domain at the time it was given to that party;`
        },
        {
            index: '(vii)',
            description: `Became part of the public domain, without that party’s involvement in any way, after being given to the party;`
        },
        {
            index: '(viii)',
            description: `Was in party's possession when it was given to the party, without having been acquired (directly or indirectly) from the disclosing party; or`
        },
        {
            index: '(ix)',
            description: `Was received from another person who had the unrestricted legal right to disclose that information free from any confidentiality obligation.`
        }
    ],
    `(j) Corporations Act means the Corporations Act 2001 (Cth).`,
    `(k) Equipment & Materials means any equipment and materials (such as books, videos or other learning materials etc.) necessary for a Session.`,
    `(l) GST has the meaning given by the A New Tax System (Goods and Services Tax) Act 1999 (Cth).`,
    `(m) Invoice means a Tax Invoice from the Company to the Student for a Session.`,
    `(n) Notes means the study notes created by the Tutor and uploaded onto Pocketnote.`,
    `(o) Pocketnote means:`,
    [
        {
            index: '(i)',
            description: `Pocketnote digital platform accessible from www.pocketnote.com.au and/or`
        },
        {
            index: '(ii)',
            description: `Any Pocketnote mobile application.`
        }
    ],
    `(p) Privacy Act means the Privacy Act 1989 (Cth).`,
    `(q) Privacy Policy means the Company’s privacy policy as updated from time-to-time, which can be found at: www.pocketnote.com.au/privacy-policy.`,
    `(r) Sale means a sale for Notes via Pocketnote as between the Tutor and a Student.`,
    `(s) Sale Commission means the commission charged by the Company on each Sale, as notified to the Tutor in writing from time-to-time.`,
    `(t) Sale Price means the price payable by Students to purchase the Notes, as set by the Tutor in their discretion.`,
    `(u) School means educational institution.`,
    `(v) Session means a Tutorial or coaching session provided by a Tutor to a Student, as arranged via Pocketnote.`,
    `(w) Session Fee means the price paid by the Student for a Session.`,
    `(x) Student means a registered user of Pocketnote that books a Session.`,
    `(y) Recipient Created Tax Invoice has the same meaning as in A New Tax System (Goods and Services Tax) Act 1999 (Cth).`,
    `(z) Tax Invoice has the meaning given by the A New Tax System (Goods and Services Tax) Act 1999 (Cth).`,
    `(aa) Terms of Service means the terms and conditions of using Pocketnote, as updated from time-to-time, which can be found at www.pocketnote.com.au/terms-of-service .`,
    `(bb) Tutor means the party to this Agreement identified as the “Tutor”.`,
    `(cc) Tutor Profile means the profile of a Tutor within Pocketnote.`,

]
const agreement = [
    [
        {
            index: '1.1',
            description: `The Tutor may offer Sessions via Pocketnote, which Students can accept by making a Booking on the terms set out in this Agreement.`
        },
        {
            index: '1.2',
            description: `The Tutor must ensure that its availability to provide Sessions remains accurate and up-to-date within Pocketnote.`
        },
        {
            index: '1.3',
            description: `Once a Tutor confirms a Booking, by accepting the Booking, the Tutor must provide the services booked by the School.`
        },
        {
            index: '1.4',
            description: `Where the Student books a Session, the Tutor agrees that:`,
        },
        {
            index: '(a)',
            description: `The Company is authorised to charge and collect the Session Fee from Student, on behalf of the Tutor, once the booking is completed; and`,
        },
        {
            index: '(b)',
            description: `The Tutor will pay to the Company (by way of deduction from the Session Fee) the Booking Fee for each Session it undertakes.`,
        },
        {
            index: '1.5',
            description: `As a material condition of using Pocketnote and receiving bookings Sessions, the Tutor must also accept the Terms of Service and Privacy Policy (done so when registering for, and/or signing into, Pocketnote).`
        }
    ]
]

const approvalToProvideSessions = [
    [{
        index: `2.1`,
        description: `Prior to being permitted to offer Sessions via Pocketnote, the Tutor must be approved by the Company to do so.`
    },
        {
            index: `2.2`,
            description: `The Tutor must make an application to the Company and provide the Company with evidence of all of the following to be eligible for approval, including:`
        },
        {
            index: `(a)`,
            description: `A Working with Children’s Check (Australian Tutors only);`
        },
        {
            index: `(b)`,
            description: `A Copy of the Tutor’s Qualification;`
        },
        {
            index: `(c)`,
            description: `Police Check, or other verification of the Tutor’s criminal record;`
        },
        {
            index: `(d)`,
            description: `Bank account details, or such other account details required by the Company in order to make payments to the Tutor; and`
        },
        {
            index: `(e)`,
            description: `Any other information the Company may reasonably require in order to approve the Tutor to provide Sessions.`
        },
        {
            index: `2.3`,
            description: `If the Tutor is tax resident in Australia, in order to receive payments from the Company the Tutor must provide the Company with its own ABN.`
        },
        {
            index: `2.4`,
            description: `The Company will assess all evidence of the Tutor’s suitability to provide Sessions, and either approve or decline the Tutor’s application at its absolute discretion.`
        },
        {
            index: `2.5`,
            description: `The Tutor must notify the Company if any information listed in clause 2.2 changes, expires or would otherwise affect the appropriateness or eligibility of the Tutor to provide Sessions.`
        },
        {
            index: `2.6`,
            description: `The Company may revoke the Tutor’s authority to provide Sessions at any time, if the Company forms the reasonable opinion that the Tutor is unsuitable to provide Sessions.`
        }]
]

const doingSession = [
    {
        index: `3.1`,
        description: `Account`
    },
    [
        {
            index: `(a)`,
            description: `Once the Company has approved the Tutor’s application to provide Sessions, the Company shall grant the Tutor a licence to set-up and operate a Tutor account within Pocketnote.`
        },
        {
            index: `(b)`,
            description: `This account will be the primary means by which the Tutor will offer Sessions to Students, and it will be where the Tutor can offer Sessions and manage its Profile.`
        },
        {
            index: `(c)`,
            description: `The Tutor must ensure that it keeps its availability for Sessions up to date and accurate on Pocketnote.`
        }
    ],
    {
        index: `3.2`,
        description: `Bookings`
    },
    [
        {
            index: `(a)`,
            description: `The Company will notify the Tutor of a Booking Confirmation by email.`
        },
        {
            index: `(b)`,
            description: `A Booking Confirmation notification will include such details supplied by the School relative to the Session, such as:`
        },
        {
            index: `i.`,
            description: `The location where the Session will take place (if that is an option permitted by the Tutor);`
        },
        {
            index: `ii.`,
            description: `The date and time of the Session; and`
        },
        {
            index: `iii.`,
            description: `The frequency of the Session (if a package of Sessions has been purchased by the Student); and`
        },
        {
            index: `iv.`,
            description: `Any other relevant information.`
        },
        {
            index: `(c)`,
            description: `The Tutor agrees that it will only offer Sessions that it can genuinely provide (namely that it is in the Tutor’s local area and is at a date and time the Tutor is available).`
        },
        {
            index: `(d)`,
            description: `All Bookings will be automatically confirmed by Pocketnote in accordance with the times that the Tutor has indicated it is available within Pocketnote.`
        },
        {
            index: `(e)`,
            description: `The Tutor must ensure that it provides each Session for which it receives a Booking Confirmation.`
        },
        {
            index: `(f)`,
            description: `The Tutor agrees that once it makes a Session time available within Pocketnote, and a Student confirms a Booking for that Session, it creates an independent agreement between the Tutor and Student with respect to the Session in accordance with the Terms of Service.`
        }
    ],
    {
        index: `3.3`,
        description: `Conducting Sessions`
    },
    [
        {
            index: `(a)`,
            description: `The Tutor agrees to undertake the Session once the Company has provided the Tutor with the Booking Confirmation.`
        },
        {
            index: `(b)`,
            description: `The Tutor must attend the location where the Session is to occur on-time (which means 5 minutes before the agreed start time). The Tutor must contact the Student as soon as possible if the Tutor is going to be late.`
        },
        {
            index: `(c)`,
            description: `The Tutor must comply with any dress code required by the Company and/or the School.`
        },
        {
            index: `(d)`,
            description: `The Tutor may only provide Sessions itself, or use subcontractors or employees approved in writing by the Company to conduct any Session.`
        }
    ],
    {
        index: `3.4`,
        description: `Equipment & Materials`
    },
    [
        {
            index: `(a)`,
            description: `Where the Session Fee is inclusive of all Equipment & Materials, the Tutor agrees that it will provide all necessary Equipment & Materials to undertake the Session.`
        },
        {
            index: `(b)`,
            description: `The Company and/or the Student may request particular types of Equipment & Materials to be used by the Tutor.`
        },
        {
            index: `(c)`,
            description: `Where the Session Fees do not include Equipment & Materials, then it is the Student’s responsibility to provide all necessary Equipment & Materials. The Tutor agrees to notify the Student of any Equipment & Materials that are required for each Session.`
        }
    ],
    {
        index: `3.5`,
        description: `Cancellations`
    },
    [
        {
            index: `(a)`,
            description: `In order to cancel a Session, the Tutor must notify the Company no less than 3 days prior to the time the next time the Tutor is scheduled to attend the Session.`
        },
        {
            index: `(b)`,
            description: `The Company may notify the Tutor that it is no longer required to attend a Session at any time, which may be effective immediately. `
        },
        {
            index: `(c)`,
            description: `The Company accepts no responsibility or liability for any Session that is cancelled.`
        },
        {
            index: `(d)`,
            description: `In the event the Company has paid the Tutor the Session Fees for a cancelled Booking, the Tutor must refund those Fees to the Company, for processing the refund to the Student, subject to the refunds policy contained in the Terms of Service.`
        }
    ],
    {
        index: `3.6`,
        description: `Ratings`
    },
    [
        {
            index: `(a)`,
            description: `Pocketnote has a rating system for Tutors.`
        },
        {
            index: `(b)`,
            description: `The Company agrees that where the Tutor believes the Student’s rating is unreasonable, it will facilitate a resolution between the Student in accordance with the Terms of Service.`
        }
    ]
]

const sellingNotes = [

    {
        index: `4.1`,
        description: `The Tutor may upload Notes onto Pocketnote from within their account at any time.`
    },
    {
        index: `4.2`,
        description: `Prior to being published on Pocketnote and being made available for purchase by Students, the Company will undertake an independent review of the Notes to determine that they are appropriate for sale on Pocketnote and in particular compliant with the Company’s guidelines.`
    },
    {
        index: `4.3`,
        description: `The Company’s assessment of Notes shall occur following the Tutor’s upload onto Pocketnote.`
    },
    {
        index: `4.4`,
        description: `As part of the review process, the Company may request further information and the Tutor must comply with such request to have the Notes approved for advertisement on Pocketnote. `
    },
    {
        index: `4.5`,
        description: `Notes shall only be approved where they follow the Company’s rules and guidelines as notified to the Tutor from time-to-time, and in particular must not contain assignment or exams questions or papers, nor the Tutor’s contact details.`
    },
    {
        index: `4.6`,
        description: `The Company shall have the complete discretion to refuse Notes from being uploaded onto Pocketnote for any reason, and such decision shall be final and not the subject of review.`
    },
    {
        index: `4.7`,
        description: `The Tutor acknowledges and accepts that the Company does not otherwise verify the accuracy or completeness of the Notes, nor the Tutor’s intellectual property rights in the Notes as part of its review process.`
    },
    {
        index: `4.8`,
        description: `By uploading Notes onto Pocketnote, the Tutor represents and warrants that:`
    },
    [
        {
            index: `(a)`,
            description: `They are the original author of the Notes;`
        },
        {
            index: `(b)`,
            description: `They have authority to upload the Notes to Pocketnote;`
        },
        {
            index: `(c)`,
            description: `They have authority to sell the Notes to Students; and`
        },
        {
            index: `(d)`,
            description: `They are not infringing the intellectual rights of any third party from uploading the Notes onto Pocketnote and/or selling the Notes to Students.`
        },
    ],

    {
        index: `4.9`,
        description: `The Tutor shall assume all responsibility in respect to the content of Notes, in particular the validity, completeness, and accuracy of the Notes.`
    },
    {
        index: `4.10`,
        description: `The Tutor shall indemnify the Company against any loss from a breach of the representations and warranties made under item 4.7.`
    },
    {
        index: `4.11`,
        description: `To avoid doubt, the Company shall not be responsible or liable in any way for any loss or damage suffered by Students in connection with their use of the Notes, and the Company’s approval of the Notes for advertisement on Pocketnote shall in no way result in the Company approving the content of the Notes nor assuming any responsibility for such.`
    },
    {
        index: `4.12`,
        description: `The ownership, copyright and other relevant intellectual property rights in the Notes shall always remain with the Tutor as the original author, including after the upload onto Pocketnote and/or the sale to Students.`
    },
    {
        index: `4.13`,
        description: `The Company reserves the right to remove the Notes from Pocketnote on immediate notice where it considers the Notes are in breach of this Agreement. The Company shall not be responsible for any loss of profit or opportunity as a result of the removal of the Notes from Pocketnote.`
    },
    {
        index: `4.14`,
        description: `The Tutor may remove Notes from Pocketnote at any time and must do so if the Notes are no longer compliant with this Agreement.`
    },
    {
        index: `4.15`,
        description: `The Tutor accepts that where the Notes are approved and advertised on Pocketnote, they must not advertise or list the Notes for sale, in the same or substantially the same form, via any other means and especially other third party platforms similar to Pocketnote.`
    },
]

const feeAndPayments = [
    {
        index: `5.1`,
        description: `Fees`
    },
    [
        {
            index: `(a)`,
            description: `The Tutor agrees that the Tutor shall set the Session Fees in its absolute discretion.`
        },
        {
            index: `(b)`,
            description: `All Session Fees shall be inclusive of GST for Tutors offering Sessions in Australia.`
        },
        {
            index: `(c)`,
            description: `If the Tutor is based outside or Australia, or otherwise provides Sessions outside of Australia, the Tutor must ensure the pricing of its Session Fees sufficient are sufficient to cover any goods and services tax that applies to the Tutor’s supply of the Session in the Tutor’s jurisdiction. The Tutor shall in this case be responsible for paying all taxes and duties to the relevant authority.`
        },
        {
            index: `(d)`,
            description: `The Tutor acknowledges that it is an independent contractor to the Student in respect of each Session it undertakes, and that the Session Fees are not increased or varied for weekends, public holidays or outside of normal business hours.`
        }
    ],
    {
        index: `5.2`,
        description: `Payments`
    },
    [
        {
            index: `(a)`,
            description: `All payments for a Session must be made via Pocketnote (and in any event, to the Company).`
        },
        {
            index: `(b)`,
            description: `The Company shall deduct the Booking Fee for each Session from the Session Fee. The Booking Fee is GST inclusive.`
        },
        {
            index: `(c)`,
            description: `The Company will deposit the Session Fees (less any Booking Fees) into the Tutor’s nominated bank account within 24 hours after the Student has paid the Session Fee.`
        },
        {
            index: `(d)`,
            description: `At the Company’s discretion (which will be notified to the Tutor in writing):`
        },
        {
            index: `i.`,
            description: `The Tutor must provide the Company with a Tax Invoice (or if the Tutor is not registered for GST, just an invoice) for its Session Fees in the previous week by no later than 5.00pm each Monday; or`
        },
        {
            index: `ii.`,
            description: `The Company will issue the Tutor a Recipient Created Tax Invoice (or if the Tutor is not registered for GST, just a recipient created invoice) for its Session Fees in the payment period.`
        },
        {
            index: `(e)`,
            description: `The Tutor warrants that it will not accept direct payment from the Student. The Tutor agrees that it will not charge (or attempt to charge) the Student for any services.`
        },
        {
            index: `(f)`,
            description: `The Company is under no obligation to pay the Tutor any amount prior to funds being received from a Student.`
        }
    ],
    {
        index: `5.3`,
        description: `Notes & Sales`
    },
    [
        {
            index: `(a)`,
            description: `All Sales shall occur via Pocketnote and must not occur outside Pocketnote directly with Students.`
        },
        {
            index: `(b)`,
            description: `The Sale Price shall be paid in full by the Student at the time of purchase and the funds shall be released to the Tutor immediately, less the Sale Commission.`
        },
        {
            index: `(c)`,
            description: `To avoid doubt, the Sale Commission shall occur on each and every Sale completed via Pocketnote as consideration for the Company facilitating the transaction via Pocketnote.`
        }
    ],
    {
        index: `5.4`,
        description: `Expenses & Taxation`
    },
    [
        {
            index: `(a)`,
            description: `The Tutor is responsible for all expenses incurred in undertaking a Session (including providing all Equipment & Materials, if that is part of the Session) and shall not be entitled to seek reimbursement from the Company unless expressly agreed by the Company in writing. `
        },
        {
            index: `(b)`,
            description: `The Tutor may be required to travel to conduct a Session. The Tutor will not be entitled to reimbursement of such travel expenses or any additional payment for such travel. `
        },
        {
            index: `(c)`,
            description: `The Tutor acknowledges that it is solely responsible for the assessment and payment of its own taxes.`
        }
    ]
]

const directContractingWithStudents = [
    [
        {
            index: `6.1`,
            description: `Without the written consent of the Company, the Tutor shall not directly contract to provide services to a Student outside of Pocketnote (and thereby exclude the Company) within 12 months of the last day that the Tutor attended a Session with the relevant Student.`
        },
        {
            index: `6.2`,
            description: `Where the Tutor contracts directly to provide Sessions to a Student outside Pocketnote, the Tutor agrees that it shall pay the Company the equivalent of the Booking Fee (as if the services been booked through Pocketnote) for the duration of the Tutor’s commercial relationship with that Student, whether or not consent is provided by the Company under clause 6.1.`
        },
        {
            index: `6.3`,
            description: `The Company will issue a Tax Invoice to the Tutor for all such fees payable to the Company under clause 6.2.`
        }
    ]

]

const relationship = [

    [
        {
            index: `7.1`,
            description: `The Tutor agrees and acknowledges that:`
        },
        {
            index: `(a)`,
            description: `The Tutor is not an employee or subcontractor of the Company, and the Tutor does not provide any services to Students on behalf of the Company;`
        },
        {
            index: `(b)`,
            description: `The Company is only the Tutor’s agent for the purpose of arranging Sessions and receiving payments from the Student; `
        },
        {
            index: `(c)`,
            description: `The Tutor is not an employee of the Student; and`
        },
        {
            index: `(d)`,
            description: `The Company is not an agent of the Student.`
        },
        {
            index: `7.2`,
            description: `The Tutor agrees that it is solely responsible for the provision of its services to the Student.`
        },
        {
            index: `7.3`,
            description: `The Tutor warrants that:`
        },
        {
            index: `(a)`,
            description: `All information submitted to the Company, or a Student, is true and accurate to the best of the Tutor’s knowledge, including with regard to the Tutor’s qualifications, experience and work history; and`
        },
        {
            index: `(b)`,
            description: `The Tutor holds all current qualifications and licences it represents as holding and necessary to undertake any Sessions it accepts.`
        },
        {
            index: `7.4`,
            description: `The Company is under no obligation to ensure that a Tutor receives a Session, and makes no warranty or representation that the Tutor will receive a Booking.`
        }
    ]
]

const termination = [
    [
        {
            index: `8.1`,
            description: `Either party may terminate this Agreement by giving the other party no less than 30 days’ written notice.`
        },
        {
            index: `8.2`,
            description: `Despite clause 8.1, either party may terminate this Agreement by written notice if the other party:`
        },
        {
            index: `(a)`,
            description: `Is in breach of this Agreement, and such breach is not remedied within 5 business days’ of receiving written notice of that breach; or`
        },
        {
            index: `(b)`,
            description: `Commits a breach incapable of remed`
        },
        {
            index: `8.3`,
            description: `Termination does not affect any of the rights accrued by a party prior to termination.`
        }
    ]
]

const privacy = [
    [
        {
            index: `9.1`,
            description: `The Company maintains the Privacy Policy in compliance with the provisions of the Privacy Act for personal information that it collects. The Tutor agrees and understands that Students, and the Company, may have obligations under the Privacy Act.`
        },
        {
            index: `9.2`,
            description: `The Tutor agrees to maintain all personal information that comes into their possession or knowledge via Sessions (in particular, the names, addresses and contact details of Students) as Confidential Information under this Agreement.`
        },
        {
            index: `9.3`,
            description: `The Tutor authorises the Company to provide personal information about the Tutor to Students for the purposes of procuring Sessions.`
        }
    ]
]

const confidentiality = [
    [
        {
            index: `10.1`,
            description: `A party shall not:`
        },
        {
            index: `(a)`,
            description: `Use any of the Confidential Information except to the extent necessary to exercise its rights and perform its obligations under this Agreement; or`
        },
        {
            index: `(b)`,
            description: `Disclose any of the Confidential Information except in accordance with clause 10.2.`
        },
        {
            index: `10.2`,
            description: `Subject to clause 10.3, a Party may disclose Confidential Information that a Party is required to disclose:`
        },
        {
            index: `(a)`,
            description: `By law or by order of any court or tribunal of competent jurisdiction; or`
        },
        {
            index: `(b)`,
            description: `By any Government Agency, stock exchange or other regulatory body.`
        },
        {
            index: `10.3`,
            description: `If a party is required to make a disclosure under clause 10.2, that party must:`
        },
        {
            index: `(a)`,
            description: `To the extent possible, notify the other party immediately after it anticipates that it may be required to disclose any of the Confidential Information; and`
        },
        {
            index: `(b)`,
            description: `Only disclose Confidential Information to the extent necessary to comply.`
        },
        {
            index: `10.4`,
            description: `The Tutor and the Company must each assist the other to comply with its obligations under the Privacy Act 1988 (Cth) in relation to Confidential Information.`
        }
    ]
]

const liabilityAndIndemnity = [
    [
        {
            index: `11.1`,
            description: `The Tutor agrees to indemnify the Company for any loss, damage, cost or expense that the Company may suffer or incur as a result of or in connection with:`
        },
        {
            index: `(a)`,
            description: `The Tutor’s conduct during or in relation to a Session; and`
        },
        {
            index: `(b)`,
            description: `The Tutor’s breach of this Agreement and/or the Terms of Service. `
        },
        {
            index: `11.2`,
            description: `In no circumstances will the Company be liable for any direct, incidental, consequential or indirect damages, personal injury, death, damage to property, loss of property, loss or corruption of data, loss of profits, goodwill, bargain or opportunity, loss of anticipated savings or any other similar or analogous loss resulting from the Tutor’s access to, or use of, or inability to use any of the Company’s services and/or Pocketnote, whether based on warranty, contract, tort, negligence, in equity or any other legal theory, and whether or not the Company knew or should have known of the possibility of such damage, loss, personal injury or death, or business interruption of any type, whether in tort, contract or otherwise.`
        },
        {
            index: `11.3`,
            description: `Certain rights and remedies may be available under the Competition and Consumer Act 2010 (Cth) or similar legislation of other States or Territories and may not be permitted to be excluded, restricted or modified.  Apart from those that cannot be excluded, the Company and the Company’s related entities exclude all conditions and warranties that may be implied by law.  To the extent permitted by law, the Company’s liability for breach of any implied warranty or condition that cannot be excluded is restricted, at the Company’s option to:`
        },
        {
            index: `(a)`,
            description: `The re-supply of services or payment of the cost of re-supply of services; or `
        },
        {
            index: `(b)`,
            description: `The replacement or repair of goods or payment of the cost of replacement or repair. `
        }
    ]
]

const disputeResolution = [
    [
        {
            index: `12.1`,
            description: `If any dispute arises between the the parties in connection with this Agreement (Dispute), then either party may notify the other of the Dispute with a notice (Dispute Notice) which:`
        },
        {
            index: `i. `,
            description: `Includes or is accompanied by full and detailed particulars of the Dispute; and`
        },
        {
            index: `ii. `,
            description: `Is delivered within 10 Business Days of the circumstances giving rise to the Dispute first occurring.`
        },
        {
            index: `12.2`,
            description: `Within 10 Business Days after a Dispute Notice is given, a representative (with the authority to resolve the dispute) parties must meet (virtually or otherwise) and seek to resolve the Dispute.`
        },
        {
            index: `12.3`,
            description: `Subject to clause (d), a party must not bring court proceedings in respect of any Dispute unless it first complies with the requirements of the dispute resolution mechanism outlined in this clause.`
        },
        {
            index: `12.4`,
            description: `Nothing in this clause prevents either party from instituting court proceedings to seek urgent injunctive, interlocutory or declaratory relief in respect of a Dispute.`
        },
        {
            index: `12.5`,
            description: `Despite the existence of a Dispute, the parties must continue to perform their respective obligations under this document and any related agreements.`
        }
    ]
]

const electronicCommunication = [
    [
        {
            index: `13.1`,
            description: `The words in this clause that are defined in the Electronic Transactions Act 1999 (Cth) have the same meaning.`
        },
        {
            index: `13.2`,
            description: `The Tutor can direct notices, enquiries, complaints and so forth to the Company as set out in this Agreement.  The Company will notify the Tutor of a change of details from time-to-time.`
        },
        {
            index: `13.3`,
            description: `The Company will send the Tutor notices and other correspondence to the details that the Tutor submits to the Company, or that the Tutor notifies the Company of from time-to-time. It is the Tutor’s responsibility to update its contact details as they change.`
        },
        {
            index: `13.4`,
            description: `A consent, notice or communication under this Agreement is effective if it is sent as an electronic communication unless required to be physically delivered under law.
`
        },
        {
            index: `13.5`,
            description: `Notices must be sent to the parties’ most recent known contact details.`
        },
        {
            index: `13.6`,
            description: `The Tutor may not assign or otherwise create an interest in this Agreement.`
        },
        {
            index: `13.7`,
            description: `The Company may assign or otherwise create an interest in its rights under this Agreement by giving written notice to the Tutor.`
        }
    ]
]

const general = [
    [
        {
            index: `14.1`,
            description: `Special Conditions. The parties may agree to any Special Conditions to this Agreement in writing.`
        },
        {
            index: `14.2`,
            description: `Prevalence. To the extent this Agreement is inconsistent with the Terms of Service, the terms of this Agreement will prevail. To the extent that any Special Conditions are inconsistent with the terms of this agreement, the Special Conditions will prevail.`
        },
        {
            index: `14.3`,
            description: `Disclaimer.  Each party acknowledges that it has not relied on any representation, warranty or statement made by any other party, other than as set out in this Agreement.`
        },
        {
            index: `14.4`,
            description: `No Joint Venture or Partnership.  The relationship of the parties to this Agreement does not form a joint venture or partnership.`
        },
        {
            index: `14.5`,
            description: `Waiver.  No clause of this Agreement will be deemed waived and no breach excused unless such waiver or consent is provided in writing.`
        },
        {
            index: `14.6`,
            description: `Further Assurances.  Each party must do anything necessary (including executing agreements and documents) to give full effect to this Agreement and the transaction facilitated by it.`
        },
        {
            index: `14.7`,
            description: `Governing Law.  This Agreement is governed by the laws of the state of New South Wales, Australia.  Each of the parties hereby submits to the non-exclusive jurisdiction of courts with jurisdiction there.`
        },
        {
            index: `14.8`,
            description: `Liability for Expenses.  Each party must pay its own expenses incurred in negotiating, executing, stamping and registering this Agreement.`
        },
        {
            index: `14.9`,
            description: `Counterparts.  This Agreement may be executed in any number of counterparts.  All counterparts together will be taken to constitute one instrument.`
        },
        {
            index: `14.10`,
            description: `Time.  Time is of the essence in this Agreement.`
        },
        {
            index: `14.11`,
            description: `Severability.  Any clause of this Agreement, which is invalid or unenforceable is ineffective to the extent of the invalidity or unenforceability without affecting the remaining clauses of this Agreement.`
        },
        {
            index: `14.12`,
            description: `Interpretation. The following rules apply unless the context requires otherwise:`
        },
        {
            index: `(a)`,
            description: `Headings are only for convenience and do not affect interpretation.  `
        },
        {
            index: `(b)`,
            description: `The singular includes the plural and the opposite also applies.`
        },
        {
            index: `(c)`,
            description: `If a word or phrase is defined, any other grammatical form of that word or phrase has a corresponding meaning.`
        },
        {
            index: `(d)`,
            description: `A reference to a clause refers to clauses in this Agreement.`
        },
        {
            index: `(e)`,
            description: `A reference to legislation is to that legislation as amended, reenacted or replaced, and includes any subordinate legislation issued under it.`
        },
        {
            index: `(f)`,
            description: `Mentioning anything after includes, including, or similar expressions, does not limit anything else that might be included. `
        },
        {
            index: `(g)`,
            description: `A reference to a party to this Agreement or another agreement or document includes that party’s successors and permitted substitutes and assigns (and, where applicable, the party’s legal personal representatives).`
        },
        {
            index: `(h)`,
            description: `A reference to a person, corporation, trust, partnership, unincorporated body or other entity includes any of them.`
        },
        {
            index: `(i)`,
            description: `A reference to information is to information of any kind in any form or medium, whether formal or informal, written or unwritten, for example, computer software or programs, concepts, data, drawings, ideas, knowledge, procedures, source codes or object codes, technology or trade secrets.`
        }
    ],

]
const main = [
    {
        index: 'AGREEMENT ',
        description: agreement
    },
    {
        index: 'APPROVAL TO PROVIDE SESSIONS',
        description: approvalToProvideSessions
    },
    {
        index: `DOING SESSIONS`,
        description: doingSession
    },
    {
        index: `SELLING NOTES`,
        description: sellingNotes
    },
    {
        index: `FEES AND PAYMENTS`,
        description: feeAndPayments
    },
    {
        index: `DIRECT CONTRACTING WITH STUDENTS`,
        description: directContractingWithStudents
    },
    {
        index: `Relationship`,
        description: relationship
    },
    {
        index: `TERMINATION`,
        description: termination
    },
    {
        index: `PRIVACY`,
        description: privacy
    },
    {
        index: `CONFIDENTIALITY`,
        description: confidentiality
    },
    {
        index: `LIABILITY & INDEMNITY`,
        description: liabilityAndIndemnity
    },
    {
        index: `Dispute Resolution`,
        description: disputeResolution
    },
    {
        index: `ELECTRONIC COMMUNICATION, AMENDMENT AND ASSIGNMENT`,
        description: electronicCommunication
    },
    {
        index: `GENERAL`,
        description: general
    }
]

export default () => {
    return (
        <Desktop
            intro={intro}
            headLine={headLine}
            tutorAgreement1={tutorAgreement1}
            main={main}
        />
    )
}

import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
// import { Link } from 'react-router-dom';
import { Button } from '../../components';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

export default [
  {
    Header: 'Actions',
    accessor: (v: any) => v,
    Cell: ({ cell: { value: conversation } }: any) => {
      const [deleteConversation] = useMutation(gql`
        mutation DeleteConversation($id: ID!) {
          deleteConversation(id: $id)
        }
      `);
      return (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            style={{ margin: 0, padding: '0 2px' }}
            onClick={async () => {
              if (!window.confirm('Really delete event?')) {
                return;
              }
              await deleteConversation({
                variables: { id: conversation.id },
                refetchQueries: ['Conversations'],
              });
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </div>
      );
    },
  },
  {
    Header: 'Users',
    accessor: 'users',
    Cell: ({ cell: { value } }: any) => (
      <div>
        {value.map(({ id, name, email }: any) => (
          <div>
            {name} ({email})
          </div>
        ))}
      </div>
    ),
  },
  {
    Header: 'Created',
    accessor: 'createdAt',
    Cell: ({ cell: { value } }: any) => (
      <div>{moment(value).fromNow(true)}</div>
    ),
  },
];

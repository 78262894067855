import React from "react";
import Switch from "../Switch";
import Mobile from "./Mobile";
import Desktop from "./Desktop";

export default () => {
  return (
    <Switch>{[<Mobile key="mobile" />, <Desktop key="desktop" />]}</Switch>
  );
};

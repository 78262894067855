import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Avatar as AntAvatar, Divider, Rate, Typography} from 'antd';
import styled from '@emotion/styled';
import {getLessonTitleToDisplay} from "../../components/static";
import {hourlyRateCalculator} from '../common/utils'

const {Text, Paragraph} = Typography;

const Avatar = styled(AntAvatar)`
  background: hsl(0, 66%, 66%);
`
export default ({id, name, avatar, distance, rate, biography, lessonTitle, title, rating, feedback}: any) => {
    let formattedRate = rate ? hourlyRateCalculator(rate) : 0;
    return (
        <>
            <Col className="flex ">
                <Row className="avatar-row-desktop">
                    <Link to={`/tutors/${id}`}>
                        <Col span={22} className="flex avatar-div-desktop ">

                            <Row>
                                <Col span={6}>
                                    <Avatar size={85} shape='circle' src={avatar?.url}>
                                        {name.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}
                                    </Avatar>
                                    {distance != null && <div className="distance-div-3">
                                        <img src='/icons/explore/small-location-icon.svg'
                                             className="use-location-icon"/>
                                        <Text className="distance-text"> {Number(distance).toFixed(1)} km</Text>
                                    </div>}
                                </Col>

                                <Col span={18}>
                                    <Row>
                                        <Text
                                            className="tutor-name">{name.charAt(0).toUpperCase() + name.slice(1)}</Text>
                                    </Row>
                                    <Row>
                                        <Paragraph className="tutor-intro-text">
                                            {title}
                                        </Paragraph>
                                    </Row>
                                    <Row>
                                        {biography ? (
                                            <Paragraph className="discriptive-text-desktop">
                                                {biography.substring(0, 192)}<span
                                                style={{color: 'grey',}}><u> read more</u></span> . . .
                                            </Paragraph>
                                        ) : (
                                            <Paragraph className="discriptive-text-desktop">
                                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                                                <span>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                                                    <u>read more</u>
                                            </span> . . .
                                            </Paragraph>
                                        )
                                        }
                                    </Row>
                                </Col>
                            </Row>

                        </Col>
                    </Link>
                    <Col className="vertical-divider">
                        <Divider type="vertical"/>
                    </Col>
                </Row>

                <Col span={8} className="padding-top-33-desktop">
                    <Text className='rate-font'> ${rate}/hour</Text> <span className={"serve-fee-text"}>{' '}+Pocketnote Fee</span>
                    <div className="flex">
                        <Rate className="star-size" disabled value={rating}/>
                        <Text className="total-rating"><span
                            className="star-rating">{rating}.0</span>({feedback?.length})</Text>
                    </div>
                    <div className="flex padding-top-4px">
                        <img src="/icons/explore/laptop-icon.svg" className="laptop-align-3"/>
                        <Text className="offers-font">Offers Sessions: <span
                            className="online-font">{getLessonTitleToDisplay(lessonTitle)}</span></Text>
                    </div>
                    <Row className="row-height"/>
                    <Row className="row-height"/>
                </Col>


            </Col>
            <Divider type="horizontal"/>
        </>
    );
}

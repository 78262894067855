import React from "react";
import {Col, Divider, Row, Tabs} from "antd";
import {SessionCards} from "./SessionCards";
import {BarChart} from "./BarChart";
import {LineChart} from "./LineChart";
import Title from "antd/es/typography/Title";
import {Sessions} from "./Sessions/Sessions";
import {Hours} from "./Hours/Hours";
import {Signups} from "./Signups/Signups";



const {TabPane} = Tabs;


export default () => {

    let sessionData = [

        {title: "Daily", approved: 5, unapproved: 3},
        {title: "Weekly", approved: 10, unapproved: 6},
        {title: "Monthly", approved: 15, unapproved: 9},

    ]

    let hrsData = [

        {title: "Daily", approved: 2, unapproved: 1},
        {title: "Weekly", approved: 4, unapproved: 2},
        {title: "Monthly", approved: 6, unapproved: 3},

    ]


    return (
        <>
            <Row >
                <Col xs={1} sm={1} md={1} lg={0} xxl={0} xl={0}/>
                <Col style={{padding: "5%"}} id="AccountDesktop" xs={23} sm={23} md={23} lg={22} xxl={22} xl={22}>

                    <Row>

                        <Col span={15}>
                            <Title className="account-setting-title" style={{paddingBottom: "5%"}}>Sessions</Title>
                        </Col>

                        <Col span={24}>
                            <Sessions/>
                        </Col>
                    </Row>


                    <Divider />

                    <Row>
                        <Col span={15}>
                            <Title className="account-setting-title" style={{paddingBottom: "5%"}}>Hours</Title>
                        </Col>

                        <Col span={24}>
                            <Hours/>
                        </Col>
                    </Row>


                    <Divider />

                    <Row>

                        <Col span={15}>
                            <Title className="account-setting-title" style={{paddingBottom: "5%"}}>Signups</Title>
                        </Col>

                        <Col span={24}>
                            <Signups/>
                        </Col>
                    </Row>

                </Col>
            </Row>
        </>
    )
}
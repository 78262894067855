import React, {useState, useEffect} from "react";
import {
    Row,
    Col,
    Collapse, Button, message,
} from "antd";
import {
    MinusCircleOutlined,
    PlusCircleOutlined,
} from "@ant-design/icons";
import "./Desktop.less";
import {useQuery, useMutation} from "@apollo/client";
import WorkForm from "./WorkForm";
import moment from "moment";
import {addWorkMutation, getWorkQuery, deleteWorkMutation, updateWorkMutation} from "./query";
import {toast} from "react-toastify";

const {Panel} = Collapse;

export interface WorkInterface {
    id: number
    title: string
    description: string
    startDate: number
    endDate: number
}

export default ({user}) => {
    const [work, setWork] = useState<WorkInterface[]>([])
    const [addWork] = useMutation(addWorkMutation)
    const {data, loading, refetch} = useQuery(getWorkQuery, {
        variables: {id: user?.id}
    })
    const [deleteWork] = useMutation(deleteWorkMutation)
    const [updateWork] = useMutation(updateWorkMutation)

    useEffect(() => {
        let temp = []
        if (loading === false && data?.getWorkByUser) {
            data?.getWorkByUser.map(workItem => {
                temp.push({
                    id: workItem.id,
                    title: workItem.title,
                    description: workItem.description,
                    startDate: workItem.startDate,
                    endDate: workItem.endDate
                })
            })
            setWork(temp)
        }

    }, [data, loading])

    const addWorkRow = () => {
        const temp: WorkInterface = {
            id: work.length ? work[work.length - 1].id + 1 : 0,
            title: '',
            description: '',
            startDate: moment().unix(),
            endDate: moment().unix()
        }

        let newWork: WorkInterface[] = [...work]
        newWork.push(temp)
        setWork(newWork)
    }
    const saveWork = () => {

        work.map(async workItem => {
            let flag = true
            data?.getWorkByUser.map(dataItem => {
                if (dataItem.id === workItem.id) flag = false
            })
            //new entries that needs to insert into database
            if (flag) {

                flag = false
                try {
                    await addWork({
                        variables: {
                            title: workItem.title,
                            description: workItem.description,
                            startDate: workItem.startDate,
                            endDate: workItem.endDate,
                            id: user?.id
                        }
                    })
                    toast.success('Saved');

                } catch (error) {
                    message.error(error.message);
                    toast.error('Error');
                }
            }
            //in case of old entries that needs to be updated ..
            else {
                try {
                    await updateWork({
                        variables: {
                            title: workItem.title,
                            description: workItem.description,
                            startDate: workItem.startDate,
                            endDate: workItem.endDate,
                            id: workItem.id
                        }
                    })


                } catch (error) {
                    message.error(error.message)
                    toast.error('Error while updating,');

                }

            }
        })
        refetch()
    }

    const deleteWorkRow = async (id) => {
        const find = data?.getWorkByUser.find(workItem => workItem.id === id)
        if (find) {
            try {
                await deleteWork({
                    variables: {
                        id: id
                    }
                })

                toast.success('Successfully deleted.');

            } catch (error) {

                message.error(error.message);
                toast.error('Unable to delete record.');

            } finally {
                refetch()
            }
        } else {
            console.log("this is not from database this entry is on only frontend ..")
            const copyWork = [...work]
            const remWork = copyWork.filter(edu => edu.id !== id)
            setWork(remWork)
        }
    }

    return (
        <>
            <Col xs={23} sm={23} md={23} lg={18} xxl={18} xl={18} id="AccountDesktopCollapse">
                <Collapse
                    defaultActiveKey={["0"]}
                    expandIcon={({isActive}) =>
                        isActive ? (
                            <MinusCircleOutlined className="icon-specs"/>
                        ) : (
                            <PlusCircleOutlined className="icon-specs"/>
                        )
                    }
                    expandIconPosition="right"
                >
                    <Panel header="Work" key="1" className="collapse-background">
                        <Row className="row-height"/>
                        <Row>
                            <Col xs={2} sm={2} md={2} lg={16} xxl={16} xl={16}/>
                            <Col xs={20} sm={20} md={20} lg={4} xxl={4} xl={4} className="textAlign">
                                <Button type="primary" size="large" block onClick={addWorkRow}>
                                      <span className="upload-pic-text">
                                        Add Work +
                                      </span>
                                </Button>
                            </Col>
                        </Row>

                        <Row className="row-height"/>
                        <Row className="flex">

                            <Col span={2}/>
                            <Col span={20}>


                                {work.map(workItem => (
                                    <>
                                        <WorkForm
                                            setWork={setWork}
                                            work={work}
                                            workItem={workItem}
                                            deleteWorkRow={deleteWorkRow}
                                        />
                                        <Row className="row-height"/>
                                    </>
                                ))}

                            </Col>
                            <Col span={2}/>
                        </Row>
                        {work.length ? (
                            <>
                                <Row>
                                    <Col xs={2} sm={2} md={2} lg={16} xxl={16} xl={16}/>
                                    <Col xs={20} sm={20} md={20} lg={4} xxl={4} xl={4} className="textAlign">
                                        <Button onClick={saveWork} type="primary" size="large" block>
                                          <span className="upload-pic-text">
                                            Save Work
                                          </span>
                                        </Button>
                                    </Col>
                                </Row>
                                <Row className="row-height"/>
                            </>
                        ) : null}
                    </Panel>
                </Collapse>
            </Col>
        </>
    )

}
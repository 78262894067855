import React from "react";
import Switch from "../Switch";
import LandingDesktopTemplate from "../Landing/LandingDesktopTemplate";
import LandingMobileTemplate from "../Landing/LandingMobileTemplate";

const heroImage = "icons/english-tutor/main-icon.png";
const innovationText = "Your One-Stop";
const tutoringText = "Destination for English Tutoring in Australia";
const parentText = (
  <>
    Welcome to Pocketnote, your go-to online destination for English tutoring in
    Australia.
    <br /> <br /> We cater to kindergarten, primary school, and high-school students,
    providing customised lessons that cater to each student's unique learning
    needs. We believe that mastering English is essential in today's world,
    helping you excel academically as well as open up new career opportunities.
  </>
);
const bookTutorBtnText = "Book Tutor";
const becomeTutorBtnText = "Become a Tutor";
const buyNotesBtnText = "Buy Notes";
const whyChooseTitle = "Why Choose Pocketnote to Find English Tutors in Australia";
const signUpText =
  "Sign up today for your risk-free trial with Pocketnote, and experience the incredible educational benefits that our convenient tutoring platform has to offer.";
const choosePocketnoteSection = [
  {
    icon: "url(/icons/english-tutor/approved.png)",
    heading: "Verified and NDIS Approved Tutors",
    details:
      "All of our English tutors are verified and qualified, ensuring that you receive the highest quality education from the best in the field. Our platform has a range of NDIS-approved tutors experienced in working with children with specialised learning needs, ensuring that we can cater to all students' unique requirements.",
  },
  {
    icon: "url(/icons/english-tutor/customised-lessons.png)",
    heading: "Customised Lessons",
    details:
      "At Pocketnote, we understand the importance of mastering essay writing in achieving academic success. We offer customised lessons that focus on key areas like writing skills, grammar, and vocabulary. We ensure that students are in the nuances of essay writing, which is a vital component in most academic assessments.",
  },
  {
    icon: "url(/icons/english-tutor/schedule.png)",
    heading: "Schedule lessons at any time and from any location",
    details:
      "Our platform offers the flexibility to schedule sessions at any time and from any location, making it easy for you to fit learning into your busy lifestyle. Online tutoring also means that students can receive help from the comfort of their own home, without having to travel to a physical location.",
  },
  {
    icon: "url(/icons/english-tutor/tailored-experience.png)",
    heading: "Tailored Experience",
    details:
      "Whether the student is a visual or auditory learner, our online tutoring ensures that each student gets the attention and guidance they need to succeed in English. By providing a tailored experience that caters to individual learning styles, Pocketnote ensures that everyone gets the most out of their tutoring sessions.",
  },
  {
    icon: "url(/icons/english-tutor/personalised-interactions.png)",
    heading: "Personalised Interaction with Tutors",
    details: (
      <>
        Online tutoring with Pocketnote has many benefits for English students.
        Our platform offers personalised interaction between the student and
        tutor, resulting in a more customised learning experience.
        <br />
        <br />
        In addition, online tutoring provides a variety of learning styles,
        including text, audio, and video, giving students the flexibility to
        choose the method that suits them best.
      </>
    ),
  },
  {
    icon: "url(/icons/english-tutor/affordable-pricing.png)",
    heading: "Affordable Pricing and Refund Guarantee",
    details:
      "To make the experience even more seamless, we offer affordable pricing options, making our high-quality educational options accessible for all Australians. We stand behind the quality of our services and offer a 100% refund guarantee on the first session, with no questions asked. We are proud of our five-star Google rating, making us the most trusted platform for online tutoring in Australia. ",
  },
];

export default ({ user }: any) => {
  return (
    <>
      <Switch>
        {[
          <LandingMobileTemplate
            key="mobile"
            user={user}
            heroImage={heroImage}
            innovationText={innovationText}
            tutoringText={tutoringText}
            parentText={parentText}
            whyChooseTitle={whyChooseTitle}
            bookTutorBtnText={bookTutorBtnText}
            becomeTutorBtnText={becomeTutorBtnText}
            buyNotesBtnText={buyNotesBtnText}
            signUpText={signUpText}
            choosePocketnoteSection={choosePocketnoteSection}
          />,
          <LandingDesktopTemplate
            key="desktop"
            user={user}
            heroImage={heroImage}
            innovationText={innovationText}
            tutoringText={tutoringText}
            parentText={parentText}
            bookTutorBtnText={bookTutorBtnText}
            becomeTutorBtnText={becomeTutorBtnText}
            signUpText={signUpText}
            whyChooseTitle={whyChooseTitle}
            choosePocketnoteSection={choosePocketnoteSection}
          />,
        ]}
      </Switch>
    </>
  );
};

import React from "react";
import {Card, Row, Col, Typography, Space} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";

import './Mobile.less'

const {Title, Text} = Typography;

export default (props: any) => {

    const {tutorNotesList, setNotesId, showDeleteModal, showModal, updateNotesUrlsList} = props

    const statusColor = (status: string) => {
        let color = (status === 'APPROVED') ? "#62AC61" : "#FF3C3C";

        return (
            <Text style={{color: color}}>
                {status}
            </Text>
        );

    }

    return (
        <div id="MyNotesMobile">

            <Card bordered={true} style={{width: "70%"}}>
                <Title className="title-text">My Notes</Title>
            </Card>

            {
                tutorNotesList?.map(item =>
                    <Card bordered={true} style={{width: "70%"}}>

                        <Row>
                            <Col
                                span={10}
                                className="label-card"
                            >
                                <p>Notes Title</p>
                                <p>Subject</p>
                                <p>Price</p>
                                <p>Semester</p>
                                <p>Exam</p>
                                <p>Status by Pocketnote</p>
                            </Col>

                            <Col span={2}>

                            </Col>


                            <Col span={10}
                                 className="value-card"
                            >
                                <p className="item-text">{item.title}</p>
                                <p className="item-text">{item.subject?.name}</p>
                                <p className="item-text">${item.sellPrice}</p>
                                <p className="item-text">{item.semester}</p>
                                <p className="item-text">{item.exam}</p>
                                <p>{statusColor(item.status)}</p>
                            </Col>

                        </Row>

                        <Row>

                            <Space size="middle">
                                {/*<a>Invite {record.title}</a>*/}
                                {/*<a>Delete</a>*/}

                                <DeleteOutlined onClick={
                                    () => {
                                        setNotesId(item.id)
                                        showDeleteModal()


                                    }} style={{color: "#FF3C3C"}}/>

                                <EditOutlined onClick={() => {
                                }} style={{color: "#1E93FF"}}/>

                                <EyeOutlined onClick={() => {
                                    updateNotesUrlsList(item?.sampleNotes.url, item?.id)
                                    showModal()
                                }}/>
                            </Space>
                        </Row>


                    </Card>
                )
            }
        </div>
    )
}
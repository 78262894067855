import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Typography, Row, Col, Button, Checkbox, Input, Slider, Select, Popover} from 'antd';
import {CaretDownFilled} from '@ant-design/icons';
import './Mobile.less';
import styled from '@emotion/styled';
import {useHistory} from "react-router-dom";
import Notes from './Notes';
import {Pagination} from 'antd';

const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
`;


const {Text} = Typography;


export default ({
                    location,
                    loading,

                    page,
                    setPage,
                    meta,
                    searchFilters,
                    setSearchFilters,


                    approvedNotesList,
                    subjectList,
                    semesterList,
                    examList


                }: any) => {
    const [isOpen, setOpen] = useState(false);
    const [searchString, setSearchString] = useState<string>(null);

    const handleChange = (event: any) => {
        setSearchString(event.target.value)
        event.persist()
    };



    const content = (
        <Row>
            <Col span={14} className="filter-main-box-popover no-margin">
                <div>
                    <div className="notes-price-font-mobile">
                        Price range: <span className="notes-price-font">$10 - $200+</span>
                    </div>
                    <Slider className="slider-line-color-mobile" range defaultValue={[0, 200]}
                            onChange={(value) => {
                                setPage(1)
                                setSearchFilters({...searchFilters, sellPrice: [value[0], value[1]]})
                            }}
                    />
                </div>

            </Col>

            <Col span={24}>
                <Row>

                    <div className="preferences-popover margin-top-8">
                        <div className="prefs-font">Subjects</div>
                        <Select className="border-width heading-text"
                                allowClear
                                showSearch
                                defaultValue="No Preferences"
                                options={subjectList}
                                suffixIcon={<CaretDownFilled/>}
                                onChange={(name, id) => {
                                    setPage(1)
                                    setSearchFilters({...searchFilters, subjects: name})
                                }}
                        />
                    </div>

                    <div className="preferences-popover margin-top-8">
                        <div className="prefs-font">Semester</div>
                        <Select className="border-width heading-text"
                                allowClear
                                showSearch
                                defaultValue=" No Preferences"
                                suffixIcon={<CaretDownFilled/>}
                                options={semesterList}
                                onChange={(value) => {
                                    setPage(1)
                                    setSearchFilters({...searchFilters, semester: value})
                                }}
                        />
                    </div>

                    <div className="preferences-popover margin-top-8">
                        <div className="prefs-font">Exam</div>
                        <Select className="border-width heading-text"
                                allowClear
                                showSearch
                                defaultValue=" No Preferences"
                                suffixIcon={<CaretDownFilled/>}
                                options={examList}
                                onChange={(value) => {
                                    setPage(1)
                                    setSearchFilters({...searchFilters, exam: value})
                                }}
                        />

                    </div>


                </Row>
                <Col span={24} className="padding-top-71">
                    <Button type="primary" onClick={() => {
                        setOpen(false)
                    }} className="btn-custom-popover">Back</Button>
                </Col>
            </Col>
        </Row>

    );

    return (
        <>
            <Row id='BuyNotesMobile'>
                <Col span={24}>
                    <Row className="subject-Row-mobile">

                        <div className="color-mobile">
                            <Row>
                                <Col span={18}>
                                    <Input
                                        prefix={<img src="/icons/explore/search-icon.svg"/>}
                                        className="no-margin mobile-search-bar"
                                        name='name'
                                        placeholder='Search Name'
                                        onChange={handleChange}
                                    />
                                </Col>
                                <Col span={6}>
                                    <Button className="search-btn" onClick={() => {
                                        setSearchFilters({...searchFilters, searchString: searchString})
                                    }
                                    }>
                                        <span className="search-btn-text">Search</span>
                                    </Button>
                                </Col>
                            </Row>
                        </div>

                    </Row>
                    <Col span={24} className="notes-column-mobile">

                        <Row className="location-main-Box-mobile">
                            <Col span={1}/>
                            <Col span={22}>

                                <div className="match-outer-Box-mobile">
                                    <div>
                                        <Popover placement="topLeft" visible={isOpen} onVisibleChange={() => {
                                            setOpen(!isOpen)
                                        }} overlayClassName={"popover-filter"} content={content}
                                                 title="Filters" trigger="click">
                                            <Button type="primary" className="btn-custom"

                                                    icon={<img src="/icons/explore/filter-icon.svg"
                                                               className="align-filters-icon"/>}
                                                    onClick={() => setOpen(!isOpen)}><Text
                                                className="filters-font">Filters</Text></Button>
                                            {isOpen && (
                                                <>
                                                    <ModalContainer>
                                                    </ModalContainer>
                                                </>
                                            )}
                                        </Popover>
                                    </div>

                                </div>
                            </Col>
                        </Row>

                        {/*<Row>*/}
                        {/*    <Col span={24}>*/}
                        {/*        <Pagination size={"small"} current={page} pageSize={15} className={"pagination-styles"}*/}
                        {/*                    total={meta?.total}*/}
                        {/*                    onChange={(page) => {*/}
                        {/*                        setPage(page)*/}
                        {/*                    }}/>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}

                        <Row className="notes-main-container">
                            <Col span={24}>
                                <Notes
                                    {...{location}}
                                    loading={loading}
                                    notesList={approvedNotesList}
                                />
                            </Col>


                        </Row>

                    </Col>
                </Col>

            </Row>
        </>
    )
};

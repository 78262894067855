import React, {useState} from "react";
import {Avatar} from "antd";
import Links from "./Links";
import {Row, Col} from "antd";
import {Link} from "react-router-dom";
import {Menu, Modal, Button, Typography, Dropdown} from "antd";
import {Login} from "../LoginPopup";
import {UserOutlined, LogoutOutlined, SettingOutlined, QuestionOutlined} from "@ant-design/icons";
import "./Desktop.less";

const LeftMenu = (props: any) => (
    <>
        <Link className="main-logo" to="/">
            <div
                style={{
                    backgroundImage: `url(/icons/pocketnote-logo.svg)`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    width: 150,
                    height: 30,
                }}
            ></div>
        </Link>

        <div className="links">
            <Links
                key={1}
                {...props}
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            />
        </div>
    </>
);
const dropdown = (
    < Menu>
        <Menu.Item className="keep-spaced">
            <Button type="link" className="link" href="/settings/account">Account</Button>
            <UserOutlined/>
        </Menu.Item>
        <Menu.Item className="keep-spaced">
            <Button type="link" className="link" href="/settings">Settings</Button>
            <SettingOutlined/>
        </Menu.Item>
        <Menu.Item className="keep-spaced">
            <Button type="link" className="link" href="/help">Help</Button>
            <QuestionOutlined/>
        </Menu.Item>
        <Menu.Item className="keep-spaced">
            <Button type="link" className="link" href="/logout">Logout</Button>
            <LogoutOutlined/>
        </Menu.Item>
    </Menu>
);

const ExpandMenu = ({user}: any) => {
    const [hover, setHover] = useState(false);
    return (
        <Col
            className="align-center extend-menu"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            span={6}
        >
            <div className="margin-top-12">
                <Typography.Text className="header-user">{user?.name}</Typography.Text>
            </div>
            <Link to="/settings/account">
                <Avatar size={50} src={user?.avatar?.url} icon={<UserOutlined/>}/>
            </Link>
            <Dropdown overlay={dropdown} placement="bottomLeft">
                <img src="/icons/dropdown-icon.svg" className="margin-left-10"/>
            </Dropdown>

        </Col>
    );
};

const DesktopNav = ({user, logout, history}: any) => {
    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);

    return (
        <Row className="navbar" align="middle">

            <Col className="align-center left-menu" span={user ? 18 : 8}>
                <LeftMenu user={user}/>
            </Col>

            {!user && (

                <Col span={16}>

                    <Row className="right-menu">
                        <Col span={4}>
                            <Link to="/explore"><span className="menu-link-text">Explore Tutors</span></Link>
                        </Col>
                        <Col span={3}>
                            <Link to="/notes"><span className="menu-link-text">Buy Notes</span></Link>
                        </Col>
                        <Col span={3}>
                            <Link to="/join/tutor"><span className="menu-link-text">Sell Notes</span></Link>
                        </Col>
                        <Col span={3}>
                            <Link to="/blog"><span className="menu-link-text">Blog</span></Link>
                        </Col>
                        <Col span={3}>
                            <Link to="/about"><span className="menu-link-text">About</span></Link>
                        </Col>


                        <Col span={3}>
                            <a
                                href={'mailto:support@pocketnote.com.au'}><span className="menu-link-text">Contact</span></a>
                        </Col>

                        <Col span={5}>
                            <Button
                                className="btn-login"
                                onClick={() => {
                                    setIsLoginModalVisible(true);
                                }}
                            >
                                <span className="login-text">Login</span>
                            </Button>
                        </Col>
                    </Row>

                </Col>


            )}
            {user && <ExpandMenu user={user}/>}

            <Modal
                title="Login"
                visible={isLoginModalVisible}
                footer={null}
                onCancel={() => setIsLoginModalVisible(false)}
            >
                <Login setIsLoginModalVisible={setIsLoginModalVisible}/>
            </Modal>
        </Row>
    );
};

export default DesktopNav;

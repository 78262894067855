import React from "react";
import {
    Row,
    Col,
    Divider,
    Input,
} from "antd";
import {useParams, useHistory} from 'react-router-dom';
import {User} from "@graphql-client/types";
import {sortBy} from 'lodash';
import {useQuery} from '@apollo/client';
import gql from 'graphql-tag';
import "./Desktop.less";
import {getCurrentUser} from "../common/queries";
import ConversationList from './ConversationList';
import Conversation from "./Conversation";
import BottomNavigation from '../../components/BottomNavigation'
import {Loader} from "../common/Loader";

export default () => {
    const ConversationData = useQuery(gql`
  query Conversations {
    me {
      id
      conversations {
        id
        updatedAt
      }
    }
  }
`);
    const conversations = sortBy(ConversationData.data?.me?.conversations || [], 'updatedAt');
    const {id: conversationId}: { id: string } = useParams();
    const history = useHistory();
    const {loading: currentUserLoading, data: user} =
        useQuery<{ me: User }>(getCurrentUser);
    const me = user?.me

    const smChatScreen = conversationId ? 24 : 0
    const smAllConversations = conversationId ? 0 : 23

    if (currentUserLoading) {
        return <Loader/>
    }

    return (
        <Row id="MainRow">
            <Col sm={1} xs={1} md={1} lg={3} xl={3} xxl={3}/>
            <Col sm={23} xs={23} md={23} lg={19} xl={19} xxl={19}>

                <Row className="keep-spaced">
                    <Col xs={smAllConversations} sm={smAllConversations} md={smAllConversations} lg={6} xl={6} xxl={6}
                         id="MessagesDesktop">
                        <Row className="margin-20">
                            <Input
                                disabled
                                placeholder="All Conversations"
                                bordered={false}
                                className="search-bar"
                            />
                        </Row>
                        <Divider className="no-margin" type="horizontal"/>
                        <div>
                            <Col className="conv-overflow hide-scroll ">
                                <ConversationList me={me} activeConversation={conversationId}
                                                  loading={ConversationData.loading}/>

                            </Col>
                        </div>
                    </Col>
                    <Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1}/>
                    <Col xs={smChatScreen} sm={smChatScreen} md={smChatScreen} lg={17} xl={17} xxl={17}>
                        {conversationId ? (
                            <Conversation id={conversationId} {...{history, me}} />
                        ) : conversations?.length ? (
                            <Conversation id={conversations[0]?.id} {...{history, me}} />
                        ) : (
                            <Conversation id={conversationId} {...{history, me}} />
                        )}
                    </Col>


                </Row>
                <Row className="row-height"/>
                <Row className="row-height"/>
            </Col>
            <Col xs={smAllConversations} sm={smAllConversations} md={smAllConversations} lg={0} xl={0} xxl={0}>
                <BottomNavigation role={me?.role}/>
            </Col>


        </Row>
    );
};

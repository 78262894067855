import React, {useState} from 'react';
import {Button, Col, Input, message, Popover, Row, Upload} from 'antd';
import {
    DeleteOutlined,
    MoreOutlined,
    PaperClipOutlined,
    SendOutlined,
    SmileOutlined,
    TeamOutlined,
} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import {isPaymentSetup} from "../common/utils";
import {toast} from "react-toastify";
import EmojiPicker from 'emoji-picker-react';
import {isMobile} from 'react-device-detect';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";

const {TextArea} = Input

export default ({
                    conversation,
                    me,
                    value,
                    setValue,
                    sendMessage,
                    createUpload,
                    loading,
                    tutorId,
                    setShowPaymentModal
                }: any) => {

    const history = useHistory();
    let checkPaymentSetupParent = isPaymentSetup(me);
    const [file, setFile] = useState(null as any)
    const [dataURL, setDataURL] = useState(null as any);
    const [attachment, setAttachment] = useState<any>("")

    const [sendPressed, setSendPressed] = useState<boolean>(false)


    const content = () => {
        return (
            <Col className="margin-5">
                <Row className="keep-spaced">
                    <Col span={2}>
                        <TeamOutlined style={{paddingTop: '7px'}}/>
                    </Col>
                    <Col span={2}/>
                    <Col span={20}>
                        <Button type="link" disabled={!tutorId} className="link" href={`/events/create/${tutorId}`}>Request
                            Session</Button>
                    </Col>
                </Row>
            </Col>
        )
    }

    const emojiView = () => {

        return (
            <EmojiPicker skinTonesDisabled={true} onEmojiClick={onEmojiClick}/>
        )
    }

    const uploadFileToServer = async () => {

        try {

            const {data} = await createUpload({
                variables: {
                    mime: attachment.mime,
                    filename: attachment.filename,
                    uploadType: "CHAT_ATTACHMENT",
                    private: false
                },
            });

            return data;

        } catch (error: any) {
            message.error(error.message)
        }

    }


    const uploadToS3 = async (uploadData: any) => {

        try {

            let s3Response = await fetch(uploadData.signedUrl, {
                method: 'PUT',
                body: file,
                headers: {
                    "Content-Type": attachment.mime
                }
            });

            return s3Response;

        } catch (error: any) {
            message.error(error.message)
        }

    }

    const handleSendMessage = async () => {
        if (sendPressed) {
            return;
        }

        setSendPressed(true)

        if (!checkPaymentSetupParent) {
            toast.info("Please setup a payment method first. You will not be charged until a session is completed.");
            setSendPressed(false)


        } else if (attachment) {

            const data: any = await uploadFileToServer()

            if (data) {
                const upload = data.createUpload;

                const s3Response: any = await uploadToS3(upload);

                if (s3Response.status === 200) {


                    await sendMessage({
                        variables: {
                            user: me.id,
                            content: value ? value : "",
                            conversation: conversation?.id,
                            attachment: upload.id
                        },
                        refetchQueries: ['Conversation'],
                    });

                } else {
                    toast.error('Upload Failed! Please try again');
                }

            }

            setValue('');
            setDataURL(null as any);
            setFile(null as any);
            setAttachment('');
            setSendPressed(false)


        } else {
            try {
                if (value) {
                    await sendMessage({
                        variables: {
                            user: me.id,
                            content: value,
                            conversation: conversation?.id,
                        },
                        refetchQueries: ['Conversation'],
                    });
                    setValue('');
                    setSendPressed(false)
                } else throw new Error('Please enter a message first')
            } catch (error) {
                message.error(error.message)
            }
        }
        setSendPressed(false)

    }

    const uploadFile = (
        <div>
            {dataURL ? (
                <div style={{}}>

                    {
                        file.type?.includes("pdf") ? (
                            <embed src={`${dataURL}#page=1&zoom=20`} width={250} height={220}/>
                        ) : (
                            <img width={250} height={220} src={dataURL}/>
                        )
                    }
                    <DeleteOutlined onClick={() => {
                        setDataURL(null as any);
                        setFile(null as any);
                        setAttachment('');
                    }} style={{color: "#FF3C3C"}}/>
                </div>
            ) : (
                <>
                </>
            )}

        </div>
    );

    const uploadProps = {
        name: 'file',
        multiple: false,
        action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
        showUploadList: false,
        accept: "image/*,.pdf",
        beforeUpload(file) {
            setAttachment({mime: file.type, filename: file.name, uploadType: "CHAT_ATTACHMENT"})
            setDataURL(URL.createObjectURL(file));
            setFile(file)
            return false;
        },
        async handleClick() {
            if (!file) {
                toast.error('Please select a file!');
                return
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const onEmojiClick = (emojiObject: any) => {

        setValue((prevInput: any) => prevInput + emojiObject.emoji);
    };

    return (
        <Col span={22}>
            <Row className="row-height"/>
            <Row>{uploadFile}</Row>
            <Row>
                {/* <Row> */}

                <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <TextArea
                        autoSize={{minRows: 1, maxRows: 4}}
                        placeholder="Write a message..."
                        value={value}
                        onChange={e => {
                            setValue(e.target.value)
                        }}
                        autoFocus={true}
                        onKeyDown={async (e: any) => {
                            console.log("ismobile", isMobile)
                            if (!isMobile) {
                                if (e.key === 'Enter' && e.shiftKey) {
                                    e.value.append("\n")
                                    setValue((prevInput: any) => prevInput + '"\n"')
                                } else if (e.key === 'Enter') handleSendMessage()
                            } else {
                                e.value.append("\n")
                                setValue((prevInput: any) => prevInput + '"\n"')
                            }
                        }}
                        className="write-msg-input"
                    />
                </Col>

                <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}/>

                <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>

                    <Popover
                        placement="top"
                        content={emojiView}
                        trigger="click"
                    >
                        <SmileOutlined/>
                    </Popover>
                </Col>

                <Col xs={1} sm={1} md={1} lg={0} xl={0} xxl={0}/>

                <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
                    <Upload {...uploadProps}>
                        <PaperClipOutlined/>
                    </Upload>
                </Col>

                <Col xs={1} sm={1} md={1} lg={0} xl={0} xxl={0}/>

                <Col xs={0} sm={0} md={0} lg={2} xl={2} xxl={2}>
                    <Button block className="search-btn" disabled={(!attachment && !value) || sendPressed} onClick={
                        handleSendMessage}>
                        <span className="search-btn-text">Send</span>
                    </Button>

                </Col>

                <Col xs={1} sm={1} md={1} lg={0} xl={0} xxl={0}>
                    <div>

                        <SendOutlined
                            disabled={(!attachment && !value) || sendPressed}
                            onClick={handleSendMessage}
                        />
                    </div>

                </Col>

                <Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1}/>

                {!isMobile && <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}
                                   style={{
                                       display: "flex",
                                       justifyContent: "center",
                                       alignItems: "center",
                                       marginBottom: "10px"
                                   }}>
                    <div className="align-dots">
                        <Popover
                            placement="topLeft"
                            content={content}
                            trigger="click"
                        >
                            <Button
                                type="text"
                                icon={<MoreOutlined className="icon-font-size"/>}
                                className="dot-btn search-btn"
                            />
                        </Popover>
                    </div>
                </Col>}

                {me?.role === "PARENT" &&
                    <div style={{fontSize: 12, marginTop: 10, marginRight: 10}}>
                        <FontAwesomeIcon icon={faTriangleExclamation}/>
                        When you have agreed to a time, request a session on the tutor's profile.
                        Payment is processed automatically at the end of each approved session.
                        <p/>
                        <p>
                            <FontAwesomeIcon icon={faTriangleExclamation} style={{marginRight: 2, marginBottom: 1}} className={"fa-light"}/>
                            <b>Payment must be processed through Pocketnote.</b>
                        </p>
                    </div>}

                {me?.role === "TUTOR" &&
                    <div style={{fontSize: 12, marginTop: 10, marginRight: 10}}>
                        When you have agreed to a time, please instruct the parent or student to request a session on
                        your
                        profile.
                        This will appear under Sessions for approval.
                        Payment is processed automatically at the end of each approved session.
                        <p/>
                        <p>
                            <FontAwesomeIcon icon={faTriangleExclamation} style={{marginRight: 2, marginBottom: 1}}  className={"fa-light"}/>
                            <b>Payment must be processed through Pocketnote.</b>
                        </p>
                    </div>}

                <Col span={19}>
                    {!checkPaymentSetupParent &&
                        <p className={'error-text'}>Setup a payment method first before you can send a message or book a
                            session. You will not be charged until a session is completed. <a onClick={() => {

                                setShowPaymentModal(true);

                            }}><u> Click here</u></a> to continue</p>
                    }
                </Col>

            </Row>
            <Row className="row-height"/>
        </Col>

    )
};

import React, {useState} from "react";
import {ColumnsType} from "antd/es/table";
import {Button, Col, Modal, Row, Space, Spin, Tag, Typography} from "antd";
import {Table} from "antd/es";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import {useMutation, useQuery} from "@apollo/client";
import {getCurrentTutorsNotes, getCurrentUserProfile, getPrivateUrlForUserNotes} from "../../common/queries";
import {deleteTutorNotes as deleteTutorNotesMutation} from "../../common/mutations";
import Mobile from "./Mobile";
import TextArea from "antd/es/input/TextArea";
import { Link } from "react-router-dom";
import apollo from "../../../apollo";
import './Desktop.less'

const {Title, Text} = Typography;


interface DataType {
    id: string;
    title: string;
    subject: any;
    sellPrice: number;
    semester: string;
    exam: string;
    publish_date?: string;
    status: string;
    notes: any
    sampleNotes: any
}


const statusColor = (status: string) => {
    let color = (status === 'APPROVED') ? "#62AC61" : "#FF3C3C";

    return (
        <Text style={{color: color}}>
            {status}
        </Text>
    );

}

let notesURLs = []

export const MyNotes = (props: any) => {

    const {user} = props

    const [notesId, setNotesId] = useState<string>('')

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

    const [deleteNotes] = useMutation(deleteTutorNotesMutation);
    const {data, loading, refetch} = useQuery(getCurrentTutorsNotes,
        {variables: {tutorId: user?.id}},
    );


    let tutorNotesList = data?.tutorNotesByUserId
    // console.log("tutorNotesList", refetch)

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const showDeleteModal = () => {
        setIsDeleteModalVisible(true);
    };

    const handleDeleteOk = async () => {

        await deleteNotes({
            variables: {id: notesId},
        });
        setIsDeleteModalVisible(false);
        setNotesId('')
        refetch()
    }


    const handleDeleteCancel = () => {
        setIsDeleteModalVisible(false);
    };

    const updateNotesUrlsList = (sampleUrl, notesId) => {
        notesURLs = []
        notesURLs.push(sampleUrl)
        notesURLs.push(notesId)
    }


    const columns: ColumnsType<DataType> = [
        {
            title: 'Notes Title',
            dataIndex: 'title',
            key: 'title',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Subject',
            dataIndex: 'subject',
            key: 'subject',
            render: (_, {subject}) => (

                <>
                    {
                        subject ?
                            (
                                <Text style={{fontWeight: "bolder"}}>{subject.name}</Text>
                            )
                            :
                            (
                                <></>
                            )
                    }
                </>
            )
        },
        {
            title: 'Price',
            dataIndex: 'sellPrice',
            key: 'sellPrice',
            render: (_, {sellPrice}) => (

                <>
                    {
                        sellPrice ?
                            (
                                <Text style={{fontWeight: "bolder"}}>${sellPrice}</Text>
                            )
                            :
                            (
                                <></>
                            )
                    }
                </>
            )
        },
        {
            title: 'Semester',
            dataIndex: 'semester',
            key: 'semester',
        },
        {
            title: 'Exam',
            dataIndex: 'exam',
            key: 'exam',
        },
        // {
        //     title: 'Published',
        //     dataIndex: 'createdAt',
        //     key: 'createdAt',
        // },
        {
            title: 'Status by Pocketnote',
            key: 'status',
            dataIndex: 'status',
            render: (_, {status}) => (
                <>
                    {
                        statusColor(status)
                    }
                </>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    {/*<a>Invite {record.title}</a>*/}
                    {/*<a>Delete</a>*/}

                    <DeleteOutlined onClick={
                        () => {
                            // console.log("hello I am delete!!!!", record)

                            setNotesId(record.id)
                            showDeleteModal()

                        }} style={{color: "#FF3C3C"}}/>


                    <Link to={{
                        pathname: `/notes/sell-your-notes`,
                        state: { notes: record },
                    }}>
                        <EditOutlined onClick={() => {
                        }} style={{color: "#1E93FF"}}/>
                    </Link>

                    <EyeOutlined onClick={() => {

                        updateNotesUrlsList(record.sampleNotes?.url, record.id)

                        showModal()
                    }}/>
                </Space>
            ),
        },
    ];

    return (

        <>
            <Row>
                <Col xs={1} sm={1} md={1} lg={0} xxl={0} xl={0}/>
                <Col id="MyNotesDesktop" xs={0} sm={0} md={23} lg={22} xxl={22} xl={22}>
                    <Row className="my-notes-notes-align">
                        <Col xs={18} sm={18} md={18} lg={16} xxl={16} xl={16}>
                            <Title className="my-notes-title">My Notes</Title>
                        </Col>

                        <Col xs={0} sm={0} md={0} lg={6} xxl={6} xl={6} style={{paddingBottom: "1%"}}>
                            <Button type="primary" size="large" block ghost>
                              <span>
                                Filter
                              </span>
                            </Button>
                        </Col>

                    </Row>


                    {
                        !loading ?
                            (
                                <Col id="MyNotesDesktop" xs={23} sm={23} md={23} lg={22} xxl={22} xl={22}>
                                    <Table columns={columns} dataSource={tutorNotesList}/>
                                    <Row className="row-height"/>
                                </Col>
                            )
                            :
                            (
                                <Spin size="large"/>
                            )
                    }
                </Col>

                <Modal
                    title="View Notes"
                    visible={isModalVisible}
                    onOk={async () => await handleOk}
                    onCancel={handleCancel}
                    width={700}
                >

                    <Row>
                        <Col span={12} style={{textAlign: "center"}}>
                            <a onClick={() => {
                                window.open(notesURLs[0])
                            }}>Show Sample Notes</a>

                        </Col>

                        <Col span={12} style={{textAlign: "center"}}>
                            <a onClick={async () => {
                                const {data: urlObj} = await apollo.query({
                                    query: getPrivateUrlForUserNotes,
                                    variables: {
                                        id: notesURLs[1]
                                    }
                                });

                                const url = urlObj.privateTempUrl;
                                window.open(url, "_blank");
                            }}>Show Full Notes</a>

                        </Col>
                    </Row>
                </Modal>

                <Modal
                    title="Delete Notes"
                    visible={isDeleteModalVisible}
                    onOk={handleDeleteOk}
                    onCancel={handleDeleteCancel}>

                    <h3>Are you sure you want to delete these notes?</h3>
                </Modal>

                <Col span={2}/>
                <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
                    {
                        !loading ?
                            (
                                <Mobile
                                    tutorNotesList={tutorNotesList}
                                    setNotesId={setNotesId}
                                    showDeleteModal={showDeleteModal}
                                    updateNotesUrlsList={updateNotesUrlsList}
                                    showModal={showModal}
                                />
                            )
                            :
                            (
                                <Spin size="large"/>
                            )
                    }
                </Col>
            </Row>


        </>
    )
}
